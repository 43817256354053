import axios, { tokenAuthorization } from 'src/utils/axios'
import { FILE_UPLOAD } from 'src/services/api'

export async function postFileUpload(body) {
  const token = tokenAuthorization().headers
  const parameters = {
    method: 'POST',
    headers: { 'content-type': 'multipart/form-data', token }
  }
  const response = await axios.post(FILE_UPLOAD, body, parameters)
  const { data } = response?.data
  return data
}
