import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  notificationActivitiesContainer: {
    height: '70px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    border: 'solid 1px ',
    borderColor: theme.palette.primary.main,
    borderRadius: '8px',
    marginBottom: '10px'
  },

  infor: {
    fontSize: theme.typography.pxToRem(14),
    color: '#000000',
    fontWeight: 400,
    fontFamily: 'Roboto',
    letterSpacing: '0.7px',
    margin: '8px 8px 5px 8px',
    lineHeight: theme.typography.pxToRem(16),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: 2,
    boxOrient: 'vertical',
    wordBreak: 'break-all'
  },
  time: {
    fontSize: theme.typography.pxToRem(12),
    color: '#787878',
    fontWeight: 400,
    fontFamily: 'Roboto',
    letterSpacing: '0.7px',
    lineHeight: theme.typography.pxToRem(14),
    margin: '0px 8px 8px 8px'
  },

  boxInfor: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '70px',
    borderRadius: '8px',
    justifyContent: 'space-between'
  },
  boxIcon: {
    width: '35px',
    marginRight: '10px',
    display: 'flex',
    justifyContent: 'center'
  },
  linkNofication: {
    cursor: 'pointer',
    textDecoration: 'none'
  }
}))
