import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  container: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    background: '#fff'
  },
  logotypeContainer: {
    width: '60%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '50%'
    },
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  logoImage: {
    width: '100%',
    objectFit: 'cover',
    margin: '0px',
    height: '100%'
  },
  formContainer: {
    position: 'absolute',
    top: 0,
    width: '100%',
    transition: 'all .3s',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      overflow: 'visible'
    }
  },
  form: {
    width: '280px',
    fontSize: theme.typography.pxToRem(16)
  },
  greeting: {
    fontWeight: 500,
    textAlign: 'center',
    color: '#14142B'
  },
  formDividerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px'
  },
  boxMessage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: '280px'
  },
  formInfor: {
    textAlign: 'center',
    width: '100%',
    fontSize: '17px',
    paddingTop: 20,
    paddingBottom: 15,
    color: '#4E4B66'
  },
  formButtons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '30px',
    columnGap: '15px'
  },
  forgetButton: {
    textTransform: 'none',
    fontWeight: 400
  },
  formInput: {
    marginTop: 10
  },
  boxScreens: {
    width: '40%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: 'calc(100% - 100px)'
    }
  },
  formContainerVisible: {
    right: 0,
    top: 0
  },
  formContainerHidden: {
    right: '-100%'
  },
  inputContainer: {
    position: 'relative',
    width: '100%'
  }
}))
