import { Switch, withStyles } from '@material-ui/core'

export const MuiSwitch = withStyles((theme) => ({
  root: {
    '& 	.MuiSwitch-switchBase': {
      color: '#CFD2D3'
    },
    '& .Mui-checked': {
      color: `${theme.palette.primary.main}`
    },
    '& .MuiSwitch-track': {
      backgroundColor: '#CFD2D3',
      opacity: 0.5
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: `${theme.palette.primary.main}`
    }
  }
}))(Switch)
