import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  containerCalendar: {
    display: 'block',
    position: 'absolute',
    width: 255,
    zIndex: 5,
    right: 0
  },
  containerDateRangePicker: {
    width: '100%',
    display: (props) => `${props?.isOpen ? 'block' : 'none'}  `
  },
  currentPeriod: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16)
  },
  auxBackground: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 4,
    cursor: 'pointer',
    display: (props) => `${props?.isOpen ? 'block' : 'none'}`
  },
  containerInput: {
    position: 'relative',
    backgroundColor: '#fff',
    boxShadow: '0px 4px 10px rgba(0, -1, 0, 0.1)',
    padding: 5,
    borderRadius: 10
  },
  buttonPeriod: {
    '& .MuiButton-label': {
      display: 'flex',
      columnGap: '10px'
    }
  },
  iconButton: {
    padding: 0,
    margin: 0,
    width: 36,
    height: 36,
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  }
}))
