import React from 'react'
import { Tooltip as TooltipBase } from '@material-ui/core'
import useStyles from './styles'

export function Tooltip({ children, backgroundColor, ...props }) {
  var classes = useStyles({ backgroundColor })

  return (
    <TooltipBase classes={{ tooltip: classes.tooltip }} {...props}>
      {children}
    </TooltipBase>
  )
}
