import React, { useState } from "react"
import { messageResponse } from 'src/utils/messageResponse'
import { patchCommentCelebration } from 'src/services/hooks/celebrations/useCelebrations'
import { useAlertContext } from "src/context/AlertContext"
import { Form } from 'react-final-form'
import { Button, Modal } from 'src/components'
import { InputMultiline } from 'src/components/Fields'
import PropTypes from 'prop-types'
import makeStyles from './style'

ModalComment.propTypes = {
  userId: PropTypes.string,
  celebrationId: PropTypes.string,
  isOpenModal: PropTypes.bool,
  onCloseModal: PropTypes.func,
  dataCelebration: PropTypes.array,
  onUpdateDataCelebration: PropTypes.func,
}

function ModalComment({
  userId,
  celebrationId,
  isOpenModal,
  onCloseModal,
  dataCelebration,
  onUpdateDataCelebration
}) {
const classes = makeStyles()
const { onCallAlert } = useAlertContext()
const [isLoading, setIsLoading] = useState(false);

  async function onSubmitComment(comment) {
    try {
      setIsLoading(true)
      const result = await  patchCommentCelebration({
        celebrationId,
        userId,
        comment
      });
      const idCelebration = result?.data?.data?.id;
      const commentsCelebration = result?.data?.data?.comments;
      const updatedDataCelebration = dataCelebration.map(itemData =>
          itemData.id !== idCelebration ?
          itemData : ({
            ...itemData,
            comments: commentsCelebration
          })
      )
      onUpdateDataCelebration(updatedDataCelebration);
      onCallAlert({
        type: 'success',
        message: 'Comentário adicionado!'
      })
      onCloseModal()
    }catch(error) {
      onCallAlert({
        type: 'error',
        message: messageResponse(error?.response)
      })
    }
    finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal isOpen={isOpenModal} title='Adicionar um comentário'>
      <div className={classes.sectionInfo}>
        <div>
          <Form
            onSubmit={(values) =>
              onSubmitComment(values.comment)
            }
            initialValues={{comment: ""}}
            render={({ handleSubmit, form, values }) => (
              <form onSubmit={handleSubmit} className={classes.boxForm}>
                <div>
                  <InputMultiline
                    label="Comentário"
                    name="comment"
                    placeholder="Digite seu comentario"
                    rows={7}
                    multiline={'multiline'}
                    onChange={(e) =>
                      form.change('comment', String(e?.target?.value ?? "").slice(0, 150) )
                    }
                  />
                  <p className={classes.wordCounter}>{values.comment.length}/150</p>
                </div>

                <div className={classes.modalButtons}>
                  <Button
                    disabled={isLoading}
                    label="Cancelar"
                    variant="underline"
                    onClick={onCloseModal}
                  />
                  <Button
                    type="submit"
                    disabled={isLoading || values.comment.length === 0}
                    variant="submit"
                    label="Comentar"
                  />
                </div>
              </form>
            )}
          />
        </div>
      </div>
    </Modal>
  )
}
export default ModalComment
