import React, { useEffect, useState, useCallback } from 'react'
import useAuth from 'src/hooks/useAuth'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import 'moment/locale/pt-br'

import { Form } from 'react-final-form'
import { Autocomplete, Date, Input } from 'src/components/Fields'
import { newUserValidation } from 'src/utils/form/validations'
import { maskCPF } from 'src/utils/regex/masks'
import { enumAssignment, enumOcupation } from 'src/utils/constants'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import classnames from 'classnames'
import { useLoadingContext } from 'src/context/LoadingContext'
import { useAlertContext } from 'src/context/AlertContext'
import { messageResponse } from 'src/utils/messageResponse'
import { getUserById } from 'src/services/hooks/users/useUsers'
import { getOccupationArea } from 'src/services/hooks/occupationArea/useOccupationArea'
import { postUserGroupsSearch } from 'src/services/hooks/userGroup/useUserGroup'
import makeStyles from './style'
import useGlobalUseStyles from 'src/themes/globalUseStyles'
import { Button, HeaderBreadcrumbs, NewBoxWidget, Helmet } from 'src/components'
import { Typography, Grid, Box } from '@material-ui/core'
import {
  PersonOutline as PersonOutlineIcon,
  VpnKeyOutlined as VpnKeyOutlinedIcon
} from '@material-ui/icons'
import ModalPassword from './Partials/ModalPassword'
import PhotoProfile from './Partials/PhotoProfile'
import ModalConfirmChange from './Partials/ModalConfirmChange'

function Profile() {
  const globalClasses = useGlobalUseStyles()
  const classes = makeStyles()

  const { user } = useAuth()

  const currentUserId = user?.id
  const currentCustomerId = user?.customerId

  const { onCloseLoading, onOpenLoading } = useLoadingContext()
  const { notifications, onCallAlert } = useAlertContext()

  const [data, setData] = useState([])
  const [dataGroups, setDataGroups] = useState([])

  const [isOpenModalPassword, setIsOpenModalPassword] = useState(false)
  const [isOpenModalConfirmChange, setIsOpenModalConfirmChange] =
    useState(false)
  const [isActiveBtns, setIsBtns] = useState(true)
  const [isActiveBtnsModeEdit, setIsBtnsModeEdit] = useState(false)
  const [isBtnGroups, setIsBtnGroups] = useState(true)
  const [isBtnGroupsDelete, setIsBtnGroupsDelete] = useState(true)

  const [populateSelectOptions, setPopulateSelectOptions] = useState({
    occupationArea: []
  })

  const controlBtnGroups = isBtnGroups
    ? 'viewBtnGroupsOpen'
    : 'viewBtnGroupsClose'
  const controlBtnGroupsDelete = isBtnGroupsDelete
    ? 'viewBtnGroupsOpenDelete'
    : 'viewBtnGroupsCloseDelete'

  function onHandleEditPassword() {
    // setIsOpenOptionsResertPassWord(!isOpenResertPassword);
    setIsOpenModalPassword(!isOpenModalPassword)
  }
  function onHandleConfirmChange() {
    setIsOpenModalConfirmChange(!isOpenModalConfirmChange)
  }

  const [isEditDetails, setIsEditDetails] = useState(true)
  const isDisableForm = isEditDetails
  const shouldRenderButtonsFunctionalities = !isEditDetails

  function onEditMode() {
    setIsBtns(!isActiveBtns)
    setIsBtnsModeEdit(!isActiveBtnsModeEdit)
    setIsBtnGroups(!isBtnGroups)
    setIsBtnGroupsDelete(!isBtnGroupsDelete)
    setIsEditDetails(!isEditDetails)
  }

  async function onHandleSubmit(values) {
    try {
      onOpenLoading()
    } catch (error) {
      const messageError = messageResponse(error?.response)
      onCallAlert({
        type: 'error',
        message: messageError ?? notifications.global.error
      })
    } finally {
      onCloseLoading()
    }
  }

  const getDataSelectOptions = useCallback(async () => {
    try {
      const resultOccupations = await getOccupationArea()
      const occupations = resultOccupations.map((key) => ({
        value: key.id,
        title: key.name
      }))

      setPopulateSelectOptions({
        occupationArea: occupations
      })
    } catch (e) {
      console.error(e)
    }
  }, [])

  const getUserData = useCallback(async (userId, customerId) => {
    try {
      onOpenLoading()
      const dataProfile = await getUserById(userId)
      setData(dataProfile?.data?.data)
      const payloadGetGroups = {
        customerId: customerId,
        isActive: true
      }
      const usersGroupsSearch = await postUserGroupsSearch(payloadGetGroups)
      const groups = usersGroupsSearch.map(
        (item) =>
          item.members.find(
            (member) => dataProfile?.data?.data?.id === member
          ) && item
      )
      const groupsUser = groups.filter((item) => !!item)
      setDataGroups(groupsUser)
    } catch (error) {
      onCallAlert({
        type: 'error',
        message: messageResponse(error?.response)
      })
    } finally {
      onCloseLoading()
    }
  }, [])

  useEffect(() => {
    if (currentUserId && currentCustomerId) {
      getUserData(currentUserId, currentCustomerId)
      getDataSelectOptions()
    }
  }, [currentUserId, currentCustomerId, getDataSelectOptions, getUserData])

  return (
    <>
      <Helmet title="Meu Perfil" />
      <Box className={globalClasses.breadcrumb}>
        <HeaderBreadcrumbs
          icon={<PersonOutlineIcon />}
          links={[{ name: 'Meu perfil', href: '/profile' }]}
        />
      </Box>
      <ModalPassword
        isOpenModal={isOpenModalPassword}
        onCloseModal={() => setIsOpenModalPassword(false)}
        email={data?.email}
      />
      <ModalConfirmChange
        isOpenModalConfirmChange={isOpenModalConfirmChange}
        onClose={onHandleConfirmChange}
      />
      <Box className={globalClasses.content}>
        <Box className={classes.boxInforUser}>
          <PhotoProfile
            isEdit={shouldRenderButtonsFunctionalities}
            data={data}
          />
          <Form
            onSubmit={onHandleSubmit}
            initialValues={{
              id: data?.id,
              name: data?.name,
              email: data?.email,
              occupation: data?.occupation,
              document: maskCPF(data?.document),
              birthData: data?.birthData,
              admissionDate: data?.admissionDate,
              occupationAreaId: data?.occupationAreaId,
              assignment: data?.assignment,
              isActive: data?.isActive
            }}
            validate={newUserValidation}
            render={({ handleSubmit, form, pristine, values }) => (
              <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
                <NewBoxWidget
                  sx={{
                    maxWidth: '1000px !important',
                    maxHeight: '100% !important',
                    height: 'auto !important'
                  }}
                  title="Detalhes do perfil"
                  subtitle="Mantenha seus dados sempre atualizados:"
                  actions={
                    <Button
                      label="Alterar senha"
                      endIcon={<VpnKeyOutlinedIcon />}
                      onClick={onHandleEditPassword}
                      variant="dark"
                    />
                  }
                  fullBody
                >
                  <form onSubmit={handleSubmit}>
                    <Grid
                      container
                      item
                      style={{
                        alignItems: 'center',
                        direction: 'column'
                      }}
                      spacing={3}
                    >
                      <Grid item md={6} xs={12}>
                        <Input
                          label="Nome*"
                          name="name"
                          disabled={isDisableForm}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Input
                          label="E-mail*"
                          name="email"
                          disabled={isDisableForm}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Autocomplete
                          options={enumOcupation}
                          label="Vinculo de papel*"
                          name="occupation"
                          getOptionLabel={(option) => option.title || ''}
                          disabled={isDisableForm}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Input
                          label="CPF*"
                          name="document"
                          parse={maskCPF}
                          disabled={isDisableForm}
                        />
                      </Grid>
                      <Grid item md={3} xs={6}>
                        <Date
                          label="Data de nascimento*"
                          name="birthData"
                          disabled={isDisableForm}
                          disableFuture
                        />
                      </Grid>
                      <Grid item md={3} xs={6}>
                        <Date
                          label="Data de admissão*"
                          name="admissionDate"
                          disabled={isDisableForm}
                          disableFuture
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Autocomplete
                          options={populateSelectOptions.occupationArea}
                          label="Área de atuação*"
                          name="occupationAreaId"
                          getOptionLabel={(option) => option.title || ''}
                          disabled={isDisableForm}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Autocomplete
                          options={enumAssignment}
                          label="Experiência*"
                          name="assignment"
                          getOptionLabel={(option) => option.title || ''}
                          disabled={isDisableForm}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Typography className={classes.groupsTitle}>
                          Meus grupos:
                        </Typography>
                        <Box
                          display="flex"
                          alignItems="center"
                          className={classes.groups}
                        >
                          {dataGroups?.map((data, index) => (
                            <Box
                              key={index}
                              className={classnames(
                                classes.formItemGroup,
                                classes[controlBtnGroups]
                              )}
                            >
                              <Typography className={classes.formItemText}>
                                {data.name}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      </Grid>
                      {shouldRenderButtonsFunctionalities && (
                        <Grid item md={6} xs={12}>
                          <div className={classes.containerObservation}>
                            <Input
                              label="Observação"
                              name="Observation"
                              placeholder="Digite sua observação"
                              className={classes.formInput8}
                            />
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  </form>
                </NewBoxWidget>
              </MuiPickersUtilsProvider>
            )}
          />
        </Box>
      </Box>
    </>
  )
}

export default Profile
