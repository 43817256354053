import React, { useEffect, useState, useCallback } from 'react'
import { useTheme } from '@material-ui/styles'
import RoleBasedGuard from 'src/guards/RoleBasedGuard'
import { useParams, useNavigate } from 'react-router-dom'
import { ArrowForwardIos, Close } from '@material-ui/icons'
import {
  Button,
  Tooltip,
  HeaderBreadcrumbs,
  Helmet,
  NewBoxWidget,
  Avatar
} from 'src/components'
import clsx from 'clsx'
import KnowledgeNotes from './Partials/knowledge'
import BehaviorNotes from './Partials/behavior'
import PerformanceNotes from './Partials/performance'
import LeadershipNotes from './Partials/leadership'
import { useAlertContext } from 'src/context/AlertContext'
import { useLoadingContext } from 'src/context/LoadingContext'
import { messageResponse } from 'src/utils/messageResponse'
import { Box, Typography, IconButton } from '@material-ui/core'
import {
  postCompetenceScore,
  getCompetenceLastScoreByUserId
} from 'src/services/hooks/competence/useCompetence'
import { getUserById } from 'src/services/hooks/users/useUsers'
import { getImageNameColor } from 'src/utils'
import { enumAssignment } from 'src/@enum/user'
import { enumCategory } from 'src/@enum/metrics'
import { AssessmentOutlined as AssessmentOutlinedIcon } from '@material-ui/icons'
import useGlobalUseStyles from 'src/themes/globalUseStyles'
import makeStyles from './style'
import groupBy from 'lodash/groupBy'

export default function CompetencyGrades() {
  const theme = useTheme()
  const classes = makeStyles()

  const globalClasses = useGlobalUseStyles()
  const navigate = useNavigate()

  const { notifications, onCallAlert } = useAlertContext()
  const { onCloseLoading, onOpenLoading } = useLoadingContext()

  const { id } = useParams()
  const [role] = useState([1, 2, 3, 4])

  const [openPerformance, setOpenPerformance] = useState(false)
  const [openKnowledge, setOpenKnowledge] = useState(false)
  const [openBehavior, setOpenBehavior] = useState(false)
  const [openLeadership, setOpenLeadership] = useState(false)

  function onHandlePerformance() {
    setOpenPerformance(!openPerformance)
    setOpenBehavior(false)
    setOpenKnowledge(false)
    setOpenLeadership(false)
  }
  function onHandleKnowledge() {
    setOpenKnowledge(!openKnowledge)
    setOpenBehavior(false)
    setOpenPerformance(false)
    setOpenLeadership(false)
  }
  function onHandleBehavior() {
    setOpenBehavior(!openBehavior)
    setOpenKnowledge(false)
    setOpenPerformance(false)
    setOpenLeadership(false)
  }
  function onHandleLeadership() {
    setOpenLeadership(!openLeadership)
    setOpenKnowledge(false)
    setOpenBehavior(false)
    setOpenPerformance(false)
  }

  const [dataUser, setDataUser] = useState()

  const [payloadPerformace, setPayloadPerformace] = useState([])
  const [payloadKnowledge, setPayloadKnowledge] = useState([])
  const [payloadBehavior, setPayloadBehavior] = useState([])
  const [payloadLeadership, setPayloadLeadership] = useState([])

  const [performancesValues, setPerformancesValues] = useState([])
  const [knowLedgesValues, setKnownLedgesValues] = useState([])
  const [behaviorsValues, setBehaviorsValues] = useState([])
  const [leadershipValues, setLeadershipValues] = useState([])

  const getDataUser = useCallback(async (id) => {
    try {
      onOpenLoading()
      const { data } = await getUserById(id)
      setDataUser(data?.data)

      const dataLastScore = await getCompetenceLastScoreByUserId(data?.data?.id)
      const dataLastScoreCategory = groupBy(dataLastScore, 'category')
      const dataListObject = Object.entries(dataLastScoreCategory).map(
        (currentData) => {
          const [categoryId, item] = currentData
          return {
            categoryId: categoryId,
            category: enumCategory[categoryId],
            lastCompetences: item
          }
        }
      )
      const dataList = dataListObject.map((item) => item)

      const payloadPerformace = dataList
        .filter((item) => item.categoryId === '1')
        .map((item) => item.lastCompetences)
        .flat()
      setPayloadPerformace(payloadPerformace)

      const payloadKnowledge = dataList
        .filter((item) => item.categoryId === '2')
        .map((item) => item.lastCompetences)
        .flat()
      setPayloadKnowledge(payloadKnowledge)

      const payloadBehavior = dataList
        .filter((item) => item.categoryId === '3')
        .map((item) => item.lastCompetences)
        .flat()
      setPayloadBehavior(payloadBehavior)

      const payloadLeadership = dataList
        .filter((item) => item.categoryId === '4')
        .map((item) => item.lastCompetences)
        .flat()
      setPayloadLeadership(payloadLeadership)
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      onCloseLoading()
    }
  }, [])

  useEffect(() => {
    getDataUser(id)
  }, [getDataUser, id])

  const onFinalize = async () => {
    try {
      onOpenLoading()

      if (performancesValues.length > 0) {
        performancesValues?.map(async (item, index) => {
          let payload = {
            competenceGrouperId: item.competenceGrouper.id,
            category: item.category,
            scores: item.scores,
            customerId: dataUser?.customerId,
            userId: dataUser?.id,
            isActive: true
          }
          await postCompetenceScore(payload)
        })
      }

      if (knowLedgesValues.length > 0) {
        knowLedgesValues?.map(async (item, index) => {
          let payload = {
            competenceGrouperId: item.competenceGrouper.id,
            category: item.category,
            scores: item.scores,
            customerId: dataUser?.customerId,
            userId: dataUser?.id,
            isActive: true
          }
          await postCompetenceScore(payload)
        })
      }

      if (behaviorsValues.length > 0) {
        behaviorsValues?.map(async (item, index) => {
          let payload = {
            competenceGrouperId: item.competenceGrouper.id,
            category: item.category,
            scores: item.scores,
            customerId: dataUser?.customerId,
            userId: dataUser?.id,
            isActive: true
          }
          await postCompetenceScore(payload)
        })
      }

      if (leadershipValues.length > 0) {
        leadershipValues?.map(async (item, index) => {
          let payload = {
            competenceGrouperId: item.competenceGrouper.id,
            category: item.category,
            scores: item.scores,
            customerId: dataUser?.customerId,
            userId: dataUser?.id,
            isActive: true
          }
          await postCompetenceScore(payload)
        })
      }

      onCallAlert({
        type: 'success',
        message: notifications.competencyGrades.success.editCompetencyGrade
      })
      navigate(`/metrics/user/${id}`)
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      onCloseLoading()
    }
  }

  function handleMetricsUser(id) {
    navigate(`/metrics/user/${id}`)
  }

  const disabled =
    performancesValues.length > 0 ||
    knowLedgesValues.length > 0 ||
    behaviorsValues.length > 0 ||
    leadershipValues.length > 0

  return (
    <RoleBasedGuard hasContent roles={role}>
      <Helmet title="Notas de competência" />
      <Box className={globalClasses.breadcrumb}>
        <HeaderBreadcrumbs
          icon={<AssessmentOutlinedIcon />}
          links={[
            {
              name: 'Métricas',
              href: '/metrics'
            },
            { name: dataUser?.name, href: `/metrics/user/${id}` },
            { name: 'Notas de competência' }
          ]}
        />
      </Box>
      <Box className={classes.container}>
        <Box className={classes.boxContent}>
          <NewBoxWidget
            title="Notas de competência"
            options={
              <Box className={classes.options}>
                <Tooltip placement="top" title="Fechar">
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => handleMetricsUser(dataUser.id)}
                  >
                    <Close htmlColor={theme.palette.primary.main} />
                  </IconButton>
                </Tooltip>
              </Box>
            }
            actions={
              <Button
                label="Finalizar"
                onClick={onFinalize}
                disabled={!disabled}
              />
            }
            width={400}
            height={500}
          >
            <Box className={classes.boxInforUser}>
              <Avatar
                src={dataUser?.imageProfile}
                aria-label={dataUser?.name}
                alt={dataUser?.name}
                color={
                  !dataUser?.imageProfile &&
                  getImageNameColor(dataUser?.name).color
                }
              >
                {getImageNameColor(dataUser?.name).name}
              </Avatar>
              <span>
                <strong>{dataUser?.name}</strong>
                <Typography className={classes.date}>
                  {dataUser?.occupationArea} -{' '}
                  {enumAssignment[dataUser?.assignment]}
                </Typography>
              </span>
            </Box>
            <div className={classes.optionsList}>
              <button
                className={clsx(
                  classes.option,
                  openPerformance ? classes.optionSelected : ''
                )}
                onClick={() => {
                  onHandlePerformance()
                }}
              >
                <p
                  className={clsx(
                    openPerformance
                      ? classes.optionTextSelected
                      : classes.optionText
                  )}
                >
                  Performance
                </p>
                {openPerformance ? (
                  <Close style={{ color: '#fff' }} />
                ) : (
                  <ArrowForwardIos style={{ color: '#787878' }} />
                )}
              </button>
              {dataUser?.occupation !== 3 && (
                <button
                  className={clsx(
                    classes.option,
                    openKnowledge ? classes.optionSelected : ''
                  )}
                  onClick={() => {
                    onHandleKnowledge()
                  }}
                >
                  <p
                    className={
                      openKnowledge
                        ? classes.optionTextSelected
                        : classes.optionText
                    }
                  >
                    Conhecimento
                  </p>
                  {openKnowledge ? (
                    <Close style={{ color: '#fff' }} />
                  ) : (
                    <ArrowForwardIos style={{ color: '#787878' }} />
                  )}
                </button>
              )}
              <button
                className={clsx(
                  classes.option,
                  openBehavior ? classes.optionSelected : ''
                )}
                onClick={() => {
                  onHandleBehavior()
                }}
              >
                <p
                  className={clsx(
                    openBehavior
                      ? classes.optionTextSelected
                      : classes.optionText
                  )}
                >
                  Comportamento
                </p>
                {openBehavior ? (
                  <Close style={{ color: '#fff' }} />
                ) : (
                  <ArrowForwardIos style={{ color: '#787878' }} />
                )}
              </button>
              {dataUser?.occupation !== 4 && (
                <button
                  className={clsx(
                    classes.option,
                    openLeadership ? classes.optionSelected : ''
                  )}
                  onClick={() => {
                    onHandleLeadership()
                  }}
                >
                  <p
                    className={clsx(
                      openLeadership
                        ? classes.optionTextSelected
                        : classes.optionText
                    )}
                  >
                    Liderança
                  </p>
                  {openLeadership ? (
                    <Close style={{ color: '#fff' }} />
                  ) : (
                    <ArrowForwardIos style={{ color: '#787878' }} />
                  )}
                </button>
              )}
            </div>
          </NewBoxWidget>
          {openPerformance && (
            <PerformanceNotes
              onClose={onHandlePerformance}
              scores={payloadPerformace}
              competencyValues={performancesValues}
              setCompetencyValues={setPerformancesValues}
            />
          )}
          {openKnowledge && (
            <KnowledgeNotes
              onClose={onHandleKnowledge}
              scores={payloadKnowledge}
              competencyValues={knowLedgesValues}
              setCompetencyValues={setKnownLedgesValues}
            />
          )}
          {openBehavior && (
            <BehaviorNotes
              onClose={onHandleBehavior}
              scores={payloadBehavior}
              competencyValues={behaviorsValues}
              setCompetencyValues={setBehaviorsValues}
            />
          )}
          {openLeadership && (
            <LeadershipNotes
              onClose={onHandleLeadership}
              scores={payloadLeadership}
              competencyValues={leadershipValues}
              setCompetencyValues={setLeadershipValues}
            />
          )}
        </Box>
      </Box>
    </RoleBasedGuard>
  )
}
