import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    width: '416px',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    background: '#ffffff',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
    padding: '20px',
    marginRight: '30px',
    overflowX: 'hidden'
  },
  headerInformations: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: -10
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20
  },
  btnClose: {
    marginRight: -10
  },
  formControlButton: {
    display: 'flex',
    justifyContent: 'end',
    paddingTop: 15,
    height: 60
  },
  boxFormControlButton: {
    display: 'flex',
    flex: 1,
    justifyContent: 'end',
    alignItems: 'end'
  },

  button: {
    width: '100%',
    padding: '5px 10px 5px 20px',
    display: 'flex',
    animation: '0.3s',
    transition: '0.3s',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#F7F7FC',
    borderRadius: theme.typography.pxToRem(40),
    border: 'none',
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 400,
    textDecoration: 'none',
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(21),
    color: '#000000'
  },
  buttonSelected: {
    backgroundColor: theme.palette.primary.main,
    animation: '0.3s',
    transition: '0.3s',
    width: '100%',
    padding: '5px 10px 5px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: theme.typography.pxToRem(40),
    border: 'none',
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(21),
    color: '#fff'
  },
  buttonAddOneToOne: {
    width: '100%',
    padding: '5px 10px 5px 20px',
    display: 'flex',
    animation: '0.3s',
    transition: '0.3s',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#F7F7FC',
    borderRadius: theme.typography.pxToRem(40),
    border: 'none',
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 400,
    textDecoration: 'none',
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(21),
    color: '#000000',
    cursor: 'pointer'
  },
  buttonAddOneToOneSelected: {
    backgroundColor: theme.palette.primary.main,
    animation: '0.3s',
    transition: '0.3s',
    width: '100%',
    padding: '5px 10px 5px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: theme.typography.pxToRem(40),
    border: 'none',
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(21),
    color: '#fff',
    cursor: 'pointer'
  },
  data: {
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    gap: 10,
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500
  },
  dataSelect: {
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    gap: 10,
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500
  },
  contentButton: {
    display: 'flex',
    gap: 10,
    flexDirection: 'column'
  },
  icon: {
    color: theme.palette.primary.main
  },
  iconSelected: {
    color: '#ffffff'
  },
  dateSection: {
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '20px',
    width: '100%',
    fontWeight: '600',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(19),
    letterSpacing: theme.typography.pxToRem(0.15),
    background: '#F7F7FC',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#787878',
    padding: 5
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    height: '100%'
  },
  commentBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'left',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: 10,
    marginBottom: 15
  },
  commentText: {
    padding: 21,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
    letterSpacing: theme.typography.pxToRem(0.15),
    color: '#000000'
  },
  user: {
    background: '#F7F7FC',
    borderRadius: '10px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '7px 9px',
    gap: 10
  },
  userName: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
    letterSpacing: theme.typography.pxToRem(0.15),
    color: '#434343'
  },
  dateOfComment: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
    letterSpacing: theme.typography.pxToRem(0.15),
    color: '#787878'
  },
  boxContent: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    columnGap: 20
  },
  formButtons: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: 25,
    gap: 20
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 15,
    marginTop: 10,
    borderBottom: '1px solid #CFD2D3'
  },
  filtersButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 10,
    alignItems: 'center'
  },
  buttonFilter: {
    width: 20,
    height: 20,
    borderRadius: '100%',
    cursor: 'pointer',
    border: 'none',
    transition: 'all 0.3s'
  },
  buttonFilterSelected: {
    width: 22,
    height: 22,
    borderRadius: '100%',
    cursor: 'pointer',
    boxShadow: '0 0 0 2px #f3f5f6 inset',
    boxSizing: 'border-box',
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    transition: 'all 0.3s'
  },
  filtersButtonsCard: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 10,
    marginTop: -60
  },
  showMoreContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  buttonShowMore: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(15),
    border: 'none',
    background: 'transparent'
  },
  buttonDetailsOneToOne: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    padding: 10,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      borderRadius: 20,
      padding: 10
    }
  },
  buttonDetailsOneToOneSelect: {
    color: '#FFFFFF',
    textAlign: 'center',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    padding: 10,
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      borderRadius: 20,
      padding: 10
    }
  },
  iconButton: {
    padding: 0,
    width: 40,
    height: 40,
    color: theme.palette.primary.main,
    '&:hover': {
      color: '#FFFFFF'
    }
  },
  iconButtonSelect: {
    padding: 0,
    width: 40,
    height: 40,
    color: '#FFFFFF',
    '&:hover': {
      color: '#FFFFFF'
    }
  },
  lastFeedbackTile: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
    color: '#434343'
  }
}))
