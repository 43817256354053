import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  actions: {
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% + 30px)',
    justifyContent: 'flex-end',
    marginLeft: '-15px',
    marginRight: '-15px',
    columnGap: 20,
    marginBottom: '-20px'
  },
 
}))
