import PropTypes from 'prop-types'
import { CardImpressionsOneToOne } from 'src/components'

export function TabOneToOne({ dataOneToOne = [] }) {
  return (
    <>
      {dataOneToOne?.map((item, index) => (
        <CardImpressionsOneToOne
          key={index}
          fromUserId={item.fromUserId}
          toUserId={item.toUserId}
          item={item}
        />
      ))}
    </>
  )
}

TabOneToOne.propTypes = {
  dataOneToOne: PropTypes.array
}
