import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    borderRadius: 10,
    border: '1px solid #CFD2D3',
    height: 41,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: 'hidden'
  },
  button: {
    background: '#F7F7FC',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    borderLeft: '1px solid #CFD2D3',
    borderRight: '1px solid #CFD2D3',
    cursor: 'pointer',
    fontWeight: '500',
    fontSize: theme.typography.pxToRem(15),
    lineHeight: theme.typography.pxToRem(18),
    letterSpacing: theme.typography.pxToRem(0.74976),
    color: '#787878'
  }
}))
