import React from 'react'
import { useTheme } from '@material-ui/styles'
import { IconButton } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { InfoBox, Tooltip, CardExpectedEvoluction } from 'src/components'
import makeStyles from './style'

export default function ExpectedEvolution({ onClose }) {
  const theme = useTheme()
  const classes = makeStyles()

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.headerInformations}>
          <InfoBox title={'Evolução prevista'} subTitle={'Exemplo 2'} />
          <div className={classes.btnClose}>
            <Tooltip title="Fechar">
              <IconButton onClick={onClose}>
                <CloseIcon htmlColor={theme.palette.primary.main} />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className={classes.containerScroll}>
        <CardExpectedEvoluction />
      </div>
    </div>
  )
}
