import React, { useMemo, createContext } from 'react'
import useAuth from 'src/hooks/useAuth'
import Themes from 'src/themes'
import { createTheme } from '@material-ui/core'

const ThemeStateContext = createContext()
const ThemeDispatchContext = createContext()

function ThemeProvider({ children }) {
  const { customer } = useAuth()

  const themeOptions = useMemo(
    () => ({
      ...Themes.default,
      palette: {
        primary: {
          main: customer?.primaryColor ?? '#38D430'
        }
      }
    }),
    [customer?.primaryColor]
  )

  const theme = createTheme(themeOptions)

  return (
    <ThemeStateContext.Provider value={theme}>
      <ThemeDispatchContext.Provider>{children}</ThemeDispatchContext.Provider>
    </ThemeStateContext.Provider>
  )
}

function useThemeState() {
  var context = React.useContext(ThemeStateContext)
  if (context === undefined) {
    throw new Error('useThemeState must be used within a ThemeProvider')
  }
  return context
}

function useThemeDispatch() {
  var context = React.useContext(ThemeDispatchContext)
  if (context === undefined) {
    throw new Error('useThemeDispatch must be used within a ThemeProvider')
  }
  return context
}

export { ThemeProvider, useThemeState, useThemeDispatch, ThemeStateContext }
