import React from 'react'
import PropTypes from 'prop-types'
import { CardImpressionsFeedback } from 'src/components'

export function TabFeedbacks({ dataFeedbacks = [] }) {
  return (
    <>
      {dataFeedbacks?.map((item, index) => (
        <CardImpressionsFeedback
          key={index}
          fromUserId={item.fromUserId}
          item={item}
        />
      ))}
    </>
  )
}

TabFeedbacks.propTypes = {
  dataFeedbacks: PropTypes.array
}
