import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'src/components'
import { Box } from '@material-ui/core'
import { screen } from './mock'
import GetEmail from './Partials/GetEmail'
import ShowMessage from './Partials/ShowMessage'
import ChangePassword from './Partials/ChangePassword'
import { useLoadingContext } from 'src/context/LoadingContext'
import { useAlertContext } from 'src/context/AlertContext'
import LoginLayout from 'src/layouts/Login'
import axios from 'src/utils/axios'

function ForgotPassword() {
  const navigate = useNavigate()

  const [email, setEmail] = useState(null)
  const { email: paramEmail } = useParams()

  const { onCloseLoading, onOpenLoading } = useLoadingContext()
  const { onCallAlert } = useAlertContext()

  const [currentScreen, setCurrentScreen] = useState(screen.GET_EMAIL)

  function onSubmitEmail({ email }) {
    setEmail(email)
    setCurrentScreen(screen.SHOW_MESSAGE)
  }

  const checkResetPassword = useCallback(async (id) => {
    try {
      onOpenLoading()
      await axios.post('/oauth', {
        email: paramEmail,
        password: '_'
      })
    } catch (error) {
      const isResetPassword = error?.data?.resetPassword
      const firstErro = error?.data[0]?.errorMessage
      if (isResetPassword) {
        setEmail(paramEmail)
        setCurrentScreen(screen.CHANGE_PASSWORD)
        return 0
      }
      if (firstErro) {
        onCallAlert({ type: 'error', message: firstErro })
      } else {
        if (!isResetPassword) {
          onCallAlert({
            type: 'error',
            message: `Solicite um código em "Esqueceu sua senha?"`
          })
        }
      }
      navigate('/')
    } finally {
      onCloseLoading()
    }
  }, [])

  useEffect(() => {
    if (paramEmail) {
      checkResetPassword()
    }
  }, [])

  return (
    <LoginLayout>
      <Helmet title="Esqueceu sua senha?" />
      <Box sx={{ width: '100%' }}>
        {currentScreen === screen.GET_EMAIL && (
          <GetEmail
            onNextScreen={({ email }) => onSubmitEmail({ email })}
            goToLogin={() => navigate('/')}
          />
        )}
        {currentScreen === screen.SHOW_MESSAGE && (
          <ShowMessage
            onNextScreen={() => setCurrentScreen(screen.CHANGE_PASSWORD)}
            onPreviousScreen={() => setCurrentScreen(screen.GET_EMAIL)}
            email={email}
          />
        )}
        {currentScreen === screen.CHANGE_PASSWORD && (
          <ChangePassword email={email} navigate={navigate} />
        )}
      </Box>
    </LoginLayout>
  )
}

export default ForgotPassword
