import React, { useCallback, useEffect, useState } from "react";
import moment from 'moment'
import useStyles from './style'
import PropTypes from 'prop-types'
import useAuth from 'src/hooks/useAuth'
import { Modal as ModalUi } from '@mui/material'
import { getUserById } from "src/services/hooks/users/useUsers";
import { postOneToOneSearch } from "src/services/hooks/oneToOne/useOneToOne";
import { IconButton, Box, Typography, useTheme } from '@material-ui/core'
import { Close as CloseIcon} from '@material-ui/icons'
import {
  CalendarToday as CalendarTodayIcon,
  ArrowForwardIos as ArrowForwardIosIcon
} from '@mui/icons-material'
import {
  Tooltip,
  Modal,
  Button,
  NewBoxWidget,
  CardOneToOne,
} from 'src/components'
import { useNavigate } from 'react-router-dom'
import EditOneToOne from "src/screens/UserMetrics/Impressions/Partials/EditOneToOne";
import NewOneToOne from "src/screens/UserMetrics/Impressions/Partials/NewOneToOne";

CreateOneToOne.propTypes = {
  idUserSelected: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

export function CreateOneToOne({
  idUserSelected,
  isOpen,
  onClose,
}) {
  const classes = useStyles();
  const { user } = useAuth()
  const theme = useTheme()
  const currentCustomerId = user?.customerId
  const currentUserId = user?.id
  const [dataUser, setDataUser] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [dataNotCompleted, setDataNotCompleted] = useState()
  const [dataCompleted, setDataCompleted] = useState()
  const [onNewScheduleOneOne, setNewScheduleOneOne] = useState(false)
  const [isSelected, setIsSelected] = useState(0)
  const [openDetailsOneToOne, setOpenDetailsOneToOne] = useState(false)
  const [isOpenModalEditOneOne, setIsOpenModalEditOneOne] = useState(false)

  const getDataUser = useCallback(async (userId) => {
    try {
      setIsLoading(true)
      const { data } = await getUserById(userId)
      setDataUser(data?.data)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }, [])

  const getOneToOneSearch = useCallback(async ({
    customerId,
    userLeaderId,
    idUserSelected,
  }) => {
    try {
      setIsLoading(true)
      let fromUserId
      if (idUserSelected !== userLeaderId) {
        fromUserId = userLeaderId
      }
      const payloadNotCompleted = {
        customerId,
        fromUserId,
        toUserId: idUserSelected,
        completed: false
      }

      const payloadCompleted = {
        customerId,
        fromUserId,
        toUserId: idUserSelected,
        completed: true
      }
      const responseNotCompleted = await postOneToOneSearch(payloadNotCompleted)
      const responseCompleted = await postOneToOneSearch(payloadCompleted)
      const notCompleted = responseNotCompleted?.filter((item) => item)[0]
      const completed = responseCompleted?.filter((item) => item)
      const completedOrderBy = completed.sort((a, b) =>
        a.updateAt > b.updateAt ? -1 : 1
      )[0]
      setDataNotCompleted(notCompleted)
      setDataCompleted(completedOrderBy)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    if (currentCustomerId && currentUserId && idUserSelected) {
      getDataUser(idUserSelected)
      getOneToOneSearch({
        idUserSelected,
        customerId: currentCustomerId,
        userLeaderId: currentUserId
      })
    }
  }, [
    currentCustomerId,
    currentUserId,
    getDataUser,
    getOneToOneSearch,
    idUserSelected
  ])

  const navigate = useNavigate()

  function onHandleNewScheduleOneOne() {
    setNewScheduleOneOne(!onNewScheduleOneOne)
  }

  function onOpenDetailsOneToOne(id) {
    setIsSelected(id)
    setIsOpenModalEditOneOne(true)
  }

  function handleViewOneToOne(id) {
    navigate(`/view/one-to-one/${id}`)
  }

  function onHandleScheduleOneOneModal() {
    setIsOpenModalEditOneOne(!isOpenModalEditOneOne)
  }

  function onCloseDetailsOneToOne() {
    setIsSelected(0)
    setIsOpenModalEditOneOne(false)
    setOpenDetailsOneToOne(false)
  }

  return (
    <>
      <Modal
        isOpen={isOpenModalEditOneOne}
        onHandleClose={() => {
          onCloseDetailsOneToOne()
        }}
        title="Alterar agendamento"
        subTitle="Você deseja mesmo remarcar o 1:1 agendado?"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ marginTop: 20, columnGap: 10 }}
        >
          <Button
            label="Não, obrigado"
            variant="dark"
            onClick={() => {
              onCloseDetailsOneToOne()
            }}
          />
          <Button
            label="Sim"
            onClick={() => {
              onHandleScheduleOneOneModal()
              setOpenDetailsOneToOne(true)
            }}
          />
        </Box>
      </Modal>
      <ModalUi open={isOpen} className={classes.containerModal}>
        <div className={classes.boxModal}>
          <NewBoxWidget
            title={`1:1 ${dataUser?.name ?? 'registrados'}`}
            width={500}
            height="100%"
            options={
              <Tooltip placement="top" title="Fechar">
                <IconButton onClick={onClose}>
                  <CloseIcon htmlColor={theme.palette.primary.main} />
                </IconButton>
              </Tooltip>
            }
          >
            <Box className={classes.contentBox}>
              {!isLoading &&
                !dataNotCompleted &&
                currentUserId !== idUserSelected && (
                <button
                  type="button"
                  className={
                    onNewScheduleOneOne
                      ? classes.buttonAddOneToOneSelected
                      : classes.buttonAddOneToOne
                  }
                  onClick={onHandleNewScheduleOneOne}
                >
                  Agendar próximo
                  {onNewScheduleOneOne ? (
                    <Tooltip placement="top" title="Fechar">
                      <IconButton
                        onClick={onHandleNewScheduleOneOne}
                        className={classes.iconButton}
                      >
                        <CloseIcon htmlColor="#FFFFFF" />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip placement="top" title="Agendar próximo">
                      <IconButton
                        onClick={onHandleNewScheduleOneOne}
                        className={classes.iconButton}
                      >
                        <CalendarTodayIcon
                          htmlColor={theme.palette.primary.main}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </button>
              )}
              {!isLoading && dataNotCompleted && (
                <button
                  type="button"
                  className={
                    isSelected === dataNotCompleted.id
                      ? classes.buttonSelected
                      : classes.button
                  }
                >
                  1:1 Agendado
                  <div
                    className={
                      isSelected === dataNotCompleted.id
                        ? classes.dataSelect
                        : classes.data
                    }
                  >
                    <Tooltip placement="top" title="Alterar agendamento">
                      <Box
                        onClick={() => {
                          if (isSelected !== dataNotCompleted.id) {
                            setOpenDetailsOneToOne(false)
                            onOpenDetailsOneToOne(dataNotCompleted.id)
                          }
                        }}
                        className={
                          isSelected === dataNotCompleted.id
                            ? classes.buttonDetailsOneToOneSelect
                            : classes.buttonDetailsOneToOne
                        }
                      >
                        {moment(dataNotCompleted?.start).format('L')} -{' '}
                        {moment(dataNotCompleted?.start).format('LT')}
                      </Box>
                    </Tooltip>
                    <Tooltip placement="top" title="Acessar 1:1">
                      <IconButton
                        onClick={() =>
                          handleViewOneToOne(dataNotCompleted.id)
                        }
                        className={
                          isSelected === dataNotCompleted.id
                            ? classes.iconButtonSelect
                            : classes.iconButton
                        }
                      >
                        <ArrowForwardIosIcon
                          className={
                            isSelected === dataNotCompleted.id
                              ? classes.iconSelect
                              : classes.icon
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                </button>
              )}
              {!isLoading && dataCompleted && (
                <>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    className={classes.dateSection}
                  >
                    <Typography variant="title">
                      {dataCompleted?.name ?? "SEM NOME"}
                    </Typography>
                    <Typography>
                      {moment(dataCompleted?.start).format('LL')}
                    </Typography>
                  </Box>
                  <CardOneToOne
                    oneToOneId={dataCompleted?.id}
                    userId={dataCompleted?.fromUserId}
                    text={dataCompleted?.description}
                    idUserSelected={idUserSelected}
                  />
                </>
              )}
            </Box>
          </NewBoxWidget>
          {onNewScheduleOneOne && (
            <NewOneToOne
              onClose={onHandleNewScheduleOneOne}
              getOneToOneSearch={getOneToOneSearch}
              idUserSelected={idUserSelected}
            />
          )}
          {openDetailsOneToOne && (
            <EditOneToOne
              onClose={onCloseDetailsOneToOne}
              getOneToOneSearch={getOneToOneSearch}
              idOneToOne={isSelected}
            />
          )}
        </div>
      </ModalUi>
    </>
  );
}
