import React from 'react'
import useAuth from 'src/hooks/useAuth'
import { PATH_DASHBOARD } from 'src/routes/paths'
import { Navigate } from 'react-router-dom'
import { LoadingScreen } from 'src/components'

export default function GuestGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuth()

  if (isAuthenticated) {
    return <Navigate to={PATH_DASHBOARD.home} />
  }

  if (!isInitialized) {
    return <LoadingScreen />
  }

  return <>{children}</>
}
