import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  infoBox: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '10px',
    width: 'fit-content'
  },
  title: {
    fontSize: theme.typography.pxToRem(18),
    color: '#000000',
    fontWeight: 600,
    lineHeight: '21px',
    letterSpacing: 0.8
  },
  subTitle: {
    fontSize: theme.typography.pxToRem(14),
    color: '#000000',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: 0.14
  }
}))
