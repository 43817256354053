import React from 'react'
import { useTheme } from '@material-ui/styles'
import { IconButton, Box } from '@material-ui/core'
import { Button, Tooltip, NewBoxWidget } from 'src/components'
import { Close as CloseIcon } from '@material-ui/icons'
import CardAddTargetsAndActivities from 'src/components/CardAddTargetsAndActivities'
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined'
import makeStyles from './style'

export default function TargetsAndActivities({
  onClose,
  nextOptionTargetsAndActivities,
  payloadTargets,
  setPayloadTargets,
  payloadActivities,
  setPayloadActivities,
  isValidForm,
  onChangeValidForm
}) {
  const theme = useTheme()
  const classes = makeStyles()

  const onHandleSubmit = () => {
    nextOptionTargetsAndActivities(true)
  }

  return (
    <NewBoxWidget
      title="Tarefas e metas"
      subtitle="Adicione metas e/ou ações para poder acompanhar a evolução do PDI"
      options={
        <>
          <Tooltip
            title={
              <Box className={classes.btnTooltip}>
                <Box>
                  Metas: São agrupadores de ações para ajudar na organização do
                  PDI
                </Box>
                <Box>
                  Ações: São tarefas a serem cumpridas para conclusão do PDI
                </Box>
              </Box>
            }
          >
            <IconButton>
              <FeedbackOutlinedIcon htmlColor={theme.palette.primary.main} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Fechar">
            <IconButton onClick={onClose}>
              <CloseIcon htmlColor={theme.palette.primary.main} />
            </IconButton>
          </Tooltip>
        </>
      }
      actions={
        <Button
          label="Avançar"
          onClick={onHandleSubmit}
          disabled={!isValidForm}
        />
      }
      width={500}
      height="100%"
    >
      <CardAddTargetsAndActivities
        isValidForm={isValidForm}
        onChangeValidForm={onChangeValidForm}
        payloadTargets={payloadTargets}
        setPayloadTargets={setPayloadTargets}
        payloadActivities={payloadActivities}
        setPayloadActivities={setPayloadActivities}
      />
    </NewBoxWidget>
  )
}
