import React from 'react'
import { Typography } from '@material-ui/core'
import { Button, Modal } from 'src/components'
import makeStyles from './style'

export function ModalShelveCompetence({
  isOpen,
  onClose,
  name,
  onConfirm,
  groupsWithCompetence
}) {
  const classes = makeStyles()
  const isExistGroups = groupsWithCompetence.length > 0
  const title = isExistGroups
    ? `Arquivar ${name}?`
    : `Arquivar competência ${name}?`
  const subTitle = isExistGroups
    ? 'Atenção! A competência será arquivada de todos os agrupadores aos quais está vinculada.'
    : 'Você deseja mesmo arquivar essa competência? Os dados coletados nos grupos ainda permanecerão disponíveis para consulta no período utilizado.'

  return (
    <Modal
      isOpen={isOpen}
      onHandleClose={onClose}
      title={title}
      subTitle={subTitle}
    >
      {isExistGroups && (
        <div className={classes.contentChildrens}>
          {groupsWithCompetence.map(({ name }) => (
            <Typography key={name} className={classes.name}>
              {name}
            </Typography>
          ))}
        </div>
      )}
      <div className={classes.modalButtons}>
        <Button label="Arquivar" variant="underline" onClick={onConfirm} />
        <Button label="Cancelar" onClick={onClose} />
      </div>
    </Modal>
  )
}
