import React from 'react'
import PropTypes from 'prop-types'
import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined'
import makeStyles from './style'

export function WithoutActivities({ text }) {
  const classes = makeStyles()

  return (
    <div className={classes.withoutItemsContainer}>
      <InboxOutlinedIcon sx={{ color: '#CFD2D3', width: 37.5, height: 37.5 }} />
      <h1 className={classes.withoutItemsText}>{text}</h1>
    </div>
  )
}

WithoutActivities.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string
}
