import AppearancePreferences from 'src/screens/AppearancePreferences'
import EditClient from 'src/screens/Customers/EditClient'
import ListClient from 'src/screens/Customers/ListClient'
import NewClient from 'src/screens/Customers/NewClient'

export default [
  {
    path: '/customers',
    element: <ListClient />
  },
  {
    path: '/customers/edit/:id',
    element: <EditClient />
  },
  {
    path: '/customers/add',
    element: <NewClient />
  },
  {
    path: '/customers/theme/:id',
    element: <AppearancePreferences />
  }
]
