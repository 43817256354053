import React, { useState, useCallback, useEffect } from 'react'
import RoleBasedGuard from 'src/guards/RoleBasedGuard'
import useGlobalUseStyles from 'src/themes/globalUseStyles'
import { useTheme } from '@material-ui/styles'
import { useNavigate } from 'react-router-dom'
import { IconButton } from '@material-ui/core'
import { styled } from '@mui/material/styles'
import { DesignServicesOutlined as DesignServicesOutlinedIcon } from '@mui/icons-material'
import { EditOutlined as EditOutlinedIcon } from '@material-ui/icons'
import { messageResponse } from 'src/utils/messageResponse'
import { useAlertContext } from 'src/context/AlertContext'
import {
  HeaderBreadcrumbs,
  Button,
  NewBoxWidget,
  TableHead,
  TableToolbar,
  TableLoading,
  TableNotFound
} from 'src/components'
import {
  Box,
  Table,
  TableRow,
  TableBody,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { getComparator, applySortFilter } from 'src/utils'
import makeStyles from './style'
import { enumCategoryType } from 'src/utils/constants'
import {
  getCompetenceGrouper,
  getUserGroups
} from 'src/services/hooks/competenceGrouper/useCompetenceGrouper'
import { getCompetence } from 'src/services/hooks/competence/useCompetence'

const TABLE_HEAD = [
  { id: 'name', label: 'Nome do agrupador', alignRight: false },
  { id: 'category', label: 'Categoria', alignRight: false },
  { id: 'competence', label: 'Competências', alignRight: false },
  { id: 'appliedGroups  ', label: 'Grupos aplicados', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'edit', label: 'Editar', alignRight: false }
]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottom: 'none',
    padding: '8px 8px 8px 16px'
  }
}))

function ListGroupers() {
  const theme = useTheme()

  const classes = makeStyles()
  const globalClasses = useGlobalUseStyles()
  const navigate = useNavigate()

  const [role] = useState([1, 2])

  const { notifications, onCallAlert } = useAlertContext()

  const [data, setData] = useState([])
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const [filterName, setFilterName] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [isLoading, setIsLoading] = useState(false)

  const [auxData, setAuxData] = useState({
    dataCompetence: [],
    dataUserGroups: []
  })

  const shoundRenderEmptyList = data.length === 0 && !isLoading

  const getDataGroupers = useCallback(async () => {
    try {
      setIsLoading(true)
      const responseDataGroupers = await getCompetenceGrouper({})
      const responseDataCompetence = await getCompetence()
      const responseDataUserGroups = await getUserGroups()
      const getOnlyCompetenceActive = (
        responseDataCompetence?.data?.data ?? []
      ).filter((elem) => elem.isActive)
      setData(responseDataGroupers?.data?.data ?? [])
      setAuxData({
        dataCompetence: getOnlyCompetenceActive ?? [],
        dataUserGroups: responseDataUserGroups?.data?.data
      })
    } catch (error) {
      const messageError = messageResponse(error?.response)
      onCallAlert({
        type: 'error',
        message: messageError ?? notifications.global.error
      })
    } finally {
      setIsLoading(false)
    }
  }, [notifications.global.error, onCallAlert])

  function getLabelAuxData({ where, value }) {
    const result = (value ?? [])?.map(
      (item) => where.filter((itemFilter) => itemFilter?.id === item)[0]?.name
    )
    const formatString = result.filter((elem) => elem)
    return formatString?.length > 0
      ? formatString.join(', ')
      : 'Sem item registrado'
  }

  function getLabelCompetence(value) {
    const result = enumCategoryType.filter((item) => item.value === value)[0]
      .title
    return result ?? 'Sem item registrado'
  }

  function onHandleEdit(id) {
    navigate('/competence-grouper/edit/' + id)
  }

  useEffect(() => {
    getDataGroupers()
  }, [getDataGroupers])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const filteredData = applySortFilter(
    data,
    getComparator(order, orderBy),
    filterName
  )

  const handleFilterByName = (event) => {
    setPage(0)
    setFilterName(event.target.value)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setPage(0)
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0

  const isNotFound = !filteredData?.length && !!filterName && data

  return (
    <RoleBasedGuard hasContent roles={role}>
      <Box className={globalClasses.breadcrumb}>
        <HeaderBreadcrumbs
          icon={<DesignServicesOutlinedIcon />}
          links={[{ name: 'Agrupadores de competência' }]}
        />
        <Button
          label="Criar novo agrupador"
          variant="dark"
          onClick={() => navigate('/competence-grouper/add')}
        />
      </Box>
      <Box className={globalClasses.content}>
        <NewBoxWidget
          title="Agrupadores de competência"
          subtitle="São categorias para medir e explorar a evolução em competencias e conhecimentos em comum:"
          options={
            <TableToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
            />
          }
          actions={
            <Box className={classes.actions}>
              <Box />
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                labelRowsPerPage={'Registros por página'}
                component="div"
                count={data?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          }
          borderActions
          noBodyPadding
          fullBody
        >
          <TableContainer
            sx={{
              maxHeight: '100%',
              '&::-webkit-scrollbar': {
                height: '4px',
                width: '4px',
                borderRadius: '10px'
              },
              '&::-webkit-scrollbar-track': {
                background: '#CFD2D3',
                borderRadius: '10px'
              },
              '&::-webkit-scrollbar-thumb': {
                background: theme.palette.primary.main,
                borderRadius: '10px'
              }
            }}
          >
            <Table stickyHeader>
              <TableHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={data?.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {filteredData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row) => {
                    const {
                      id,
                      name,
                      category,
                      competences,
                      userGroups,
                      isActive
                    } = row
                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <StyledTableCell align="left">{name}</StyledTableCell>
                        <StyledTableCell align="left">
                          {getLabelCompetence(category)}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {getLabelAuxData({
                            where: auxData.dataCompetence,
                            value: competences
                          })}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {getLabelAuxData({
                            where: auxData.dataUserGroups,
                            value: userGroups
                          })}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {isActive ? 'Ativo' : 'Inativo'}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <IconButton
                            className={classes.icon}
                            onClick={() => onHandleEdit(id)}
                          >
                            <EditOutlinedIcon />
                          </IconButton>
                        </StyledTableCell>
                      </TableRow>
                    )
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell
                      colSpan={12}
                      style={{ height: 53 * emptyRows, border: 'none' }}
                    />
                  </TableRow>
                )}
              </TableBody>
              {isLoading && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={12}
                      sx={{
                        border: 'none',
                        height: 'calc(100vh - 410px)'
                      }}
                    >
                      <div className={classes.boxInfor}>
                        <TableLoading />
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              {shoundRenderEmptyList && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={12}
                      sx={{
                        border: 'none',
                        height: 'calc(100vh - 410px)'
                      }}
                    >
                      <div className={classes.boxInfor}>
                        <TableNotFound />
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={12}
                      sx={{
                        border: 'none',
                        height: 'calc(100vh - 410px)'
                      }}
                    >
                      <div className={classes.boxInfor}>
                        <Typography variant="h6" paragraph>
                          Nenhum registro encontrado!
                        </Typography>

                        <Typography variant="body2">
                          Nenhum resultado encontrado para &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Tente verificar erros de digitação.
                        </Typography>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </NewBoxWidget>
      </Box>
    </RoleBasedGuard>
  )
}

export default ListGroupers
