import React, { createContext, useEffect, useReducer } from 'react'
import axios from 'src/utils/axios'
import { getCustumersById } from 'src/services/hooks/customers/useCustumers'
import { getNotificationsNotWasViewedById } from 'src/services/hooks/notifications/useNotifications'
import { setSession } from 'src/utils/jwt'

const Types = {
  Initial: 'INITIALIZE',
  Login: 'LOGIN',
  Logout: 'LOGOUT'
}

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  customer: null
}

const JWTReducer = (state, action) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        customer: action.payload.customer,
        notifications: action.payload.notifications,
        notificationsTotal: action.payload.notificationsTotal
      }
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        customer: action.payload.customer,
        notifications: action.payload.notifications,
        notificationsTotal: action.payload.notificationsTotal
      }
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        customer: null
      }
    default:
      return state
  }
}

const AuthContext = createContext()

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState)

  const initialize = async () => {
    try {
      const accessToken =
        typeof window !== 'undefined' ? localStorage.getItem('accessToken') : ''

      if (accessToken) {
        const storage = {
          token: localStorage.getItem('accessToken'),
          refresh: localStorage.getItem('refreshToken'),
          email: localStorage.getItem('email'),
          userId: localStorage.getItem('userId'),
          customer: localStorage.getItem('customer')
        }

        setSession(
          accessToken,
          storage.refresh,
          storage.email,
          storage.userId,
          storage.customer
        )

        const response = await axios.get(`user/${storage.userId}`)
        const user = response.data?.data

        const customer = JSON.parse(storage.customer)

        const notifications = await getNotificationsNotWasViewedById(
          storage.userId
        )

        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: true,
            user,
            customer,
            notifications: notifications.slice(0, 15),
            notificationsTotal: notifications.length
          }
        })
      } else {
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
            customer: null
          }
        })
      }
    } catch (err) {
      console.error(err)
      dispatch({
        type: Types.Initial,
        payload: {
          isAuthenticated: false,
          user: null,
          customer: null
        }
      })
    }
  }

  useEffect(() => {
    initialize()
  }, [])

  const login = async (email, password) => {
    const response = await axios.post('/oauth', {
      email,
      password
    })
    const { data } = response.data
    const {
      token: accessToken,
      email: emailUser,
      refresh: refreshToken,
      userId,
      customerId
    } = data

    if (accessToken) {
      const customerRes = await getCustumersById({
        customerId,
        token: accessToken
      })

      const customer = {
        customerId,
        logo: customerRes.logo,
        favicon: customerRes.favicon,
        primaryColor: customerRes.primaryColor
      }

      setSession(
        accessToken,
        refreshToken,
        emailUser,
        userId,
        JSON.stringify(customer)
      )

      const userRes = await axios.get(`user/${userId}`)
      const user = userRes.data?.data

      const notifications = await getNotificationsNotWasViewedById(userId)

      dispatch({
        type: Types.Login,
        payload: {
          user,
          customer,
          notifications: notifications.slice(0, 15),
          notificationsTotal: notifications.length
        }
      })
    } else {
      dispatch({
        type: Types.Initial,
        payload: {
          isAuthenticated: false,
          user: null,
          customer: null
        }
      })
    }
  }

  const logout = async () => {
    setSession(null, null, null, null, null)
    dispatch({ type: Types.Logout })
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
