import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  withoutItemsContainer: {
    height: 'calc(100% - 140px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    borderTop: `solid 1px ${theme.palette.primary.main}`,
    borderBottom: `solid 1px ${theme.palette.primary.main}`
  },
  withoutItemsTitle: {
    marginTop: '47px',
    marginBottom: 0,
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontFamily: 'Roboto',
    lineHeight: theme.typography.pxToRem(21),
    letterSpacing: '0.7px'
  },
  withoutItemsSubTitle: {
    marginTop: '10px',
    width: '386px',
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(18),
    color: '#787878',
    fontWeight: 400,
    fontFamily: 'Roboto',
    lineHeight: theme.typography.pxToRem(21),
    letterSpacing: '0.7px'
  }
}))
