import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    backgroundColor: '#fff',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '20px',
    transition: 'all .3s',
    boxSizing: 'border-box',
    width: '415.61px',
    maxHeight: '560px',
    height: 'calc(100vh - 200px)',
    marginLeft: '30px',
    display: 'flex',
    flexDirection: 'column'
  },
  titleTable: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '25px 25px 20px 25px'
  },
  boxUpdate: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    flexDirection: 'row',
    borderRadius: '20px'
  },
  centerBoxUpdate: {
    display: 'flex',
    flexBasis: '100%',
    flexWrap: 'wrap'
  },
  colorsUpdate: {
    width: '68px',
    height: '71.89px',
    marginLeft: '1px',
    marginBottom: '1px',
    display: 'flex',
    cursor: 'pointer'
  },
  controlButton: {
    display: 'flex',
    columnGap: '20px'
  },
  color: {
    width: '50px',
    display: 'flex',
    height: '50px',
    borderRadius: '8px'
  },
  boxColor: {
    background: '#e9e9e9',
    width: '352px',
    height: '192.5px',
    padding: '1px',
    overflow: 'hidden'
  },
  boxColorHue: {
    background: '#e9e9e9',
    width: '352px',
    marginBottom: '20px',
    padding: '1px',
    overflow: 'hidden'
  },
  swatch: {
    width: '100%',
    height: '60px',
    display: 'flex',
    marginBottom: '10px',
    alignItems: 'center'
  },
  colorInput: {
    display: 'flex',
    width: '290px',
    marginLeft: '10px',
    marginTop: '4px'
  },
  textColor: { fontSize: '14px', color: '#000' },
  containerButton: { background: 'red !important' }
}))
