import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  boxContent: {
    display: 'flex',
    flexDirection: 'row',
    maxHeight: 'calc(100vh - 175px)',
    justifyContent: 'center'
  },
  boxStarTooltip: {
    height: 40,
    width: 40
  },
  container: {
    width: '456px',
    background: '#fff',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
    padding: '0px 16px 16px 16px',
    marginRight: 30,
    height: 'fit-content'
  },
  containerScroll: {
    width: 'calc(100% + 32px)',
    maxHeight: 'calc(100vh - 310px)',
    background: '#fff',
    borderRadius: '10px',
    overflow: 'auto',
    padding: '0px 16px 0px 16px',
    marginLeft: -16,
    borderBottom: '#fff 5px solid',
    '&::-webkit-scrollbar': {
      width: 8
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: 10
    },
    '::-webkit-scrollbar-track': {
      background: 'red',
      borderRadius: 10
    }
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 0
  },
  headerRightSide: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 0
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(21),
    letterSpacing: theme.typography.pxToRem(0.74976),
    color: '#000000'
  }
}))
