export async function convertBlobToFile(dataUrl, fileName, context) {
  const res = await fetch(dataUrl)
  const blob = await res.blob()
  const fileGenerate = new File([blob], fileName)
  const payload = {
    fileContext: context,
    file: fileGenerate
  }
  return payload
}
