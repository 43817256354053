import useCurrentState from './useCurrentState'

const useInput = (initialValue) => {
  const [state, setState, currentRef] = useCurrentState(initialValue)

  return {
    state,
    setState,
    currentRef,
    reset: () => setState(initialValue),
    bindings: {
      value: state,
      onChange: (event) => {
        if (typeof event === 'object' && event.target) {
          setState(event.target.value)
        } else {
          setState(event)
        }
      }
    }
  }
}

export default useInput
