import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  modalChildren: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#FCFCFC',
    position: 'absolute',
    margin: 'auto',
    width: 'fit-content',
    height: 'fit-content',
    borderRadius: '20px',
    padding: '20px',
    maxWidth: '666.67px',
    '& h1': {
      margin: 0
    }
  },

  modalChildrenSimple: {
    top: 0,
    bottom: 0,
    left: 64,
    right: 0,
    backgroundColor: '#fff',
    position: 'absolute',
    margin: 'auto',
    width: 'fit-content',
    height: 'fit-content',
    borderRadius: '20px',
    padding: '20px',
    maxWidth: '416px',
    '& h1': {
      margin: 0
    }
  },
  modalTitle: {
    color: '#222222',
    fontSize: theme.typography.pxToRem(17),
    lineHeight: theme.typography.pxToRem(21.09),
    letterSpacing: '0.74976px',
    fontWeight: '600'
  },
  modalSubTitle: {
    marginTop: '10px',
    color: '#222222',
    fontWeight: '400',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
    letterSpacing: '0.15px'
  }
}))
