import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import { InputMultiline } from 'src/components/Fields'
import { Button } from 'src/components'
import { messageResponse } from 'src/utils/messageResponse'
import { useAlertContext } from 'src/context/AlertContext'
import { postCelebration } from 'src/services/hooks/celebrations/useCelebrations'
import makeStyles from './style'

CreateCelebration.propTypes = {
  goBackToList: PropTypes.func,
  onClose: PropTypes.func,
  fromUserId: PropTypes.string,
  customerId: PropTypes.string,
  withUsers: PropTypes.array,
  onUpdateCelebrations: PropTypes.func,
}

export function CreateCelebration({
  goBackToList,
  onClose,
  fromUserId,
  customerId,
  withUsers,
  onUpdateCelebrations
}) {
  const classes = makeStyles()
  const { onCallAlert } = useAlertContext()
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmitCelebration(description) {

    try {
      setIsLoading(true)
      const payload = {
        fromUserId,
        customerId,
        withUsers: withUsers.map(itemUser => (itemUser.id)),
        description
      }
      await postCelebration(payload);
      onUpdateCelebrations()
      onCallAlert({
        type: 'success',
        message: 'Celebração criada com sucesso!'
      })
      onClose()
    }catch(error) {
      onCallAlert({
        type: 'error',
        message: messageResponse(error?.response)
      })
    }
    finally {
      setIsLoading(false)
    }
  }
  return (
    <div className={classes.containerCreate}>
        <Form
          onSubmit={(values) =>
            onSubmitCelebration(values.description)
          }
          initialValues={{description: ""}}
          render={({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit}>
              <div>
                <InputMultiline
                  label="Celebração"
                  name="description"
                  placeholder="Digite sua mensagem"
                  rows={7}
                  multiline={'multiline'}
                  onChange={(e) =>
                    form.change('description', String(e?.target?.value ?? "").slice(0, 350) )
                  }
                />
                <p className={classes.wordCounter}>{values.description.length}/350</p>
              </div>

              <div className={classes.modalButtons}>
                <Button
                  disabled={isLoading}
                  label="Voltar a listagem"
                  variant="underline"
                  onClick={goBackToList}
                />
                <Button
                  type="submit"
                  disabled={isLoading || values.description.length === 0}
                  variant="submit"
                  label="Enviar"
                />
              </div>
            </form>
          )}
        />
    </div>
  )
}
