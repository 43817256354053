import {
  COMPETENCE,
  COMPETENCE_SCORE,
  COMPETENCE_SCORE_SEARCH,
  COMPETENCE_SEARCH,
  COMPETENCE_LAST_SCORE
} from 'src/services/api'
import axios, { tokenAuthorization } from 'src/utils/axios'

export async function getCompetence() {
  const response = await axios.get(COMPETENCE, tokenAuthorization())
  return response
}

export async function getCompetenceById(competence) {
  const response = await axios.get(
    competence ? `${COMPETENCE}/${competence}` : COMPETENCE,
    tokenAuthorization()
  )
  return response
}

export async function getCompetenceScore(competence) {
  const response = await axios.get(
    competence ? `${COMPETENCE_SCORE}/${competence}` : COMPETENCE_SCORE,
    tokenAuthorization()
  )
  return response
}

export async function postCompetenceScoreSearch(payload) {
  const response = await axios.post(
    COMPETENCE_SCORE_SEARCH,
    payload,
    tokenAuthorization()
  )
  const { data } = response.data
  return data
}

export async function postCompetenceSearch(payload) {
  const response = await axios.post(
    COMPETENCE_SEARCH,
    payload,
    tokenAuthorization()
  )
  const { data } = response.data
  return data
}

export async function postCompetenceScore(payload) {
  const response = await axios.post(
    COMPETENCE_SCORE,
    payload,
    tokenAuthorization()
  )
  return response
}

export async function putCompetenceScore(id, payload) {
  const response = await axios.put(
    COMPETENCE_SCORE + `/${id}`,
    payload,
    tokenAuthorization()
  )
  return response
}

export async function putCompetence(payload) {
  const { id } = payload
  const response = await axios.put(
    `${COMPETENCE}/${id}`,
    payload,
    tokenAuthorization()
  )
  return response
}

export async function postCompetence(payload) {
  const response = await axios.post(COMPETENCE, payload, tokenAuthorization())
  return response
}

export async function getCompetenceLastScoreByUserId(id) {
  const response = await axios.get(
    COMPETENCE_LAST_SCORE + `/${id}`,
    tokenAuthorization()
  )
  const { data } = response?.data
  return data
}
