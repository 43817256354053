import React from 'react'
import { useTheme } from '@material-ui/styles'
import { Typography, Box } from '@material-ui/core'
import { MultiProgressBar, StarFavorite } from 'src/components'
import { AvatarGroups } from 'src/screens/Metrics/Partials/MetricsGroups'
import makeStyles from './style'

export function CardGroupNavigation({ data, userId }) {
  const theme = useTheme()
  const classes = makeStyles()

  return (
    <>
      <Box className={classes.displayContent}>
        <Box>
          <Typography className={classes.name}>{data?.name}</Typography>
        </Box>
        <Box className={classes.boxArrow}>
          <StarFavorite
            checked={data?.isFavorite}
            componentId={data?.id}
            userId={userId}
            favoriteId={data?.favoriteId}
            className={classes.boxBtnOptions}
            type="userGroup"
            colorIcon={theme.palette.primary.main}
          />
        </Box>
      </Box>
      <Box>
        <Typography className={classes.subTitle}>Líderes:</Typography>
      </Box>
      <AvatarGroups data={data?.groupLeaders ?? []} />
      <div className={classes.containerColorMetrics}>
        {data?.averageMetricsByCategoryType && (
          <MultiProgressBar
            data={data?.averageMetricsByCategoryType}
            showLegend
          />
        )}
      </div>
    </>
  )
}
