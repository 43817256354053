import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    margin: 'auto',
    width: (props) => (!props.fullBody && !props.width ? '810px' : props.width),
    maxWidth: (props) =>
      !props.fullBody && !props.width ? '810px' : props.width,
    maxHeight: (props) => (props.fullBody ? '100%' : props.height),
    borderRadius: 20,
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    backgroundColor: '#FFFFFF'
  },

  content: {
    flex: 1,
    overflowY: 'overlay',
    padding: theme.spacing(3),
    '&::-webkit-scrollbar': {
      height: '4px',
      width: '4px',
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-track': {
      background: '#CFD2D3',
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: '10px'
    }
  },

  header: {
    padding: theme.spacing(2)
  },

  headerSpacing: {
    paddingRight: theme.spacing(3)
  },

  headerIconSpacing: {
    paddingRight: theme.spacing(2)
  },

  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 18,
    color: '#000000'
  },

  subtitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#000000'
  },

  options: {
    columnGap: 15
  },

  footer: {
    display: 'flex',
    padding: theme.spacing(2),
    borderTop: (props) => props.borderActions && '1px solid',
    borderColor: theme.palette.primary.main
  },

  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    columnGap: 15
  },

  noPadding: {
    padding: 0
  }
}))
