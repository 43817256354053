import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  containerButton: {
    backgroundColor: (props) =>
      props.customerPrimaryColor ?? theme.palette.primary.main,
    color: '#fff',
    borderRadius: 30,
    padding: '12px 20px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: (props) =>
        props.customerPrimaryColor ?? theme.palette.primary.main,
      color: '#fff'
    }
  },
  outlined: {
    backgroundColor: '#fff !important',
    border: 'solid 1.5px ',
    borderColor: (props) =>
      props.customerPrimaryColor ?? theme.palette.primary.main,
    color: (props) => props.customerPrimaryColor ?? theme.palette.primary.main,
    borderRadius: 30,
    padding: '12px 20px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: (props) =>
        props.customerPrimaryColor ?? theme.palette.primary.main,
      color: '#fff'
    }
  },
  button: {
    gap: '10px !important',
    color: (props) => props.disabled && '#fff'
  },
  dark: {
    backgroundColor: '#000000 !important',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#000000',
      color: '#fff'
    }
  },
  sizeIcon: {
    width: 24,
    height: 24
  },
  link: {
    backgroundColor: 'transparent !important',
    color: (props) => props.customerPrimaryColor ?? theme.palette.primary.main,
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      color: (props) =>
        props.customerPrimaryColor ?? theme.palette.primary.main,
      textDecoration: 'underline'
    }
  },

  underline: {
    backgroundColor: '#fff !important',
    color: (props) => props.customerPrimaryColor ?? theme.palette.primary.main,
    border: '2px solid ',
    borderColor: (props) =>
      props.customerPrimaryColor ?? theme.palette.primary.main,
    '&:hover': {
      backgroundColor: '#fff',
      color: (props) => props.customerPrimaryColor ?? theme.palette.primary.main
    }
  },
  disabled: {
    backgroundColor: '#CFD2D3 !important',
    color: '#FFFFFF'
  }
}))
