import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    height: '100%',
    padding: '10px 30px 0px 30px',
    overflow: 'hidden'
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    height: 'calc(100vh - 180px)',
    paddingBottom: 10,
    marginTop: 10
  },
  breadcrumb: {
    padding: '0px 10px 0px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  boxContentBtn: {
    background: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    boxShadow: '0px 0px 6px -3px #3e3e3e',
    borderRadius: 50,
    border: 'none',
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  boxBtnNavegation: {
    gap: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  base: {
    paddingBottom: 32
  },
  titleTable: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: 20
  },
  buttonIcon: {
    backgroundColor: 'transparent',
    cursor: 'pointer',
    border: 'none'
  },
  titleHead: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 16,
    color: '#434343'
  },
  avatar: {
    marginRight: 10
  },
  userContainer: {
    padding: 10,
    backgroundColor: '#F7F7FC',
    borderRadius: 10,
    marginBottom: 25
  },
  displayContent: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F7F7FC',
    borderRadius: 10,
    marginBottom: 10
  },
  name: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 14,
    color: '#787878'
  },
  date: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#787878'
  },
  occupation: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#787878'
  },
  email: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#787878'
  },
  simpleProgress: {
    marginTop: 20
  },
  filterChartCompare: {
    display: 'flex',
    justifyContent: 'flex-start',
    columnGap: 10,
    paddingRight: 10
  },
  filterChartEvolution: {
    display: 'flex',
    justifyContent: 'flex-start',
    columnGap: 10
  },
  buttonAvatarRemove: {
    width: '50px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFFFFF',
    border: 'none',
    borderRadius: '50%',
    textAlign: 'center',
    cursor: 'pointer'
  },
  containerItensLayout: {
    height: '100%',
    width: '100%',
    display: 'flex',
    columnGap: 20,
    overflowX: 'scroll',
    overflowY: 'hidden',
    alignItems: 'start',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: 20,
    backgroundColor: '#FFFFFF',
    padding: theme.spacing(3),
    '&::-webkit-scrollbar': {
      height: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: 10
    }
  },
  columnMetricsScroll: {
    position: 'relative',
    width: '430px',
    height: '100%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: '10px'
    }
  },
  columnMetrics: {
    position: 'absolute',
    paddingRight: '10px',
    width: '100%'
  },
  containerUsers: {
    display: 'flex',
    height: '100%',
    flexWrap: 'wrap',
    flexDirection: 'column',
    gap: 15,
    width: '70%'
  },
  itemUser: {
    height: 'fit-content',
    minHeight: '250px',
    width: '45%'
  },
  body: {
    border: '1px solid transparent'
  }
}))
