import React, { useState, useCallback, useEffect } from 'react'
import useAuth from 'src/hooks/useAuth'
import { useTheme } from '@material-ui/styles'
import { useParams } from 'react-router-dom'
import { IconButton, Typography, Box, Grid, Switch } from '@material-ui/core'
import { Tooltip, CardCalendar, Button, NewBoxWidget } from 'src/components'
import { Input, Autocomplete, Date as DateInput } from 'src/components/Fields'
import { Close as CloseIcon } from '@material-ui/icons'
import { useAlertContext } from 'src/context/AlertContext'
import { useLoadingContext } from 'src/context/LoadingContext'
import { messageResponse } from 'src/utils/messageResponse'
import {
  editOneToOne,
  editOneToOneRepetition
} from 'src/utils/form/validations'
import 'moment/locale/pt-br'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import {
  putOneToOne,
  getOneToOneById
} from 'src/services/hooks/oneToOne/useOneToOne'
import {
  getIntervalType,
  getRepeatOnType
} from 'src/services/hooks/cycleSetting/useCycleSetting'
import {
  enumCycleSettingIntervalType,
  enumCycleSettingRepeatOnType
} from 'src/@enum/cycleSetting'
import { Form } from 'react-final-form'
import makeStyles from './style'

export default function EditOneToOne({
  onClose,
  idOneToOne,
  getOneToOneSearch
}) {
  const theme = useTheme()
  const classes = makeStyles()

  const { notifications, onCallAlert } = useAlertContext()
  const { onCloseLoading, onOpenLoading } = useLoadingContext()

  const { user } = useAuth()
  const currentUserId = user?.id
  const currentCustomerId = user?.customerId

  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [checkedEnableRepetition, setCheckedEnableRepetition] = useState(false)

  const [value, onChange] = useState(new Date())

  const [populateSelectOptions, setPopulateSelectOptions] = useState({
    intervalTypes: [],
    repeatOnTypes: []
  })
  const [oneToOneRepetitionIntervalTypes, setOneToOneRepetitionIntervalTypes] =
    useState()

  const getDataSelectOptions = useCallback(async () => {
    try {
      const resultIntervalTypes = await getIntervalType()
      const intervalTypes = Object.keys(resultIntervalTypes)
        .map((key) => ({
          value: resultIntervalTypes[key],
          title: enumCycleSettingIntervalType[resultIntervalTypes[key]]
        }))
        .filter((key) => key.value !== 1)

      const resultRepeatOnTypes = await getRepeatOnType()
      const repeatOnTypes = Object.keys(resultRepeatOnTypes).map((key) => ({
        value: resultRepeatOnTypes[key],
        title: enumCycleSettingRepeatOnType[resultRepeatOnTypes[key]]
      }))

      setPopulateSelectOptions({
        intervalTypes: intervalTypes,
        repeatOnTypes: repeatOnTypes
      })
    } catch (error) {
      console.error(error)
    }
  }, [])

  const onHandleSubmit = async (values) => {
    try {
      onOpenLoading()

      const startTime = `${moment(value).format('YYYY-MM-DD')}T${values.start}`
      const finishTime = `${moment(value).format('YYYY-MM-DD')}T${
        values.finish
      }`

      let repetition

      if (checkedEnableRepetition) {
        repetition = {
          each: Number(values.repetitionEach),
          interval: values.repetitionInterval,
          repeatOn: values.repetitionRepeatOn || 2, //repetir mesmo dia da semana
          repeatUntil: values.repetitionUntil
        }
      }

      const payload = {
        id: data?.id,
        name: values.name,
        description: '',
        annotation: '',
        start: new Date(startTime),
        finish: new Date(finishTime),
        repetition,
        completed: false,
        fromUserId: data?.fromUserId,
        toUserId: data?.toUserId,
        customerId: currentCustomerId,
        isPrivate: false,
        isActive: true
      }
      await putOneToOne(data?.id, payload)
      await getOneToOneSearch({
        customerId: currentCustomerId,
        userLeaderId: currentUserId,
        idUserSelected: data?.toUserId
      })
      onCallAlert({
        type: 'success',
        message: notifications.oneToOne.success.editOneToOne
      })
      onClose()
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      onCloseLoading()
    }
  }

  const getOneToOne = useCallback(async (id) => {
    try {
      setIsLoading(true)
      const response = await getOneToOneById(id)
      setCheckedEnableRepetition(response?.repetition)
      const year = moment(response?.start).format('YYYY')
      const month = moment(response?.start).format('MM') - 1
      const day = moment(response?.start).format('DD')
      onChange(new Date(year, month, day))
      setData(response)
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    idOneToOne && getOneToOne(idOneToOne)
  }, [getOneToOne, idOneToOne])

  useEffect(() => {
    getDataSelectOptions()
  }, [getDataSelectOptions])

  const handleChangeEnableRepetition = (event) => {
    setCheckedEnableRepetition(event.target.checked)
  }

  return (
    <Form
      onSubmit={(values) => onHandleSubmit(values)}
      validate={checkedEnableRepetition ? editOneToOneRepetition : editOneToOne}
      initialValues={{
        name: data?.name,
        start: moment(data?.start).format('LT'),
        finish: moment(data?.finish).format('LT'),
        repetitionEach: data?.repetition?.each,
        repetitionInterval: data?.repetition?.interval,
        repetitionRepeatOn: data?.repetition?.repeatOn,
        repetitionUntil: data?.repetition?.repeatUntil
      }}
      render={({ handleSubmit, form, valid }) => (
        <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
          <NewBoxWidget
            title="Agendar 1:1"
            options={
              <Tooltip placement="top" title="Fechar">
                <IconButton onClick={onClose}>
                  <CloseIcon htmlColor={theme.palette.primary.main} />
                </IconButton>
              </Tooltip>
            }
            actions={
              <Box className={classes.formButtons}>
                <Typography className={classes.labelRequired}>
                  *Campos obrigatórios
                </Typography>
                <Button
                  label="Alterar"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={!valid}
                />
              </Box>
            }
            width={500}
            height="100%"
            borderActions
          >
            <form onSubmit={handleSubmit}>
              {!isLoading && data && (
                <Grid container>
                  <CardCalendar
                    value={value}
                    onChange={onChange}
                    minDate={new Date()}
                  />
                  <Grid
                    container
                    item
                    style={{
                      alignItems: 'center',
                      direction: 'column'
                    }}
                    spacing={3}
                  >
                    <Grid item xs={6}>
                      <Input
                        name="start"
                        label="Horário de início*"
                        type="time"
                        InputLabelProps={{
                          shrink: true
                        }}
                        inputProps={{
                          step: 300 // 5 min
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        name="finish"
                        label="Horário de término*"
                        type="time"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        label="Adicionar assunto"
                        placeholder="Digite o assunto"
                        name="name"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Switch
                        name="isActive"
                        color="primary"
                        checked={checkedEnableRepetition}
                        onChange={handleChangeEnableRepetition}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                      <Typography variant="inherit">
                        Adicionar recorrência
                      </Typography>
                    </Grid>
                    {checkedEnableRepetition && (
                      <>
                        <Grid item xs={5}>
                          <Input
                            label="Número de intervalos"
                            type="number"
                            placeholder="Número de intervalos"
                            name="repetitionEach"
                          />
                        </Grid>
                        <Grid item xs={7}>
                          <Autocomplete
                            label="Período"
                            options={populateSelectOptions.intervalTypes}
                            placeholder="Período"
                            name="repetitionInterval"
                            getOptionLabel={(option) => option.title || ''}
                            onChange={(value) =>
                              setOneToOneRepetitionIntervalTypes(value)
                            }
                          />
                        </Grid>
                        {oneToOneRepetitionIntervalTypes !== 2 && (
                          <Grid item xs={7}>
                            <Autocomplete
                              label="Repetir"
                              options={populateSelectOptions.repeatOnTypes}
                              placeholder="Repetir"
                              name="repetitionRepeatOn"
                              getOptionLabel={(option) => option.title || ''}
                            />
                          </Grid>
                        )}
                        <Grid item xs={5}>
                          <DateInput
                            label="Até a data*"
                            name="repetitionUntil"
                            disablePast
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              )}
            </form>
          </NewBoxWidget>
        </MuiPickersUtilsProvider>
      )}
    />
  )
}
