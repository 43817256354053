import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    // flexFlow: 'column wrap',
    flexFlow: 'column',
    justifyContent: 'space-between',
    width: (props) => (props.fullBody ? '100%' : props.width),
    maxWidth: (props) =>
      !props.fullBody && !props.width ? '500px' : props.width,
    height: (props) => (props.fullBody ? '100%' : props.height),
    borderRadius: 20,
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    backgroundColor: '#FFFFFF'
  },

  content: {
    flex: 1,
    overflowX: (props) => !props.removeScroll && 'auto',
    scrollbarColor: `${theme.palette.primary.main} #CFD2D3`,
    padding: theme.spacing(3),
    '&::-webkit-scrollbar': {
      height: '4px',
      width: '4px',
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-track': {
      background: '#CFD2D3',
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: '10px'
    }
  },

  header: {
    padding: theme.spacing(2)
  },

  headerSpacing: {
    paddingRight: theme.spacing(3)
  },

  headerIconSpacing: {
    paddingRight: theme.spacing(2)
  },

  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 18,
    color: '#000000'
  },

  subtitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#000000'
  },

  options: {
    columnGap: 15
  },

  footer: {
    display: 'flex',
    padding: theme.spacing(2),
    borderTop: (props) =>
      props.borderActions && `1px solid ${theme.palette.primary.main}`
  },

  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    columnGap: 15
  },

  tabOptions: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.15)',
    zIndex: 1
  },

  noPadding: {
    padding: 0
  }
}))
