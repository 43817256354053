import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    width: '416px',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    background: '#ffffff',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
    padding: '20px',
    marginRight: '10px',
    overflowX: 'hidden'
  },
  headerInformations: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: -10,
    marginBottom: 10
  },
  btnClose: {
    marginRight: -10
  },
  boxFormTime: {
    display: 'flex',
    gap: 10
  },
  boxForm: {
    display: 'flex',
    gap: 20,
    flexDirection: 'column',
    padding: '15px 0px 0px 0px'
  },
  formButtons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  labelRequired: {
    color: '#6E7191',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(15)
  },
  boxScroll: {
    width: 'calc(100% + 40px)',
    marginLeft: -20,
    maxHeight: 'calc(100vh - 305px)',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px 10px 5px 0px',
    overflowX: 'hidden',
    overflowY: 'scroll',
    gap: 20,
    padding: '10px 20px 0px 20px',
    justifyContent: 'space-between',
    '&::-webkit-scrollbar': {
      width: 4,
      marginRight: -30
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: 10
    }
  }
}))
