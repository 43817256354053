import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  Box: {
    '& h3': {
      borderTop: 'solid 1px #F7F7FC',
      paddingTop: 10,
      margin: 0
    }
  },
  boxContent: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 5
  },
  boxContentBadgeOne: {
    height: '6px',
    display: 'flex',
    minWidth: '6px',
    borderRadius: '10px',
    marginTop: '0px',
    marginRight: '2px',
    backgroundColor: '#F7F7FC'
  },
  boxContentBadgeTwo: {
    height: '15px',
    display: 'flex',
    minWidth: '15px',
    borderRadius: '10px',
    marginTop: '0px',
    marginRight: '-12px',
    backgroundColor: '#F7F7FC'
  },

  boxContentText: {
    backgroundColor: '#F7F7FC',
    borderRadius: 10,
    marginBottom: 15,
    padding: 10,
    width: '100%',
    '& h4': {
      margin: 0
    },
    '& p': {
      margin: 0
    }
  },
  iconButtonClose: {
    width: '100%',
    height: 35,
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 4,
    padding: '0px 20px 0px 20px'
  },
  iconButtonAllComment: {
    width: 'auto',
    height: 35,
    marginTop: 0,
    marginBottom: 10,
    borderRadius: 4,
    padding: '0px 5px 0px 5px',
    '& p': {
      fontSize: 16,
      padding: 0,
      width: '100%'
    }
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'end',
    paddingTop: 15,
    height: 60,
    gap: 14
  },
  sectionComment: {
    display: 'flex',
    flexDirection: 'column',
    width: '400px',
    maxWidth: 500,
    marginLeft: -20,
    marginRight: -20,
    paddingLeft: 20,
    paddingRight: 20,
    maxHeight: 'calc(100vh - 400px)',
    margin: 0,
    padding: '20px 0px 0px 0px',
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingBottom: 5,
    '&::-webkit-scrollbar': {
      height: 6,
      width: 4,
      background: '#CFD2D3',
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-thumb:active': {
      background: theme.palette.primary.main,
      cursor: 'pointer'
    }
  },
  labelData: {
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(15),
    letterSpacing: theme.typography.pxToRem(0.15),
    color: 'gray',
    margin: '-10px 0 14px',
    textAlign: 'right'
  },
  name: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
    color: '#434343'
  },
  description: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 400,
    color: '#434343'
  }
}))
