import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  externalLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    padding: '0px !important'
  },
  link: {
    textDecoration: 'none',
    padding: '0px 0px',
    color: '#fff',
    '&:hover .MuiListItemIcon-root': {
      color: theme.palette.primary.main
    },
    '&:hover': {
      backgroundColor: '#fff',
      color: '#000 !important',
      '& > .MuiSvgIcon-root': {
        display: 'inline-block',
        color: '#000 !important'
      }
    }
  },
  expandWrapperLink: {
    textDecoration: 'none',
    padding: '0px 0px',
    color: '#fff',

    '&:hover .MuiListItemIcon-root': {
      color: theme.palette.primary.main
    },
    '&:hover': {
      backgroundColor: '#fff',
      color: '#000 !important',
      '& > .MuiSvgIcon-root': {
        display: 'inline-block',
        color: '#000 !important'
      }
    }
  },
  expandLink: {
    color: '#000',
    backgroundColor: '#fff',
    '& > .MuiListItemIcon-root': {
      color: theme.palette.primary.main
    },
    '&:hover': {
      backgroundColor: '#fff',
      color: '#000 !important'
    },
    '& > .MuiSvgIcon-root': {
      display: 'inline-block',
      color: '#000 !important'
    }
  },
  linkActive: {
    backgroundColor: '#fff'
  },
  linkNested: {
    paddingLeft: 0,
    color: '#000',
    '&:hover': {
      backgroundColor:
        theme.palette.type === 'dark'
          ? theme.palette.background.light
          : theme.palette.primary.main
    }
  },
  linkIcon: {
    color: theme.palette.type === 'dark' ? '#616168 !important' : '#ffffff',
    transition: theme.transitions.create('color'),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px !important',
    height: '64px',
    width: '64px'
  },
  linkIconActive: {
    color: theme.palette.primary.main,
    '&:[disabled]:hover': {
      backgroundColor: 'red'
    }
  },

  linkText: {
    padding: 0,
    transition: theme.transitions.create(['opacity', 'color']),
    fontSize: 14
  },
  linkTextActive: {
    color: '#000'
  },
  nestedList: {
    paddingLeft: 0,
    backgroundColor: '#fff'
  },
  sectionTitle: {
    marginLeft: theme.spacing(4.5),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: 1,
    backgroundColor:
      theme.palette.type === 'dark' ? 'rgba(151,151,151, .15)' : '#D8D8D880'
  },
  expand: {
    transform: 'rotate(180deg)',
    color: '#000'
  },
  expandWrapper: {
    color: '#fff !important',
    transition: theme.transitions.create('transform'),
    marginRight: '10px'
  },

  nestedMenu: {
    paddingLeft: 0
  },
  nestedMenuItem: {
    paddingLeft: 0
  }
}))
