import React from 'react'
import { useTheme } from '@material-ui/styles'
import { Button, Tooltip, NewBoxWidget } from 'src/components'
import { IconButton, Typography, Grid } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { Date as DateInput, Input } from 'src/components/Fields'
import { Form } from 'react-final-form'
import { developmentPlanBasicData } from 'src/utils/form/validations'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { useAlertContext } from 'src/context/AlertContext'
import MomentUtils from '@date-io/moment'
import makeStyles from './style'

export default function BasicData({
  onClose,
  nextOptionBasicData,
  payloadBasicData,
  setPayloadBasicData
}) {
  const theme = useTheme()
  const classes = makeStyles()

  const { onCallAlert } = useAlertContext()

  const initialValues = React.useMemo(
    () => ({
      name: payloadBasicData?.name || '',
      description: payloadBasicData?.description || '',
      createAt: payloadBasicData?.createAt,
      estimatedCompletion: payloadBasicData?.estimatedCompletion || ''
    }),
    [
      payloadBasicData?.createAt,
      payloadBasicData?.description,
      payloadBasicData?.estimatedCompletion,
      payloadBasicData?.name
    ]
  )

  const onHandleSave = (values) => {
    const createAt = new Date(values.createAt)
    const estimatedCompletion = new Date(values.estimatedCompletion)
    if (estimatedCompletion <= createAt) {
      onCallAlert({
        type: 'error',
        message: 'Data de previsão de término inválida'
      })
      return false
    }

    const payload = {
      name: values.name,
      description: values.description,
      estimatedCompletion: values.estimatedCompletion,
      createAt: values.createAt
    }
    setPayloadBasicData(payload)
    nextOptionBasicData(true)
  }

  return (
    <Form
      onSubmit={onHandleSave}
      initialValues={initialValues}
      validate={developmentPlanBasicData}
      render={({ handleSubmit, valid }) => (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <NewBoxWidget
            title="Dados básicos"
            subtitle="Adicione dados básicos do novo PDI"
            options={
              <Tooltip title="Fechar">
                <IconButton onClick={onClose}>
                  <CloseIcon htmlColor={theme.palette.primary.main} />
                </IconButton>
              </Tooltip>
            }
            actions={
              <div className={classes.actions}>
                <Typography variant="inherit">*Campos obrigatórios</Typography>
                <Button
                  label="Confirmar"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={!valid}
                />
              </div>
            }
            width={500}
            height="100%"
          >
            <form onSubmit={handleSubmit}>
              <Grid container item spacing={3}>
                <Grid item xs={12}>
                  <Input
                    label="Nome de Plano de ação*"
                    name="name"
                    placeholder="Digite seu Plano de ação"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    label="Descrição de Plano de ação*"
                    name="description"
                    placeholder="Digite a descrição de Plano de ação"
                  />
                </Grid>
                <Grid item xs={6}>
                  <DateInput
                    label="Data de criação"
                    placeholder="Digite a data"
                    name="createAt"
                    disablePast
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <DateInput
                    label="Previsão de término"
                    placeholder="Digite a data"
                    name="estimatedCompletion"
                    disablePast
                  />
                </Grid>
              </Grid>
            </form>
          </NewBoxWidget>
        </MuiPickersUtilsProvider>
      )}
    />
  )
}
