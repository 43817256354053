import React, { useState } from 'react'
import { useTheme } from '@material-ui/styles'
import { styled } from '@mui/material/styles'
import {
  NewBoxWidget,
  TableHead,
  TableToolbar,
  TableLoading,
  TableNotFound,
  Button
} from 'src/components'
import {
  Box,
  Table,
  TableRow,
  TableBody,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material'
import { getComparator, applySortFilter } from 'src/utils'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import makeStyles from './style'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottom: 'none',
    padding: '16px'
  }
}))

export function TabArchivedCompetences({ data, onUnarchiveCompetence }) {
  const theme = useTheme()
  const classes = makeStyles()
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const [filterName, setFilterName] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [isLoading, setIsLoading] = useState(false)

  const TABLE_HEAD = [
    { id: 'name', label: 'Nome', alignRight: false },
    { id: 'description', label: 'Descrição', alignRight: false },
    { id: 'added', label: 'Ação', alignRight: false }
  ]

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event) => {
    setPage(0)
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  const handleFilterByName = (event) => {
    setPage(0)
    setFilterName(event.target.value)
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0

  const filteredData = applySortFilter(
    data,
    getComparator(order, orderBy),
    filterName
  )

  const isNotFound = !filteredData?.length && !!filterName && data

  return (
    <div className={classes.containerTab}>
      <NewBoxWidget
        borderActions
        style={{ boxShadow: 'none', background: 'transparent' }}
        noBodyPadding
        width={'100%'}
        minHeight="75vh"
        options={
          <TableToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
          />
        }
        actions={
          <Box className={classes.actions}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              labelRowsPerPage={'Exibir'}
              component="div"
              count={data?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        }
      >
        <TableContainer
          sx={{
            maxHeight: '320px',
            overflow: 'inherit',
            '&::-webkit-scrollbar': {
              height: '4px',
              width: '4px',
              borderRadius: '10px'
            },
            '&::-webkit-scrollbar-track': {
              background: '#CFD2D3',
              borderRadius: '10px'
            },
            '&::-webkit-scrollbar-thumb': {
              background: `${theme.palette.primary.main}`,
              borderRadius: '10px'
            }
          }}
        >
          <Table stickyHeader>
            <TableHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={data?.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row) => {
                  const { id, name, description, isActive } = row
                  return (
                    <TableRow
                      hover
                      key={id}
                      tabIndex={-1}
                      role="checkbox"
                      // className={clsx(
                      //   classes.listGroupsBodyTable,
                      //   classes.listGroupsBodyTableTitle,
                      // )}
                      style={{ cursor: 'pointer' }}
                      onClick={() => onUnarchiveCompetence({ id, name })}
                    >
                      <StyledTableCell align="left">{name}</StyledTableCell>
                      <StyledTableCell align="left">
                        {description}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Button
                          label="Desarquivar"
                          variant="dark"
                          onClick={() => console.log('desarquivar')}
                        />
                      </StyledTableCell>
                    </TableRow>
                  )
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell
                    colSpan={12}
                    style={{ height: 53 * emptyRows, border: 'none' }}
                  />
                </TableRow>
              )}
            </TableBody>
            {isLoading && (
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={12}
                    sx={{
                      border: 'none',
                      height: 'calc(50vh - 220px)'
                    }}
                  >
                    <TableLoading />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            {data.length === 0 && !isLoading && (
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={12}
                    sx={{ border: 'none' }}
                  >
                    <TableNotFound />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            {isNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={12}
                    sx={{ py: 10, border: 'none' }}
                  >
                    <Typography variant="h6" paragraph>
                      Nenhum registro encontrado!
                    </Typography>

                    <Typography variant="body2">
                      Nenhum resultado encontrado para &nbsp;
                      <strong>&quot;{filterName}&quot;</strong>.
                      <br /> Tente verificar erros de digitação.
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </NewBoxWidget>
    </div>
  )
}
