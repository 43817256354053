import React from 'react'
import { Typography, Box } from '@material-ui/core'
import { Button } from 'src/components'
import useStyles from '../styles'

function ShowMessage({ onNextScreen, onPreviousScreen, email }) {
  const classes = useStyles()

  const textEmail = email ? `, para ${email}, ` : ''
  return (
    <Box>
      <Typography variant="h2" className={classes.greeting}>
        Nova senha
      </Typography>
      <div className={classes.formDividerContainer}>
        <p className={classes.formInfor}>
          Enviamos um e-mail {textEmail} com um novo acesso. Confira sua caixa
          de entrada ou de spam para seguir.
        </p>
      </div>
      <div className={classes.formButtons}>
        <Button label="Corrigir e-mail" onClick={onPreviousScreen} />
        <Button label="Avançar" onClick={onNextScreen} />
      </div>
    </Box>
  )
}

export default ShowMessage
