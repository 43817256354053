import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  container: {
    width: 'auto'
  },
  card: {
    minWidth: '100%',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '10px'
  },
  content: {
    padding: 20
  },
  display: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    backgroundColor: '#F7F7FC',
    borderRadius: '10px'
  },
  avatar: {
    marginRight: 10
  },
  text: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    color: '#000000'
  },
  name: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 14,
    color: '#434343'
  },
  occupation: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#787878'
  }
}))
