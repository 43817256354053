import React, { useState } from 'react'
import useAuth from 'src/hooks/useAuth'
import { useTheme } from '@material-ui/styles'
import { useNavigate } from 'react-router-dom'
import { alpha } from '@mui/material/styles'
import { Box, Divider, Typography, Stack, MenuItem } from '@mui/material'
import { IconButton } from '@material-ui/core'
import {
  PersonOutlineOutlined as PersonOutlineOutlinedIcon,
  AccountCircleOutlined as AccountCircleOutlinedIcon,
  ExitToAppOutlined as ExitToAppOutlinedIcon
} from '@mui/icons-material'
import { PATH_DASHBOARD, PATH_AUTH } from 'src/routes/paths'
import { useSettingsContext } from 'src/context/SettingsContext'
import { useCurrentPeriodContext } from 'src/context/CurrentPeriodContext'
import MenuPopover from 'src/components/MenuPopover'
import { Avatar } from 'src/components'
import { getImageNameColor } from 'src/utils'
import makeStyles from './styles'

const OPTIONS = [
  {
    label: 'Meu perfil',
    linkTo: PATH_DASHBOARD.profile,
    icon: <AccountCircleOutlinedIcon fontSize="small" />
  }
]

export default function AccountPopover() {
  const theme = useTheme()
  const classes = makeStyles()
  const navigate = useNavigate()

  const { user, logout } = useAuth()
  const { onResetSetting } = useSettingsContext()
  const { onResetCurrentPeriod } = useCurrentPeriodContext()

  const [openPopover, setOpenPopover] = useState(null)

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget)
  }

  const handleClosePopover = () => {
    setOpenPopover(null)
  }

  const handleLogout = async () => {
    try {
      logout()
      onResetSetting()
      onResetCurrentPeriod()
      navigate(PATH_AUTH.login, { replace: true })
      handleClosePopover()
    } catch (error) {
      console.error(error)
    }
  }

  const handleClickItem = (path) => {
    handleClosePopover()
    navigate(path)
  }

  return (
    <>
      <IconButton
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8)
            }
          })
        }}
      >
        <PersonOutlineOutlinedIcon
          sx={{ width: 34, height: 34, color: '#FFFFFF' }}
        />
      </IconButton>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 200, p: 0 }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          sx={{ my: 1.5, px: 2.5 }}
        >
          <Avatar
            src={user?.imageProfile}
            aria-label={user?.name}
            alt={user?.name}
            color={getImageNameColor(user?.name).color}
            className={classes.avatar}
          >
            {getImageNameColor(user?.name).name}
          </Avatar>
          <Typography variant="subtitle2" noWrap>
            {user?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider style={{ borderColor: theme.palette.primary.main }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              onClick={() => handleClickItem(option.linkTo)}
            >
              {option.icon}
              {option.label}
            </MenuItem>
          ))}
          <MenuItem onClick={handleLogout} sx={{ mt: 0.5 }}>
            <ExitToAppOutlinedIcon fontSize="small" />
            Sair
          </MenuItem>
        </Stack>
      </MenuPopover>
    </>
  )
}
