import React, { useState } from 'react'
import { IconButton } from '@material-ui/core'
import { Paper, InputBase } from '@mui/material'
import { Add as AddIcon } from '@material-ui/icons'
import { useAlertContext } from 'src/context/AlertContext'

export default function AddTarget({ addTargets }) {
  const { onCallAlert } = useAlertContext()

  const [description, setDescription] = useState('')

  const create = (description) => {
    if (description.length > 3) {
      const actionObj = {
        id: Math.random(3).toString(),
        status: 1,
        description: description,
        activities: []
      }
      addTargets(actionObj)
      setDescription('')
      document.getElementById('target-description').value = ''
    } else {
      onCallAlert({
        type: 'error',
        message: 'O descrição da ação deve conter ao menos 4 caracteres!'
      })
    }
  }

  return (
    <Paper
      component="form"
      sx={{
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 2,
        height: 56,
        border: '1px solid #D9DBE9 ',
        boxShadow: 'none',
        color: '#000000'
      }}
    >
      <InputBase
        sx={{ ml: 2, flex: 1 }}
        id="target-description"
        placeholder="Adicionar meta"
        onChange={(e) => setDescription(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            create(description)
            e.preventDefault()
          }
        }}
        fullWidth
        autoFocus
      />
      <IconButton
        type="button"
        onClick={() => create(description)}
        disabled={!description}
      >
        <AddIcon />
      </IconButton>
    </Paper>
  )
}
