import React, { useState, useCallback, useEffect } from 'react'
import { useTheme } from '@material-ui/styles'
import clsx from 'clsx'
import { useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { ArrowForwardIos as ArrowForwardIosIcon } from '@material-ui/icons'
import {
  Box,
  Table,
  TableRow,
  TableBody,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import {
  getUserGroups,
  getUserGroupNameById
} from 'src/services/hooks/userGroup/useUserGroup'
import {
  NewBoxWidget,
  TableHead,
  TableToolbar,
  TableLoading,
  TableNotFound
} from 'src/components'
import { getComparator, applySortFilter } from 'src/utils'
import makeStyles from '../style'

const TABLE_HEAD = [
  { id: 'name', label: 'Nome do grupo', alignRight: false },
  { id: 'action', label: '', alignRight: false }
]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottom: 'none',
    padding: '16px'
  }
}))

export default function Groups({ onSelectGroup, onGetGroupSelected }) {
  const theme = useTheme()
  const classes = makeStyles()

  const { group } = useParams()

  const [data, setData] = useState([])
  const [name, setName] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isSelected, setIsSelected] = useState(false)

  const [page, setPage] = useState(0)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const [filterName, setFilterName] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setPage(0)
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  const handleFilterByName = (event) => {
    setPage(0)
    setFilterName(event.target.value)
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0

  const filteredData = applySortFilter(
    data,
    getComparator(order, orderBy),
    filterName
  )

  const isNotFound = !filteredData?.length && !!filterName && data

  const listUserGroups = useCallback(async () => {
    try {
      setIsLoading(true)
      const response = await getUserGroups()
      setData(response)
      setIsLoading(false)
    } catch (e) {
      console.error(e)
      setIsLoading(false)
    }
  }, [])

  const getUserGroupName = useCallback(async (id) => {
    try {
      const response = await getUserGroupNameById(id)
      setName(response)
    } catch (e) {
      console.error(e)
    }
  }, [])

  useEffect(() => {
    listUserGroups()

    if (group) {
      getUserGroupName(group)
      onChangeGroup(group, name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserGroupName, group, listUserGroups, name])

  function onChangeGroup(id, name) {
    onGetGroupSelected({ id, name })

    setIsSelected(id)
    if (!isSelected) {
      onSelectGroup(true)
    }

    //validar novamente em outro momento
    // navigate('/groups/view/' + id)
  }

  return (
    <div className={classes.listSettingsGroup}>
      <NewBoxWidget
        title="Seus grupos"
        subtitle="Analise e edite seus grupos:"
        options={
          <TableToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
          />
        }
        actions={
          <Box className={classes.actions}>
            <Box />
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              labelRowsPerPage={'Registros por página'}
              component="div"
              count={data?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        }
        borderActions
        noBodyPadding
        height={500}
      >
        <div className={classes.listGroupsContainer}>
          <TableContainer
            sx={{
              maxHeight: '320px',
              overflow: 'inherit',
              '&::-webkit-scrollbar': {
                height: '4px',
                width: '4px',
                borderRadius: '10px'
              },
              '&::-webkit-scrollbar-track': {
                background: '#CFD2D3',
                borderRadius: '10px'
              },
              '&::-webkit-scrollbar-thumb': {
                background: `${theme.palette.primary.main}`,
                borderRadius: '10px'
              }
            }}
          >
            <Table stickyHeader>
              <TableHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={data?.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {filteredData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row) => {
                    const { id, name } = row
                    return (
                      <TableRow
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        className={clsx(
                          classes.listGroupsBodyTable,
                          classes.listGroupsBodyTableTitle,
                          isSelected === (id || group)
                            ? classes.selected
                            : classes.hovered
                        )}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          onChangeGroup(id, name)
                        }}
                      >
                        <StyledTableCell align="left">{name}</StyledTableCell>
                        <StyledTableCell align="right">
                          <ArrowForwardIosIcon htmlColor="#787878" />
                        </StyledTableCell>
                      </TableRow>
                    )
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell
                      colSpan={12}
                      style={{ height: 53 * emptyRows, border: 'none' }}
                    />
                  </TableRow>
                )}
              </TableBody>
              {isLoading && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={12}
                      sx={{
                        border: 'none',
                        height: 'calc(50vh - 220px)'
                      }}
                    >
                      <TableLoading />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              {data.length === 0 && !isLoading && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={12}
                      sx={{ border: 'none' }}
                    >
                      <TableNotFound />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={12}
                      sx={{ py: 10, border: 'none' }}
                    >
                      <Typography variant="h6" paragraph>
                        Nenhum registro encontrado!
                      </Typography>

                      <Typography variant="body2">
                        Nenhum resultado encontrado para &nbsp;
                        <strong>&quot;{filterName}&quot;</strong>.
                        <br /> Tente verificar erros de digitação.
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </div>
      </NewBoxWidget>
    </div>
  )
}
