import PropTypes from 'prop-types'
import { CardImpressionsCelebrations } from "src/components";


TabCelebrations.propTypes = {
  data: PropTypes.array,
  userId: PropTypes.string,
  dataUsers: PropTypes.array,
}

export function TabCelebrations({
  data = [],
  userId,
  dataUsers = [],
}) {
  return (
    <>
      <CardImpressionsCelebrations
        items={data}
        userId={userId}
        dataUsers={dataUsers}
      />
    </>
);
}
