import React, { useState, useEffect, useCallback } from 'react'
import useAuth from 'src/hooks/useAuth'
import { useTheme } from '@material-ui/styles'
import { useParams, useNavigate } from 'react-router-dom'
import 'moment/locale/pt-br'
import moment from 'moment'
import { IconButton, Box, Typography } from '@material-ui/core'
import {
  Close as CloseIcon,
  ZoomOutMap as ZoomOutMapIcon
} from '@material-ui/icons'
import {
  Add as AddIcon,
  CalendarToday as CalendarTodayIcon,
  ArrowForwardIos as ArrowForwardIosIcon
} from '@mui/icons-material'
import {
  Tooltip,
  Modal,
  Button,
  NewBoxWidget,
  CardOneToOne,
  ButtonGroup
} from 'src/components'
import NewOneToOne from './Partials/NewOneToOne'
import EditOneToOne from './Partials/EditOneToOne'
import { postOneToOneSearch } from 'src/services/hooks/oneToOne/useOneToOne'
import { postFeedbackSearch } from 'src/services/hooks/feedback/useFeedback'
import { Celebrations } from './Partials/Celebrations'
import { getCompetenceGrouper } from 'src/services/hooks/competenceGrouper/useCompetenceGrouper'
import { getUserById } from 'src/services/hooks/users/useUsers'
import Feedbacks from './Partials/Feedbacks'
import { FILTER_BUTTONS_LEADER, FILTER_BUTTONS_LED } from 'src/utils/constants'
import groupBy from 'lodash/groupBy'
import makeStyles from './style'

export default function Impressions({ onClose }) {
  const theme = useTheme()
  const classes = makeStyles()

  const navigate = useNavigate()

  const { user } = useAuth()
  const { id: userId } = useParams()
  const currentCustomerId = user?.customerId
  const currentUserId = user?.id
  const currentRole = user?.occupation
  const [roleAddOneToOne] = useState([3, 4])
  const [roleEditOneToOne] = useState([3, 4])
  const [roleAddFeedback] = useState([3, 4])

  const [dataNotCompleted, setDataNotCompleted] = useState()
  const [dataCompleted, setDataCompleted] = useState()
  const [dataUser, setDataUser] = useState()
  const [dataFeedback, setDataFeedback] = useState([])

  const [selectedButton, setSelectedButton] = useState(0)
  const [onNewScheduleOneOne, setNewScheduleOneOne] = useState(false)
  const [isOpenModalEditOneOne, setIsOpenModalEditOneOne] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const [isSelected, setIsSelected] = useState(0)
  const [openDetailsOneToOne, setOpenDetailsOneToOne] = useState(false)
  const isTabOneToOne = selectedButton === 0
  const isTabFeedbacks = selectedButton === 1
  const isTabCelebrations = selectedButton === 2

  const [filterSelected, setFilterSelected] = useState()

  function onHandleNewScheduleOneOne() {
    setNewScheduleOneOne(!onNewScheduleOneOne)
  }

  function onHandleScheduleOneOneModal() {
    setIsOpenModalEditOneOne(!isOpenModalEditOneOne)
  }

  function onOpenDetailsOneToOne(id) {
    setIsSelected(id)
    setIsOpenModalEditOneOne(true)
  }

  function handleViewOneToOne(id) {
    navigate(`/view/one-to-one/${id}`)
  }

  function onCloseDetailsOneToOne() {
    setIsSelected(0)
    setIsOpenModalEditOneOne(false)
    setOpenDetailsOneToOne(false)
  }

  const getDataUser = useCallback(async (userId) => {
    try {
      setIsLoading(true)
      const { data } = await getUserById(userId)
      setDataUser(data?.data)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }, [])

  const getOneToOneSearch = useCallback(
    async ({ customerId, userLeaderId }) => {
      try {
        setIsLoading(true)
        let fromUserId
        if (userId !== userLeaderId) {
          fromUserId = userLeaderId
        }
        const payloadNotCompleted = {
          customerId,
          fromUserId,
          toUserId: userId,
          completed: false
        }

        const payloadCompleted = {
          customerId,
          fromUserId,
          toUserId: userId,
          completed: true
        }
        const responseNotCompleted = await postOneToOneSearch(
          payloadNotCompleted
        )
        const responseCompleted = await postOneToOneSearch(payloadCompleted)
        const notCompleted = responseNotCompleted?.filter((item) => item)[0]
        const completed = responseCompleted?.filter((item) => item)
        const completedOrderBy = completed.sort((a, b) =>
          a.updateAt > b.updateAt ? -1 : 1
        )[0]
        setDataNotCompleted(notCompleted)
        setDataCompleted(completedOrderBy)
        setIsLoading(false)
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    },
    []
  )

  const getFeedbackSearch = useCallback(
    async (customerId, userId) => {
      try {
        setIsLoading(true)
        const payload = {
          customerId: customerId,
          toUserId: userId
        }
        const response = await postFeedbackSearch(payload)

        if (filterSelected) {
          const dataObject = response.map((currentData) => {
            const { competencies } = currentData
            return [...competencies]
          })
          const competenciesMerged = dataObject.flatMap((e) => [...e])

          const { data: competenceGrouper } = await getCompetenceGrouper({})
          const filteredCompetences = competenceGrouper?.data?.map((item) => {
            const filter = item.competences.filter((elem) =>
              competenciesMerged.includes(elem)
            )
            return {
              ...item,
              competences: filter
            }
          })
          const dataCompetencesCategory = filteredCompetences.filter(
            (item) => !!item.competences.length
          )
          const dataCategory = groupBy(dataCompetencesCategory, 'category')
          const dataListObject = Object.entries(dataCategory).map(
            (currentData) => {
              const [categoryId, item] = currentData
              return {
                categoryId: categoryId,
                competences: item
              }
            }
          )

          const dataList = dataListObject
            .filter((item) => item.categoryId === filterSelected.toString())
            .map((item) => item.competences)
            .flat()

          const dataListCompetences = dataList.map((item) => item.competences)
          const dataCompetences = dataListCompetences.flatMap((e) => [...e])

          const resResult = response?.map((item) => {
            const filter = item.competencies.filter((elem) =>
              dataCompetences.includes(elem)
            )
            return {
              ...item,
              competences: filter
            }
          })
          const responseFiltered = resResult.filter(
            (item) => !!item.competences.length
          )
          setDataFeedback(responseFiltered.slice(0, 3))
        } else {
          setDataFeedback(response.slice(0, 3))
        }
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    },
    [filterSelected]
  )

  useEffect(() => {
    if (currentCustomerId && currentUserId && userId) {
      getDataUser(userId)
      getOneToOneSearch({
        customerId: currentCustomerId,
        userLeaderId: currentUserId
      })
      getFeedbackSearch(currentCustomerId, userId)
    }
  }, [
    currentCustomerId,
    currentUserId,
    getDataUser,
    getFeedbackSearch,
    getOneToOneSearch,
    userId
  ])

  return (
    <>
      <Modal
        isOpen={isOpenModalEditOneOne}
        onHandleClose={() => {
          onCloseDetailsOneToOne()
        }}
        title="Alterar agendamento"
        subTitle="Você deseja mesmo remarcar o 1:1 agendado?"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ marginTop: 20, columnGap: 10 }}
        >
          <Button
            label="Não, obrigado"
            variant="dark"
            onClick={() => {
              onCloseDetailsOneToOne()
            }}
          />
          <Button
            label="Sim"
            onClick={() => {
              onHandleScheduleOneOneModal()
              setOpenDetailsOneToOne(true)
            }}
          />
        </Box>
      </Modal>
      <NewBoxWidget
        title="Impressões"
        options={
          <>
            <Tooltip title="Ver completo">
              <IconButton
                className={classes.iconButton}
                onClick={() => navigate(`/impressions/${userId}`)}
              >
                <ZoomOutMapIcon htmlColor={theme.palette.primary.main} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Fechar">
              <IconButton onClick={onClose} className={classes.iconButton}>
                <CloseIcon htmlColor={theme.palette.primary.main} />
              </IconButton>
            </Tooltip>
          </>
        }
        tabOptions={
          <ButtonGroup
            buttons={[
              {
                key: 0,
                label: '1:1'
              },
              {
                key: 1,
                label: 'Feedbacks'
              },
              {
                key: 2,
                label: 'Celebrações'
              }
            ]}
            setSelectedButton={setSelectedButton}
            selectedButton={selectedButton}
          />
        }
        width={450}
        height="100%"
      >
        <Box style={{ height: '100%' }}>
          <Box className={classes.content}>
            {isTabOneToOne && (
              <>
                <Box className={classes.contentButton}>
                  <>
                    {roleAddOneToOne.includes(currentRole) && (
                      <>
                        {!isLoading &&
                          !dataNotCompleted &&
                          currentUserId !== userId && (
                            <button
                              className={
                                onNewScheduleOneOne
                                  ? classes.buttonAddOneToOneSelected
                                  : classes.buttonAddOneToOne
                              }
                              onClick={onHandleNewScheduleOneOne}
                            >
                              Agendar próximo
                              {onNewScheduleOneOne ? (
                                <Tooltip placement="top" title="Fechar">
                                  <IconButton
                                    onClick={onHandleNewScheduleOneOne}
                                    className={classes.iconButton}
                                  >
                                    <CloseIcon htmlColor="#FFFFFF" />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  placement="top"
                                  title="Agendar próximo"
                                >
                                  <IconButton
                                    onClick={onHandleNewScheduleOneOne}
                                    className={classes.iconButton}
                                  >
                                    <CalendarTodayIcon
                                      htmlColor={theme.palette.primary.main}
                                    />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </button>
                          )}
                      </>
                    )}
                  </>
                  {!isLoading && dataNotCompleted && (
                    <button
                      className={
                        isSelected === dataNotCompleted.id
                          ? classes.buttonSelected
                          : classes.button
                      }
                    >
                      1:1 Agendado
                      <div
                        className={
                          isSelected === dataNotCompleted.id
                            ? classes.dataSelect
                            : classes.data
                        }
                      >
                        {roleEditOneToOne.includes(currentRole) ? (
                          <Tooltip placement="top" title="Alterar agendamento">
                            <Box
                              onClick={() => {
                                if (isSelected !== dataNotCompleted.id) {
                                  setOpenDetailsOneToOne(false)
                                  onOpenDetailsOneToOne(dataNotCompleted.id)
                                }
                              }}
                              className={
                                isSelected === dataNotCompleted.id
                                  ? classes.buttonDetailsOneToOneSelect
                                  : classes.buttonDetailsOneToOne
                              }
                            >
                              {moment(dataNotCompleted?.start).format('L')} -{' '}
                              {moment(dataNotCompleted?.start).format('LT')}
                            </Box>
                          </Tooltip>
                        ) : (
                          <Box
                            className={
                              isSelected === dataNotCompleted.id
                                ? classes.buttonDetailsOneToOneSelect
                                : classes.buttonDetailsOneToOne
                            }
                          >
                            {moment(dataNotCompleted?.start).format('L')} -{' '}
                            {moment(dataNotCompleted?.start).format('LT')}
                          </Box>
                        )}
                        <Tooltip placement="top" title="Acessar 1:1">
                          <IconButton
                            onClick={() =>
                              handleViewOneToOne(dataNotCompleted.id)
                            }
                            className={
                              isSelected === dataNotCompleted.id
                                ? classes.iconButtonSelect
                                : classes.iconButton
                            }
                          >
                            <ArrowForwardIosIcon
                              className={
                                isSelected === dataNotCompleted.id
                                  ? classes.iconSelect
                                  : classes.icon
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </button>
                  )}
                </Box>
                {!isLoading && dataCompleted && (
                  <>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                      className={classes.dateSection}
                    >
                      <Typography variant="title">
                        {dataCompleted?.name}
                      </Typography>
                      <Typography>
                        {moment(dataCompleted?.start).format('LL')}
                      </Typography>
                    </Box>
                    <CardOneToOne
                      oneToOneId={dataCompleted.id}
                      userId={dataCompleted.fromUserId}
                      text={dataCompleted.description}
                    />
                    <Box className={classes.showMoreContainer}>
                      <Button
                        type="button"
                        className={classes.buttonShowMore}
                        onClick={() => navigate(`/impressions/${userId}`)}
                        label="Ver mais"
                        variant="underline"
                      />
                    </Box>
                  </>
                )}
              </>
            )}
            {isTabFeedbacks && (
              <>
                {roleAddFeedback.includes(currentRole) && (
                  <Box className={classes.contentButton}>
                    <button
                      className={classes.buttonAddOneToOne}
                      onClick={() => navigate(`/feedback/add/${userId}`)}
                    >
                      Enviar um Feedback
                      <Tooltip placement="top" title="Enviar um feedback">
                        <IconButton
                          onClick={() => navigate(`/feedback/add/${userId}`)}
                          className={classes.iconButton}
                        >
                          <AddIcon htmlColor={theme.palette.primary.main} />
                        </IconButton>
                      </Tooltip>
                    </button>
                  </Box>
                )}
                <Box className={classes.filterContainer}>
                  <Typography className={classes.lastFeedbackTile}>
                    Últimos Feedbacks
                  </Typography>
                  <Box className={classes.filtersButtons}>
                    {(dataUser?.occupation === 3
                      ? FILTER_BUTTONS_LEADER
                      : FILTER_BUTTONS_LED
                    ).map((item) => (
                      <Tooltip title={item.label} key={item.value}>
                        <div
                          className={
                            filterSelected != null &&
                            filterSelected === item.value
                              ? classes.buttonFilterSelected
                              : classes.buttonFilter
                          }
                          onClick={
                            filterSelected === item.value
                              ? () => setFilterSelected(null)
                              : () => setFilterSelected(item.value)
                          }
                          style={{ backgroundColor: item.color }}
                        />
                      </Tooltip>
                    ))}
                  </Box>
                </Box>
                {dataFeedback?.map((item, index) => (
                  <Feedbacks data={item} key={index} />
                ))}
                {dataFeedback.length > 0 && (
                  <Box className={classes.showMoreContainer}>
                    <Button
                      type="button"
                      className={classes.buttonShowMore}
                      onClick={() => navigate(`/impressions/${userId}`)}
                      label="Ver mais"
                      variant="underline"
                    />
                  </Box>
                )}
              </>
            )}
            {isTabCelebrations && (
              <Celebrations
                customerId={currentCustomerId}
                fromUserId={userId}
              />
            )}
          </Box>
        </Box>
      </NewBoxWidget>
      {onNewScheduleOneOne && (
        <NewOneToOne
          onClose={onHandleNewScheduleOneOne}
          getOneToOneSearch={getOneToOneSearch}
          idUserSelected={userId}
        />
      )}
      {openDetailsOneToOne && (
        <EditOneToOne
          onClose={onCloseDetailsOneToOne}
          getOneToOneSearch={getOneToOneSearch}
          idOneToOne={isSelected}
        />
      )}
    </>
  )
}
