import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  iconButton: {
    padding: 0,
    width: 40,
    height: 40,
    color: theme.palette.primary.main,
    '&:hover': {
      color: '#FFFFFF'
    }
  },
  titleLineChart: {
    color: '#000000',
    borderBottom: 'solid 1px #cfd2d3',
    margin: '0px 0px 7px 0px',
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.8,
    paddingBottom: 7,
    paddingLeft: 10
  },
  notFoundMetricsIcon: {
    fontSize: 52,
    color: '#CFD2D3'
  },
  notFoundMetricsTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 18,
    color: theme.palette.primary.main
  },
  notFoundMetricsDescription: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 18,
    color: '#787878'
  }
}))
