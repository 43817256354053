import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  iconButton: {
    padding: 0,
    width: 40,
    height: 40,
    color: theme.palette.primary.main,
    '&:hover': {
      color: '#FFFFFF'
    }
  },
  addPdi: {
    width: '100%',
    paddingLeft: 15,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#F7F7FC',
    borderRadius: theme.typography.pxToRem(40),
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(21),
    color: '#000000',
    cursor: 'pointer'
  },
  buttonPlanSelected: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '3px solid',
    borderColor: theme.palette.primary.main,
    animation: '0.3s',
    transition: '0.3s',
    width: '100%',
    padding: '15px 20px',
    background: '#F7F7FC',
    borderRadius: theme.typography.pxToRem(10),
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(21),
    letterSpacing: theme.typography.pxToRem(0.74976),
    color: '#000000',
    cursor: 'pointer'
  },
  buttonPlan: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '15px 20px ',
    border: '3px solid #F7F7FC',
    background: '#F7F7FC',
    borderRadius: theme.typography.pxToRem(10),
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(21),
    letterSpacing: theme.typography.pxToRem(0.74976),
    color: '#000000',
    cursor: 'pointer',
    animation: '0.3s',
    transition: '0.3s'
  },
  planProgress: {
    width: '20%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end'
  },
  buttonDescription: {
    width: '100%',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    h6: {
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(15),
      margin: 0
    }
  },
  content: {
    display: 'flex',
    marginTop: 20
  }
}))
