export const enumCategory = {
  1: 'Performance',
  2: 'Conhecimento',
  3: 'Comportamento',
  4: 'Liderança'
}

export const enumCategoryColor = {
  1: '#FFA520',
  2: '#D81B60',
  3: '#9C27B0',
  4: '#0063BF'
}

export const enumCategoryNameColor = {
  performance: '#FFA520',
  conhecimento: '#D81B60',
  comportamento: '#9C27B0',
  liderança: '#0063BF'
}

export const enumCategoryId = {
  Performance: 1,
  Conhecimento: 2,
  Comportamento: 3,
  Liderança: 4
}
