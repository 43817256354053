import axios, { tokenAuthorization } from 'src/utils/axios'
import {
  USER_CHANGE_PASSWORD,
  USER_FORGOT_PASSWORD,
  USER_URL
} from 'src/services/api'
import { messageResponse } from 'src/utils/messageResponse'

export async function getUsers() {
  try {
    const result = await axios.get(USER_URL, tokenAuthorization())
    return result?.data?.data
  } catch (e) {
    const messageError = messageResponse(e?.response)
    console.log(messageError)
  }
}

export async function putUser(body) {
  const { id } = body
  const token = tokenAuthorization().headers
  const parameters = {
    method: 'PUT',
    headers: token
  }
  const result = await axios.put(USER_URL + `/${id}`, body, parameters)
  return result
}

export async function postChangePassword(body) {
  const token = tokenAuthorization().headers
  const parameters = {
    method: 'POST',
    headers: token
  }
  const response = await axios.post(USER_CHANGE_PASSWORD, body, parameters)
  return response
}

export async function getUserById(id) {
  const response = await axios.get(USER_URL + `/${id}`, tokenAuthorization())
  return response
}

export async function postUser(body) {
  const token = tokenAuthorization().headers
  const parameters = {
    method: 'POST',
    headers: token
  }
  const response = await axios.post(USER_URL, body, parameters)
  return response
}

export async function postForgotPassword(body) {
  const parameters = {
    method: 'POST'
  }
  const response = await axios.post(USER_FORGOT_PASSWORD, body, parameters)
  return response
}
