import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  containerNotFound: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    rowGap: '10px',
  },
  notFoundIcon: {
    fontSize: 52,
    color: '#CFD2D3'
  },
  notFoundTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 18,
    color: theme.palette.primary.main
  },
  notFoundDescription: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 18,
    color: '#787878'
  },
}))
