import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    width: '100%',
    marginTop: 5
  },
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    justifyContent: 'space-between',
    padding: '5px 10px 5px 20px',
    background: '#F7F7FC',
    marginTop: 15,
    gap: 20,
    borderRadius: 100,
    '& section': {
      display: 'flex',
      gap: 8,
      width: '100%',
      justifyContent: 'start'
    }
  },
  titleOptionsList: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: theme.typography.pxToRem(15),
    lineHeight: theme.typography.pxToRem(18),
    letterSpacing: theme.typography.pxToRem(0.74976),
    color: '#787878',
    marginTop: 10,
    marginBottom: 10
  },
  optionsList: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(15),
    lineHeight: theme.typography.pxToRem(18),
    letterSpacing: theme.typography.pxToRem(0.74976),
    color: theme.palette.primary.main,
    marginTop: 10,
    marginBottom: 10
  },
  boxIcon: {
    height: 40,
    width: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  selectedCompetences: {
    width: '100%',
    minHeight: 40,
    flexWrap: 'wrap',
    borderRadius: '20px',
    padding: '10px 20px',
    marginTop: 20,
    gap: 5,
    transition: '0.5s',
    background: '#F7F7FC',
    border: '1px solid',
    borderColor: theme.palette.primary.main
  }
}))
