import React, { useCallback, useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import useAuth from 'src/hooks/useAuth'
import RoleBasedGuard from 'src/guards/RoleBasedGuard'
import { useAlertContext } from 'src/context/AlertContext'
import { useLoadingContext } from 'src/context/LoadingContext'
import { messageResponse } from 'src/utils/messageResponse'
import { postOneToOneSearch } from 'src/services/hooks/oneToOne/useOneToOne'
import { postFeedbackSearch } from 'src/services/hooks/feedback/useFeedback'
import { getCompetenceGrouper } from 'src/services/hooks/competenceGrouper/useCompetenceGrouper'
import { getUserById, getUsers } from 'src/services/hooks/users/useUsers'
import { useTheme } from '@material-ui/styles'
import {
  HeaderBreadcrumbs,
  Tooltip,
  Helmet,
  CalendarPeriodImpressions
} from 'src/components'
import { Box, IconButton } from '@material-ui/core'
import {
  Add as AddIcon,
  MarkChatReadOutlined as MarkChatReadOutlinedIcon
} from '@mui/icons-material'
import { enumFilterType } from 'src/@enum/impressions'
import useGlobalUseStyles from 'src/themes/globalUseStyles'
import groupBy from 'lodash/groupBy'
import makeStyles from './style'

import { ImpressionsFilter } from './Partials/Filter'
import { TabOneToOne } from './Partials/TabOneToOne'
import { TabFeedbacks } from './Partials/TabFeedbacks'
import { TabCelebrations } from './Partials/TabCelebrations'
import { getPayloadPeriod } from 'src/utils/getPayloadPeriod'
import { ModalSelectUsers } from './Partials/ModalSelectUsers'
import { getUserCelebration } from 'src/services/hooks/celebrations/useCelebrations'
import { CreateOneToOne } from './Partials/CreateOneToOne'

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Impressions() {
  const theme = useTheme()
  const globalClasses = useGlobalUseStyles()
  const query = useQuery();
  const classes = makeStyles()
  const formatedDatePayload = getPayloadPeriod()
  const navigate = useNavigate()

  const { onCallAlert } = useAlertContext()
  const { onCloseLoading, onOpenLoading } = useLoadingContext()

  const { id: paramUserId } = useParams()
  const { user } = useAuth()

  const currentCustomerId = user?.customerId
  const currentUserId = user?.id
  const currentUserName = user?.name
  const currentRole = user?.occupation
  const [role] = useState([2, 3, 4])
  const [roleAdd] = useState([3, 4])

  const [dataUserParam, setDataUserParam] = useState()
  const [selectedButton, setSelectedButton] = useState(0)
  const [dataOneToOne, setDataOneToOne] = useState([])
  const [dataFeedbacks, setDataFeedbacks] = useState([])
  const [dataCelebrations, setDataCelebrations] = useState([])
  const [dataUsers, setDataUsers] = useState([])
  const [dataUsersSelected, setDataUsersSelected] = useState([])
  const [isOpenModalUsers, setIsOpenModalUsers] = useState(false)
  const [updateCelebrations, setUpdateCelebrations] = useState(false)
  const [isOpenModalCreateOneToOne, setIsOpenModalCreateOneToOne] =
    useState(false)

  const [selectedFilterType, setSelectedFilterType] = useState(1)
  const [selectedFilterCategory, setSelectedFilterCategory] = useState([])

  const isTabOneToOne = selectedButton === 0
  const isTabFeedbacks = selectedButton === 1
  const isTabCelebrations = selectedButton === 2
  const textTabs = {
    breadCrumbTab: { 0: '1:1', 1: 'Feedbacks', 2: 'Celebrações' },
    filter: {
      0: 'Filtro de 1:1',
      1: 'Filtro de Feedbacks',
      2: 'Filtro de Celebrações'
    }
  }

  const getDataUser = useCallback(async (userParamId) => {
    try {
      onOpenLoading()
      const { data } = await getUserById(userParamId)
      setDataUserParam(data?.data)
    } catch (error) {
      onCallAlert({
        type: 'error',
        message: messageResponse(error?.response)
      })
    } finally {
      onCloseLoading()
    }
  }, [])

  const getOneToOneSearch = useCallback(
    async ({ customerId, userId, period = formatedDatePayload }) => {
      try {
        onOpenLoading()

        let fromUserId
        let toUserId

        if (
          selectedFilterType === 0 ||
          selectedFilterType === enumFilterType.RECEBIDOS
        ) {
          toUserId = userId
        }

        if (selectedFilterType === enumFilterType.ENVIADOS) {
          fromUserId = userId
        }

        const payload = {
          customerId,
          fromUserId,
          toUserId,
          completed: true,
          ...period
        }
        const response = await postOneToOneSearch(payload)
        setDataOneToOne(response)
      } catch (error) {
        onCallAlert({
          type: 'error',
          message: messageResponse(error?.response)
        })
      } finally {
        onCloseLoading()
      }
    },
    [selectedFilterType]
  )

  const getFeedbackSearch = useCallback(
    async ({ customerId, userId, period = formatedDatePayload }) => {
      try {
        onOpenLoading()

        let fromUserId
        let toUserId

        if (
          selectedFilterType === 0 ||
          selectedFilterType === enumFilterType.RECEBIDOS
        ) {
          toUserId = userId
        }

        if (selectedFilterType === enumFilterType.ENVIADOS) {
          fromUserId = userId
        }

        const payload = {
          customerId,
          fromUserId,
          toUserId,
          completed: true,
          ...period
        }
        const response = await postFeedbackSearch(payload)
        const dataObject = response.map((currentData) => {
          const { competencies } = currentData
          return [...competencies]
        })
        const competenciesMerged = dataObject.flatMap((e) => [...e])

        const { data: competenceGrouper } = await getCompetenceGrouper({})
        const filteredCompetences = competenceGrouper?.data?.map((item) => {
          const filter = item.competences.filter((elem) =>
            competenciesMerged.includes(elem)
          )
          return {
            ...item,
            competences: filter
          }
        })
        const dataCompetencesCategory = filteredCompetences.filter(
          (item) => !!item.competences.length
        )
        const dataCategory = groupBy(dataCompetencesCategory, 'category')
        const dataListObject = Object.entries(dataCategory).map(
          (currentData) => {
            const [categoryId, item] = currentData
            return {
              categoryId: categoryId,
              competences: item
            }
          }
        )

        const dataList = selectedFilterCategory.length
          ? dataListObject
              .filter((item) =>
                selectedFilterCategory.includes(item.categoryId)
              )
              .map((item) => item.competences)
              .flat()
          : dataListObject.map((item) => item.competences).flat()

        const dataListCompetences = dataList.map((item) => item.competences)
        const dataCompetences = dataListCompetences.flatMap((e) => [...e])

        const resResult = response?.map((item) => {
          const filter = item.competencies.filter((elem) =>
            dataCompetences.includes(elem)
          )
          return {
            ...item,
            competences: filter
          }
        })
        const responseFiltered = resResult.filter(
          (item) => !!item.competences.length
        )
        setDataFeedbacks(responseFiltered)
      } catch (error) {
        onCallAlert({
          type: 'error',
          message: messageResponse(error?.response)
        })
      } finally {
        onCloseLoading()
      }
    },
    [selectedFilterType, selectedFilterCategory]
  )

  const getDataCelebrations = useCallback(
    async ({ customerId, userId, period = formatedDatePayload }) => {
      try {
        onOpenLoading()

        const isWithUsers =
          selectedFilterType === 0 ||
          selectedFilterType === enumFilterType.RECEBIDOS
        const [resultUsers, resultCelebration] = await Promise.all([
          getUsers(),
          getUserCelebration({
            customerId,
            [isWithUsers ? 'withUsers' : 'fromUserId']: isWithUsers
              ? [userId]
              : userId
          })
        ])
        setDataUsers(resultUsers)
        setDataCelebrations(resultCelebration?.data?.data)
      } catch (error) {
        onCallAlert({
          type: 'error',
          message: messageResponse(error?.response)
        })
      } finally {
        onCloseLoading()
      }
    },
    [selectedFilterType]
  )

  function setInitialTab(tab) {
    if(tab === "feedback") {
      setSelectedButton(1)
      return;
    }
    if(tab === "celebration") {
      setSelectedButton(2)
      return;
    }
    return 0;
  }

  useEffect(() => {
    const queryFilter = query.get('filter');
    queryFilter && setInitialTab(queryFilter)
    paramUserId && getDataUser(paramUserId)

    if (isTabOneToOne && currentCustomerId && (paramUserId || currentUserId)) {
      getOneToOneSearch({
        customerId: currentCustomerId,
        userId: paramUserId || currentUserId
      })
    }
    if (isTabFeedbacks && currentCustomerId && (paramUserId || currentUserId)) {
      getFeedbackSearch({
        customerId: currentCustomerId,
        userId: paramUserId || currentUserId
      })
    }
    if (
      isTabCelebrations &&
      currentCustomerId &&
      (paramUserId || currentUserId)
    ) {
      getDataCelebrations({
        customerId: currentCustomerId,
        userId: paramUserId || currentUserId
      })
    }
  }, [
    currentCustomerId,
    currentUserId,
    getFeedbackSearch,
    getOneToOneSearch,
    isTabOneToOne,
    isTabFeedbacks,
    paramUserId,
    getDataUser,
    updateCelebrations,
    isTabCelebrations,
    getDataCelebrations
  ])

  function onSelectPeriod(period) {
    if (isTabOneToOne && currentCustomerId && (paramUserId || currentUserId)) {
      getOneToOneSearch({
        customerId: currentCustomerId,
        userId: paramUserId || currentUserId,
        period
      })
      return 0
    }
    if (isTabFeedbacks && currentCustomerId && (paramUserId || currentUserId)) {
      getFeedbackSearch({
        customerId: currentCustomerId,
        userId: paramUserId || currentUserId,
        period
      })
      return 0
    }
  }

  function onHandleModalUsers() {
    setIsOpenModalUsers(!isOpenModalUsers)
  }

  function onHandleModalCreateOneToOne() {
    setIsOpenModalCreateOneToOne(!isOpenModalCreateOneToOne)
  }

  function onHandleSendFeedback(id) {
    navigate(`/feedback/add/${id}`)
  }

  function onNextAction(usersSelected) {
    if (isTabFeedbacks) {
      navigate(`/feedback/add/${usersSelected[0]?.id}`)
      return
    }
    if (isTabOneToOne) {
      setDataUsersSelected(usersSelected)
      onHandleModalCreateOneToOne()
    }
  }

  function onUpdateCelebrations() {
    setUpdateCelebrations(!updateCelebrations)
  }

  return (
    <RoleBasedGuard hasContent roles={role}>
      <Helmet title="Lista de Impressões" />
      <ModalSelectUsers
        onNextAction={onNextAction}
        onClose={onHandleModalUsers}
        onUpdateCelebrations={onUpdateCelebrations}
        currentTab={selectedButton}
        isOpen={isOpenModalUsers}
        customerId={currentCustomerId}
        userId={currentUserId}
        paramUserId={paramUserId}
        dataUserParam={dataUserParam}
      />
      <CreateOneToOne
        onClose={onHandleModalCreateOneToOne}
        isOpen={isOpenModalCreateOneToOne}
        idUserSelected={dataUsersSelected[0]?.id ?? 0}
      />
      <Box className={globalClasses.breadcrumb}>
        <HeaderBreadcrumbs
          icon={<MarkChatReadOutlinedIcon />}
          links={[
            { name: 'Lista de Impressões' },
            {
              name: textTabs.breadCrumbTab[selectedButton]
            },
            { name: `${paramUserId ? dataUserParam?.name : currentUserName}` }
          ]}
        />
        <Box className={classes.boxBtnNavigation}>
          <>
            {roleAdd.includes(currentRole) && (
              <>
                {isTabOneToOne && (
                  <>
                    <Tooltip title="Criar um novo 1:1">
                      <IconButton
                        className={classes.boxContentBtn}
                        onClick={() => onHandleModalUsers()}
                      >
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                    <ImpressionsFilter
                      title="Filtrar 1:1"
                      selectedFilterType={selectedFilterType}
                      setSelectedFilterType={setSelectedFilterType}
                      selectedFilterCategory={selectedFilterCategory}
                      setSelectedFilterCategory={setSelectedFilterCategory}
                    />
                  </>
                )}
                {isTabFeedbacks && (
                  <>
                    <Tooltip title="Enviar um novo feedback">
                      <IconButton
                        className={classes.boxContentBtn}
                        onClick={() =>
                          paramUserId
                            ? onHandleSendFeedback(paramUserId)
                            : onHandleModalUsers()
                        }
                      >
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                    <ImpressionsFilter
                      title="Filtrar feedbacks"
                      showFilterCategory
                      userRole={
                        paramUserId ? dataUserParam?.occupation : currentRole
                      }
                      selectedFilterType={selectedFilterType}
                      setSelectedFilterType={setSelectedFilterType}
                      selectedFilterCategory={selectedFilterCategory}
                      setSelectedFilterCategory={setSelectedFilterCategory}
                    />
                  </>
                )}
                {isTabCelebrations && (
                  <>
                    <Tooltip title="Enviar uma nova celebração">
                      <IconButton
                        className={classes.boxContentBtn}
                        onClick={onHandleModalUsers}
                      >
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                    <ImpressionsFilter
                      title="Filtrar celebrações"
                      selectedFilterType={selectedFilterType}
                      setSelectedFilterType={setSelectedFilterType}
                      selectedFilterCategory={selectedFilterCategory}
                      setSelectedFilterCategory={setSelectedFilterCategory}
                    />
                  </>
                )}
              </>
            )}
          </>
          <CalendarPeriodImpressions
            onSelectPeriod={(period) => onSelectPeriod(period)}
            isShowIcon
          />
          <Box className={classes.containerBtn}>
            <button
              key={0}
              onClick={() => setSelectedButton(0)}
              style={{
                background: isTabOneToOne && theme.palette.primary.main,
                border:
                  isTabOneToOne && `"2px solid" ${theme.palette.primary.main}`,
                color: isTabOneToOne && '#fff',
                boxShadow: isTabOneToOne && '0px 4px 20px rgba(0, 0, 0, 0.25)'
              }}
              className={classes.button}
            >
              <p
                style={{
                  width: '100%',
                  borderRight: isTabCelebrations && '2px solid #CFD2D3'
                }}
              >
                1:1
              </p>
            </button>
            <button
              key={1}
              onClick={() => setSelectedButton(1)}
              style={{
                background: isTabFeedbacks && theme.palette.primary.main,
                border:
                  isTabFeedbacks && `"2px solid" ${theme.palette.primary.main}`,
                color: isTabFeedbacks && '#fff',
                boxShadow: isTabFeedbacks && '0px 4px 20px rgba(0, 0, 0, 0.25)'
              }}
              className={classes.button}
            >
              <p>Feedbacks</p>
            </button>
            <button
              key={2}
              onClick={() => setSelectedButton(2)}
              style={{
                background: isTabCelebrations && theme.palette.primary.main,
                border:
                  isTabCelebrations &&
                  `"2px solid" ${theme.palette.primary.main}`,
                color: isTabCelebrations && '#fff',
                boxShadow:
                  isTabCelebrations && '0px 4px 20px rgba(0, 0, 0, 0.25)'
              }}
              className={classes.button}
            >
              <p
                style={{
                  width: '100%',
                  borderLeft: isTabOneToOne && '2px solid #CFD2D3'
                }}
              >
                Celebrações
              </p>
            </button>
          </Box>
        </Box>
      </Box>
      <Box className={classes.container}>
        <Box className={classes.boxContent}>
          {isTabOneToOne && <TabOneToOne dataOneToOne={dataOneToOne} />}
          {isTabFeedbacks && <TabFeedbacks dataFeedbacks={dataFeedbacks} />}
          {isTabCelebrations && (
            <TabCelebrations
              data={dataCelebrations}
              dataUsers={dataUsers}
              userId={currentUserId}
            />
          )}
        </Box>
      </Box>
    </RoleBasedGuard>
  )
}
