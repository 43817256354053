import React, { useState, useEffect, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useAlertContext } from 'src/context/AlertContext'
import { useLoadingContext } from 'src/context/LoadingContext'
import { messageResponse } from 'src/utils/messageResponse'
import {
  HeaderBreadcrumbs,
  Helmet,
  NewBoxWidget,
  CardExpectedEvoluction,
  Tooltip,
  Button
} from 'src/components'
import { Box, IconButton } from '@material-ui/core'
import {
  Close as CloseIcon,
  AssessmentOutlined as AssessmentOutlinedIcon
} from '@material-ui/icons'
import { getMetricsUsers } from 'src/services/hooks/metrics/useMetrics'
import {
  getDevelopmentPlanById,
  putDevelopmentPlan
} from 'src/services/hooks/developmentPlan/useDevelopmentPlan'
import {
  postCompetenceScore,
  getCompetenceLastScoreByUserId
} from 'src/services/hooks/competence/useCompetence'
import { enumCategory } from 'src/@enum/metrics'
import KnowledgeNotes from 'src/screens/UserMetrics/CompetencyGrades/Partials/knowledge'
import BehaviorNotes from 'src/screens/UserMetrics/CompetencyGrades/Partials/behavior'
import PerformanceNotes from 'src/screens/UserMetrics/CompetencyGrades/Partials/performance'
import LeadershipNotes from 'src/screens/UserMetrics/CompetencyGrades/Partials/leadership'
import useGlobalUseStyles from 'src/themes/globalUseStyles'
import makeStyles from './styles'
import groupBy from 'lodash/groupBy'

function ReviewPDI() {
  const classes = makeStyles()
  const globalClasses = useGlobalUseStyles()
  const navigate = useNavigate()

  const { id: idDevPlanReview, user: idUserReview } = useParams()

  const { notifications, onCallAlert } = useAlertContext()
  const { onCloseLoading, onOpenLoading } = useLoadingContext()

  const [isLoading, setIsLoading] = useState(false)

  const [dataUser, setDataUser] = useState([])
  const [data, setData] = useState()

  const [payloadTargets, setPayloadTargets] = useState([])
  const [payloadActivities, setPayloadActivities] = useState([])

  const [payloadPerformace, setPayloadPerformace] = useState([])
  const [payloadKnowledge, setPayloadKnowledge] = useState([])
  const [payloadBehavior, setPayloadBehavior] = useState([])
  const [payloadLeadership, setPayloadLeadership] = useState([])

  const [performancesValues, setPerformancesValues] = useState([])
  const [knowLedgesValues, setKnownLedgesValues] = useState([])
  const [behaviorsValues, setBehaviorsValues] = useState([])
  const [leadershipValues, setLeadershipValues] = useState([])

  const onHandleSubmit = async (event, concluded) => {
    try {
      onOpenLoading()

      if (performancesValues.length > 0) {
        performancesValues?.map(async (item, index) => {
          let payload = {
            competenceGrouperId: item.competenceGrouper.id,
            category: item.category,
            scores: item.scores,
            customerId: dataUser?.customerId,
            userId: dataUser?.id,
            isActive: true
          }
          await postCompetenceScore(payload)
        })
      }

      if (knowLedgesValues.length > 0) {
        knowLedgesValues?.map(async (item, index) => {
          let payload = {
            competenceGrouperId: item.competenceGrouper.id,
            category: item.category,
            scores: item.scores,
            customerId: dataUser?.customerId,
            userId: dataUser?.id,
            isActive: true
          }
          await postCompetenceScore(payload)
        })
      }

      if (behaviorsValues.length > 0) {
        behaviorsValues?.map(async (item, index) => {
          let payload = {
            competenceGrouperId: item.competenceGrouper.id,
            category: item.category,
            scores: item.scores,
            customerId: dataUser?.customerId,
            userId: dataUser?.id,
            isActive: true
          }
          await postCompetenceScore(payload)
        })
      }

      if (leadershipValues.length > 0) {
        leadershipValues?.map(async (item, index) => {
          let payload = {
            competenceGrouperId: item.competenceGrouper.id,
            category: item.category,
            scores: item.scores,
            customerId: dataUser?.customerId,
            userId: dataUser?.id,
            isActive: true
          }
          await postCompetenceScore(payload)
        })
      }

      const payload = {
        id: data?.id,
        name: data?.name,
        description: data?.description,
        estimatedCompletion: data?.estimatedCompletion,
        status: 3,
        progress: data?.progress,
        activities: payloadActivities,
        targets: payloadTargets,
        competencies: data?.competencies,
        fromUserId: data?.fromUserId,
        toUserId: data?.toUserId,
        customerId: data?.customerId,
        isTemplate: data?.isTemplate,
        isActive: data?.isActive
      }
      await putDevelopmentPlan(data?.id, payload)
      onCallAlert({
        type: 'success',
        message: notifications.pdi.success.editPDI
      })
      navigate(`/metrics/user/${data?.toUserId}`)
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      onCloseLoading()
    }
  }

  const getDevelopmentPlan = useCallback(async (id) => {
    try {
      onOpenLoading()

      const response = await getDevelopmentPlanById(id)
      setData(response)
      const competenciesDevPlan = response?.competencies?.map((item) => item)

      const dataLastScore = await getCompetenceLastScoreByUserId(
        response?.toUserId
      )
      const dataLastScoreCategory = groupBy(dataLastScore, 'category')
      const dataListObject = Object.entries(dataLastScoreCategory).map(
        (currentData) => {
          const [categoryId, item] = currentData
          return {
            categoryId: categoryId,
            category: enumCategory[categoryId],
            lastCompetences: item
          }
        }
      )
      const dataList = dataListObject.map((item) => item)

      const payloadPerformance = dataList
        .filter((item) => item.categoryId === '1')
        .map((item) => item.lastCompetences)
        .flat()
      const filteredPerformance = payloadPerformance.map((item) => {
        const filter = item.scores.filter((score) =>
          competenciesDevPlan.includes(score.competenceId)
        )
        return {
          ...item,
          scores: filter
        }
      })
      const payloadPerformanceCompetences = filteredPerformance.filter(
        (item) => !!item.scores.length
      )
      setPayloadPerformace(payloadPerformanceCompetences)

      const payloadKnowledge = dataList
        .filter((item) => item.categoryId === '2')
        .map((item) => item.lastCompetences)
        .flat()
      const filteredKnowledge = payloadKnowledge.map((item) => {
        const filter = item.scores.filter((score) =>
          competenciesDevPlan.includes(score.competenceId)
        )
        return {
          ...item,
          scores: filter
        }
      })
      const payloadKnowledgeCompetences = filteredKnowledge.filter(
        (item) => !!item.scores.length
      )
      setPayloadKnowledge(payloadKnowledgeCompetences)

      const payloadBehavior = dataList
        .filter((item) => item.categoryId === '3')
        .map((item) => item.lastCompetences)
        .flat()
      const filteredBehavior = payloadBehavior.map((item) => {
        const filter = item.scores.filter((score) =>
          competenciesDevPlan.includes(score.competenceId)
        )
        return {
          ...item,
          scores: filter
        }
      })
      const payloadBehaviorCompetences = filteredBehavior.filter(
        (item) => !!item.scores.length
      )
      setPayloadBehavior(payloadBehaviorCompetences)

      const payloadLeadership = dataList
        .filter((item) => item.categoryId === '4')
        .map((item) => item.lastCompetences)
        .flat()
      const filteredLeadership = payloadLeadership.map((item) => {
        const filter = item.scores.filter((score) =>
          competenciesDevPlan.includes(score.competenceId)
        )
        return {
          ...item,
          scores: filter
        }
      })
      const payloadLeadershipCompetences = filteredLeadership.filter(
        (item) => !!item.scores.length
      )
      setPayloadLeadership(payloadLeadershipCompetences)
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      onCloseLoading()
    }
  }, [])

  const getDataUser = useCallback(async (id) => {
    try {
      const data = await getMetricsUsers({ id })
      setDataUser(data)
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    }
  }, [])

  useEffect(() => {
    idUserReview && getDataUser(idUserReview)
    idDevPlanReview && getDevelopmentPlan(idDevPlanReview)
  }, [getDataUser, getDevelopmentPlan, idDevPlanReview, idUserReview])

  function handleMetricsUser(id) {
    navigate(`/metrics/user/${id}`)
  }

  return (
    <>
      <Helmet title="Conclusão de PDI" />
      <Box className={globalClasses.breadcrumb}>
        <HeaderBreadcrumbs
          icon={<AssessmentOutlinedIcon />}
          links={[
            {
              name: 'Métricas',
              href: '/metrics'
            },
            {
              name: `${dataUser?.name || ''}`,
              href: `/metrics/user/${idUserReview}`
            },
            { name: 'Conclusão de PDI', href: `/metrics/user/${idUserReview}` },
            { name: `${data?.name || ''}` }
          ]}
        />
        <Box className={classes.boxBtnNavegation}>
          <Tooltip title="Fechar">
            <IconButton
              className={classes.boxContentBtn}
              onClick={() => handleMetricsUser(dataUser?.id)}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box className={classes.container}>
        <Box className={classes.boxContent}>
          <NewBoxWidget
            width={450}
            actions={<Button label="Finalizar" onClick={onHandleSubmit} />}
            height="100%"
          >
            {!isLoading && data && (
              <CardExpectedEvoluction
                data={data}
                payloadTargets={payloadTargets}
                setPayloadTargets={setPayloadTargets}
                payloadActivities={payloadActivities}
                setPayloadActivities={setPayloadActivities}
                disabledSave
              />
            )}
          </NewBoxWidget>
          {payloadPerformace.length > 0 && (
            <PerformanceNotes
              removeClose
              scores={payloadPerformace}
              competencyValues={performancesValues}
              setCompetencyValues={setPerformancesValues}
            />
          )}
          {payloadKnowledge.length > 0 && (
            <KnowledgeNotes
              removeClose
              scores={payloadKnowledge}
              competencyValues={knowLedgesValues}
              setCompetencyValues={setKnownLedgesValues}
            />
          )}
          {payloadBehavior.length > 0 && (
            <BehaviorNotes
              removeClose
              scores={payloadBehavior}
              competencyValues={behaviorsValues}
              setCompetencyValues={setBehaviorsValues}
            />
          )}
          {payloadLeadership.length > 0 && (
            <LeadershipNotes
              removeClose
              scores={payloadLeadership}
              competencyValues={leadershipValues}
              setCompetencyValues={setLeadershipValues}
            />
          )}
        </Box>
      </Box>
    </>
  )
}

export default ReviewPDI
