import React, { useState } from 'react'
import { Typography, IconButton, Box, InputBase } from '@mui/material'
import { Tooltip } from 'src/components'
import {
  CircleOutlined as CircleOutlinedIcon,
  Check as CheckIcon
} from '@mui/icons-material'
import {
  Close as CloseIcon,
  Add as AddIcon,
  Edit as EditIcon
} from '@material-ui/icons'
import useStyles from './styles'
import { useAlertContext } from 'src/context/AlertContext'

export default function ItemTarget({
  index,
  target,
  deleteTarget,
  handleAddNewActivitieToTarget,
  handleRemoveActivitieOfTarget,
  handleUpdateTarget
}) {
  const classes = useStyles()

  const { onCallAlert } = useAlertContext()

  const [description, setDescription] = useState('')

  const [openEdit, setOpenEdit] = useState(false)

  const create = (description, index) => {
    if (description.length > 3) {
      handleAddNewActivitieToTarget(index, description)
      setDescription('')
      document.getElementById(`target-activitie-description-${index}`).value =
        ''
    } else {
      onCallAlert({
        type: 'error',
        message: 'O descrição da ação deve conter ao menos 4 caracteres!'
      })
    }
  }

  const editTarget = (id) => {
    setOpenEdit(true)
  }

  const saveTarget = (description, index) => {
    if (description.length > 3) {
      handleUpdateTarget(description, index)
      setOpenEdit(false)
    } else {
      onCallAlert({
        type: 'error',
        message: 'O descrição da meta deve conter ao menos 4 caracteres!'
      })
    }
  }

  return (
    <Box className={classes.actionOption}>
      <Box className={classes.actionOptionLeftSide}>
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          sx={{
            py: 1,
            display: 'flex',
            overflowY: 'auto'
          }}
        >
          {!openEdit && (
            <IconButton onClick={() => editTarget(index)}>
              <EditIcon />
            </IconButton>
          )}
          {openEdit && (
            <IconButton
              onClick={() => saveTarget(description, index)}
              disabled={!description}
            >
              <CheckIcon />
            </IconButton>
          )}
          {!openEdit && <Typography>{target.description}</Typography>}
          {openEdit && (
            <InputBase
              id={`target-name-description-${index}`}
              onChange={(e) => setDescription(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  saveTarget(description, index)
                  e.preventDefault()
                }
              }}
              fullWidth
              autoFocus
            />
          )}
        </Box>
        <Tooltip title="Remover ação">
          <IconButton onClick={() => deleteTarget(target.id)} fullWidth>
            <CloseIcon className={classes.deleteIcon} />
          </IconButton>
        </Tooltip>
      </Box>
      {target?.activities?.map((activitie, indexOfActivitie) => (
        <Box key={activitie.id} className={classes.actionOption}>
          <Box className={classes.actionOptionLeftSide}>
            <Box display="flex" alignItems="center" gap={2}>
              <CircleOutlinedIcon
                style={{ width: 10, height: 10, marginLeft: 15 }}
              />
              <Typography>{activitie.description}</Typography>
            </Box>
            <Tooltip title="Remover ação">
              <IconButton
                onClick={() =>
                  handleRemoveActivitieOfTarget(index, indexOfActivitie)
                }
              >
                <CloseIcon className={classes.deleteIcon} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      ))}
      <Box className={classes.actionOption}>
        <Box className={classes.actionOptionLeftSide}>
          <InputBase
            sx={{ ml: 2, flex: 1 }}
            id={`target-activitie-description-${index}`}
            placeholder="Adicionar ação"
            onChange={(e) => setDescription(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                create(description, index)
                e.preventDefault()
              }
            }}
            fullWidth
            autoFocus
          />
          <IconButton
            type="button"
            onClick={() => create(description, index)}
            disabled={!description}
          >
            <AddIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}
