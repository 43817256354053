import { Switch, makeStyles, withStyles, TextField } from '@material-ui/core'

export const DefaultStyledInput = withStyles((theme) => ({
  root: {
    '& .MuiInputBase-formControl': {
      height: '56px'
    },
    '& .MuiInputBase-root': {
      '& :hover': {
        borderColor: theme.palette.primary.main
      }
    },
    '& .MuiInputLabel-root': {
      whiteSpace: 'nowrap',
      maxWidth: '80%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'inline-block',
      color: '#000000',
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(18.75),
      '-webkit-box-orient': 'vertical'
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main
      }
    }
  }
}))(TextField)

export const DefaultStyledInputMultiline = withStyles((theme) => ({
  root: {
    '& .MuiInputBase-formControl': {
      height: 'auto'
    },
    '& .MuiInputBase-root': {
      '& :hover': {
        borderColor: theme.palette.primary.main
      }
    },
    '& .MuiInputLabel-root': {
      whiteSpace: 'nowrap',
      maxWidth: '80%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'inline-block',
      color: '#000000',
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(18.75),
      '-webkit-box-orient': 'vertical'
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main
      }
    }
  }
}))(TextField)
export const DefaultStyledInputSearch = withStyles((theme) => ({
  root: {
    '& .MuiInputBase-formControl': {
      height: '56px'
    },
    '& .MuiInputBase-root': {
      backgroundColor: '#F7F7FC',
      borderRadius: '50px',
      borderColor: theme.palette.primary.main,
      '& :hover': {
        borderColor: theme.palette.primary.main
      }
    },
    '& .MuiInputLabel-root': {
      whiteSpace: 'nowrap',
      backgroundColor: '#F7F7FC',
      maxWidth: '80%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'inline-block',
      color: '#000000',
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(18.75),
      '-webkit-box-orient': 'vertical'
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused': {
        border: 'none',
        borderRadius: 10
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
        borderRadius: '5px'
      }
    }
  }
}))(TextField)

export default makeStyles((theme) => ({
  customAutoComplete: {
    '& .MuiChip-root': {
      display: 'none'
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(12px, 22px) scale(1)'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(8px, 19px) scale(1)',
      backgroundColor: '#FFF',
      padding: '0 5px'
    },
    '& .MuiFormLabel-root.Mui-focused': {
      transform: 'translate(14px, -6px) scale(0.75)'
    },
    '& legend': {
      maxWidth: 0
    }
  },
  customDate: {
    '& .MuiInputLabel-root': {
      whiteSpace: 'nowrap',
      maxWidth: '65%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'inline-block',
      '-webkit-box-orient': 'vertical'
    }
  }
}))

export const MuiSwitch = withStyles((theme) => ({
  root: {
    '& 	.MuiSwitch-switchBase': {
      color: '#CFD2D3'
    },
    '& .Mui-checked': {
      color: theme.palette.primary.main
    },
    '& .MuiSwitch-track': {
      backgroundColor: '#CFD2D3',
      opacity: 0.5
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.primary.main
    }
  }
}))(Switch)
