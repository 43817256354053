import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  containerList: {
    marginTop: '8px',
    zIndex: 2,
    position: 'absolute',
    left: '20px',
    width: 'calc(100% - 40px)',
    height: '40vh',
  },
  containerLoading: {
    backgroundColor: '#FFFFFF',
    padding: '0 0 20px',
    borderRadius: '0 0 10px 10px'
  },
  containerListUsers: {
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
    borderRadius: '0 0 10px 10px',
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  listUsers: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    overflow: 'auto',
    padding: '0 20px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '30vh',
    '&::-webkit-scrollbar': {
      width: 4
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: '10px'
    }
  },
  userItem: {
    display: 'flex',
    padding: '5px',
    justifyContent: 'space-between',
    backgroundColor: '#F7F7FC',
    borderRadius: '10px',
    marginBottom: '10px'
  },
  userItemSelected: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  userInfos: {
    display: 'flex',
    columnGap: '10px',
    alignItems: 'center'
  },
  listFooter: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 20px'
  },
  sizeButton: {
    padding: 0,
    width: 40,
    height: 40
  },
  iconButton: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: theme.palette.primary.main
    }
  },
  notFound: {
    height: 'fit-content',
    padding: '20px 0'
  }
}))
