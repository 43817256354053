import React, { useState } from 'react'
import { Button, NewBoxWidget, Tooltip } from 'src/components'
import { IconButton } from '@material-ui/core'
import { Input } from 'src/components/Fields'
import { Form } from 'react-final-form'
import { ChromePicker, HuePicker } from 'react-color'
import { FeedbackOutlined as FeedbackOutlinedIcon } from '@mui/icons-material'
import { putCustomers } from 'src/services/hooks/customers/useCustumers'
import { postFileUpload } from 'src/services/hooks/fileUpload/useFileUpload'
import { useAlertContext } from 'src/context/AlertContext'
import { useLoadingContext } from 'src/context/LoadingContext'
import { messageResponse } from 'src/utils/messageResponse'
import { convertBlobToFile } from 'src/utils'
import { setSession } from 'src/utils/jwt'
import { appearancePreferences } from 'src/utils/form/validations'
import useGlobalUseStyles from 'src/themes/globalUseStyles'
import makeStyles from './style'

function Color({ onCloseEdit, setCurrentColorEdit, dataAppearance }) {
  const globalClasses = useGlobalUseStyles()
  const classes = makeStyles()

  const { notifications, onCallAlert } = useAlertContext()
  const { onCloseLoading, onOpenLoading } = useLoadingContext()
  const customerId = dataAppearance.id

  const [currentColor, setCurrentColor] = useState({
    colorHex: dataAppearance.primaryColor ?? '#38d430'
  })

  async function putColor(color) {
    try {
      onOpenLoading()

      const payloadFileUploadLogo = await convertBlobToFile(
        dataAppearance?.logo,
        dataAppearance?.id,
        2
      )
      const urlImagemLogo = await postFileUpload(payloadFileUploadLogo)

      const payloadFileUploadFavicon = await convertBlobToFile(
        dataAppearance?.favicon,
        dataAppearance?.id,
        3
      )
      const urlImagemFavicon = await postFileUpload(payloadFileUploadFavicon)

      const payload = {
        ...dataAppearance,
        primaryColor: color,
        logo: dataAppearance?.logo && urlImagemLogo,
        favicon: dataAppearance?.favicon && urlImagemFavicon
      }
      const { data } = await putCustomers(customerId, payload)
      const customer = {
        customerId: data?.data?.id,
        logo: data?.data?.logo,
        favicon: data?.data?.favicon,
        primaryColor: data?.data?.primaryColor
      }
      setSession(
        localStorage.getItem('accessToken'),
        localStorage.getItem('refreshToken'),
        localStorage.getItem('email'),
        localStorage.getItem('userId'),
        JSON.stringify(customer)
      )
      onCallAlert({
        type: 'success',
        message: notifications.customers.success.updateAppearance
      })
      refreshPage()
    } catch (error) {
      const messageError = messageResponse(error?.response)
      onCallAlert({
        type: 'error',
        message: messageError ?? notifications.global.error
      })
    } finally {
      onCloseLoading()
    }
  }

  const handleChange = (color) => {
    setCurrentColor({ color: color.rgb, colorHex: color.hex })
    setCurrentColorEdit(`${currentColor.colorHex}`)
  }

  const onClose = () => {
    onCloseEdit()
  }

  const onHandleSubmit = () => {
    putColor(currentColor.colorHex)
  }

  const refreshPage = () => {
    window.location.reload()
  }

  return (
    <Form
      onSubmit={(values) =>
        onHandleSubmit({
          inputColor: values.name
        })
      }
      validate={appearancePreferences}
      initialValues={{
        name: `${currentColor.colorHex}`
      }}
      render={({ handleSubmit, form, valid, pristine }) => (
        <NewBoxWidget
          title="Cor principal"
          subtitle="Defina a cor principal da sua plataforma, ela esta presente na barra superior e na maioria dos componentes de destaque."
          options={
            <Tooltip
              title={
                <p className={globalClasses.tooltipText}>
                  De preferência a cores comum a tonalidade mais escura para
                  melhor visualização
                </p>
              }
            >
              <IconButton>
                <FeedbackOutlinedIcon />
              </IconButton>
            </Tooltip>
          }
          actions={
            <div className={classes.controlButton}>
              <Button label="Cancelar" onClick={onClose} variant="dark" />
              <Button label="Confirmar" onClick={onHandleSubmit} />
            </div>
          }
          noBodyPadding
          width={420}
          style={{ marginLeft: '30px' }}
        >
          <div className={classes.boxUpdate}>
            <div>
              <div className={classes.swatch}>
                <div
                  className={classes.color}
                  style={{
                    backgroundColor: `${currentColor.colorHex}`
                  }}
                />
                <form className={classes.colorInput} onSubmit={handleSubmit}>
                  <Input
                    name="name"
                    label="Cor principal"
                    placeholder="Digite o código da cor"
                    onChange={(e) => {
                      form.change('name', e?.target?.value)
                      setCurrentColor({ colorHex: e?.target?.value })
                      setCurrentColorEdit(e?.target?.value)
                    }}
                    maxLength={7}
                  />
                </form>
              </div>
              <p className={classes.textColor}>
                Selecione a cor e sua tonalidade abaixo:
              </p>
              <div className={classes.boxColorHue}>
                <HuePicker
                  width={350}
                  color={currentColor.colorHex}
                  onChange={handleChange}
                />
              </div>
              <div className={classes.boxColor}>
                <ChromePicker
                  width={350}
                  color={currentColor.colorHex}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </NewBoxWidget>
      )}
    />
  )
}

export default Color
