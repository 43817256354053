import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { Button, Modal } from 'src/components'

function ModalEvolutions({
  isOpenModalEvolutions,
  onClose,
  onHandleSubmit,
  idUser,
  idDevPlan
}) {
  const navigate = useNavigate()

  function onHandleToReview() {
    navigate(`/development-plan/review/${idUser}/${idDevPlan}`)
  }

  return (
    <Modal
      isOpen={isOpenModalEvolutions}
      onHandleClose={onClose}
      title="Aplicar evoluções?"
      subTitle="Você deseja rever as métricas de evolução desse PDI agora?"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        sx={{ marginTop: 20, columnGap: 10 }}
      >
        <Button
          label="Não, obrigado"
          variant="dark"
          onClick={(event) => {
            onHandleSubmit(event, true)
            onClose()
          }}
        />
        <Button label="Sim" onClick={onHandleToReview} />
      </Box>
    </Modal>
  )
}

export default ModalEvolutions

ModalEvolutions.propTypes = {
  isOpenModalEvolutions: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}
