import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { Add } from '@material-ui/icons'
import { useTheme } from '@material-ui/styles'
import UseAutocomplete from './UseAutocomplete'
import makeStyles from './style'

export function Autocomplete({
  name,
  label,
  options,
  onChange,
  multiple,
  getOptionLabel,
  getOptionSelected,
  disabled,
  noOptionsText,
  className,
  contentOut,
  ...props
}) {
  const theme = useTheme()
  const classes = makeStyles()

  function identity(value) {
    if (value === undefined) {
      return null
    }
    return value
  }

  return (
    <Field
      primaryColor={theme.palette.primary.main}
      name={name}
      parse={identity}
      multiple={multiple}
      render={({ input: { onChange: inputChange, ...input }, meta }) => (
        <UseAutocomplete
          primaryColor={theme.palette.primary.main}
          {...input}
          noOptionsText={noOptionsText}
          options={options}
          multiple={multiple}
          renderOption={(props) => {
            return (
              <>
                {props.type === 'add' ? (
                  <div onClick={() => console.log('aqui')}>
                    {props.title}
                    <Add />
                  </div>
                ) : (
                  props.title
                )}
              </>
            )
          }}
          label={label}
          getOptionLabel={getOptionLabel}
          getOptionSelected={getOptionSelected}
          disabled={disabled}
          className={clsx(className, contentOut && classes.customAutoComplete)}
          onChange={(event, option) => {
            const value = multiple ? option : option?.value
            if (option !== -1) {
              inputChange(value)
              if (onChange) onChange(value)
            }
          }}
          inputProps={{
            ...props,
            error: meta.touched && !!meta.error
          }}
        />
      )}
    />
  )
}

Autocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  noOptionsText: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionSelected: PropTypes.func,
  disabled: PropTypes.bool,
  contentOut: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  )
}

export default Autocomplete
