import React, { useState, useEffect, useCallback, Fragment } from 'react'
import useAuth from 'src/hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import {
  Tooltip,
  Avatar,
  MultiProgressBar,
  AccordionMeasurement,
  ProgressBar,
  SimpleLineChart,
  Helmet,
  StarFavorite
} from 'src/components'
import { Typography, Box, IconButton } from '@material-ui/core'
import { getImageNameColor } from 'src/utils'
import { useAlertContext } from 'src/context/AlertContext'
import { useLoadingContext } from 'src/context/LoadingContext'
import { useSettingsContext } from 'src/context/SettingsContext'
import { useCurrentPeriodContext } from 'src/context/CurrentPeriodContext'
import { messageResponse } from 'src/utils/messageResponse'
import {
  ArrowForwardIos as ArrowForwardIosIcon,
  AssessmentOutlined as AssessmentOutlinedIcon
} from '@material-ui/icons'
import { enumAssignment } from 'src/@enum/user'
import { getMetricsCustomersUsers } from 'src/services/hooks/metrics/useMetrics'
import { styled, alpha } from '@mui/material/styles'
import { OutlinedInput } from '@mui/material'
import { MetricsGroups } from './Partials/MetricsGroups'
import { ToolsHeader } from './Partials/ToolsHeader'
import useInput from 'src/hooks/useInput'
import makeStyles from './style'
import { useTheme } from '@material-ui/styles'
import { getPayloadPeriod } from 'src/utils/getPayloadPeriod'

export const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  marginRight: 10,
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`
  }
}))

export default function Metrics() {
  const theme = useTheme()
  const classes = makeStyles()
  const navigate = useNavigate()

  const { user } = useAuth()
  const currentUserId = user?.id
  const currentCustomerId = user?.customerId

  const { currentPeriod } = useCurrentPeriodContext()

  const { onCallAlert } = useAlertContext()
  const { onCloseLoading, onOpenLoading } = useLoadingContext()
  const { metricsCurrentTab, metricsUserView } = useSettingsContext()

  const [dataMetricsUsers, setDataMetricsUsers] = useState([])

  const [controlTools, setControlTools] = useState({
    isOpenSearch: false,
    filterSelected: null
  })

  const [currentTab, setCurrentTab] = useState(metricsCurrentTab)
  const isTabUser = currentTab === 'user'
  const isTabGroup = currentTab === 'group'

  const { state: query, bindings, reset } = useInput()
  const metricsUsers = dataMetricsUsers.filter(
    ({ name }) => !query || name.toLowerCase().includes(query.toLowerCase())
  )

  const getListMetricsUsersSearch = useCallback(async () => {
    try {
      onOpenLoading()
      const formatedDatePayload = getPayloadPeriod(currentPeriod)
      const data = await getMetricsCustomersUsers({
        period: formatedDatePayload
      })
      setDataMetricsUsers(data)
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      onCloseLoading()
    }
  }, [])

  useEffect(() => {
    isTabUser &&
      currentUserId &&
      currentCustomerId &&
      getListMetricsUsersSearch()
  }, [currentCustomerId, currentUserId, getListMetricsUsersSearch, isTabUser])

  function handleMetricsUser(id) {
    navigate(`/metrics/user/${id}`)
  }

  return (
    <>
      <Helmet title="Métricas" />
      <Box className={classes.container}>
        {isTabUser && (
          <>
            <ToolsHeader
              controlTools={controlTools}
              onChangeControlTools={setControlTools}
              onChangeDataMetrics={setDataMetricsUsers}
              tab="user"
              onChangeTab={setCurrentTab}
              inputReset={reset}
              inputSearch={
                <StyledSearch
                  color="success"
                  id="search-collaborators"
                  placeholder="Buscar..."
                  style={{
                    width: '100%',
                    height: 44
                  }}
                  {...bindings}
                />
              }
              theme={theme}
            />
            <Box className={classes.boxContent}>
              {(metricsUsers ?? []).map((data, index) => (
                <div className={classes.boxContentAll} key={index}>
                  <Box
                    className={
                      metricsUserView === 'simplified'
                        ? classes.userContainerShadow
                        : classes.userContainer
                    }
                  >
                    <Box className={classes.displayContent}>
                      <Box className={classes.avatar}>
                        <Avatar
                          src={data.imageProfile}
                          aria-label={data.name}
                          alt={data.name}
                          color={getImageNameColor(data.name).color}
                        >
                          {getImageNameColor(data.name).name}
                        </Avatar>
                      </Box>
                      <Box>
                        <Typography className={classes.name}>
                          {data.name}
                        </Typography>
                        <Typography className={classes.date}>
                          {data.occupationAreaName} -{' '}
                          {enumAssignment[data.assignment]}
                        </Typography>
                      </Box>
                      <Box className={classes.boxArrow}>
                        <StarFavorite
                          checked={data?.isFavorite}
                          componentId={data?.id}
                          userId={currentUserId}
                          favoriteId={data?.favoriteId}
                          className={classes.boxBtnOptions}
                          type="user"
                          colorIcon={theme.palette.primary.main}
                        />
                        <Tooltip title="Ver perfil">
                          <IconButton
                            className={classes.boxBtnOptions}
                            onClick={() => handleMetricsUser(data.id)}
                          >
                            <ArrowForwardIosIcon
                              htmlColor={theme.palette.primary.main}
                            />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                    {data?.averageMetricsByCategoryType && (
                      <MultiProgressBar
                        data={data?.averageMetricsByCategoryType}
                        showLegend
                      />
                    )}
                    {!data?.overallMetric && (
                      <Box>
                        <Box display="flex" alignItems="center">
                          <AssessmentOutlinedIcon
                            className={classes.notFoundMetricsSimplifiedIcon}
                          />
                          <Typography
                            className={
                              classes.notFoundMetricsSimplifiedDescription
                            }
                          >
                            Não há avaliações para este usuário.
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                  {metricsUserView === 'details' && (
                    <Box className={classes.containerScroll}>
                      {data?.overallMetric ? (
                        <Box className={classes.containerTable}>
                          <Box className={classes.cardContainer}>
                            <p className={classes.titleLineChart}>
                              Médias gerais
                            </p>
                            <SimpleLineChart data={data?.timeline} />
                          </Box>
                          <Box className={classes.cardContainer}>
                            <ProgressBar
                              data={data?.averageMetricsByCategoryType}
                              overallMetric={data?.overallMetric}
                              overallMetricGap={data?.overallMetricGap}
                            />
                          </Box>
                          <Box className={classes.cardContainer}>
                            <Box mt={5}>
                              {data?.radarsCompetenceGrouper?.map(
                                ({ category, competenceGroups }, index) => (
                                  <>
                                    {competenceGroups &&
                                      competenceGroups.length > 0 && (
                                        <Box key={index} mb={2}>
                                          <AccordionMeasurement
                                            categoryName={category}
                                            scoreAverage={competenceGroups}
                                            type="user"
                                          />
                                        </Box>
                                      )}
                                  </>
                                )
                              )}
                            </Box>
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          item
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          textAlign="center"
                          height="100%"
                        >
                          <Box>
                            <AssessmentOutlinedIcon
                              className={classes.notFoundMetricsIcon}
                            />
                            <Typography
                              className={classes.notFoundMetricsTitle}
                            >
                              Nenhuma informação
                            </Typography>
                            <Typography
                              className={classes.notFoundMetricsDescription}
                            >
                              Não há avaliações para este usuário.
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  )}
                </div>
              ))}
            </Box>
          </>
        )}
        {isTabGroup && <MetricsGroups setCurrentTab={setCurrentTab} />}
      </Box>
    </>
  )
}
