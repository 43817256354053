import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    position: 'absolute',
    backgroundColor: '#434343',
    zIndex: 1,
    bottom: 70,
    width: '100%',
    borderRadius: '10px 10px 4px 4px',
    transition: 'all .3s',
    overflow: 'hidden',
    maxHeight: (props) => (props.isVisible ? '400px' : 0),
    padding: (props) => (props.isVisible ? '10px' : 0)
  },
  ruleItem: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '4px',
    padding: '4px 0'
  },
  ruleDescription: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(15),
    lineHeight: theme.typography.pxToRem(17),
    letterSpacing: theme.typography.pxToRem(0.15),
    color: '#fff'
  }
}))
