import React from 'react'
import { Box } from '@mui/material'
import Calendar from 'react-calendar'
import makeStyles from './style'

export function CardCalendar({ value, onChange, minDate }) {
  const classes = makeStyles()

  return (
    <Box className={classes.boxCalendar}>
      <Calendar
        calendarType="US"
        className={classes.calendar}
        onChange={onChange}
        value={value}
        minDate={minDate}
      />
    </Box>
  )
}
