import React, { useCallback, useEffect } from 'react'
import { Box } from '@mui/material'
import AddTarget from './AddTarget'
import ItemTarget from './ItemTarget'
// import AddActivitie from './AddActivitie'
// import ItemActivitie from './ItemActivitie'

import useStyles from './style'

export default function CardAddTargetsAndActivities({
  payloadTargets,
  setPayloadTargets,
  isValidForm,
  onChangeValidForm,
  payloadActivities,
  setPayloadActivities
}) {
  const classes = useStyles()

  const handleAddNewActivitieToTarget = (idx, description) => {
    const newActivitie = {
      description: description,
      completed: false
    }
    const newObjTarget = [...payloadTargets]
    newObjTarget[idx].activities.push(newActivitie)
    setPayloadTargets(newObjTarget)
  }

  const handleRemoveActivitieOfTarget = (indexOfTarget, indexOfActivitie) => {
    const filtered = payloadTargets[indexOfTarget]?.activities.filter(
      (_, index) => index !== indexOfActivitie
    )
    const formatedGoalFiltered = {
      id: payloadTargets[indexOfTarget]?.id,
      status: payloadTargets[indexOfTarget]?.status,
      description: payloadTargets[indexOfTarget]?.description,
      activities: filtered
    }
    let auxPayloadTargets = [...payloadTargets]
    auxPayloadTargets[indexOfTarget] = formatedGoalFiltered
    setPayloadTargets(auxPayloadTargets)
  }

  const handleUpdateTarget = (description, idx) => {
    let updateObj = [...payloadTargets]
    updateObj[idx].description = description
    setPayloadTargets(updateObj)
  }

  const addTargets = (target) => {
    setPayloadTargets([...payloadTargets, target])
  }

  const deleteTarget = (id) => {
    var filtered = payloadTargets.filter((target) => target.id !== id)
    setPayloadTargets(filtered)
  }

  const checkValidateForm = useCallback(
    (itensList) => {
      const getActivists = itensList.map((element) => element.activities) ?? []
      const isExisEmpty =
        getActivists.length === 0
          ? true
          : getActivists.some((element) => element.length === 0)
      onChangeValidForm(!isExisEmpty)
    },
    [onChangeValidForm]
  )

  useEffect(() => {
    checkValidateForm(payloadTargets)
  }, [checkValidateForm, payloadTargets])

  // const addActivitie = (activitie) => {
  //   setPayloadActivities([...payloadActivities, activitie])
  // }

  // const deleteActivitie = (id) => {
  //   var filtered = payloadActivities.filter((activitie) => activitie.id !== id)
  //   setPayloadActivities(filtered)
  // }

  return (
    <Box className={classes.container}>
      <Box>
        <Box className={classes.buttonsContainer}>
          <AddTarget addTargets={addTargets} />
          {payloadTargets.map((target, index) => (
            <Box key={target.id}>
              <ItemTarget
                index={index}
                target={target}
                deleteTarget={deleteTarget}
                handleAddNewActivitieToTarget={handleAddNewActivitieToTarget}
                handleRemoveActivitieOfTarget={handleRemoveActivitieOfTarget}
                handleUpdateTarget={handleUpdateTarget}
              />
            </Box>
          ))}
          {/* <AddActivitie addActivitie={addActivitie} />
          {payloadActivities.map((activitie) => (
            <Box key={activitie.id}>
              <ItemActivitie
                activitie={activitie}
                deleteActivitie={deleteActivitie}
              />
            </Box>
          ))} */}
        </Box>
      </Box>
    </Box>
  )
}
