import React, { useState } from 'react'
import useAuth from 'src/hooks/useAuth'
import { useTheme } from '@mui/material/styles'
import { Box, IconButton } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import clsx from 'clsx'
import {
  ChromeReaderModeOutlined as ChromeReaderModeOutlinedIcon,
  PeopleAltOutlined as PeopleAltOutlinedIcon,
  PermContactCalendarOutlined as PermContactCalendarOutlinedIcon,
  Search as SearchIcon,
  Close as CloseIcon
} from '@material-ui/icons'
import useGlobalUseStyles from 'src/themes/globalUseStyles'
import { CalendarPeriod, Tooltip } from 'src/components'
import makeStyles from '../style'
import { FILTER_BUTTONS, FILTER_BUTTONS_LED } from 'src/utils/constants'
import {
  getMetricsCustomersUsers,
  getMetricsUserGroups
} from 'src/services/hooks/metrics/useMetrics'
import { useLoadingContext } from 'src/context/LoadingContext'
import { useAlertContext } from 'src/context/AlertContext'
import { messageResponse } from 'src/utils/messageResponse'
import { useSettingsContext } from 'src/context/SettingsContext'

export function ToolsHeader({
  controlTools,
  onChangeControlTools,
  onChangeDataMetrics,
  tab,
  onChangeTab,
  inputReset,
  inputSearch
}) {
  const theme = useTheme()
  const classes = makeStyles()
  const globalClasses = useGlobalUseStyles()

  const { isExpandCard, isOpenSearch, filterSelected } = controlTools
  const { onCloseLoading, onOpenLoading } = useLoadingContext()
  const { onCallAlert } = useAlertContext()

  const isTabUser = tab === 'user'
  const isTabGroup = tab === 'group'

  const { user } = useAuth()
  const currentRole = user?.occupation
  const [role] = useState([1, 2, 3])

  const {
    onChangeMetricsCurrentTab,
    onToggleMetricsUserView,
    onToggleMetricsGroupView
  } = useSettingsContext()

  function onChangeBoolean(value) {
    onChangeControlTools({
      ...controlTools,
      [value]: !controlTools[value]
    })
  }

  function onChangeValue({ item, value }) {
    onChangeControlTools({
      ...controlTools,
      [item]: value
    })
  }

  async function onSelectedFilter(category = null) {
    try {
      onOpenLoading()
      if (tab === 'user') {
        const data = await getMetricsCustomersUsers({ category })
        onChangeDataMetrics(data)
        return 0
      }
      const data = await getMetricsUserGroups({ category })
      onChangeDataMetrics(data)
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      onCloseLoading()
    }
  }

  return (
    <Box className={globalClasses.breadcrumb}>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Box className={classes.containerBtn}>
            <button
              onClick={() => {
                onChangeTab('user')
                onChangeMetricsCurrentTab('user')
              }}
              className={clsx(
                classes.button,
                isTabUser && classes.buttonTabSelected
              )}
            >
              <PeopleAltOutlinedIcon sx={{ fontSize: 28 }} />
              Usuário
            </button>
            {role.includes(currentRole) && (
              <button
                onClick={() => {
                  onChangeTab('group')
                  onChangeMetricsCurrentTab('group')
                }}
                className={clsx(
                  classes.button,
                  isTabGroup && classes.buttonTabSelected
                )}
              >
                Grupos <PermContactCalendarOutlinedIcon sx={{ fontSize: 28 }} />
              </button>
            )}
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box className={classes.containerBtnOptions}>
            {isOpenSearch && (
              <Box className={classes.containerSearch}>{inputSearch}</Box>
            )}
            {!isOpenSearch ? (
              <IconButton
                color="primary"
                className={classes.iconSearchButton}
                aria-owns="search"
                aria-haspopup="true"
                onClick={() => onChangeBoolean('isOpenSearch')}
              >
                <SearchIcon />
              </IconButton>
            ) : (
              <IconButton
                color="primary"
                className={classes.iconActiveSearchButton}
                aria-owns="close-search"
                aria-haspopup="true"
                onClick={() => {
                  onChangeBoolean('isOpenSearch')
                  inputReset()
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
            <CalendarPeriod
              onChangeDataMetrics={onChangeDataMetrics}
              filterSelected={filterSelected}
              isShowIcon
              type={tab}
            />
            <Box className={classes.filterContainer}>
              <Box className={classes.filtersButtons}>
                {(role.includes(currentRole)
                  ? FILTER_BUTTONS
                  : FILTER_BUTTONS_LED
                ).map((item) => (
                  <Tooltip title={item.label} key={item.value}>
                    <div
                      className={
                        filterSelected != null && filterSelected === item.value
                          ? classes.buttonFilterSelected
                          : classes.buttonFilter
                      }
                      onClick={
                        filterSelected === item.value
                          ? () => {
                              onChangeValue({
                                item: 'filterSelected',
                                value: null
                              })
                              onSelectedFilter()
                            }
                          : () => {
                              onChangeValue({
                                item: 'filterSelected',
                                value: item.value
                              })
                              onSelectedFilter(item.value)
                            }
                      }
                      style={{ backgroundColor: item.color }}
                    />
                  </Tooltip>
                ))}
              </Box>
            </Box>
            <span>
              <Tooltip
                title={
                  isExpandCard
                    ? 'Visualizar simplificado'
                    : 'Visualizar com detalhes'
                }
              >
                <IconButton
                  onClick={() =>
                    tab === 'user'
                      ? onToggleMetricsUserView()
                      : onToggleMetricsGroupView()
                  }
                  style={{
                    background:
                      isExpandCard === true && theme.palette.primary.main,
                    color: isExpandCard === true && '#fff'
                  }}
                  className={classes.boxContentBtn}
                >
                  <ChromeReaderModeOutlinedIcon />
                </IconButton>
              </Tooltip>
            </span>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
