import React, { useState, useEffect, useCallback } from 'react'
import useAuth from 'src/hooks/useAuth'
import { useTheme } from '@material-ui/styles'
import { IconButton, Box } from '@material-ui/core'
import { useParams, useNavigate } from 'react-router-dom'
import {
  Close as CloseIcon,
  EditOutlined as EditOutlinedIcon
} from '@material-ui/icons'
import {
  Button,
  Tooltip,
  CardExpectedEvoluction,
  NewBoxWidget,
  ProgressChips
} from 'src/components'
import { useAlertContext } from 'src/context/AlertContext'
import { useLoadingContext } from 'src/context/LoadingContext'
import { messageResponse } from 'src/utils/messageResponse'
import {
  getDevelopmentPlanById,
  putDevelopmentPlan
} from 'src/services/hooks/developmentPlan/useDevelopmentPlan'
import { postUserGroupsSearch } from 'src/services/hooks/userGroup/useUserGroup'
import ModalEvolutions from './ModalEvolutions'
import makeStyles from './style'

function DetailsPDI({
  onClose,
  userId,
  idDevPlan,
  getDevelopmentPlanSearch,
  userRole
}) {
  const theme = useTheme()
  const classes = makeStyles()

  const navigate = useNavigate()

  const { id: paramUserId } = useParams()

  const { user } = useAuth()
  const currentCustomerId = user?.customerId

  const { notifications, onCallAlert } = useAlertContext()
  const { onCloseLoading, onOpenLoading } = useLoadingContext()

  const [payloadTargets, setPayloadTargets] = useState([])
  const [payloadActivities, setPayloadActivities] = useState([])

  const [data, setData] = useState()
  const [dataUserGroups, setDataUserGroups] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const [isOpenModalEvolutions, setIsOpenModalEvolutions] = useState(false)

  const [enabledReview, setEnabledReview] = useState(false)

  function onHandleEvolutions() {
    setIsOpenModalEvolutions(!isOpenModalEvolutions)
  }

  function onHandleReview() {
    setEnabledReview(true)
  }

  const onHandleSubmit = async (event, concluded) => {
    try {
      onOpenLoading()
      const payload = {
        id: data?.id,
        name: data?.name,
        description: data?.description,
        estimatedCompletion: data?.estimatedCompletion,
        status: concluded ? 3 : data?.status,
        progress: data?.progress,
        activities: payloadActivities,
        targets: payloadTargets,
        competencies: data?.competencies,
        fromUserId: data?.fromUserId,
        toUserId: data?.toUserId,
        customerId: data?.customerId,
        isTemplate: data?.isTemplate,
        isActive: data?.isActive
      }
      await putDevelopmentPlan(data?.id, payload)
      await getDevelopmentPlanSearch(data?.customerId, data?.toUserId)
      await getDevelopmentPlan(data?.id)
      concluded && onClose()
      onCallAlert({
        type: 'success',
        message: notifications.pdi.success.editPDI
      })
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      onCloseLoading()
    }
  }

  const getDevelopmentPlan = useCallback(async (idDevPlan) => {
    try {
      setIsLoading(true)
      const response = await getDevelopmentPlanById(idDevPlan)
      response?.progress === 100 && setEnabledReview(false)
      setData(response)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }, [])

  const getDataUserGroups = useCallback(async (idUser, customerId) => {
    try {
      setIsLoading(true)

      const payloadGetGroups = {
        customerId: customerId,
        isActive: true
      }
      const usersGroupsSearch = await postUserGroupsSearch(payloadGetGroups)
      const groups = usersGroupsSearch.map(
        (item) => item.members.find((member) => idUser === member) && item
      )
      const groupsUser = groups.filter((item) => !!item)
      const userGroups = groupsUser.map((item) => item.id)

      setDataUserGroups(userGroups)
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    setPayloadTargets([])
    setPayloadActivities([])
    setEnabledReview(false)
    idDevPlan && getDevelopmentPlan(idDevPlan)
    paramUserId && getDataUserGroups(paramUserId, currentCustomerId)
  }, [
    currentCustomerId,
    getDataUserGroups,
    getDevelopmentPlan,
    idDevPlan,
    paramUserId
  ])

  function onHandleClose() {
    setIsOpenModalEvolutions(!isOpenModalEvolutions)
  }

  function onHandleEdit(id) {
    navigate(`/development-plan/edit/${userId}/${id}`)
  }

  const isReview = data?.progress === 100

  return (
    <>
      <ModalEvolutions
        isOpenModalEvolutions={isOpenModalEvolutions}
        onClose={onHandleClose}
        onHandleSubmit={onHandleSubmit}
        idUser={userId}
        idDevPlan={idDevPlan}
      />
      <NewBoxWidget
        title="Detalhes do PDI"
        options={
          <>
            <Tooltip title="Editar PDI">
              <IconButton
                className={classes.boxStarTooltip}
                onClick={() => onHandleEdit(idDevPlan)}
              >
                <EditOutlinedIcon htmlColor={theme.palette.primary.main} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Fechar">
              <IconButton onClick={onClose} className={classes.boxStarTooltip}>
                <CloseIcon htmlColor={theme.palette.primary.main} />
              </IconButton>
            </Tooltip>
          </>
        }
        actions={
          <>
            {isReview && !enabledReview ? (
              <>
                <Button
                  label="Revisar PDI"
                  variant="dark"
                  onClick={onHandleReview}
                />
                <Button label="Concluir PDI" onClick={onHandleEvolutions} />
              </>
            ) : (
              <Button label="Salvar PDI" onClick={onHandleSubmit} />
            )}
          </>
        }
        width={450}
        height="100%"
      >
        {!isLoading && data && dataUserGroups && (
          <>
            <CardExpectedEvoluction
              data={data}
              payloadTargets={payloadTargets}
              setPayloadTargets={setPayloadTargets}
              payloadActivities={payloadActivities}
              setPayloadActivities={setPayloadActivities}
              disabledSave={isReview && !enabledReview}
            />
            <ProgressChips
              customerId={currentCustomerId}
              userRole={userRole}
              dataCompetencies={data?.competencies}
              dataUserGroups={dataUserGroups}
            />
          </>
        )}
      </NewBoxWidget>
    </>
  )
}

export default DetailsPDI
