import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  appBarOpenSideBar: {
    width: 'calc(100% - 236.77px)',
    marginLeft: '236.77px',
    transition: 'all .3s'
  },

  appBar: {
    height: '69.28px',
    width: '100%',
    display: 'flex',
    position: 'relative',
    top: '0',
    zIndex: '20',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    transition: 'all .3s'
  },
  userContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0px 30px'
  },
  userIcons: {
    marginRight: theme.spacing(1),
    color: '#fff',
    width: '29px'
  },
  userText: {
    fontSize: '14px',
    fontFamily: 'Roboto',
    color: '#fff',
    marginRight: theme.spacing(1),
    padding: '0px 40px'
  },
  sideBar: {
    height: '100vh !important',
    zIndex: '10',
    minWidth: '236.77px !important',
    transition: 'all 0.3s',
    overflow: 'hidden',
    backgroundColor: '#000000'
  },
  iconWrapper: {
    width: '64px',
    height: '64px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  accordionIcons: {
    color: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden',
    transition: 'margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
  },
  btnSideBarRight: {
    cursor: 'pointer',
    border: 'none',
    minWidth: '69px',
    height: '69px',
    backgroundColor: 'transparent'
  },
  boxLinksProfile: {
    display: 'flex',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    position: 'fixed',
    backgroundColor: '#ffffff',
    borderRadius: '0px 0px 10px 10px',
    top: '70px',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '203px',
    transition: 'all 0.3s',
    overflow: 'hidden',
    zIndex: 15
  },
  boxAccordionIcons: {
    height: '64px',
    width: '64px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  },
  boxCloseOptions: {
    top: '0px',
    right: '0px',
    left: '0px',
    bottom: '0px',
    zIndex: 10,
    position: 'absolute',
    backgroundColor: 'transparent',
    border: 'none'
  },
  boxLinksNotification: {
    display: 'flex',
    right: '111px',
    zIndex: 10,
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    position: 'fixed',
    backgroundColor: '#ffffff',
    borderRadius: '0px 0px 10px 10px',
    top: '68px',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    minWidth: '203px',
    transition: 'all 0.3s',
    overflow: 'hidden'
  },
  boxLinksProfileOpen: {
    maxHeight: '500px',
    transition: 'all .3s'
  },
  boxLinksProfileClose: {
    maxHeight: 0,
    transition: 'all .3s'
  },
  boxLinksProfileOpenBtn: {
    backgroundColor: '#ffffff',
    color: `${theme.palette.primary.main} !important`
  },
  boxLinksProfileCloseBtn: {
    backgroundColor: 'transparent'
  },
  boxLinksProfileTop: {
    display: 'flex',
    padding: '20px',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderBottom: `solid 2px ${theme.palette.primary.main}`
  },
  boxProfileImg: {
    backgroundColor: 'transparent',
    border: `solid 2px ${theme.palette.primary.main}`,
    width: '80px',
    height: '80px',
    margin: '20px 0px 10px 0px',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    color: '#787878',
    fontSize: theme.typography.pxToRem(17)
  },
  boxProfileName: {
    fontSize: theme.typography.pxToRem(15),
    margin: '0px',
    textAlign: 'center',
    marginTop: '10px',
    color: '#000'
  },
  boxLinksProfileBottom: {
    display: 'flex',
    padding: '10px 0px',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%'
  },
  btnLinks: {
    border: 'none',
    display: 'flex',
    width: '100%',
    padding: '0px 15px',
    marginBottom: '5px',
    height: '50px',
    backgroundColor: 'transparent',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main
    },
    textDecoration: 'none'
  },
  textBtnLinks: {
    fontSize: theme.typography.pxToRem(15),
    color: '#000',
    padding: '0px 15px',
    margin: '0px',
    right: '109px',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    transition: 'all 0.3s',
    overflow: 'hidden',
    zIndex: '10'
  },
  boxLinksNotificationOpen: {
    maxHeight: 'auto',
    transition: 'all .3s'
  },
  boxLinksNotificationClose: {
    maxHeight: 0,
    marginTop: '-200px',
    overflow: 'hidden',
    transition: 'all .3s'
  },
  boxLinksNotificationOpenBtn: {
    backgroundColor: '#ffffff',
    color: `${theme.palette.primary.main} !important`
  },
  boxLinksNotificationCloseBtn: {
    backgroundColor: 'transparent'
  },

  /////////////////////

  divTest: {
    width: '64px',
    height: '100%',
    paddingTop: '8px',
    paddingBottom: '8px'
  },
  divTestRemover: {
    width: '64px',
    backgroundColor: 'transparent',
    height: '100%',
    position: 'fixed',
    zIndex: '10'
  },
  boxList: {
    display: 'flex !important',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100vh'
  },
  sidebarList: {},

  boxClickAccordion: {
    display: 'flex',
    width: '100%',
    transition: 'all .4s',
    '& .MuiTypography-root ': {
      fontSize: '14px'
    },
    '& .accordionIconsClose': {
      border: 'solid 3px #fff',
      height: '20px',
      backgroundColor: '#000',
      padding: '2px'
    },
    '&:hover': {
      backgroundColor: '#fff !important',
      color: '#000 !important',
      '& .MuiSvgIcon-root ': {
        color: '#000 !important'
      },
      '& .accordionIconsClose': {
        border: `solid 3px ${theme.palette.primary.main}`,
        backgroundColor: '#fff'
      },
      '& .MuiTypography-root ': {
        color: '#000 !important'
      }
    }
  },

  container: {
    display: 'flex'
  },

  containerChildren: {
    display: 'flex',
    flex: 1,
    height: '100vh',
    width: '100%',
    flexDirection: 'column'
  },
  boxImage: {
    width: 160,
    height: 60,
    marginLeft: 20,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    overflow: 'hidden',
    '& img': {
      height: 55,
      maxWidth: '100%'
    }
  },
  boxImageTwo: {
    width: 160,
    height: 40,
    marginLeft: 20,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    overflow: 'hidden',
    '& img': {
      height: 40,
      width: 'auto'
    }
  }
}))
