import * as React from 'react'
import {
  CircularProgress,
  Typography,
  circularProgressClasses
} from '@mui/material'
import { Box } from '@mui/material'
import { Tooltip } from 'src/components'
import { enumStatus } from 'src/@enum/developmentPlan'

function TooltipInformation({ props }) {
  return (
    <Box textAlign="center">
      <Typography variant="bold">{enumStatus[props.status]}</Typography>
      <Typography variant="body2">
        {`${Math.round(props.value)}%`} completo
      </Typography>
    </Box>
  )
}

export function CircularProgressWithLabel(props) {
  return (
    <Tooltip title={<TooltipInformation props={props} />} placement="right">
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: (theme) => theme.palette.grey[300]
          }}
          size={50}
          thickness={1}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="determinate"
          sx={{
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round'
            }
          }}
          size={50}
          thickness={4}
          {...props}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  )
}
