import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  metricsBarProgress: {
    width: '100%',
    height: 20,
    backgroundColor: '#D8D8D8',
    display: 'flex'
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    marginRight: 2
  },
  value: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 14
  },
  titleLegend: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    marginRight: 2,
    color: '#787878'
  },
  valueLegend: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#787878'
  },
  containerLegend: {
    marginTop: 10
  }
}))
