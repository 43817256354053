import * as React from 'react'
import { Container, Toolbar } from '@mui/material'
import { LogoDark } from '../../assets'
import useStyles from './styles'

export function NavbarAuth() {
  var classes = useStyles()

  return (
    <Container
      sx={{
        display: {
          xs: 'flex',
          md: 'none'
        },
        justifyContent: 'center'
      }}
    >
      <Toolbar>
        <img src={LogoDark} alt="Growk Logo" className={classes.logo} />
      </Toolbar>
    </Container>
  )
}
