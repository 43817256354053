import {
  CELEBRATION,
  CELEBRATION_COMMENT,
  CELEBRATION_LIKE,
  CELEBRATION_SEARCH
} from 'src/services/api'
import axios, { tokenAuthorization } from 'src/utils/axios'

export async function getCelebration() {
  const response = await axios.get(CELEBRATION, tokenAuthorization())
  return response
}


export async function getUserCelebration({
  customerId,
  fromUserId,
  withUsers
}) {
  const isFromUserId = !!fromUserId;
  let payload;

  if(isFromUserId) {
    payload = {
      customerId,
      fromUserId: fromUserId,
    }
  } else {
    payload = {
      customerId,
      withUsers,
    }
  }

  const response = await axios.post(
    CELEBRATION_SEARCH,
    payload,
    tokenAuthorization()
  )
  return response
}

export async function patchLikeCelebration({
  celebrationId,
  userId,
  liked,
}) {
  const response = await axios.patch(
    CELEBRATION_LIKE,
    {
      celebrationId,
      userId,
      liked
    },
    tokenAuthorization()
  )
  return response
}

export async function patchCommentCelebration({
  celebrationId,
  userId,
  comment,
}) {
  const response = await axios.patch(
    CELEBRATION_COMMENT,
    {
      celebrationId,
      userId,
      comment
    },
    tokenAuthorization()
  )
  return response
}

export async function postCelebration({
  fromUserId,
  customerId,
  withUsers,
  description,
}) {
  const response = await axios.post(
    CELEBRATION,
    {
      fromUserId,
      customerId,
      withUsers,
      description,
    },
    tokenAuthorization()
  )
  return response
}
