import React, { useState } from 'react'
import { useTheme } from '@material-ui/styles'
import { Button, Tooltip, SliderChip, NewBoxWidget, NotFound } from 'src/components'
import { IconButton, Grid, Typography, Box } from '@material-ui/core'
import ExpectedEvolution from '../expectedEvolution'
import {
  Close as CloseIcon,
  TrendingUpOutlined as TrendingUpOutlinedIcon,
  FeedbackOutlined as FeedbackOutlinedIcon
} from '@material-ui/icons'
import makeStyles from './style'
import { useAlertContext } from 'src/context/AlertContext'

export default function BehaviorNotes({
  removeClose = false,
  onClose,
  nextOption,
  scores,
  competencyValues,
  setCompetencyValues
}) {
  const theme = useTheme()
  const classes = makeStyles()

  const { onCallAlert } = useAlertContext()

  const [isOpenExpectedEvolution, setOpenExpectedEvolution] = useState(false)

  const [competence, setCompetence] = useState(
    !competencyValues.length ? scores : competencyValues
  )

  function onHandleExpectedEvolution() {
    setOpenExpectedEvolution(!isOpenExpectedEvolution)
  }

  const onChangeScore = (score, idx, idx_scores) => {
    let newData = [...competence]
    newData[idx].scores[idx_scores].competenceScore = score
    setCompetence(newData)
  }

  const onSave = () => {
    setCompetencyValues(competence)
    onCallAlert({
      type: 'success',
      message: 'Notas de comportamento salvas com sucesso!'
    })
  }

  const shouldRenderContent = competence?.length > 0;

  return (
    <>
      <NewBoxWidget
        title="Notas de Comportamento"
        subtitle="Selecione a nota atualizada de comportamento do Goker (selecione apenas as notas que deseja atualizar):"
        options={
          <>
            <Tooltip
              placement="top"
              title={
                <Box>
                  <Typography className={classes.tooltiptText}>
                    1 - Insatisfatório
                  </Typography>
                  <Typography className={classes.tooltiptText}>
                    2 - Abaixo do esperado
                  </Typography>
                  <Typography className={classes.tooltiptText}>
                    3 - Dentro do esperado
                  </Typography>
                  <Typography className={classes.tooltiptText}>
                    4 - Acima do esperado
                  </Typography>
                  <Typography className={classes.tooltiptText}>
                    5 - Excepcional
                  </Typography>
                </Box>
              }
            >
              <IconButton>
                <FeedbackOutlinedIcon htmlColor={theme.palette.primary.main} />
              </IconButton>
            </Tooltip>
            {!removeClose && (
              <Tooltip title="Fechar">
                <IconButton onClick={onClose}>
                  <CloseIcon htmlColor={theme.palette.primary.main} />
                </IconButton>
              </Tooltip>
            )}
          </>
        }
        actions={shouldRenderContent && <Button label="Salvar" onClick={onSave} />}
        width={750}
        height="100%"
      >
        <Grid className={classes.cardContainer}>
        {
          shouldRenderContent ? (
            <SliderChip
              data={competence}
              onChangeScore={onChangeScore}
              // onOpenExpectedEvolution={
              //   <div className={classes.boxBtnOpenExpectedEvolution}>
              //     <IconButton
              //       className={clsx(
              //         classes.btnOpenExpectedEvolution,
              //         isOpenExpectedEvolution
              //           ? classes.btnOpenExpectedEvolutionSelected
              //           : ''
              //       )}
              //       onClick={onHandleExpectedEvolution}
              //     >
              //       <TrendingUpOutlinedIcon />
              //     </IconButton>
              //   </div>
              // }
            />
          ) : (
            <div className={classes.notFound}>
              <NotFound
                title="Nenhuma informação"
                subTitle="Não há agrupadores de competências vinculados a essa categoria."
              />
            </div>
          )
        }
        </Grid>
      </NewBoxWidget>
      {isOpenExpectedEvolution && (
        <ExpectedEvolution onClose={onHandleExpectedEvolution} />
      )}
    </>
  )
}
