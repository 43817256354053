import { makeStyles, withStyles, Slider } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: '#fff'
  },
  containerCrop: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '240px',
    width: '100%'
  },
  controls: {
    display: 'flex',
    padding: '10px 20% 10px 20%',
    width: '100%',
    background: '#f6f7ff'
  },
  boxUplod: {
    width: 200,
    height: 200,
    borderRadius: 200,
    overflow: 'hidden',
    border: 'solid 3px',
    borderColor: theme.palette.primary.main,
    background: '#f6f7ff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  controlButton: {
    display: 'flex',
    justifyContent: 'end',
    paddingBottom: '15px',
    marginBottom: '10px',
    width: '100%',
    gap: '15px',
    background: '#f6f7ff',
    paddingRight: '15px'
  },
  imgCropped: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  boxUplodImg: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& p': {
      width: 100,
      fontSize: 14,
      margin: 0,
      textAlign: 'center'
    }
  }
}))

export const DefaultStyledSlider = withStyles((theme) => ({
  root: {
    color: 'rgba(0, 0, 0, 0.26)'
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: theme.palette.primary.main,
    border: '1px solid currentColor',
    marginTop: -9,
    marginLeft: -11,
    boxShadow: '0 0 0 0',
    '&:focus, &:hover, &$active': {
      boxShadow: '0 0 0 0'
    },
    color: theme.palette.primary.main
  }
}))(Slider)
