import { makeStyles, withStyles, Slider } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '290px',
    width: '100%',
    backgroundColor: '#fff',
    backgroundSize: '50px 50px'
  },
  containerCrop: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background:
      'repeating-linear-gradient(transparent, transparent 2px, #334343 2px, #334343 22px, transparent 22px, transparent 23px, #334343 23px, #334343 43px),repeating-linear-gradient(0.25turn, transparent, transparent 2px, #334343 2px, #334343 22px, transparent 22px, transparent 23px, #334343 23px, #334343 43px)',
    flexDirection: 'column',
    height: '272.83px',
    width: '100%'
  },
  controls: {
    display: 'flex',
    margin: '20px auto auto auto',
    width: '202.42px'
  },
  boxUplod: {
    width: 100,
    height: 100,
    border: 'solid 3px #fff',
    borderRadius: 10,
    backgroundColor: (props) =>
      props.customerPrimaryColor ?? theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  controlButton: {
    display: 'flex',
    columnGap: '20px',
    height: '48.47px',
    margin: '20px',
    justifyContent: 'end'
  },
  imgCropped: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  }
}))

export const DefaultStyledSlider = withStyles((theme) => ({
  root: {
    color: 'rgba(0, 0, 0, 0.26)'
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: (props) =>
      props.customerPrimaryColor ?? theme.palette.primary.main,
    border: '1px solid currentColor',
    marginTop: -9,
    marginLeft: -11,
    boxShadow: '0 0 0 0',
    '&:focus, &:hover, &$active': {
      boxShadow: '0 0 0 0'
    },
    color: (props) => props.customerPrimaryColor ?? theme.palette.primary.main
  }
}))(Slider)
