import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    marginLeft: '30px'
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20
  },
  btnClose: {
    marginRight: -10,
    marginTop: -15
  },
  containerScroll: {
    width: 'calc(100% + 32px)',
    maxHeight: 'calc(100vh - 310px)',
    background: '#fff',
    borderRadius: '10px',
    overflow: 'auto',
    overflowX: 'hidden',
    padding: '0px 16px 0px 16px',
    marginLeft: -16,
    '&::-webkit-scrollbar': {
      width: 8
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: 10
    }
  },
  cardContainer: {
    width: '100%',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    gap: 13,
    marginTop: -30,
    height: '100%',
  },
  btnOpenExpectedEvolution: {
    marginRight: 0,
    marginTop: -8,
    marginBottom: -10,
    width: 30,
    height: 30,
    borderRadius: 5
  },
  boxBtnOpenExpectedEvolution: {
    width: 35,
    height: 35,
    marginTop: -8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& :hover': {
      background: theme.palette.primary.main,
      color: '#fff'
    }
  },
  btnOpenExpectedEvolutionSelected: {
    background: theme.palette.primary.main,
    color: '#fff'
  },
  tooltiptText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14
  },
  notFound: {
    padding: '20px 0',
    margin: 'auto',
  }
}))
