import React from 'react'
import { UploadImage, NewBoxWidget } from 'src/components'

function Favicon({
  onCloseEdit,
  onConfirmFavicon,
  onCloseNextIcon,
  dataAppearance
}) {
  function onChangeFavicon(value) {
    onConfirmFavicon(value)
  }

  return (
    <NewBoxWidget
      title="Favicon da plataforma"
      subtitle="Adicione um Favicon, lembre-se: ele tem que ser bem simples, poís será aplicado em pequenos espaços."
      noBodyPadding
      width={420}
      style={{ marginLeft: '30px' }}
    >
      <UploadImage
        dataAppearance={dataAppearance}
        onConfirmImage={onChangeFavicon}
        onCloseNext={onCloseNextIcon}
        onCloseEdit={onCloseEdit}
        aspect={5 / 5}
      />
    </NewBoxWidget>
  )
}

export default Favicon
