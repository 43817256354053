import React, { useState } from 'react'
import useAuth from 'src/hooks/useAuth'
import PropTypes from 'prop-types'
import { LogoGrey } from 'src/assets'
import { useTheme } from '@material-ui/styles'
import { Stack, AppBar, Toolbar, IconButton } from '@mui/material'
import { bgBlur } from 'src/utils/cssStyles'
import useResponsive from 'src/hooks/useResponsive'
import { Menu as MenuIcon } from '@material-ui/icons'
import { HEADER, NAV } from 'src/config-global'
import { Box } from '@material-ui/core'
import { useSettingsContext } from 'src/context/SettingsContext'
import RootPopover from './RootPopover'
import AccountPopover from './AccountPopover'
import NotificationsPopover from './NotificationsPopover'
import makeStyles from './styles'

Header.propTypes = {
  onOpenNav: PropTypes.func
}

export default function Header({ onOpenNav, colorEdit, getImageLogoPlatform }) {
  const theme = useTheme()
  const classes = makeStyles()

  const customer = JSON.parse(localStorage.getItem('customer'))
  const customerName = customer?.name
  const customerLogo = customer?.logo

  const { user } = useAuth()
  const isRoot = user?.occupation === 1

  const { themeLayout } = useSettingsContext()

  const isNavHorizontal = themeLayout === 'horizontal'

  const isNavMini = themeLayout === 'mini'

  const isDesktop = useResponsive('up', 'lg')

  const renderContent = (
    <>
      {isDesktop && (
        <>
          {getImageLogoPlatform ? (
            <Box className={classes.logo}>
              <Box
                component="img"
                alt="Logo da Plataforma"
                src={getImageLogoPlatform}
              />
            </Box>
          ) : (
            <Box className={classes.logo}>
              <Box
                component="img"
                alt={customerName ?? 'Logo da Plataforma'}
                src={customerLogo ? customerLogo : LogoGrey}
              />
            </Box>
          )}
        </>
      )}

      {!isDesktop && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1, color: 'text.primary' }}>
          <MenuIcon htmlColor="#FFFFFF" />
        </IconButton>
      )}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1.5 }}
      >
        <NotificationsPopover />
        <AccountPopover />
        {isRoot && <RootPopover />}
      </Stack>
    </>
  )

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter
        }),
        ...(isDesktop && {
          width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: `solid 1px ${theme.palette.divider}`
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`
          })
        }),
        backgroundColor: theme.palette.primary.main
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 }
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  )
}
