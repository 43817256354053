import axios, { tokenAuthorization } from 'src/utils/axios'
import { formatQueryParams } from 'src/utils/formatQueryParams'
import {
  METRICS_USERS,
  METRICS_USER_GROUPS,
  METRICS_USER_SEARCH,
  METRICS_CUSTOMERS_USERS,
  METRICS_FAVORITE_GROUP,
  METRICS_USER_GROUPS_BY_ID
} from 'src/services/api'

export async function getMetricsUsers({ id, category, period }) {
  const querys = [
    { key: 'category', value: category },
    { key: 'start', value: period?.start },
    { key: 'finish', value: period?.finish }
  ]
  const getQuerys = formatQueryParams(querys)
  const url = `${METRICS_USERS}/${id}${getQuerys}`
  const response = await axios.get(url, tokenAuthorization())
  const { data } = response.data
  return data
}

export async function getMetricsUsersByCategory(id, category) {
  const url = category
    ? `${METRICS_USERS}/${id}?category=${category}`
    : `${METRICS_USERS}/${id}`
  const response = await axios.get(url, tokenAuthorization())
  const { data } = response.data
  return data
}

export async function getMetricsUserGroups({ category, period }) {
  const querys = [
    { key: 'category', value: category },
    { key: 'start', value: period?.start },
    { key: 'finish', value: period?.finish }
  ]
  const getQuerys = formatQueryParams(querys)
  const url = `${METRICS_USER_GROUPS}${getQuerys}`

  const response = await axios.get(url)
  const { data } = response.data
  return data
}

export async function getMetricsUserGroupsById({
  groupId,
  category = null,
  period
}) {
  const querys = [
    { key: 'category', value: category },
    { key: 'start', value: period?.start },
    { key: 'finish', value: period?.finish }
  ]
  const getQuerys = formatQueryParams(querys)
  const url = `${METRICS_USER_GROUPS_BY_ID}/${groupId}${getQuerys}`
  const response = await axios.get(url, tokenAuthorization())
  const { data } = response.data
  return data
}

export async function postFavoriteGroup(body) {
  const token = tokenAuthorization().headers
  const parameters = {
    method: 'POST',
    headers: token
  }
  const response = await axios.post(METRICS_FAVORITE_GROUP, body, parameters)
  const { data } = response.data
  return data
}

export async function deleteFavoriteGroup(body) {
  const { id } = body
  const token = tokenAuthorization().headers
  const parameters = {
    method: 'DELETE',
    headers: token
  }
  const response = await axios.delete(
    METRICS_FAVORITE_GROUP + `/${id}`,
    parameters
  )
  const { data } = response.data
  return data
}

export async function postMetricsUsersSearch(body) {
  const token = tokenAuthorization().headers
  const parameters = {
    method: 'POST',
    headers: token
  }
  const response = await axios.post(METRICS_USER_SEARCH, body, parameters)
  const { data } = response.data
  return data
}

export async function getMetricsCustomersUsers({ category, period }) {
  const querys = [
    { key: 'category', value: category },
    { key: 'start', value: period?.start },
    { key: 'finish', value: period?.finish }
  ]
  const getQuerys = formatQueryParams(querys)
  const url = `${METRICS_CUSTOMERS_USERS}${getQuerys}`
  const response = await axios.get(url)
  const { data } = response.data
  return data
}

export async function getMetricsUserGroupsCompetences() {
  const response = await axios.get(METRICS_USER_GROUPS)
  const { data } = response.data
  return data
}
