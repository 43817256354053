export const enumStatus = {
  1: 'Pendente',
  2: 'Em andamento',
  3: 'Concluído',
  4: 'Cancelado',
  5: 'Prorrogado',
  6: 'Atrasado'
}

export const enumStatusId = {
  Pendente: 1,
  EmAndamento: 2,
  Concluído: 3,
  Cancelado: 4,
  Prorrogado: 5,
  Atrasado: 6
}
