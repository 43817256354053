import axios, { tokenAuthorization } from 'src/utils/axios'
import { OCCUPATION_AREA } from 'src/services/api'

export async function getOccupationArea() {
  const response = await axios.get(OCCUPATION_AREA, tokenAuthorization())
  const { data } = response?.data
  return data
}

export async function putOccupationArea(id, body) {
  const token = tokenAuthorization().headers
  const parameters = {
    method: 'PUT',
    headers: token
  }
  const response = await axios.put(OCCUPATION_AREA + `/${id}`, body, parameters)
  return response
}

export async function getOccupationAreaById(id) {
  const response = await axios.get(
    OCCUPATION_AREA + `/${id}`,
    tokenAuthorization()
  )
  const { data } = response?.data
  return data
}
