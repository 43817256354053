import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    width: '416px',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    background: '#ffffff',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
    padding: '20px',
    marginLeft: '20px'
  },
  btnTooltip: {
    gap: 10,
    display: 'flex',
    flexDirection: 'column'
  },
  headerInformations: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 0,
    marginBottom: 20
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5
  },
  btnClose: {
    marginRight: -10,
    marginTop: -32,
    display: 'flex'
  },
  formControlButton: {
    display: 'flex',
    justifyContent: 'end',
    paddingTop: 30,
    height: 75
  },
  boxScroll: {
    maxHeight: '58vh',
    width: '416px',
    marginRight: -10,
    marginLeft: '-20px',
    padding: '0px 20px',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: '10px'
    }
  },
  boxFormControlButton: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end'
  }
}))
