import { currentPeriodYear } from 'src/utils/constants'

export function getPayloadPeriod(data) {
  if(data) {
    const currentPeriodStart = new Date(data.start)
    const currentPeriodFinish = new Date(data.finish)
    const formatedDatePayload = {
      start: `${currentPeriodStart.getFullYear()}-${(currentPeriodStart.getMonth() + 1)}-${currentPeriodStart.getDate()}`,
      finish: `${currentPeriodFinish.getFullYear()}-${(currentPeriodFinish.getMonth() + 1)}-${currentPeriodFinish.getDate()}`,
    }
    return formatedDatePayload;
  }

  return {
      start: `${currentPeriodYear?.firstDay.getFullYear()}-${("0" + (currentPeriodYear?.firstDay.getMonth() + 1)).slice(-2)}-${("0" + currentPeriodYear?.firstDay.getDate()).slice(-2)}`,
      finish: `${currentPeriodYear?.lastDay.getFullYear()}-${("0" + (currentPeriodYear?.lastDay.getMonth() + 1)).slice(-2)}-${("0" + currentPeriodYear?.lastDay.getDate()).slice(-2)}`,
    }
}
