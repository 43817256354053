import React, { useState, useCallback, useEffect } from 'react'
import 'moment/locale/pt-br'
import moment from 'moment'
import { Typography } from '@material-ui/core'
import { Box } from '@mui/material'
import { BadgeTooltip, Tooltip, Dot } from 'src/components'
import { messageResponse } from 'src/utils/messageResponse'
import { useAlertContext } from 'src/context/AlertContext'
import { getUserById } from 'src/services/hooks/users/useUsers'
import { getCompetenceGrouper } from 'src/services/hooks/competenceGrouper/useCompetenceGrouper'
import { enumCategory, enumCategoryColor } from 'src/@enum/metrics'
import groupBy from 'lodash/groupBy'
import makeStyles from './style'

export function CardImpressionsFeedback({ fromUserId, item }) {
  const classes = makeStyles()

  const { onCallAlert } = useAlertContext()

  const [dataUserFrom, setDataUserFrom] = useState()
  const [dataCategories, setDataCategories] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const getDataUser = useCallback(async (userFromId) => {
    try {
      setIsLoading(true)
      const { data: dataUserFrom } = await getUserById(userFromId)
      setDataUserFrom(dataUserFrom?.data)
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }, [])

  const getDataCategories = useCallback(async (competences) => {
    try {
      setIsLoading(true)
      const dataCompetences = competences?.map((item) => item)

      const { data: competenceGrouper } = await getCompetenceGrouper({})
      const filteredCompetences = competenceGrouper?.data?.map((item) => {
        const filter = item.competences.filter((elem) =>
          dataCompetences.includes(elem)
        )
        return {
          ...item,
          competences: filter
        }
      })
      const dataCompetencesCategory = filteredCompetences.filter(
        (item) => !!item.competences.length
      )
      const dataCategory = groupBy(dataCompetencesCategory, 'category')
      const dataListCategory = Object.entries(dataCategory).map(
        (currentData) => {
          const [categoryId] = currentData
          return {
            categoryId: categoryId
          }
        }
      )

      setDataCategories(dataListCategory)
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    fromUserId && getDataUser(fromUserId)
    item.competencies && getDataCategories(item.competencies)
  }, [getDataUser, fromUserId, item, getDataCategories])

  return (
    <Box className={classes.boxContainer}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography className={classes.date}>
          {moment(item?.start).format('LL')}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          gap={1}
        >
          {dataCategories?.map((item, index) => (
            <Tooltip key={index} title={enumCategory[item.categoryId]}>
              <div>
                <Dot color={enumCategoryColor[item.categoryId]} size="large" />
              </div>
            </Tooltip>
          ))}
        </Box>
      </Box>
      <Box display="flex" py={2}>
        {!isLoading && dataUserFrom && (
          <Box display="flex" mr={1}>
            <BadgeTooltip
              placement="top"
              disableFocusListener
              disableTouchListener
              user={dataUserFrom}
            />
          </Box>
        )}
        <Typography variant="body">{item.description}</Typography>
      </Box>
    </Box>
  )
}
