import React, { useState, useRef, useEffect } from 'react'
import { styled, alpha } from '@mui/material/styles'
import {
  Grow,
  Paper,
  Popper,
  MenuList,
  Stack,
  Toolbar,
  OutlinedInput,
  InputAdornment
} from '@mui/material'
import { IconButton, Box } from '@material-ui/core'
import { Search as SearchIcon, Close as CloseIcon } from '@material-ui/icons'
import { Tooltip } from 'src/components'
import { useTheme } from '@material-ui/styles'
import useStyles from './styles'

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}))

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  marginRight: 10,
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`
  }
}))

export default function TableToolbar({
  numSelected,
  filterName,
  onFilterName,
  options
}) {
  const classes = useStyles()

  const theme = useTheme()
  const anchorRef = useRef(null)

  const [openSearch, setOpenSearch] = useState(false)

  const handleToggle = () => {
    setOpenSearch((prevOpen) => !prevOpen)
  }

  const prevOpen = useRef(openSearch)
  useEffect(() => {
    if (prevOpen.current === true && openSearch === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = openSearch
  }, [openSearch])

  return (
    <StyledRoot>
      <Stack direction="row" spacing={2}>
        <div>
          <Box display="flex" alignItems="center">
            {!openSearch ? (
              <Tooltip placement="top" title="Buscar">
                <IconButton
                  ref={anchorRef}
                  id="button-open"
                  aria-controls={openSearch ? 'menu-open' : undefined}
                  aria-expanded={openSearch ? 'true' : undefined}
                  aria-haspopup="true"
                  className={classes.iconSearchButton}
                  onClick={handleToggle}
                >
                  <SearchIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip placement="top" title="Fechar">
                <IconButton
                  ref={anchorRef}
                  id="menu-close"
                  aria-controls={openSearch ? 'menu-close' : undefined}
                  aria-expanded={openSearch ? 'true' : undefined}
                  aria-haspopup="true"
                  className={classes.iconActiveSearchButton}
                  onClick={handleToggle}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            )}
            {options}
          </Box>
          <Popper
            open={openSearch}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="left"
            transition
            disablePortal
            sx={{ paddingRight: 2 }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom-start' ? 'left top' : 'left bottom'
                }}
              >
                <Paper
                  style={{
                    borderRadius: 6,
                    paddingLeft: theme.spacing(1),
                    paddingRight: theme.spacing(0),
                    backgroundColor: '#FFFFFF',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)'
                  }}
                >
                  <MenuList
                    autoFocusItem={openSearch}
                    id="menu-open"
                    aria-labelledby="menu-open"
                  >
                    <StyledSearch
                      value={filterName}
                      onChange={onFilterName}
                      placeholder="Buscar..."
                      startAdornment={
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      }
                    />
                  </MenuList>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </Stack>
    </StyledRoot>
  )
}
