import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  commonScroll: {
    overflowY: (props) => (props.scrollY ? 'auto' : 'hidden'),
    overflowX: (props) => (props.scrollX ? 'auto' : 'hidden'),
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: '10px'
    }
  },
  insideMaxHeight: {
    maxHeight: '35vh',
    height: '35vh',
    paddingBottom: '20px',
    width: '100%'
  }
}))
