import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  chip: {
    padding: theme.spacing(1),
    borderRadius: 20,
    backgroundColor: '#F7F7FC',
    '&:hover': {
      backgroundColor: '#CFD2D3'
    },
    cursor: 'pointer'
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 10,
    color: '#787878',
    marginLeft: 8,
    marginRight: 8
  },
  close: {
    padding: 0,
    width: 16,
    height: 16,
    backgroundColor: '#787878',
    color: '#CFD2D3',
    '&:hover': {
      backgroundColor: '#787878',
      color: '#FFFFFF'
    }
  },
  iconClose: {
    fontSize: 12
  }
}))
