import React, { useState } from 'react'
import makeStyles from './style'
import moment from 'moment'
import { BadgeTooltip } from 'src/components'
import { Box, IconButton, Typography } from '@material-ui/core'
import ModalAllComment from './modalAllComment'

export function CommentOnCelebrations({ data, dataUsers }) {
  const classes = makeStyles()

  const [isOpenModalAllComment, setIsOpenModalAllComment] = useState(false)
  function onHandleEditAllComment() {
    setIsOpenModalAllComment(!isOpenModalAllComment)
  }

  const comments = data.map((itemData) => {
    const dataUser = dataUsers.find(
      (itemUsers) => itemUsers.id === itemData.userId
    )
    const mergeDataUser = {
      ...itemData,
      imageProfile: dataUser?.imageProfile,
      name: dataUser?.name,
      occupationAreaName: dataUser?.occupationAreaName,
      occupationArea: dataUser?.occupationArea,
      email: dataUser?.email
    }
    return mergeDataUser
  })

  return (
    <>
      <ModalAllComment
        isOpenModal={isOpenModalAllComment}
        onCloseModal={() => setIsOpenModalAllComment(false)}
        data={comments}
      />
      <Box className={classes.Box}>
        <IconButton
          className={classes.iconButtonAllComment}
          onClick={onHandleEditAllComment}
        >
          <Typography style={{ fontSize: 14 }}>Ver mais comentários</Typography>
        </IconButton>
        {comments.map((data) => (
          <React.Fragment key={data.id}>
            <Box className={classes.boxContent}>
              <BadgeTooltip
                placement="top"
                disableFocusListener
                disableTouchListener
                user={data}
              />
              <Box className={classes.boxContentBadgeOne} />
              <Box className={classes.boxContentBadgeTwo} />
              <Box className={classes.boxContentText}>
                <Typography className={classes.name}>{data.name}</Typography>
                <Typography className={classes.description}>
                  {data.description}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.labelData}>
              Em: {moment(data.createdAt).format('L')}
            </Box>
          </React.Fragment>
        ))}
      </Box>
    </>
  )
}
