import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  dotBase: {
    width: 6,
    height: 6,
    backgroundColor: theme.palette.text.hint,
    borderRadius: '50%',
    transition: theme.transitions.create('background-color')
  },
  dotMedium: {
    width: 8,
    height: 8
  },
  dotLarge: {
    width: 14,
    height: 14
  }
}))
