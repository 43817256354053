import React, { useCallback, useEffect, useState } from 'react'
import { Helmet, HeaderBreadcrumbs, CalendarPeriodHome } from 'src/components'
import { Box } from '@material-ui/core'
import { HouseOutlined as HouseOutlinedIcon } from '@material-ui/icons'
import {
  getMetricsCustomersUsers,
  getMetricsUserGroups
} from 'src/services/hooks/metrics/useMetrics'
import { useLoadingContext } from 'src/context/LoadingContext'
import { useNavigate } from 'react-router-dom'
import { useAlertContext } from 'src/context/AlertContext'
import { useCurrentPeriodContext } from 'src/context/CurrentPeriodContext'
import { messageResponse } from 'src/utils/messageResponse'
import useGlobalUseStyles from 'src/themes/globalUseStyles'
import useAuth from 'src/hooks/useAuth'
import makeStyles from './style'
import { CardUser } from './Partials/CardUser'
import { CardGroup } from './Partials/CardGroup'
import { getPayloadPeriod } from 'src/utils/getPayloadPeriod'
import { useTheme } from '@material-ui/styles'

export default function Home() {
  const theme = useTheme()
  const globalClasses = useGlobalUseStyles()
  const navigate = useNavigate()
  const classes = makeStyles()

  const { user } = useAuth()
  const currentUserId = user?.id
  const currentCustomerId = user?.customerId
  const currentRole = user?.occupation
  const [role] = useState([1, 2, 3])

  const { currentPeriod } = useCurrentPeriodContext()
  const { onCloseLoading, onOpenLoading } = useLoadingContext()
  const { onCallAlert } = useAlertContext()

  const [dataMetricsUsers, setDataMetricsUsers] = useState([])
  const [dataMetricsGroupUsers, setDataMetricsGroupUsers] = useState([])

  const getListMetricsUsersAndGroups = useCallback(async (currentRole) => {
    try {
      onOpenLoading()
      const formatedDatePayload = getPayloadPeriod(currentPeriod)
      const data = await getMetricsCustomersUsers({
        period: formatedDatePayload
      })
      const getOnlyFavorites =
        data.filter((element) => element?.isFavorite) ?? []
      setDataMetricsUsers(getOnlyFavorites)

      if (role.includes(currentRole)) {
        const groupUsers = await getMetricsUserGroups({
          period: formatedDatePayload
        })
        const getOnlyGroupUsersFavorites =
          groupUsers.filter((element) => element?.isFavorite) ?? []
        setDataMetricsGroupUsers(getOnlyGroupUsersFavorites)
      }
      if(dataMetricsGroupUsers?.length === 0 && getOnlyFavorites?.length === 0) {
          onCallAlert({
            type: 'success',
            message: 'Sem favoritos para exibir'
          })
          navigate(`/metrics`)
          return;
        }
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      onCloseLoading()
    }
  }, [])

  useEffect(() => {
    if (currentUserId && currentCustomerId && currentRole) {
      getListMetricsUsersAndGroups(currentRole)
    }
  }, [
    currentCustomerId,
    currentUserId,
    currentRole,
    getListMetricsUsersAndGroups
  ])

  function onRemoveFavorite({ type, id }) {
    if (type === 'user') {
      const newDataFavorite = dataMetricsUsers.filter((elem) => elem.id !== id)
      setDataMetricsUsers(newDataFavorite)
      return 0
    }
    if (type === 'group') {
      const newDataGroupFavorite = dataMetricsGroupUsers.filter(
        (elem) => elem.id !== id
      )
      setDataMetricsGroupUsers(newDataGroupFavorite)
    }
    return 0
  }

  return (
    <>
      <Helmet title="Home" />
      <Box className={globalClasses.breadcrumb}>
        <HeaderBreadcrumbs
          icon={<HouseOutlinedIcon />}
          links={[{ name: 'Home' }]}
        />
        <CalendarPeriodHome
          onChangeDataUserMetrics={setDataMetricsUsers}
          onChangeDataGroupUsersMetrics={setDataMetricsGroupUsers}
          shouldShowGroups={role.includes(currentRole)}
        />
      </Box>
      <Box className={classes.container}>
        <Box className={classes.boxContent}>
          {(dataMetricsUsers ?? []).map((data) => (
            <div className={classes.boxContentAll} key={data?.id}>
              <CardUser
                classes={classes}
                primaryColor={theme.palette.primary.main}
                data={data}
                currentUserId={currentUserId}
                onActionRemove={onRemoveFavorite}
              />
            </div>
          ))}
          {(dataMetricsGroupUsers ?? []).map((data) => (
            <div className={classes.boxContentAll} key={data?.id}>
              <CardGroup
                classes={classes}
                primaryColor={theme.palette.primary.main}
                data={data}
                currentUserId={currentUserId}
                onActionRemove={onRemoveFavorite}
              />
            </div>
          ))}
        </Box>
      </Box>
    </>
  )
}
