import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  content: {
    backgroundColor: '#F7F7FC',
    width: '100%',
    borderRadius: 10,
    padding: 15
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 15,
    marginTop: 10
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 600,
    color: '#000000',
    fontSize: 16,
    paddingBottom: 8,
    borderBottom: '1px solid #CFD2D3'
  },
  description: {
    display: 'flex',
    color: '#434343',
    fontSize: 12,
    margin: '10px 0'
  },
  containerInformationFirst: {
    width: 150
  },
  containerInformationSecond: {
    width: 250
  },
  information: {
    color: '#434343',
    fontSize: 12
  },

  titleCompetence: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 600,
    color: '#000000',
    fontSize: 16
  },
  descriptionCompetence: {
    display: 'flex',
    color: '#434343',
    fontSize: 14
  }
}))
