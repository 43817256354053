import { makeStyles, withStyles, Checkbox } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    background: '#fff'
  },
  boxContainerOfCollapse: {
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    width: '100%',
    borderRadius: '10px',
    borderBottom: '#fff 5px solid'
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 30
  },
  headerRightSide: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 10
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
    letterSpacing: theme.typography.pxToRem(0.15),
    color: '#000000'
  },
  content: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
    gap: 20
  },
  closeButton: {
    cursor: 'pointer'
  },
  footer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 30
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15
  },
  closeIcon: {
    width: '24px important!',
    height: '24px important!',
    color: 'white',
    borderRadius: '30px',
    background: '#787878'
  },
  buttonAddMetaToAction: {
    border: 'none',
    width: '100%',
    height: 40,
    background: '#F7F7FC',
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '25px 10px',
    gap: 10
  },
  containerOfCollapse: {
    width: '100%',
    maxHeight: '42vh',
    display: 'flex',
    flexDirection: 'column',
    background: '#CFD2D3',
    borderRadius: '10px 10px 0px 0px',
    overflowX: 'hidden',
    overflowY: 'scroll',
    justifyContent: 'space-between',
    '&::-webkit-scrollbar': {
      width: 4
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: 10
    }
  },
  containerOfGrouper: {
    width: '100%',
    height: 46,
    display: 'flex',
    paddingLeft: 30,
    border: 'none',
    background: '#F7F7FC',
    borderTop: '1px solid #CFD2D3'
  },
  containerOfCompetence: {
    width: '100%',
    height: 46,
    display: 'flex',
    paddingLeft: 55,
    border: 'none',
    background: '#F7F7FC',
    borderTop: '1px solid #CFD2D3'
  },
  btnExpand: {
    width: '100%',
    height: 40,
    display: 'flex',
    border: 'none'
  },
  footerCollapse: {
    width: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 50,
    marginTop: ' -4px',
    padding: '13px 20px 10px 10px ',
    borderRadius: '0px 0px 10px 10px'
  },
  accordionTitlesCategory: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
    display: 'flex',
    alignItems: 'center',
    letterSpacing: theme.typography.pxToRem(0.15),
    color: '#434343',
    height: '100%',
    margin: 0,
    paddingLeft: 10
  },
  accordionTitlesGrouper: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
    display: 'flex',
    alignItems: 'center',
    letterSpacing: theme.typography.pxToRem(0.15),
    color: '#434343',
    height: '100%',
    margin: 0,
    paddingLeft: 10
  },
  competenceTitles: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
    display: 'flex',
    alignItems: 'center',
    letterSpacing: theme.typography.pxToRem(0.15),
    color: '#434343',
    height: '100%',
    margin: 0
  },
  selectedCompetences: {
    width: '100%',
    minHeight: 40,
    flexWrap: 'wrap',
    borderRadius: '20px',
    padding: '10px 20px',
    marginTop: 20,
    gap: 5,
    transition: '0.5s',
    background: '#F7F7FC',
    border: '1px solid',
    borderColor: theme.palette.primary.main
  }
}))

export const MuiCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    '&$checked': {
      color: theme.palette.primary.main
    }
  }
}))(Checkbox)
