import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  boxTitleListModalUpdateInfor: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-around'
  },
  titleListModalUpdateInfor: {
    display: 'flex',
    color: '#000',
    width: '180px',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 500
  },
  boxListModalUpdateInfor: {
    display: 'flex',
    margin: '0px',
    width: '100%',
    borderTop: `solid 1px ${theme.palette.primary.main}`,
    borderBottom: `solid 1px ${theme.palette.primary.main}`,
    padding: '0px',
    overflow: 'hidden',
    flexDirection: 'column'
  },
  listModalUpdateInfor: {
    display: 'flex',
    flex: '1',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'row',
    listStyle: 'none',
    borderBottom: '1px #dfdfdf  solid',
    backgroundColor: '#fff'
  },
  textlistModalUpdateInfor: {
    display: 'flex',
    width: '235px',
    marginLeft: '52px',
    justifyContent: 'space-between',
    padding: '17px 0px'
  },
  nameListModalUpdateInfor: {
    display: 'flex',
    textAlign: 'start',
    margin: '0px',
    fontSize: theme.typography.pxToRem(14)
  },
  inforListModalUpdateInfor: {
    display: 'flex',
    textAlign: 'start',
    margin: '2px 0px 0px 0px',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500
  },
  iconeArrowlistModalUpdateInfor: {
    fontSize: '20px !important',
    margin: '0px -12px 0px -12px',
    color: theme.palette.primary.main
  },
  boxModalConfirmChange: {
    backgroundColor: '#ffffff',
    width: '600px',
    margin: '0px -20px'
  },
  titleModal: {
    margin: 0,
    '& span': {
      color: theme.palette.primary.main
    }
  }
}))
