import React from 'react'
import { Avatar as AvatarBase } from '@material-ui/core'
import useStyles from './styles'

export function Avatar({
  children,
  color,
  colorBrightness,
  borderColor,
  hoverColor,
  optionRemove,
  ...props
}) {
  var classes = useStyles({
    color,
    colorBrightness,
    borderColor,
    hoverColor,
    optionRemove
  })

  return (
    <div className={borderColor && classes.container}>
      <AvatarBase
        classes={{
          root: !optionRemove ? classes.avatar : classes.avatarRemove
        }}
        {...props}
      >
        {children}
      </AvatarBase>
    </div>
  )
}
