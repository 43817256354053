import React, { useEffect, useState } from 'react'
import RoleBasedGuard from 'src/guards/RoleBasedGuard'
import 'moment/locale/pt-br'
import makeStyles from './style'
import useGlobalUseStyles from 'src/themes/globalUseStyles'
import { useNavigate, useParams } from 'react-router-dom'
import { Typography, Box, Grid } from '@material-ui/core'
import { Form } from 'react-final-form'
import {
  Autocomplete,
  Input,
  InputMultiline,
  Switch
} from 'src/components/Fields'
import { newGrouperValidation } from 'src/utils/form/validations'
import { enumCategoryType } from 'src/utils/constants'
import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined'
import {
  Button,
  NewBoxWidget,
  WordCloud,
  HeaderBreadcrumbs,
  InputCompetences
} from 'src/components'
import { useLoadingContext } from 'src/context/LoadingContext'
import { messageResponse } from 'src/utils/messageResponse'
import { useAlertContext } from 'src/context/AlertContext'
import {
  getCompetenceGrouper,
  putCompetenceGrouper,
  getUserGroups
} from 'src/services/hooks/competenceGrouper/useCompetenceGrouper'
import { postCompetenceSearch } from 'src/services/hooks/competence/useCompetence'
import useAuth from 'src/hooks/useAuth'

function EditGroupers() {
  const { id: groupersId } = useParams()
  const navigate = useNavigate()
  const { onCloseLoading, onOpenLoading } = useLoadingContext()
  const { user } = useAuth()
  const globalClasses = useGlobalUseStyles()
  const customerId = user?.customerId

  const [role] = useState([1, 2])

  const [dataGrouper, setDataGrouper] = useState([])

  const { notifications, onCallAlert } = useAlertContext()
  const [auxData, setAuxData] = useState({
    dataCompetence: [],
    dataUserGroups: []
  })
  const [isOpenModalCompetences, setIsOpenModalCompetences] = useState(false)

  const classes = makeStyles()

  async function onHandleSubmit(values) {
    try {
      const payload = {
        id: values?.id,
        name: values?.name,
        category: values?.category,
        description: values?.description,
        competences: values?.competences ?? [],
        userGroups: values?.userGroups.map((item) => item.value),
        isActive: values?.isActive,
        customerId: customerId
      }
      if (payload?.competences.length === 0) {
        onCallAlert({
          type: 'error',
          message: 'Adicione ao menos 1 competência ao grupo'
        })
      }
      onOpenLoading()
      await putCompetenceGrouper(payload)
      onCallAlert({
        type: 'success',
        message: notifications.groupers.success.editGroup
      })
      navigate('/competence-grouper')
    } catch (error) {
      const messageError = messageResponse(error?.response)
      onCallAlert({
        type: 'error',
        message: messageError ?? notifications.global.error
      })
    } finally {
      onCloseLoading()
    }
  }

  function getFormInitialUserGroupers({ userGroups, groupers }) {
    const response = (groupers ?? []).map((item) => ({
      value: item,
      title: (userGroups ?? []).filter(
        (filterItem) => filterItem?.id === item
      )[0]?.name
    }))
    const result = response.filter((item) => item?.title)
    return result ?? []
  }

  function onHandleOpenCompetences() {
    setIsOpenModalCompetences(!isOpenModalCompetences)
  }

  useEffect(() => {
    async function getDataGroupers() {
      try {
        onOpenLoading()
        const responseDataGroupers = await getCompetenceGrouper({
          id: groupersId
        })
        const responseDataCompetence = await postCompetenceSearch({
          customerId: customerId ?? ''
        })
        const responseDataUserGroups = await getUserGroups()

        setAuxData({
          dataCompetence: responseDataCompetence ?? [],
          dataUserGroups: responseDataUserGroups?.data?.data
        })
        setDataGrouper({
          ...responseDataGroupers?.data?.data,
          userGroups: getFormInitialUserGroupers({
            userGroups: responseDataUserGroups?.data?.data,
            groupers: responseDataGroupers?.data?.data?.userGroups
          })
        })
      } catch (error) {
        const messageError = messageResponse(error?.response)
        onCallAlert({
          type: 'error',
          message: messageError ?? notifications.global.error
        })
      } finally {
        onCloseLoading()
      }
    }
    getDataGroupers()
  }, [groupersId])

  function onRemoveCompetencyFromWordCloud({ value, form, valuesForm }) {
    form.change(
      'competences',
      valuesForm.filter((e) => e !== value)
    )
  }

  function onRemoveGroupFromWordCloud({ value, form, valuesForm }) {
    form.change(
      'userGroups',
      valuesForm.filter((e) => e.value !== value)
    )
  }

  return (
    <RoleBasedGuard hasContent roles={role}>
      <Box className={globalClasses.breadcrumb}>
        <HeaderBreadcrumbs
          icon={<DesignServicesOutlinedIcon />}
          links={[
            {
              name: 'Agrupadores de competência',
              href: '/competence-grouper'
            },
            { name: 'Editar agrupador' }
          ]}
        />
      </Box>
      <Box className={globalClasses.content}>
        <Form
          onSubmit={onHandleSubmit}
          validate={newGrouperValidation}
          initialValues={{
            id: dataGrouper?.id,
            name: dataGrouper?.name,
            category: dataGrouper?.category,
            description: dataGrouper?.description,
            competences: dataGrouper?.competences ?? [],
            userGroups: dataGrouper?.userGroups ?? [],
            isActive: dataGrouper?.isActive
          }}
          render={({ handleSubmit, form, values, valid }) => (
            <NewBoxWidget
              title="Editar agrupador"
              subtitle="Insira as especificações do agrupador de competências:"
              options={
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="inherit"
                    className={classes.textStatusGroup}
                  >
                    Status do agrupador:
                  </Typography>
                  <Switch name="isActive" />
                </Box>
              }
              actions={
                <div className={classes.formFooter}>
                  <Typography className={classes.formInfo}>
                    *Campos obrigatórios
                  </Typography>
                  <div className={classes.formControlButton}>
                    <Button
                      label="Cancelar"
                      type="button"
                      variant="underline"
                      onClick={() => navigate('/competence-grouper')}
                    />
                    <Button
                      label="Salvar"
                      onClick={handleSubmit}
                      type="submit"
                      disabled={!valid}
                    />
                  </div>
                </div>
              }
              fullBody
            >
              <form onSubmit={handleSubmit} className={globalClasses.formFull}>
                <Grid
                  container
                  item
                  style={{
                    alignItems: 'flex-start',
                    direction: 'column'
                  }}
                  spacing={3}
                >
                  <Grid
                    container
                    item
                    style={{
                      flexDirection: 'column',
                      gap: '30px'
                    }}
                    spacing={12}
                    md={6}
                  >
                    <Grid item md={12} xs={12}>
                      <Input
                        label="Nome de Agrupador*"
                        name="name"
                        maxLength={30}
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <div className={classes.wordCloud}>
                        <InputCompetences
                          isOpen={isOpenModalCompetences}
                          onHandleModal={onHandleOpenCompetences}
                          auxData={auxData}
                          values={values}
                          customerId={customerId}
                          setAuxData={setAuxData}
                          onChangeValue={(e) => form.change('competences', e)}
                        />
                        {values.competences && values.competences.length > 0 ? (
                          <WordCloud
                            words={auxData.dataCompetence
                              .filter(
                                (item) =>
                                  values.competences.includes(item.id) &&
                                  item?.isActive
                              )
                              .map((elem) => ({
                                value: elem.id,
                                title: elem.name
                              }))}
                            onRemove={(value) =>
                              onRemoveCompetencyFromWordCloud({
                                value,
                                form,
                                valuesForm: values?.competences
                              })
                            }
                          />
                        ) : null}
                      </div>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <div className={classes.wordCloud}>
                        <Autocomplete
                          multiple
                          name="userGroups"
                          options={
                            auxData.dataUserGroups.map((item) => ({
                              title: item.name,
                              value: item.id
                            })) ?? []
                          }
                          label="Aplicar a grupo*"
                          getOptionLabel={(option) => option.title || ''}
                          getOptionSelected={(option, value) =>
                            option.value === value.value
                          }
                          contentOut
                        />
                        {values.userGroups && values.userGroups.length > 0 ? (
                          <WordCloud
                            words={values.userGroups}
                            onRemove={(value) =>
                              onRemoveGroupFromWordCloud({
                                value,
                                form,
                                valuesForm: values?.userGroups
                              })
                            }
                          />
                        ) : null}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    style={{
                      flexDirection: 'column',
                      gap: '30px'
                    }}
                    spacing={12}
                    md={6}
                  >
                    <Grid item md={12} xs={12}>
                      <Autocomplete
                        options={enumCategoryType}
                        label="Categoria*"
                        name="category"
                        getOptionLabel={(option) => option.title || ''}
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <InputMultiline
                        label="Descrição do agrupador*"
                        name="description"
                        placeholder="Digite a descritivo"
                        rows={5}
                        multiline={'multiline'}
                        onChange={(e) =>
                          form.change(
                            'description',
                            String(e?.target?.value ?? '').slice(0, 350)
                          )
                        }
                      />
                      <p className={classes.wordCounter}>
                        {values.description ? values.description.length : 0}/350
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </NewBoxWidget>
          )}
        />
      </Box>
    </RoleBasedGuard>
  )
}

export default EditGroupers
