import React, { useState, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTheme, styled } from '@material-ui/styles'
import {
  Box,
  Table,
  TableRow,
  TableBody,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { Button, NewBoxWidget } from 'src/components'
import { getUserGroupById } from 'src/services/hooks/userGroup/useUserGroup'
import { getOccupationArea } from 'src/services/hooks/occupationArea/useOccupationArea'
import { enumAssignment } from 'src/@enum/user'
import {
  TableHead,
  TableToolbar,
  TableLoading,
  TableNotFound
} from 'src/components'
import { getComparator, applySortFilter } from 'src/utils'
import { maskCPF } from 'src/utils/regex/masks'
import makeStyles from '../style'

const TABLE_HEAD = [
  { id: 'name', label: 'Nome', alignRight: false },
  { id: 'email', label: 'E-mail', alignRight: false },
  { id: 'document', label: 'CPF', alignRight: false },
  { id: 'occupation', label: 'Área de atuação', alignRight: false },
  { id: 'assignment', label: 'Experiência', alignRight: false }
]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottom: 'none',
    padding: '20px 20px 20px 16px'
  }
}))

export default function DataGroup({ groupSelected }) {
  const theme = useTheme()
  const classes = makeStyles()

  const navigate = useNavigate()

  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const [populateOptions, setPopulateOptions] = useState({
    occupationArea: []
  })

  const [page, setPage] = useState(0)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const [filterName, setFilterName] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setPage(0)
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  const handleFilterByName = (event) => {
    setPage(0)
    setFilterName(event.target.value)
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0

  const filteredData = applySortFilter(
    data,
    getComparator(order, orderBy),
    filterName
  )

  const isNotFound = !filteredData?.length && !!filterName && data

  const getDataSelectOptions = useCallback(async () => {
    try {
      const resultOccupations = await getOccupationArea()
      const occupations = resultOccupations.map((key) => ({
        value: key.id,
        title: key.name
      }))

      setPopulateOptions({
        occupationArea: occupations
      })
    } catch (e) {
      console.error(e)
    }
  }, [])

  const getUserGroup = useCallback(async (id) => {
    try {
      setIsLoading(true)
      const response = await getUserGroupById(id)
      setData(response)
      setIsLoading(false)
    } catch (e) {
      console.error(e)
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    getDataSelectOptions()
    getUserGroup(groupSelected?.id)
  }, [getDataSelectOptions, getUserGroup, groupSelected])

  function handleOpen(id) {
    navigate('/groups/edit/' + id)
  }

  return (
    <div className={classes.listDataGroupsContainer}>
      <NewBoxWidget
        title={groupSelected?.name || 'Grupo'}
        subtitle="Visualize e edite dados do grupo:"
        options={
          <TableToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
          />
        }
        actions={
          <Box className={classes.actions}>
            <Button
              variant="contained"
              label="Editar grupo"
              disabled={!groupSelected?.id}
              onClick={() => handleOpen(groupSelected?.id)}
            />
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              labelRowsPerPage={'Registros por página'}
              component="div"
              count={data?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        }
        borderActions
        noBodyPadding
        height={500}
        width={'100%'}
      >
        <TableContainer
          sx={{
            maxHeight: '320px',
            overflow: 'initial',
            '&::-webkit-scrollbar': {
              height: '4px',
              width: '4px',
              borderRadius: '10px'
            },
            '&::-webkit-scrollbar-track': {
              background: '#CFD2D3',
              borderRadius: '10px'
            },
            '&::-webkit-scrollbar-thumb': {
              background: theme.palette.primary.main,
              borderRadius: '10px'
            }
          }}
        >
          <Table stickyHeader>
            <TableHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={data?.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row) => {
                  const {
                    id,
                    name,
                    email,
                    document,
                    occupationAreaId,
                    assignment,
                    isLeader
                  } = row
                  return (
                    <TableRow hover key={id} tabIndex={-1} role="checkbox">
                      <StyledTableCell align="left">
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          {name}
                          {isLeader && (
                            <span className={classes.flagAdmin}>Líder</span>
                          )}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="left">{email}</StyledTableCell>
                      <StyledTableCell align="left">
                        {maskCPF(document) ?? 'Não informado'}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {
                          populateOptions.occupationArea.find(
                            (items) => items.value === occupationAreaId
                          )?.title
                        }
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {enumAssignment[assignment]}
                      </StyledTableCell>
                    </TableRow>
                  )
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell
                    colSpan={12}
                    style={{ height: 53 * emptyRows, border: 'none' }}
                  />
                </TableRow>
              )}
            </TableBody>
            {isLoading && (
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={12}
                    sx={{
                      border: 'none',
                      height: 'calc(50vh - 220px)'
                    }}
                  >
                    <TableLoading />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            {data.length === 0 && !isLoading && (
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={12}
                    sx={{ border: 'none' }}
                  >
                    <TableNotFound />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            {isNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={12}
                    sx={{ py: 10, border: 'none' }}
                  >
                    <Typography variant="h6" paragraph>
                      Nenhum registro encontrado!
                    </Typography>

                    <Typography variant="body2">
                      Nenhum resultado encontrado para &nbsp;
                      <strong>&quot;{filterName}&quot;</strong>.
                      <br /> Tente verificar erros de digitação.
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </NewBoxWidget>
    </div>
  )
}
