import React, { useState } from 'react'
import { useTheme } from '@material-ui/styles'
import { Tooltip } from 'src/components'
import { IconButton } from '@material-ui/core'
import { messageResponse } from 'src/utils/messageResponse'
import { patchLikeCelebration } from 'src/services/hooks/celebrations/useCelebrations'
import { useAlertContext } from 'src/context/AlertContext'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import makeStyles from '../style'

export function RenderLikeSection({
  userId,
  isCurrentUserLike,
  itemCelebration,
  dataCelebration,
  onUpdateDataCelebration
}) {
  const { onCallAlert } = useAlertContext()
  const [isLoading, setIsLoading] = useState(false)
  const theme = useTheme()
  const classes = makeStyles()

  async function onHandlerLike({ celebrationId, liked }) {
    try {
      setIsLoading(true)
      const result = await patchLikeCelebration({
        celebrationId,
        userId,
        liked: !liked
      })
      const idCelebration = result?.data?.data?.id
      const likesCelebration = result?.data?.data?.likes
      const updatedDataCelebration = dataCelebration.map((itemData) =>
        itemData.id !== idCelebration
          ? itemData
          : {
              ...itemData,
              likes: likesCelebration
            }
      )
      onUpdateDataCelebration(updatedDataCelebration)
    } catch (error) {
      onCallAlert({
        type: 'error',
        message: messageResponse(error?.response)
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <Tooltip
        placement="top"
        title={isCurrentUserLike ? 'Remover curtida' : 'Curtir'}
      >
        <section>
          <IconButton
            className={classes.iconButtonClose}
            disabled={isLoading}
            key={0}
            onClick={() =>
              onHandlerLike({
                celebrationId: itemCelebration.id,
                liked: isCurrentUserLike
              })
            }
            style={{
              border: 'none'
            }}
          >
            <ThumbUpAltIcon
              htmlColor={
                isCurrentUserLike ? theme.palette.primary.main : 'gray'
              }
              sx={{ fontSize: 20 }}
            />
            <p
              style={{
                color: isCurrentUserLike ? theme.palette.primary.main : 'gray',
                fontSize: 14
              }}
            >
              {isLoading ? 'Curtir...' : 'Curtir'}
            </p>
          </IconButton>
        </section>
      </Tooltip>
    </div>
  )
}
