import React, { useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import {
  AccordionMeasurementDetails,
  AccordionMeasurement
} from 'src/components'

function CompetenceGroups({ data, type, categoryName }) {
  const [dataCompetenceGroups, setDataCompetenceGroups] = useState([])

  useEffect(() => {
    setDataCompetenceGroups(data)
  }, [data])

  return (
    <>
      {dataCompetenceGroups?.map(
        ({ competenceGroupName, competences }, index) => {
          const scoreAverage = (competences ?? []).map((item) => {
            return {
              competenceGrouperName: item?.competenceName,
              average: item?.averageScore
            }
          })
          return type === 'userTechnicalMetrics' ? (
            <Box mb={2}>
              <AccordionMeasurement
                key={index}
                type="user"
                competenceGroupName={competenceGroupName}
                scoreAverage={scoreAverage}
                categoryName={categoryName}
                allExpanded
              />
            </Box>
          ) : (
            <Box mb={2}>
              <AccordionMeasurementDetails
                key={index}
                competenceGroupName={competenceGroupName}
                competences={scoreAverage}
                categoryName={categoryName}
                allExpanded
              />
            </Box>
          )
        }
      )}
    </>
  )
}

export default CompetenceGroups
