import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    width: '416px',
    height: 'auto',
    background: '#fff',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
    padding: '20px',
    marginLeft: '20px'
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20
  },
  btnClose: {
    marginRight: -10,
    marginTop: -10
  },
  formControlButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 15,
    height: 60,
    marginTop: 15
  },
  containerOptions: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  containerForm: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  fieldsForm: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    rowGap: 30,
    columnGap: 34,
    marginTop: 30
  },
  fieldsFormDate: {
    display: 'flex',
    overflow: 'hidden',
    rowGap: 30,
    columnGap: 15,
    marginTop: 20,
    height: 70,
    paddingTop: 10
  },
  boxSearch: {
    flexWrap: 'wrap',
    rowGap: 30,
    columnGap: 34,
    marginTop: 10
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  wordCounter: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
    letterSpacing: theme.typography.pxToRem(0.15),
    marginTop: '10px',
    textAlign: 'left'
  }
}))
