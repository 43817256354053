import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/styles'
import { Field } from 'react-final-form'
import { DefaultStyledInput } from './style'

export function Input({
  name,
  label,
  placeholder,
  parse,
  rows,
  multiline,
  ...props
}) {
  const theme = useTheme()

  return (
    <Field
      parse={parse}
      name={name ?? 'name'}
      render={({ input, meta }) => (
        <DefaultStyledInput
          primaryColor={theme.palette.primary.main}
          {...props}
          {...input}
          inputProps={props}
          label={label}
          placeholder={placeholder}
          variant="outlined"
          fullWidth
          error={meta.touched && !!meta.error}
        />
      )}
    />
  )
}

Input.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  parse: PropTypes.func
}
