import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 30px 30px 30px',
    overflowX: 'hidden'
  },
  boxContent: {
    width: '100%',
    gap: '25px',
    height: 'calc(100vh - 165px)',
    scrollbarColor: `${theme.palette.primary.main} #CFD2D3`,
    display: 'flex',
    padding: '0 0 30px 10px',
    overflowX: 'scroll',
    overflowY: 'hidden',
    alignItems: 'start',
    marginRight: '10px',
    justifyContent: 'stretch',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignContent: 'flex-start',
    '&::-webkit-scrollbar': {
      height: '10px',
      background: '#CFD2D3',
      boxShadow: 'inset 0px 4px 4px rgb(0 0 0 / 25%)',
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: 10
    }
  },
  boxContentBtn: {
    padding: 0,
    width: 40,
    height: 40,
    color: theme.palette.primary.main,
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 0px 6px -3px #3e3e3e',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFFFFF'
    }
  },
  headerInformations: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',

    marginBottom: 10
  },
  btnClose: {
    marginRight: -10,
    marginTop: -10
  },
  boxFormTime: {
    display: 'flex',
    gap: 10
  },
  boxForm: {
    display: 'flex',
    gap: 30,
    flexDirection: 'column',
    padding: '15px 0px 0px 0px'
  },
  formButtons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center'
  },
  labelRequired: {
    color: '#6E7191',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(15)
  },
  breadcrumb: {
    padding: '0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  boxBtnNavigation: {
    gap: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  boxInforUser: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    backgroundColor: '#F7F7FC',
    padding: 10,
    borderRadius: 10,
    marginBottom: 10,
    marginTop: 10,
    fontSize: 14,
    '& span': {
      marginLeft: 15
    },
    '& strong': {
      margin: 0
    },
    '& p': {
      margin: 0,
      marginTop: 3
    }
  },
  containerBtn: {
    borderRadius: 50,
    background: '#fff',
    border: '1px solid #fff',
    height: 47,
    width: 380,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: 'hidden',
    boxShadow: 'inset 0px 2px 7px 0px #b3b3b3',
    '& button': {
      background: 'transparent',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: 'none',
      borderRadius: 50,
      cursor: 'pointer',
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(15),
      lineHeight: theme.typography.pxToRem(18),
      letterSpacing: theme.typography.pxToRem(0.74976),
      color: '#787878'
    }
  }
}))
