import { makeStyles, withStyles, Checkbox } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    width: '100%',
    background: '#fff',
    borderRadius: '10px',
    padding: '8px',
    borderBottom: '#fff 5px solid'
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 30
  },
  headerRightSide: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 10
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(21),
    letterSpacing: '0.74976px',
    color: '#000000'
  },
  description: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(19),
    letterSpacing: theme.typography.pxToRem(0.15),
    color: '#000000'
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 20
  },
  boxEvolutionStatus: {
    width: '100%',
    height: '100%',
    border: 'solid 1.5px',
    borderColor: '#F7F7FC',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: 10
  },
  headerBoxEvolutionStatus: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: 5,
    background: '#F7F7FC',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
    padding: theme.typography.pxToRem(20),
    width: '102%',
    marginLeft: '-1%',
    marginTop: '-1%'
  },
  titleBoxEvolutionStatus: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 15,
    lineHeight: theme.typography.pxToRem(18),
    letterSpacing: theme.typography.pxToRem(0.74976),
    color: '#000000'
  },
  descriptionBoxEvolutionStatus: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 15,
    lineHeight: theme.typography.pxToRem(19),
    letterSpacing: theme.typography.pxToRem(0.15),
    color: '#000000'
  },
  statusOfEvolution: {
    background: (props) =>
      props?.statusColor === 4
        ? '#787878'
        : props?.statusColor === 6
        ? '#E32929'
        : theme.palette.primary.main,
    borderRadius: 10
  },
  statusOfEvolutionText: {
    marginTop: 10,
    marginBottom: 10,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(19),
    letterSpacing: theme.typography.pxToRem(0.15),
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    color: 'white'
  },
  footerStatusOfEvolutionTitle: {
    fontWeight: 600,
    fontSize: '14px'
  },
  footerStatusOfEvolution: {
    width: '102%',
    marginLeft: '-1%',
    marginBottom: '-1%',
    display: 'flex',
    justifyContent: 'flex-start',
    gap: 40,
    fontSize: '14px',
    background: '#F7F7FC',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
    padding: theme.typography.pxToRem(20)
  },
  optionsList: {
    width: '100%',
    maxHeight: '20px',
    paddingLeft: theme.typography.pxToRem(20),
    paddingRight: theme.typography.pxToRem(20),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  option: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    cursor: 'pointer',
    border: 'none',
    background: 'none'
  },
  titleOptionsList: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(15),
    lineHeight: theme.typography.pxToRem(18),
    letterSpacing: theme.typography.pxToRem(0.74976),
    color: '#000000',
    width: '100%',
    paddingLeft: theme.typography.pxToRem(20),
    paddingRight: theme.typography.pxToRem(20),
    marginTop: 10,
    marginBottom: 10,
    '& p': {
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(15)
    }
  },
  optionsCheckList: {
    paddingLeft: theme.typography.pxToRem(20),
    opacity: (props) => (props?.disabledSave ? 0.7 : 1)
  },
  descriptionCheckList: {
    marginLeft: -10
  },
  optionList: {
    padding: '10px 0px'
  }
}))

export const MuiCheckbox = withStyles((theme) => ({
  root: {
    color: (props) =>
      props?.statusColor === 4
        ? '#787878'
        : props?.statusColor === 6
        ? '#E32929'
        : theme.palette.primary.main,
    '&$checked': {
      color: (props) =>
        props?.statusColor === 4
          ? '#787878'
          : props?.statusColor === 6
          ? '#E32929'
          : theme.palette.primary.main
    }
  }
}))(Checkbox)
