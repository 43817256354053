import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  iconButton: {
    padding: 0,
    width: 40,
    height: 40,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFFFFF'
    }
  }
}))
