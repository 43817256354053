import React, { useState } from 'react'
import { Button, Modal, PasswordRule } from 'src/components'
import { Form } from 'react-final-form'
import { Input } from 'src/components/Fields'
import { InputAdornment, IconButton } from '@material-ui/core'
import { changePassword } from 'src/utils/form/validations'
import { useLoadingContext } from 'src/context/LoadingContext'
import { useAlertContext } from 'src/context/AlertContext'
import PropTypes from 'prop-types'
import makeStyles from 'src/screens/Users/EditUser/style'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import useGlobalUseStyles from 'src/themes/globalUseStyles'
import { postChangePassword } from 'src/services/hooks/users/useUsers'
import { messageResponse } from 'src/utils/messageResponse'

function ChangePassword({ isOpenModal, onCloseModal, email }) {
  const classes = makeStyles()

  const globalClasses = useGlobalUseStyles()
  const { onCloseLoading, onOpenLoading } = useLoadingContext()
  const { notifications, onCallAlert } = useAlertContext()
  const [viewRulePassword, setViewRulePassword] = useState(false)
  const [viewPassword, setViewPassword] = useState({
    password: false,
    newPassword: false,
    passwordConfirmation: false
  })

  function handlePassword(key) {
    setViewPassword({
      ...viewPassword,
      [key]: !viewPassword[key]
    })
  }

  function renderIconViewPassword(key) {
    return viewPassword[key] ? <VisibilityIcon /> : <VisibilityOffIcon />
  }

  function renderTypeInput(key) {
    return viewPassword[key] ? 'text' : 'password'
  }

  const onHandleSubmit = async (values) => {
    try {
      onOpenLoading()
      await postChangePassword(values)
      onCallAlert({
        type: 'success',
        message: notifications.users.success.changePassword
      })
      onCloseModal()
    } catch (error) {
      const messageError = messageResponse(error?.response)
      onCallAlert({
        type: 'error',
        message: messageError ?? notifications.global.error
      })
    } finally {
      onCloseLoading()
    }
  }

  return (
    <Modal
      isOpen={isOpenModal}
      onHandleClose={onCloseModal}
      className={classes.modalContainer}
      title="Nova senha"
      subTitle="Defina a nova senha do usuário:"
    >
      <Form
        onSubmit={onHandleSubmit}
        initialValues={{
          email
        }}
        validate={changePassword}
        render={({ handleSubmit, valid, values }) => (
          <form onSubmit={handleSubmit}>
            <div className={globalClasses.fieldsForm}>
              <Input
                label="Senha atual*"
                name="password"
                placeholder="Informe sua senha atual"
                type={renderTypeInput('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => handlePassword('password')}>
                        {renderIconViewPassword('password')}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <div className={classes.inputContainer}>
                <PasswordRule
                  isVisible={viewRulePassword}
                  value={values?.newPassword}
                />
                <Input
                  label="Nova senha*"
                  name="newPassword"
                  placeholder="Informe uma nova senha"
                  type={renderTypeInput('newPassword')}
                  onFocus={() => setViewRulePassword(true)}
                  onBlur={() => setViewRulePassword(false)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => handlePassword('newPassword')}
                        >
                          {renderIconViewPassword('newPassword')}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              <Input
                label="Confirme a nova senha*"
                name="passwordConfirmation"
                placeholder="Repita a nova senha"
                type={renderTypeInput('passwordConfirmation')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handlePassword('passwordConfirmation')}
                      >
                        {renderIconViewPassword('passwordConfirmation')}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </div>
            <div className={classes.modalButtons}>
              <Button
                label="Cancelar"
                variant="underline"
                onClick={() => onCloseModal(false)}
              />
              <Button type="submit" label="Salvar" disabled={!valid} />
            </div>
          </form>
        )}
      />
    </Modal>
  )
}

ChangePassword.propTypes = {
  isOpenModal: PropTypes.bool,
  onCloseModal: PropTypes.func,
  email: PropTypes.string
}

export default ChangePassword
