import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  containerCelebrations: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '20px',
    height: '100%',
  },
  containerLoading: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
  }
}))
