import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    width: '416px',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    background: '#ffffff',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
    padding: '20px',
    marginLeft: '30px',
    marginRight: '10px'
  },
  containerScroll: {
    width: 'calc(100% + 32px)',
    maxHeight: 'calc(100vh - 350px)',
    background: '#fff',
    borderRadius: '10px',
    overflow: 'auto',
    padding: '0px 16px 0px 16px',
    marginLeft: -16,
    borderBottom: '#fff 5px solid',
    '&::-webkit-scrollbar': {
      width: 8
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: 10
    },
    '::-webkit-scrollbar-track': {
      background: 'red',
      borderRadius: 10
    }
  },
  headerInformations: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 0
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20
  },
  btnClose: {
    marginRight: -10,
    marginTop: -32
  },
  formControlButton: {
    display: 'flex',
    justifyContent: 'end',
    paddingTop: 15,
    height: 60
  },
  boxFormControlButton: {
    display: 'flex',
    flex: 1,
    justifyContent: 'end',
    alignItems: 'end'
  }
}))
