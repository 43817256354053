import { styled } from '@mui/material/styles'

export const StyledRoot = styled('main')(() => ({
  height: '100%',
  display: 'flex',
  position: 'relative'
}))

export const StyledSection = styled('div')(({ theme }) => ({
  display: 'none',
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  }
}))

export const StyledSectionBg = styled('img')(({ theme }) => ({
  width: '100%',
  objectFit: 'cover',
  margin: '0px',
  height: '100%'
}))

export const StyledContent = styled('div')(({ theme }) => ({
  width: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  justifyContent: 'center',
  padding: theme.spacing(15, 2),
  [theme.breakpoints.up('md')]: {
    flexShrink: 0,
    padding: theme.spacing(30, 8, 0, 8)
  }
}))

export const StyledNavbarLogo = styled('main')(() => ({
  position: 'relative'
}))
