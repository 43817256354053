import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  containerModal: {
    display: 'flex',
    justifyContent: 'center'
  },
  boxModal: {
    margin: 'auto',
    width: '416px',
    backgroundColor: '#FFFFFF',
    padding: '20px 0',
    borderRadius: '10px',
    position: 'relative'
  },
  boxTop: {
    display: 'flex',
    columnGap: '20px',
    justifyContent: 'space-between',
    padding: '0 20px',
  },
  topTitle: {
    color: '#222222',
    fontSize: theme.typography.pxToRem(17),
    lineHeight: theme.typography.pxToRem(21.09),
    letterSpacing: '0.74976px',
    fontWeight: '600'
  },
  topSubTitle: {
    marginTop: '10px',
    color: '#222222',
    fontWeight: '400',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
    letterSpacing: '0.15px'
  },
  sizeButton: {
    padding: 0,
    width: 40,
    height: 40,
  },
  iconButton: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: theme.palette.primary.main,
    }
  },
  expandButton: {
    background: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: theme.palette.primary.main,
    }
  },
  listFooter: {
    marginTop: '10px',
    padding: '0 20px',
    display: 'flex',
    justifyContent: 'flex-end'
  }
}))
