import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  contentHorizontal: {
    scrollbarColor: `${theme.palette.primary.main} #CFD2D3`,
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'calc(100vh - 165px)',
    gap: '20px',
    padding: '20px 10px 30px 10px',
    overflowX: 'scroll',
    marginRight: '10px',
    scrollBehavior: 'smooth',
    transition: 'all .3s',
    transitionDelay: '0.2s',
    overflowY: (props) => (props.selectedGroup ? 'auto' : 'hidden'),
    '&::-webkit-scrollbar': {
      height: 10,
      background: '#CFD2D3',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: 10
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: 10
    }
  },
  listDataGroupsContainer: {
    minWidth: 'calc(70% - 20px)',
    transition: 'all .5s'
  },
  listSettingsGroup: {
    minWidth: '30%',
    transition: 'all .5s'
  },
  listSettingsGroupContainer: {
    minWidth: '30%',
    transition: 'all .5s'
  },
  listGroupsBodyTable: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    listStyle: 'none',
    marginTop: '30px'
  },
  listGroupsBodyTableTitle: {
    justifyContent: 'flex-start'
  },
  listGroupsNormal: {
    marginTop: 0,
    marginBottom: 0,
    padding: '26px 32px',
    transition: 'all 0.2s'
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    '& td': {
      color: '#fff !important'
    },
    '& td svg': {
      color: '#fff !important'
    }
  },
  hovered: {
    '&:hover': {
      backgroundColor: '#F2F2F5'
    }
  },
  iconButton: {
    padding: 0,
    width: 40,
    height: 40,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFFFFF'
    }
  },
  lineFlag: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '5px'
  },
  flagAdmin: {
    backgroundColor: theme.palette.primary.main,
    display: 'block',
    padding: '2px 10px',
    color: '#FFF',
    borderRadius: '100px'
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  }
}))
