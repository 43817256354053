import React from 'react'
import { useTheme } from '@material-ui/styles'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Field } from 'react-final-form'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { withStyles, TextField } from '@material-ui/core'
import { DefaultStyledInput } from './style'
import makeStyles from './style'

export const StyledDate = withStyles((theme) => ({
  root: {
    paddingTop: '10.5px',
    paddingBottom: '10.5px',
    '& .MuiInputBase-root': {
      '& .MuiOutlinedInput-input': {
        padding: '5px 0',
        border: '1px solid red !important'
      }
    }
  }
}))(TextField)

export function Date({
  name,
  onChange,
  minDate,
  maxDate,
  className,
  ...props
}) {
  const theme = useTheme()
  const classes = makeStyles()
  const { onBlur } = props;
  return (
    <Field
      name={name}
      render={({ input, meta }) => (
        <KeyboardDatePicker
          primaryColor={theme.palette.primary.main}
          {...input}
          {...props}
          autoOk
          fullWidth
          maskChar=" "
          name={input.name}
          format="DD/MM/yyyy"
          orientation="portrait"
          inputVariant="outlined"
          initialFocusedDate
          className={clsx(className, classes.customDate)}
          value={input.value || null}
          minDate={minDate || undefined}
          maxDate={maxDate || undefined}
          TextFieldComponent={DefaultStyledInput}
          error={meta.touched && !!meta.error}
          helperText={false}
          onChange={(date) => input.onChange(date?.format() || null)}
          onBlur={field => {
            input.onBlur(field);
            onBlur && onBlur();
          }}
          cancelLabel="Fechar"
        />
      )}
    />
  )
}

Date.propTypes = {
  onChange: PropTypes.func,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  name: PropTypes.string.isRequired,
  className: PropTypes.string
}
