import React from 'react'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { Modal as ModalUi, OutlinedInput, InputAdornment } from '@mui/material'
import { TabContext, TabPanel } from '@material-ui/lab'
import { Button, Tooltip } from 'src/components'
import { messageResponse } from 'src/utils/messageResponse'
import { IconButton } from '@material-ui/core'
import { styled, alpha } from '@mui/material/styles'
import { Search as SearchIcon, Close as CloseIcon } from '@material-ui/icons'
import { getUsers } from 'src/services/hooks/users/useUsers'
import { useAlertContext } from 'src/context/AlertContext'
import { ListSearchUsers } from './Partials/ListSearchUsers'
import { ListSelectedUsers } from './Partials/ListSelectedUsers'
import { postUserGroupsSearch } from 'src/services/hooks/userGroup/useUserGroup'
import CallToActionOutlinedIcon from '@mui/icons-material/CallToActionOutlined'
import clsx from 'clsx'
import makeStyles from './style'
import useInput from 'src/hooks/useInput'
import useAuth from 'src/hooks/useAuth'
import { CreateCelebration } from './Partials/CreateCelebration'

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 'calc(100% - 40px)',
  margin: '16px 20px 0',
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`
  }
}))

ModalSelectUsers.propTypes = {
  onNextAction: PropTypes.func,
  currentTab: PropTypes.number,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  customerId: PropTypes.string,
  userId: PropTypes.string,
  onUpdateCelebrations: PropTypes.func,
  paramUserId: PropTypes.string,
  dataUserParam: PropTypes.object
}

export function ModalSelectUsers({
  onNextAction,
  currentTab,
  isOpen,
  onClose,
  customerId,
  userId,
  onUpdateCelebrations,
  paramUserId,
  dataUserParam
}) {
  const tabs = {
    DEFAULT_LIST: 0,
    ADD_CELEBRATION: 1
  }
  const classes = makeStyles()
  const { user } = useAuth()
  const { notifications, onCallAlert } = useAlertContext()
  const isTabOneToOne = currentTab === 0
  const isTabFeedbacks = currentTab === 1
  const isTabCelebrations = currentTab === 2
  const [dataUsers, setDataUsers] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isExpandItens, setIsExpandItens] = useState(false)
  const [shouldRenderSearchListUser, setShouldRenderSearchListUser] =
    useState(false)
  const [currentTabModal, setCurrentTabModal] = useState(
    isTabCelebrations && !!paramUserId
      ? tabs.ADD_CELEBRATION
      : tabs.DEFAULT_LIST
  )
  const usersSelected = dataUsers.filter((item) => item.isSelected) ?? []
  const isExistUsersSelected = usersSelected.length > 0
  const shouldRenderListUsersSelect =
    !isLoading && isExistUsersSelected

  const currentRole = user?.occupation

  const showModeUsersSelectd = isTabFeedbacks
    ? 'column'
    : isExpandItens
    ? 'column'
    : 'row'

  const { state: query, bindings, reset: resetInputSearch } = useInput()

  const getTitle = () =>  {
    if(currentTabModal === tabs.DEFAULT_LIST) {
      return 'Destinatário'
    }
    if(!paramUserId && isTabCelebrations) {
      return 'Nova celebração'
    }
    if(!paramUserId && isTabOneToOne) {
      return 'Novo 1:1'
    }

    return `Celebrar com ${dataUserParam?.name}`
  }
  const getSubTitle = () =>{
    if(currentTabModal === tabs.DEFAULT_LIST && isTabOneToOne) {
      return 'Para quem você deseja criar um 1:1?'
    }
    if(currentTabModal === tabs.DEFAULT_LIST && isTabFeedbacks) {
      return 'Para quem você deseja enviar seu feedback?'
    }
    if(currentTabModal === tabs.DEFAULT_LIST && isTabCelebrations) {
      return 'Para quem você deseja enviar sua celebração?'
    }
    return 'Escreve sua mensagem de celebração';
  }


  const dataSearch = dataUsers?.filter(
    ({ name }) => !query || name.toLowerCase().includes(query.toLowerCase())
  )

  function onChangeSelectUser({ idUser, action }) {
    const isAllowSelectOnlyOne = isTabFeedbacks || isTabOneToOne

    if (isAllowSelectOnlyOne && action === 'add') {
      const usersSelected = dataUsers.filter(
        (itemUsers) => !!itemUsers.isSelected
      )
      setShouldRenderSearchListUser(false)
      if (usersSelected.length >= 1) {
        onCallAlert({
          type: 'error',
          message: isTabFeedbacks
            ? 'O envio de feedback é direcionado apenas a 1 usuário.'
            : 'O 1:1 é direcionado apenas a 1 usuário.'
        })
        return
      }
    }

    setDataUsers(
      dataUsers.map((itemUsers) =>
        itemUsers.id !== idUser
          ? itemUsers
          : {
              ...itemUsers,
              isSelected: !itemUsers.isSelected
            }
      )
    )
  }

  function onConfirmUsersSelected() {
    if (isTabFeedbacks || isTabOneToOne) {
      onNextAction(usersSelected)
      onCloseModalAndTabs()
    }
    if (isTabCelebrations) {
      setCurrentTabModal(tabs.ADD_CELEBRATION)
    }
  }

  const getDataUsers = useCallback(
    async (idUser) => {
      try {
        setIsLoading(true)
        const resultUsers = await getUsers()
        if (isTabCelebrations || isTabOneToOne) {
          setDataUsers(
            resultUsers.map((item) => ({
              isSelected: false,
              id: item.id,
              name: item.name,
              imageProfile: item.imageProfile,
              occupationAreaName: item.occupationAreaName,
              occupationArea: item.occupationArea,
              assignment: item.assignment,
              email: item.email
            }))
          )
        }
        if (isTabFeedbacks || isTabOneToOne ) {
          const payloadGetGroups = {
            customerId,
            userId: idUser,
            isActive: true
          }
          const isLeader = currentRole === 3
          const isLed = currentRole === 4
          if (isLeader || isLed) {
            const usersGroupsSearch = await postUserGroupsSearch(
              payloadGetGroups
            )
            const groups = usersGroupsSearch.map(
              (item) => item.members.find((member) => idUser === member) && item
            )
            const groupsUser = groups.filter((item) => !!item)
            const usersBelongingGroup = resultUsers.map((itemUser) => {
              const groupsBelonging = groupsUser.filter((itemGroup) =>
                itemGroup.members.includes(itemUser.id)
              )
              return groupsBelonging.length > 0 ? itemUser : null
            })
            if (isLeader) {
              const getLedBelongingGroup = usersBelongingGroup.filter(
                (itemUser) => itemUser !== null && itemUser.id !== idUser
              )
              setDataUsers(
                getLedBelongingGroup.map((item) => ({
                  isSelected: false,
                  id: item.id,
                  name: item.name,
                  imageProfile: item.imageProfile,
                  occupationAreaName: item.occupationAreaName,
                  occupationArea: item.occupationArea,
                  assignment: item.assignment,
                  email: item.email
                }))
              )
            }
            if (isLed) {
              const getLeadersBelongingGroup = usersBelongingGroup.filter(
                (itemUser) =>
                  itemUser !== null &&
                  itemUser?.id !== idUser &&
                  itemUser.occupation === 3
              )
              setDataUsers(
                getLeadersBelongingGroup.map((item) => ({
                  isSelected: false,
                  id: item.id,
                  name: item.name,
                  imageProfile: item.imageProfile,
                  occupationAreaName: item.occupationAreaName,
                  occupationArea: item.occupationArea,
                  assignment: item.assignment,
                  email: item.email
                }))
              )
            }
          }
        }
      } catch (error) {
        const messageError = messageResponse(error?.response)
        onCallAlert({
          type: 'error',
          message: messageError ?? notifications.global.error
        })
      } finally {
        setIsLoading(false)
      }
    },
    [
      currentRole,
      customerId,
      isTabCelebrations,
      isTabFeedbacks,
      notifications.global.error,
      userId
    ]
  )

  useEffect(() => {
    getDataUsers(userId)
  }, [getDataUsers, userId])

  function onReset() {
    setCurrentTabModal(tabs.DEFAULT_LIST)
    const resetUsersSelected = dataUsers.map((item) => ({...item, isSelected: false}));
    setDataUsers(resetUsersSelected)
  }

  function onCloseModalAndTabs () {
    resetInputSearch();
    onClose();
    onReset();
    setIsExpandItens(false)
    setShouldRenderSearchListUser(false)
  }

  function onOpenSearchList() {
    if(isExpandItens) {
      setIsExpandItens(false)
    }
    setShouldRenderSearchListUser(true)
  }

  return (
    <TabContext value={currentTabModal}>
      <ModalUi open={isOpen} className={classes.containerModal}>
        <div className={classes.boxModal}>
          <div className={classes.boxTop}>
            <div>
              <h1 className={classes.topTitle}>{getTitle()}</h1>
              <h2 className={classes.topSubTitle}>{getSubTitle()}</h2>
            </div>
            {currentTabModal === tabs.DEFAULT_LIST && (
              <Tooltip
                placement="top"
                title={isExpandItens ? 'Reduzir' : 'Expandir'}
              >
                <IconButton
                  onClick={() => setIsExpandItens(!isExpandItens)}
                  disabled={isTabFeedbacks || !isExistUsersSelected || shouldRenderSearchListUser}
                  className={clsx(
                    classes.sizeButton,
                    classes[isExpandItens ? 'expandButton' : 'iconButton']
                  )}
                >
                  <CallToActionOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip placement="top" title="Fechar">
              <IconButton
                className={clsx(classes.sizeButton, classes.iconButton)}
                onClick={onCloseModalAndTabs}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </div>
          <TabPanel
            style={{ padding: '0px' }}
            value={tabs.DEFAULT_LIST}
            index={tabs.DEFAULT_LIST}
          >
            <>
              <StyledSearch
                placeholder="Buscar"
                onFocus={onOpenSearchList}
                {...bindings}
                endAdornment={
                  <InputAdornment>
                    <SearchIcon />
                  </InputAdornment>
                }
              />
              {shouldRenderListUsersSelect && (
                <ListSelectedUsers
                  showMode={showModeUsersSelectd}
                  onRemoveSelectUser={onChangeSelectUser}
                  usersSelected={usersSelected}
                />
              )}
              {shouldRenderSearchListUser && (
                <ListSearchUsers
                  onClose={() => setShouldRenderSearchListUser(false)}
                  isLoading={isLoading}
                  users={dataSearch}
                  onChangeSelectUser={onChangeSelectUser}
                  usersSelected={usersSelected}
                />
              )}
              <div className={classes.listFooter}>
                <Button
                  disabled={!isExistUsersSelected}
                  label="Confirmar"
                  onClick={onConfirmUsersSelected}
                />
              </div>
            </>
          </TabPanel>
          <TabPanel
            style={{ padding: '0px' }}
            value={tabs.ADD_CELEBRATION}
            index={tabs.ADD_CELEBRATION}
          >
            <CreateCelebration
              goBackToList={() => setCurrentTabModal(tabs.DEFAULT_LIST)}
              onClose={onCloseModalAndTabs}
              fromUserId={userId}
              customerId={customerId}
              withUsers={
                !paramUserId ? usersSelected : [{ id: dataUserParam?.id }]
              }
              onUpdateCelebrations={onUpdateCelebrations}
            />
          </TabPanel>
        </div>
      </ModalUi>
    </TabContext>
  )
}
