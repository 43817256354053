import { validateFormValues } from './validateForm'
import { validateCPF, validateMinAge, yupValidadePassword } from './rules'
import * as yup from 'yup'

export const exemploValidacao = validateFormValues(
  yup.object({
    nome: yup.string().required(),
    cpf: yup.string().required().min(14),
    cidade: yup.string()
  })
)

export const newUserValidation = validateFormValues(
  yup.object({
    name: yup.string().min(2).required(),
    email: yup.string().email().required(),
    occupation: yup.number().required(),
    document: yup
      .string()
      .min(14)
      .required()
      .test('document', 'CPF inválido', (value) => validateCPF(value)),
    birthData: yup
      .string()
      .required()
      .test('birthData', 'A idade mínima é 16 anos', (value) =>
        validateMinAge(value)
      ),
    admissionDate: yup.string().required(),
    occupationAreaId: yup.string().required(),
    assignment: yup.number().required()
  })
)

export const newClientValidation = validateFormValues(
  yup.object({
    name: yup.string().required(),
    email: yup.string().email().required(),
    document: yup.string().min(18).required()
  })
)

export const editClientValidation = validateFormValues(
  yup.object({
    name: yup.string().required(),
    email: yup.string().email().required(),
    isActive: yup.boolean()
  })
)

export const loginValidation = validateFormValues(
  yup.object({
    email: yup.string().email().required(),
    password: yup.string().required()
  })
)

export const emailLoginValidation = validateFormValues(
  yup.object({
    email: yup.string().email().required()
  })
)
export const newLoginValidation = validateFormValues(
  yup.object({
    password: yup.string().required(),
    passwordNext: yup.string().required()
  })
)
export const newGrouperValidation = validateFormValues(
  yup.object({
    name: yup.string('dsjcjsbhdcds').required(),
    description: yup.string().required(),
    category: yup.string().required(),
    competences: yup.array().min(1),
    userGroups: yup.array().min(1)
  })
)
export const evaluationCyclesValidation = validateFormValues(
  yup.object({
    competenceScoreRepetitionEach: yup.number().required(),
    competenceScoreRepetitionInterval: yup.number().required(),
    competenceScoreRepetitionStart: yup.string().required(),
    oneToOneRepetitionEach: yup.number().required(),
    oneToOneRepetitionInterval: yup.number().required(),
    oneToOneRepetitionStart: yup.string().required()
  })
)
export const developmentPlanBasicData = validateFormValues(
  yup.object({
    name: yup.string().required(),
    description: yup.string().required(),
    createAt: yup.string().required(),
    estimatedCompletion: yup.string().required()
  })
)
export const profileChangePassword = validateFormValues(
  yup.object({
    currentPassword: yup.string().required(),
    password: yup.string().required(),
    confirmPassword: yup.string().required()
  })
)

export const newOneToOne = validateFormValues(
  yup.object({
    start: yup.string().required(),
    finish: yup.string().required(),
    name: yup.string().required()
  })
)
export const newOneToOneRepetition = validateFormValues(
  yup.object({
    start: yup.string().required(),
    finish: yup.string().required(),
    name: yup.string().required(),

    repetitionEach: yup.number().required(),
    repetitionInterval: yup.number().required(),
    repetitionUntil: yup.string().required()
  })
)
export const editOneToOne = validateFormValues(
  yup.object({
    start: yup.string().required(),
    finish: yup.string().required(),
    name: yup.string().required()
  })
)
export const editOneToOneRepetition = validateFormValues(
  yup.object({
    start: yup.string().required(),
    finish: yup.string().required(),
    name: yup.string().required(),

    repetitionEach: yup.number().required(),
    repetitionInterval: yup.number().required(),
    repetitionUntil: yup.string().required()
  })
)
export const viewOneToOneLeader = validateFormValues(
  yup.object({
    name: yup.string().required(),
    description: yup.string().required()
  })
)
export const viewOneToOneLed = validateFormValues(
  yup.object({
    annotation: yup.string().required()
  })
)

export const newUserGroupValidation = validateFormValues(
  yup.object({
    name: yup.string().required()
  })
)

export const editUserGroupValidation = validateFormValues(
  yup.object({
    name: yup.string().required()
  })
)

export const descriptionFeedback = validateFormValues(
  yup.object({
    description: yup.string().required()
  })
)

export const changePassword = validateFormValues(
  yup.object({
    password: yup.string().required(),
    newPassword: yup
      .string()
      .required()
      .test((value) => yupValidadePassword(value)),
    passwordConfirmation: yup
      .string()
      .required()
      .oneOf([yup.ref('newPassword')])
  })
)

export const formCompetence = validateFormValues(
  yup.object({
    name: yup.string().required(),
    description: yup.string().nullable()
  })
)
export const appearancePreferences = validateFormValues(
  yup.object({
    name: yup
      .string()
      .required()
      .test((value) => isHexColor(value))
  })
)

function isHexColor(hex) {
  return (
    typeof hex === 'string' && hex.length === 6 && !isNaN(Number('0x' + hex))
  )
}

export const feedbackBasicData = validateFormValues(
  yup.object({
    description: yup.string().min(3).max(3000).required()
  })
)

export const changeCustomerPassword = validateFormValues(
  yup.object({
    password: yup.string().required()
  })
)
