import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  competencesContainer: {
    width: '100%',
    height: '56px',
    backgroundColor: 'transparent',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 12px',
    transition: 'all .1s',
    cursor: 'pointer',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    '&:hover': {
      border: '1px solid #4A4A4A'
    }
  }
}))
