import React, { useCallback, useEffect, useState } from 'react'
import { Grid, Box } from '@material-ui/core'
import { Radar } from 'react-chartjs-2'
import { getHexToRGBA } from 'src/utils'
import { enumCategoryNameColor } from 'src/@enum/metrics'
import { Chip } from 'src/components'

export function RadarChartDetails({
  title = false,
  legend = false,
  aspectRatio = 2,
  data,
  labels,
  categoryName
}) {
  const [dataState, setDataState] = useState([])

  const options = {
    responsive: true,
    aspectRatio: aspectRatio,
    scales: {
      r: {
        min: 0,
        max: 5,
        ticks: {
          beginAtZero: true,
          fontColor: 'white',
          showLabelBackdrop: false
        },
        grid: {
          color: '#CFD2D3',
          circular: true
        },
        angleLines: {
          color: '#CFD2D3'
        },
        pointLabels: {
          color: '#787878'
        }
      }
    },
    plugins: {
      title: {
        display: title
      },
      legend: {
        display: legend
      }
    }
  }

  const color = enumCategoryNameColor[categoryName.toLocaleLowerCase()]

  const getData = useCallback(() => {
    const radar = (dataState ?? []).map((currentData) => {
      return {
        label: currentData?.competenceGrouperName,
        value: currentData?.average
      }
    })

    const labelsMap = radar?.map((item) => item.label)
    const score = radar?.map((item) => item.value)

    return {
      labels: labels && labels.length >= 1 ? labels : labelsMap,
      datasets: [
        {
          data: score,
          backgroundColor: getHexToRGBA(color ? color : '#CCCCCC', 0.1),
          borderColor: color,
          borderWidth: 1
        }
      ]
    }
  }, [color, dataState, labels])

  useEffect(() => {
    setDataState(data)
  }, [data, labels])

  return (
    <Box>
      <Radar data={getData()} options={options} />
      <Grid container spacing={2} direction="row" justifyContent="center">
        {labels?.map((item) => {
          return (
            <Grid item xs="auto" key={item}>
              <Chip name={item} dotColor={color} id={item} />
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}
