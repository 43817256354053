import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    minWidth: '350px',
    maxHeight: '100%',
    position: 'relative',
    backgroundColor: '#FFFFFF',
    borderRadius: '20px',
    marginRight: '20px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)'
  },
  containerBlur: {
    backgroundColor: ' #ffffffd6',
    filter: 'blur(3px)'
  },
  cardBoxInforBottom: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },

  textName: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500,
    color: theme.palette.primary.main,
    padding: '5px 0px 10px 0px',
    borderBottom: 'solid 2px',
    borderColor: theme.palette.primary.main,
    margin: '0px',
    textAlign: 'center'
  },
  textEmail: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500,
    margin: '0px',
    padding: '15px 0px 10px 0px',
    textAlign: 'center',
    color: '#000'
  },
  textArea: {
    fontSize: theme.typography.pxToRem(12),
    margin: '0px',
    padding: '0px 0px 10px 0px',
    textAlign: 'center',
    color: '#000'
  }
}))
