import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  displayContent: {
    display: 'flex',
    gap: 10,
    marginBottom: 10,
    alignItems: 'center',
  },
  name: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
    color: '#000000'
  },
  subTitle: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 400,
    color: '#000000',
  },
  boxArrow: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'start',
    flex: 1,
    gap: 5
  },
  boxBtnOptions: {
    width: 37,
    height: 37
  },
  containerColorMetrics: {
    margin: "15px 0",
  },
}))
