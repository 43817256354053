import React, { useState, useEffect, useCallback } from 'react'
import useAuth from 'src/hooks/useAuth'
import { useParams, useNavigate } from 'react-router-dom'
import { useAlertContext } from 'src/context/AlertContext'
import { useLoadingContext } from 'src/context/LoadingContext'
import { messageResponse } from 'src/utils/messageResponse'
import {
  HeaderBreadcrumbs,
  Helmet,
  NewBoxWidget,
  CardExpectedEvoluction,
  Tooltip,
  ProgressChips
} from 'src/components'
import { Box, IconButton } from '@material-ui/core'
import {
  Close as CloseIcon,
  AssessmentOutlined as AssessmentOutlinedIcon
} from '@material-ui/icons'
import { getMetricsUsers } from 'src/services/hooks/metrics/useMetrics'
import { postDevelopmentPlanSearch } from 'src/services/hooks/developmentPlan/useDevelopmentPlan'
import { postUserGroupsSearch } from 'src/services/hooks/userGroup/useUserGroup'
import useGlobalUseStyles from 'src/themes/globalUseStyles'
import makeStyles from './styles'

function ArchivePDI() {
  const classes = makeStyles()
  const globalClasses = useGlobalUseStyles()
  const navigate = useNavigate()

  const { id: paramUserId } = useParams()

  const { user } = useAuth()
  const currentUserId = user?.id
  const currentCustomerId = user?.customerId

  const { onCallAlert } = useAlertContext()
  const { onCloseLoading, onOpenLoading } = useLoadingContext()

  const [isLoading, setIsLoading] = useState(false)

  const [data, setData] = useState([])
  const [dataUser, setDataUser] = useState([])
  const [dataUserGroups, setDataUserGroups] = useState([])

  const [payloadTargets, setPayloadTargets] = useState([])
  const [payloadActivities, setPayloadActivities] = useState([])

  const getDevelopmentPlanSearch = useCallback(
    async (customerId, userLeaderId) => {
      try {
        setIsLoading(true)
        const payload = {
          customerId,
          fromUserId: userLeaderId,
          toUserId: paramUserId
        }
        const response = await postDevelopmentPlanSearch(payload)
        const developmentPlans = response?.filter(
          (item) => item.status === 3 || item.status === 4
        )

        setData(developmentPlans)
        setIsLoading(false)
      } catch (error) {
        console.error(error)
        setIsLoading(false)
      }
    },
    []
  )

  const getDataUser = useCallback(async (id, customerId) => {
    try {
      onOpenLoading()
      const data = await getMetricsUsers({ id })
      setDataUser(data)

      const payloadGetGroups = {
        customerId: customerId,
        isActive: true
      }
      const usersGroupsSearch = await postUserGroupsSearch(payloadGetGroups)
      const groups = usersGroupsSearch.map(
        (item) => item.members.find((member) => id === member) && item
      )
      const groupsUser = groups.filter((item) => !!item)
      const userGroups = groupsUser.map((item) => item.id)

      setDataUserGroups(userGroups)
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      onCloseLoading()
    }
  }, [])

  useEffect(() => {
    paramUserId && getDataUser(paramUserId, currentCustomerId)
    currentCustomerId &&
      currentUserId &&
      getDevelopmentPlanSearch(currentCustomerId, currentUserId)
  }, [
    currentCustomerId,
    currentUserId,
    getDataUser,
    getDevelopmentPlanSearch,
    paramUserId
  ])

  function handleMetricsUser(id) {
    navigate(`/metrics/user/${id}`)
  }

  return (
    <>
      <Helmet title="PDI - Arquivo" />
      <Box className={globalClasses.breadcrumb}>
        <HeaderBreadcrumbs
          icon={<AssessmentOutlinedIcon />}
          links={[
            {
              name: 'Métricas',
              href: '/metrics'
            },
            {
              name: `${dataUser?.name || ''}`,
              href: `/metrics/user/${paramUserId}`
            },
            { name: 'PDI', href: `/metrics/user/${paramUserId}` },
            { name: 'Arquivo' }
          ]}
        />
        <Box className={classes.boxBtnNavegation}>
          <Tooltip title="Fechar">
            <IconButton
              className={classes.boxContentBtn}
              onClick={() => handleMetricsUser(dataUser?.id)}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box className={classes.container}>
        <Box className={classes.boxContent}>
          {!isLoading && data && dataUserGroups && (
            <>
              {data?.map((item, index) => (
                <NewBoxWidget key={index} width={450} height="100%">
                  <CardExpectedEvoluction
                    data={item}
                    payloadTargets={item.targets}
                    setPayloadTargets={setPayloadTargets}
                    payloadActivities={item.activities}
                    setPayloadActivities={setPayloadActivities}
                    disabledSave
                    isArchive
                  />
                  <ProgressChips
                    customerId={currentCustomerId}
                    userRole={dataUser?.occupation}
                    dataCompetencies={item?.competencies}
                    dataUserGroups={dataUserGroups}
                  />
                </NewBoxWidget>
              ))}
            </>
          )}
        </Box>
      </Box>
    </>
  )
}

export default ArchivePDI
