import React, { useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import Login from 'src/screens/Auth/Login'
import useAuth from 'src/hooks/useAuth'
import { LoadingScreen } from 'src/components'

export default function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuth()

  const { pathname } = useLocation()

  const [requestedLocation, setRequestedLocation] = useState(null)

  if (!isInitialized) {
    return <LoadingScreen />
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname)
    }
    return <Login />
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null)
    return <Navigate to={requestedLocation} />
  }

  return <> {children} </>
}
