import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  avatar: {
    backgroundColor: 'transparent',
    border: `solid 2px ${theme.palette.primary.main}`,
    width: '80px',
    height: '80px',
    margin: '10px 0px 10px 0px',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    color: '#787878',
    fontSize: theme.typography.pxToRem(17)
  },
  logo: {
    width: 160,
    alignItems: 'center',
    display: 'flex',
    overflow: 'hidden',
    '& img': {
      height: 60,
      maxWidth: '100%'
    }
  }
}))
