export function maskCPF(value) {
  if (value) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(.\d{3})(\d)/, '$1.$2')
      .replace(/(.\d{3})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d/, '$1')
  }
  return value
}

export function maskName(value) {
  return value
    .replace(/(?:^|\s)\S/g, (X) => X.toUpperCase()) //Coloca a primeira letra da palavra em maiúscula
    .replace(/[0-9!@#¨$%^&*)(+=._-]+/g, '') //Apenas letras
    .replace(/( )+/g, ' ') //Remove espaços vazios
    .toLowerCase()
    .replace(/(?:^|\s)\S/g, (rest) => rest.toUpperCase()) //Apos a primeira letra, todas as outras são converridas a minúscula
}

export function maskCNPJ(value) {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(.\d{3})(\d)/, '$1.$2')
    .replace(/(.\d{3})(\d)/, '$1/$2')
    .replace(/(.\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})(\d)/, '$1')
}

export function maskPhone(value) {
  if (value.length <= 14) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d)/, '($1')
      .replace(/(\(\d{2})(\d)/, '$1) $2')
      .replace(/(\d{4})(\d{1,4})/, '$1-$2')
      .replace(/(-\d{4})\d/, '$1')
  }
  return value
    .replace(/\D/g, '')
    .replace(/(\d)/, '($1')
    .replace(/(\(\d{2})(\d)/, '$1) $2')
    .replace(/(\d{5})(\d{1,4})/, '$1-$2')
    .replace(/(-\d{4})\d/, '$1')
}

export function onlyNumbers(value) {
  return value.replace(/\D/g, '')
}
