import React, { useState, useCallback, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import useAuth from 'src/hooks/useAuth'
import { Box, IconButton } from '@material-ui/core'
import { BadgeTooltip, Tooltip } from 'src/components'
import { Edit as EditIcon } from '@material-ui/icons'
import { getUserById } from 'src/services/hooks/users/useUsers'
import { postUserGroupsSearch } from 'src/services/hooks/userGroup/useUserGroup'
import { messageResponse } from 'src/utils/messageResponse'
import { useAlertContext } from 'src/context/AlertContext'
import useStyles from './styles'

export function CardOneToOne({
  oneToOneId,
  userId,
  text,
  idUserSelected
}) {
  const classes = useStyles()

  const navigate = useNavigate()

  const { onCallAlert } = useAlertContext()

  const { id: userIdParams } = useParams()
  const { user } = useAuth()
  const currentCustomerId = user?.customerId

  const [dataUser, setDataUser] = useState()
  const [dataGroups, setDataGroups] = useState([])
  const [isLeader, setIsLeader] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getDataUser = useCallback(async (id, customerId) => {
    try {
      setIsLoading(true)
      const { data } = await getUserById(id)
      setDataUser(data?.data)
      const payloadGetGroups = {
        customerId: customerId,
        userId: id,
        isActive: true
      }
      const usersGroupsSearch = await postUserGroupsSearch(payloadGetGroups)
      const toUserId = userIdParams ?? idUserSelected;
      const groups = usersGroupsSearch.map(
        (item) => item.members.find((member) => toUserId === member) && item
      )
      const leader = usersGroupsSearch.map(
        (item) => item.leaders.find((leader) => id === leader) && item
      )
      const isLeader = leader.filter((item) => !!item)
      const groupsUser = groups.filter((item) => !!item)
      setDataGroups(groupsUser)
      setIsLeader(isLeader)
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    userId && currentCustomerId && getDataUser(userId, currentCustomerId)
  }, [currentCustomerId, getDataUser, userId])

  function handleViewOneToOne(id) {
    navigate(`/view/one-to-one/${id}`)
  }

  return (
    <>
      {!isLoading && dataUser && (
        <Box className={classes.container}>
          <Box className={classes.card}>
            <Box className={classes.display}>
              <Box
                display="flex"
                alignItems="center"
                className={classes.avatar}
              >
                <BadgeTooltip
                  placement="top"
                  disableFocusListener
                  disableTouchListener
                  user={dataUser}
                />
                <Box ml={1}>
                  <Box className={classes.name}>{dataUser?.name}</Box>
                  <Box className={classes.occupation}>
                    <Box>
                      <div className={classes.formItemText}>
                        {isLeader.length ? 'Lider' : 'Liderado'}
                        {dataGroups.length > 0 && ` - ${dataGroups[0].name}` }
                      </div>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Tooltip placement="top" title="Editar 1:1">
                  <IconButton
                    className={classes.iconEditButton}
                    onClick={() => handleViewOneToOne(oneToOneId)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}
