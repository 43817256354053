import { Typography } from '@mui/material'
import { Grid, Box } from '@material-ui/core'
import useAuth from 'src/hooks/useAuth'

export default function RoleBasedGuard({ hasContent, roles, children }) {
  const { user } = useAuth()

  const currentRole = user?.occupation

  if (typeof roles !== 'undefined' && !roles.includes(currentRole)) {
    return (
      hasContent && (
        <Grid
          container
          item
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center'
          }}
          spacing={3}
        >
          <Grid item xs={12}>
            <Box>
              <Typography variant="h3" paragraph>
                Permissão negada!
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ color: 'text.secondary' }}>
                Você não tem permissão para acessar esta página.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )
    )
  }

  return <>{children}</>
}
