import EditGroupers from 'src/screens/Groupers/EditGroupers'
import ListGroupers from 'src/screens/Groupers/ListGroupers'
import NewGroupers from 'src/screens/Groupers/NewGroupers'

export default [
  {
    path: '/competence-grouper',
    element: <ListGroupers />
  },
  {
    path: '/competence-grouper/edit/:id',
    element: <EditGroupers />
  },
  {
    path: '/competence-grouper/add',
    element: <NewGroupers />
  }
]
