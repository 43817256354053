import React from 'react'
import {
  Box,
  Link,
  Typography,
  Breadcrumbs as MUIBreadcrumbs
} from '@mui/material'
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined'

export default function NewBreadcrumbs({
  links,
  activeLast = false,
  ...other
}) {
  const currentLink = links[links.length - 1].name

  const listDefault = links.map((link) => (
    <LinkItem key={link.name} link={link} />
  ))

  const listActiveLast = links.map((link) => (
    <div key={link.name}>
      {link.name && (
        <div key={link.name}>
          {link.name !== currentLink ? (
            <LinkItem link={link} />
          ) : (
            <Typography
              variant="body2"
              sx={{
                maxWidth: 260,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                display: 'flex',
                alignItems: 'center',
                padding: '12px 0px',
                fontSize: 14,
                zIndex: 1,
                color: '#000'
              }}
            >
              {currentLink}
            </Typography>
          )}
        </div>
      )}
    </div>
  ))

  return (
    <MUIBreadcrumbs
      separator={
        <KeyboardArrowRightOutlinedIcon
          sx={{ marginLeft: '-4px', marginRight: '-4px' }}
        />
      }
      {...other}
    >
      {activeLast ? listDefault : listActiveLast}
    </MUIBreadcrumbs>
  )
}

function LinkItem({ link }) {
  const { href = '', name, icon } = link
  return (
    <Link
      href={href}
      key={name}
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '12px 0px',
        fontSize: 14,
        zIndex: 1,
        color: '#000',
        '& > div': { display: 'inherit' }
      }}
      underline="hover"
    >
      {icon && (
        <Box sx={{ mr: 1, '& svg': { width: 20, height: 20 } }}>{icon}</Box>
      )}
      {name}
    </Link>
  )
}
