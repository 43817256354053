import React, { useState, useEffect, useCallback } from 'react'
import 'moment/locale/pt-br'
import moment from 'moment'
import { Box, Typography } from '@material-ui/core'
import { Avatar, Tooltip } from 'src/components'
import { useAlertContext } from 'src/context/AlertContext'
import { messageResponse } from 'src/utils/messageResponse'
import { getUserById } from 'src/services/hooks/users/useUsers'
import { getCompetenceGrouper } from 'src/services/hooks/competenceGrouper/useCompetenceGrouper'
import { enumCategory, enumCategoryColor } from 'src/@enum/metrics'
import groupBy from 'lodash/groupBy'
import makeStyles from './style'

function Feedbacks({ data }) {
  const classes = makeStyles()

  const { onCallAlert } = useAlertContext()

  const [dataFeedback, setDataFeedback] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const getDataUser = useCallback(async (data) => {
    try {
      setIsLoading(true)
      const { data: user } = await getUserById(data.fromUserId)
      const competencies = data?.competencies?.map((item) => item)

      const { data: competenceGrouper } = await getCompetenceGrouper({})
      const filteredCompetences = competenceGrouper?.data?.map((item) => {
        const filter = item.competences.filter((elem) =>
          competencies.includes(elem)
        )
        return {
          ...item,
          competences: filter
        }
      })
      const dataCompetencesCategory = filteredCompetences.filter(
        (item) => !!item.competences.length
      )
      const dataCategory = groupBy(dataCompetencesCategory, 'category')
      const dataListCategory = Object.entries(dataCategory).map(
        (currentData) => {
          const [categoryId] = currentData
          return {
            categoryId: categoryId
          }
        }
      )

      const responseFeedback = {
        ...data,
        user: user?.data,
        filters: dataListCategory
      }

      setDataFeedback(responseFeedback)
    } catch (error) {
      onCallAlert({
        type: 'error',
        message: messageResponse(error?.response)
      })
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    data && getDataUser(data)
  }, [data, getDataUser])

  return (
    <>
      {!isLoading && dataFeedback && (
        <Box className={classes.commentBox}>
          <Box className={classes.commentText}>{dataFeedback.description}</Box>
          <Box className={classes.user}>
            <Box display="flex" alignItems="center" gridGap={10}>
              <Avatar src={dataFeedback.user.imageProfile} />
              <Box>
                <Typography className={classes.userName}>
                  {dataFeedback.user.name}
                </Typography>
                <Typography className={classes.dateOfComment}>
                  {moment(dataFeedback?.createAt).format('LL')}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.filtersButtonsCard}>
              {dataFeedback?.filters?.map((item, index) => (
                <Tooltip key={index} title={enumCategory[item.categoryId]}>
                  <div
                    className={classes.buttonFilter}
                    style={{
                      backgroundColor: enumCategoryColor[item.categoryId]
                    }}
                  />
                </Tooltip>
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}

export default Feedbacks
