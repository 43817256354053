import React, { useState, useEffect, useCallback } from 'react'
import useAuth from 'src/hooks/useAuth'
import { useTheme, styled } from '@material-ui/styles'
import clsx from 'clsx'
import { useLoadingContext } from 'src/context/LoadingContext'
import { useAlertContext } from 'src/context/AlertContext'
import { messageResponse } from 'src/utils/messageResponse'
import { Close as CloseIcon } from '@material-ui/icons'
import { Box, Table, TableRow, TableBody, TableContainer } from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { Button, NewBoxWidget, Tooltip } from 'src/components'
import { IconButton } from '@material-ui/core'
import { MuiSwitch } from 'src/components/Fields'
import { TableHead, TableNotFound } from 'src/components'
import {
  getUserGroupDataById,
  putUserGroups
} from 'src/services/hooks/userGroup/useUserGroup'
import { getComparator, applySortFilter } from 'src/utils'
import { dataPermissions } from './data'
import makeStyles from '../style'

const TABLE_HEAD = [
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'name', label: 'Nome', alignRight: false }
]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottom: 'none',
    padding: '10px 10px 10px 16px'
  }
}))

export default function SettingsGroup({ groupSelected, onCloseEditGroup }) {
  const theme = useTheme()
  const classes = makeStyles()

  const { user } = useAuth()
  const currentCustomerId = user?.customerId

  const { notifications, onCallAlert } = useAlertContext()
  const { onCloseLoading, onOpenLoading } = useLoadingContext()

  const [data, setData] = useState([])
  const [dataGroup, setDataGroup] = useState([])

  const [order, setOrder] = useState('asc')
  const [selectedGroupPermissions, setSelectedGroupPermissions] = useState([])
  const [orderBy, setOrderBy] = useState('name')

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleClickGroupPermissions = (event, id) => {
    const selectedIndex = selectedGroupPermissions.indexOf(id)
    let selected = []
    if (selectedIndex === -1) {
      selected = selected.concat(selectedGroupPermissions, id)
    } else if (selectedIndex === 0) {
      selected = selected.concat(selectedGroupPermissions.slice(1))
    } else if (selectedIndex === selectedGroupPermissions.length - 1) {
      selected = selected.concat(selectedGroupPermissions.slice(0, -1))
    } else if (selectedIndex > 0) {
      selected = selected.concat(
        selectedGroupPermissions.slice(0, selectedIndex),
        selectedGroupPermissions.slice(selectedIndex + 1)
      )
    }
    setSelectedGroupPermissions(selected)
  }

  const filteredData = applySortFilter(data, getComparator(order, orderBy))

  const getUserGroup = useCallback(async (id) => {
    try {
      const response = await getUserGroupDataById(id)
      setDataGroup(response)
      setSelectedGroupPermissions(response.permissions)
    } catch (e) {
      console.error(e)
    }
  }, [])

  useEffect(() => {
    setData(dataPermissions)
    getUserGroup(groupSelected?.id)
  }, [getUserGroup, groupSelected])

  const onSubmit = async (values) => {
    try {
      const selectedGroupPermissionsItems = selectedGroupPermissions.map(
        (item) => ({
          ...item,
          permissionId: item,
          permissionGranted: true
        })
      )

      const payload = {
        id: dataGroup?.id,
        name: dataGroup?.name,
        customerId: currentCustomerId,
        members: dataGroup?.members,
        leaders: dataGroup?.leaders,
        groupPermissions: selectedGroupPermissionsItems,
        isActive: true
      }

      onOpenLoading()
      await putUserGroups(dataGroup?.id, payload)
      onCallAlert({
        type: 'success',
        message: notifications.userGroups.success.updateSettingsGroup
      })
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      onCloseLoading()
    }
  }

  return (
    <div className={classes.listSettingsGroupContainer}>
      <NewBoxWidget
        title="Permissões do grupo"
        subtitle="Analíse e edite especificações:"
        options={
          <>
            <Tooltip placement="top" title="Fechar">
              <IconButton
                className={classes.iconButton}
                aria-owns="filter-table"
                onClick={onCloseEditGroup}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </>
        }
        actions={
          <Box className={classes.actions}>
            <Box />
            <Button
              variant="contained"
              label="Salvar"
              onClick={() => onSubmit()}
            />
          </Box>
        }
        borderActions
        noBodyPadding
        height={500}
      >
        <div className={classes.listGroupsContainer}>
          <TableContainer
            sx={{
              maxHeight: '400px',
              overflow: 'initial',
              '&::-webkit-scrollbar': {
                height: '4px',
                width: '4px',
                borderRadius: '10px'
              },
              '&::-webkit-scrollbar-track': {
                background: '#CFD2D3',
                borderRadius: '10px'
              },
              '&::-webkit-scrollbar-thumb': {
                background: theme.palette.primary.main,
                borderRadius: '10px'
              }
            }}
          >
            <Table stickyHeader>
              <TableHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={data?.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {filteredData?.slice()?.map((row) => {
                  const { id, name } = row
                  const checkPermission =
                    selectedGroupPermissions.indexOf(id) !== -1
                  return (
                    <TableRow
                      hover
                      key={id}
                      tabIndex={-1}
                      role="checkbox"
                      className={clsx(
                        classes.listGroupsBodyTable,
                        classes.listGroupsNormal
                      )}
                    >
                      <StyledTableCell align="left">
                        <MuiSwitch
                          checked={checkPermission}
                          onChange={(event) =>
                            handleClickGroupPermissions(event, id)
                          }
                          inputProps={{ 'aria-label': 'controlled' }}
                          color="default"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="left">{name}</StyledTableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
              {!data && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={12}
                      sx={{ border: 'none' }}
                    >
                      <TableNotFound />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </div>
      </NewBoxWidget>
    </div>
  )
}
