import React from 'react'
import { NewBoxWidget } from 'src/components'
import { Form } from 'react-final-form'
import { Input, InputMultiline, Switch } from 'src/components/Fields'
import { Button } from 'src/components'
import { formCompetence } from 'src/utils/form/validations'
import makeStyles from './style'

export function TabNewCompetences({
  onAddNewCompetence,
  onCloseModal
}) {
  const classes = makeStyles()

  function onHandleSubmit({ name, description, shouldBind }) {
    onAddNewCompetence({ nameCompetence: name, description, shouldBind })
  }

  return (
    <div className={classes.containerTab}>
      <NewBoxWidget
        borderActions
        style={{ boxShadow: 'none', background: 'transparent' }}
        noBodyPadding
        width={'100%'}
      >
        <section>
          <Form
            onSubmit={(values) =>
              onHandleSubmit({
                name: values.name,
                description: values.description,
                shouldBind: values.shouldBind
              })
            }
            initialValues={{
              description: ""
            }}
            validate={formCompetence}
            render={({ handleSubmit, values, form}) => (
              <form onSubmit={handleSubmit} className={classes.boxForm}>
                <Input
                  label="Nome*"
                  name="name"
                  placeholder="Digite o nome"
                  maxLength={30}
                />
                <div>
                  <InputMultiline
                    label="Descrição"
                    name="description"
                    placeholder="Digite a descritivo"
                    rows={5}
                    multiline={'multiline'}
                    onChange={(e) =>
                      form.change('description', String(e?.target?.value ?? "").slice(0, 300) )
                    }
                  />
                  <p className={classes.wordCounter}>{values.description ? values.description.length : 0}/300</p>
                </div>
                <span className={classes.modalButtons}>
                  <div className={classes.BoxSwitch}>
                    <Switch name="shouldBind" />
                    <p>Vincular ao agrupador</p>
                  </div>
                  <Button label="Fechar" variant="underline" onClick={onCloseModal} />
                  <Button label="Enviar" type="submit" />
                </span>
              </form>
            )}
          />
        </section>
      </NewBoxWidget>
    </div>
  )
}
