import axios, { tokenAuthorization } from 'src/utils/axios'
import {
  COMPETENCE_GROUPER,
  COMPETENCE_GROUPER_SEARCH,
  USER_GROUP,
  COMPETENCE_GROUPER_FROM_USER
} from 'src/services/api'

export async function getCompetenceGrouper({ id }) {
  const COMPETENCE_URL = id ? `${COMPETENCE_GROUPER}/${id}` : COMPETENCE_GROUPER
  const response = await axios.get(COMPETENCE_URL, tokenAuthorization())
  return response
}

export async function getUserGroups() {
  const response = await axios.get(USER_GROUP, tokenAuthorization())
  return response
}

export async function putCompetenceGrouper(body) {
  const { id } = body
  const token = tokenAuthorization().headers
  const parameters = {
    method: 'PUT',
    headers: token
  }
  const result = await axios.put(
    COMPETENCE_GROUPER + `/${id}`,
    body,
    parameters
  )
  return result
}

export async function postCompetenceGrouper(body) {
  const token = tokenAuthorization().headers
  const parameters = {
    method: 'POST',
    headers: token
  }
  const response = await axios.post(COMPETENCE_GROUPER, body, parameters)
  return response
}

export async function postCompetenceGrouperSearch(payload) {
  const response = await axios.post(
    COMPETENCE_GROUPER_SEARCH,
    payload,
    tokenAuthorization()
  )
  const { data } = response.data
  return data
}

export async function getCompetenceGrouperFromUser(id) {
  const URL = id
    ? `${COMPETENCE_GROUPER_FROM_USER}/${id}`
    : COMPETENCE_GROUPER_FROM_USER
  const response = await axios.get(URL, tokenAuthorization())
  const { data } = response?.data
  return data
}
