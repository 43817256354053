import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useTheme } from '@material-ui/styles'
import { Typography, Box, IconButton } from '@material-ui/core'
import {
  ArrowForwardIos,
  AssessmentOutlined as AssessmentOutlinedIcon
} from '@material-ui/icons'
import {
  Tooltip,
  MultiProgressBar,
  AccordionMeasurement,
  SimpleLineChart,
  BadgeTooltip,
  StarFavorite,
  ProgressBar
} from 'src/components'
import { Grid } from '@material-ui/core'
import { getMetricsUserGroups } from 'src/services/hooks/metrics/useMetrics'
import makeStyles from '../style'
import useAuth from 'src/hooks/useAuth'
import { messageResponse } from 'src/utils/messageResponse'
import { useAlertContext } from 'src/context/AlertContext'
import { useLoadingContext } from 'src/context/LoadingContext'
import { useSettingsContext } from 'src/context/SettingsContext'
import { ToolsHeader } from './ToolsHeader'
import useInput from 'src/hooks/useInput'
import { StyledSearch } from '..'
import { useCurrentPeriodContext } from 'src/context/CurrentPeriodContext'
import { getPayloadPeriod } from 'src/utils/getPayloadPeriod'

export function AvatarGroups({ data = [] }) {
  const classes = makeStyles()

  return (
    <Box className={classes.boxAvatarGroups}>
      {data.map((data) => (
        <Fragment key={data?.id}>
          <Box className={classes.avatar}>
            <BadgeTooltip
              placement="top"
              disableFocusListener
              disableTouchListener
              user={data}
            />
          </Box>
        </Fragment>
      ))}
    </Box>
  )
}

export function MetricsGroups({ setCurrentTab }) {
  const theme = useTheme()
  const classes = makeStyles()

  const { onCallAlert } = useAlertContext()
  const { onCloseLoading, onOpenLoading } = useLoadingContext()
  const { metricsGroupView } = useSettingsContext()
  const { currentPeriod } = useCurrentPeriodContext()

  const { user } = useAuth()
  const userId = user?.id

  const [controlTools, setControlTools] = useState({
    isOpenSearch: false,
    filterSelected: null
  })

  const [dataMetricsGroupUsers, setDataMetricsGroupUsers] = useState([])

  const { state: query, bindings, reset } = useInput()
  const metricsGroupUsers = dataMetricsGroupUsers.filter(
    ({ name }) => !query || name.toLowerCase().includes(query.toLowerCase())
  )

  const getListMetricsGroupUsers = useCallback(async () => {
    try {
      onOpenLoading()
      const formatedDatePayload = getPayloadPeriod(currentPeriod)
      const groupUsers = await getMetricsUserGroups({
        period: formatedDatePayload
      })
      setDataMetricsGroupUsers(groupUsers)
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      onCloseLoading()
    }
  }, [])

  useEffect(() => {
    getListMetricsGroupUsers()
  }, [getListMetricsGroupUsers])

  return (
    <>
      <ToolsHeader
        controlTools={controlTools}
        onChangeControlTools={setControlTools}
        tab="group"
        onChangeTab={setCurrentTab}
        onChangeDataMetrics={setDataMetricsGroupUsers}
        inputReset={reset}
        inputSearch={
          <StyledSearch
            color="success"
            id="search-collaborators"
            placeholder="Buscar..."
            style={{
              width: '100%',
              height: 44
            }}
            {...bindings}
          />
        }
      />
      <div className={classes.boxContent}>
        {(metricsGroupUsers ?? []).map((dataMetrics) => {
          const isExistRadarsCompetenceGrouper =
            dataMetrics?.radarsCompetenceGrouper &&
            dataMetrics?.radarsCompetenceGrouper.length > 0
          return (
            <Fragment key={dataMetrics?.id}>
              <div className={classes.boxContentAll}>
                <Box
                  className={
                    metricsGroupView === 'simplified'
                      ? classes.userContainerShadow
                      : classes.userContainer
                  }
                >
                  <Box className={classes.displayContent}>
                    <Box>
                      <Typography className={classes.name}>
                        {dataMetrics?.name}
                      </Typography>
                    </Box>
                    <Box className={classes.boxArrow}>
                      <StarFavorite
                        checked={dataMetrics?.isFavorite}
                        componentId={dataMetrics?.id}
                        userId={userId}
                        favoriteId={dataMetrics?.favoriteId}
                        className={classes.boxBtnOptions}
                        type="userGroup"
                        colorIcon={theme.palette.primary.main}
                      />
                      <Tooltip title="Ver grupo">
                        <IconButton
                          className={classes.boxBtnOptions}
                          href={`/metrics/group/${dataMetrics?.id}`}
                        >
                          <ArrowForwardIos
                            htmlColor={theme.palette.primary.main}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                  <AvatarGroups data={dataMetrics?.groupMembers} />
                  <div className={classes.containerColorMetrics}>
                    {dataMetrics?.averageMetricsByCategoryType && (
                      <MultiProgressBar
                        data={dataMetrics?.averageMetricsByCategoryType}
                        showLegend
                      />
                    )}
                  </div>
                </Box>
                {metricsGroupView === 'details' && (
                  <Box className={classes.containerScroll}>
                    <Box className={classes.containerTable}>
                      <Box className={classes.cardContainer}>
                        <p className={classes.titleLineChart}>Médias gerais</p>
                        <SimpleLineChart data={dataMetrics?.timeline} />
                      </Box>
                      <Box className={classes.cardContainer}>
                        <ProgressBar
                          data={dataMetrics?.averageMetricsByCategoryType}
                          overallMetric={dataMetrics?.overallMetric}
                          overallMetricGap={dataMetrics?.overallMetricGap}
                        />
                      </Box>
                      <Grid className={classes.cardContainer}>
                        {isExistRadarsCompetenceGrouper ? (
                          <Box mt={5} className={classes.boxDatasRadar}>
                            {(dataMetrics?.radarsCompetenceGrouper ?? [])?.map(
                              ({ category, competenceGroups }, index) => (
                                <Box key={index} mb={2}>
                                  <AccordionMeasurement
                                    categoryName={category}
                                    scoreAverage={competenceGroups}
                                    users={dataMetrics?.groupMembers}
                                    type="userGroup"
                                  />
                                </Box>
                              )
                            )}
                          </Box>
                        ) : (
                          <Box
                            item
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            textAlign="center"
                            height="100%"
                          >
                            <Box>
                              <AssessmentOutlinedIcon
                                className={classes.notFoundMetricsIcon}
                              />
                              <Typography
                                className={classes.notFoundMetricsTitle}
                              >
                                Nenhuma informação
                              </Typography>
                              <Typography
                                className={classes.notFoundMetricsDescription}
                              >
                                Não há avaliações para este grupo.
                              </Typography>
                            </Box>
                          </Box>
                        )}
                      </Grid>
                    </Box>
                  </Box>
                )}
              </div>
            </Fragment>
          )
        })}
      </div>
    </>
  )
}
