import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER || ''
})

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    )
)

export function tokenAuthorization() {
  const accessToken = localStorage.getItem('accessToken')
  return {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  }
}

export default axiosInstance
