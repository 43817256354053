import React from 'react'
import PropTypes from 'prop-types'
import { BadgeTooltip, Tooltip } from 'src/components'
import { IconButton } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { enumAssignment } from 'src/@enum/user'
import clsx from 'clsx'
import makeStyles from './style'

ListSelectedUsers.propTypes = {
  showMode: PropTypes.string,
  usersSelected: PropTypes.array,
  onRemoveSelectUser: PropTypes.func
}

export function ListSelectedUsers({
  showMode,
  onRemoveSelectUser,
  usersSelected
}) {
  const classes = makeStyles({ showMode })
  return (
    <div className={classes.containerList}>
      <div
        className={clsx(
          classes.containerListUsers,
          classes[
            showMode === 'column' ? 'containerListColumn' : 'containerListRow'
          ]
        )}
      >
        {showMode === 'row' && (
          <span className={classes.listTextFor}>Para: </span>
        )}
        <div className={clsx(classes.listUsers, 'listUsers')}>
          {usersSelected.map((itemUser, index) => (
            <div className={clsx(classes.userItem, 'userItem')} key={index}>
              <div className={clsx(classes.userInfos, 'userInfos')}>
                <button
                  disabled={showMode === 'column'}
                  className={clsx(classes.badgeTooltip, 'badgeTooltip')}
                  onClick={() => onRemoveSelectUser({ idUser: itemUser.id })}
                >
                  <BadgeTooltip
                    placement="top"
                    disableFocusListener
                    disableTouchListener
                    user={itemUser}
                  />
                </button>
                <div>
                  <p className={clsx(classes.userName, 'userName')}>
                    {itemUser.name}
                  </p>
                  {showMode === 'column' && (
                    <p className={classes.userOccupation}>
                      {itemUser?.occupationAreaName || itemUser?.occupationArea}{' '}
                      - {enumAssignment[itemUser?.assignment]}
                    </p>
                  )}
                </div>
              </div>
              <Tooltip
                placement="top"
                className="iconHandlerItem"
                title={itemUser.isSelected ? 'Remover' : 'Adicionar'}
              >
                <IconButton
                  className={clsx(classes.sizeButton, classes.iconButton)}
                  onClick={() => onRemoveSelectUser({ idUser: itemUser.id })}
                >
                  <CloseIcon
                    style={{
                      transform: `rotate(${
                        itemUser.isSelected ? '0deg' : '45deg'
                      })`,
                      transition: 'all .2s'
                    }}
                  />
                </IconButton>
              </Tooltip>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
