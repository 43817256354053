import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/styles'
import { Autocomplete } from '@material-ui/lab'
import { DefaultStyledInput } from './style'

function UseAutocomplete({
  name,
  label,
  options,
  value,
  inputProps,
  multiple,
  getOptionLabel,
  getOptionSelected,
  noOptionsText,
  ...props
}) {
  const theme = useTheme()

  function getDefaultOptionLabel(option) {
    return option.label || ''
  }

  function getDefaultOptionSelected(option, { value: selectedValue }) {
    return option.value === selectedValue
  }

  return (
    <Autocomplete
      primaryColor={theme.palette.primary.main}
      {...props}
      name={name}
      options={options}
      multiple={multiple}
      noOptionsText={noOptionsText ?? 'Sem opções'}
      getOptionLabel={getOptionLabel || getDefaultOptionLabel}
      getOptionSelected={getOptionSelected || getDefaultOptionSelected}
      value={
        multiple
          ? value || []
          : options.find((option) => option.value === value) || null
      }
      renderInput={(params) => (
        <DefaultStyledInput
          primaryColor={theme.palette.primary.main}
          {...params}
          {...inputProps}
          label={label}
          variant="outlined"
          fullWidth
          multiple={multiple}
        />
      )}
    />
  )
}

UseAutocomplete.propTypes = {
  multiple: PropTypes.bool,
  inputProps: PropTypes.object,
  noOptionsText: PropTypes.string,
  getOptionLabel: PropTypes.func,
  getOptionSelected: PropTypes.func,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.array
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array
      ])
    })
  )
}

export default UseAutocomplete
