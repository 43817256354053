import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Typography } from '@material-ui/core'
import { NotificationActivitiesModal, WithoutActivities } from 'src/components'
import clsx from 'clsx'
import makeStyles from './styles'

export function Notification({ data, userId }) {
  const classes = makeStyles()
  const navigate = useNavigate()

  const [isExpandNotification, setExpandNotification] = useState(false)
  const controlExpandNotification = isExpandNotification
    ? 'expandNotificationActive'
    : 'expandNotificationDisabled'
  const [isHistoryButton, setHistoryButton] = useState(false)
  const controlHistoryButton = isHistoryButton
    ? 'historyButtonActive'
    : 'historyButtonDisabled'
  const [isButtonSeeMore, setButtonSeeMore] = useState(false)
  const controlButtonSeeMore = isButtonSeeMore
    ? 'buttonSeeMoreActive'
    : 'buttonSeeMoreDisabled'

  const sholdRenderList = data.length > 0

  function onHandleOptionsProfile() {
    setExpandNotification(!isExpandNotification)
    setHistoryButton(!isHistoryButton)
    setButtonSeeMore(!isButtonSeeMore)
  }

  return (
    <Box className={classes.boxActivities}>
      <Typography className={classes.titleTop}>Atividades recentes</Typography>
      {sholdRenderList ? (
        <Box
          className={clsx(
            classes.scrollListNotificationActivities,
            classes[controlExpandNotification]
          )}
        >
          <Box className={classes.listNotificationActivities}>
            {data?.map(
              ({
                id,
                wasViewed,
                isActive,
                description,
                createAt,
                redirect,
                title
              }) => (
                <NotificationActivitiesModal
                  key={id}
                  notificationId={id}
                  title={title}
                  userId={userId}
                  infor={description}
                  time={createAt}
                  wasViewed={wasViewed}
                  isActive={isActive}
                  redirect={redirect}
                />
              )
            )}
          </Box>
        </Box>
      ) : (
        <WithoutActivities text="Você não tem atividades recentes." />
      )}
      {data?.length > 0 && (
        <>
          {data?.length >= 3 && (
            <>
              <button
                onClick={onHandleOptionsProfile}
                className={clsx(
                  classes.linkBottom,
                  classes[controlButtonSeeMore]
                )}
              >
                <p>Ver mais</p>
              </button>
              <button
                onClick={() => navigate('/notifications')}
                className={clsx(
                  classes.linkBottom,
                  classes[controlHistoryButton]
                )}
              >
                <Box>
                  <p>Ver histórico</p>
                </Box>
              </button>
            </>
          )}
          {data?.length < 3 && (
            <button
              onClick={() => navigate('/notifications')}
              className={clsx(classes.linkBottom)}
            >
              <div>
                <p>Ver histórico</p>
              </div>
            </button>
          )}
        </>
      )}
    </Box>
  )
}
export default Notification
