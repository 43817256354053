import { DEVELOPMENTPLAN, DEVELOPMENTPLAN_SEARCH } from 'src/services/api'
import axios, { tokenAuthorization } from 'src/utils/axios'

export async function getDevelopmentPlan() {
  const response = await axios.get(DEVELOPMENTPLAN, tokenAuthorization())
  const { data } = response.data
  return data
}

export async function getDevelopmentPlanById(id) {
  const response = await axios.get(
    DEVELOPMENTPLAN + `/${id}`,
    tokenAuthorization()
  )
  const { data } = response?.data
  return data
}

export async function postDevelopmentPlan(payload) {
  const response = await axios.post(
    DEVELOPMENTPLAN,
    payload,
    tokenAuthorization()
  )
  const { data } = response.data
  return data
}

export async function postDevelopmentPlanSearch(payload) {
  const response = await axios.post(
    DEVELOPMENTPLAN_SEARCH,
    payload,
    tokenAuthorization()
  )
  const { data } = response.data
  return data
}

export async function putDevelopmentPlan(id, payload) {
  const response = await axios.put(
    DEVELOPMENTPLAN + `/${id}`,
    payload,
    tokenAuthorization()
  )
  return response
}
