import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  formSwitch: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: 30
  },
  formInput2: {
    width: '100%'
  },
  formInput3: {
    width: '100%'
  },
  formInput4: {
    width: '100%'
  },
  containerForm: {
    paddingLeft: '32px',
    paddingRight: '32px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  formDoubleInputSmall: {
    display: 'flex',
    columnGap: '18px'
  },
  formInputSmall: {
    width: '100%'
  },
  formGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '15px 10px',
    position: 'relative'
  },
  formItemGroup: {
    border: '1px solid ',
    borderColor: theme.palette.primary.main,
    display: 'flex',
    padding: '10px 10px',
    columnGap: '10px',
    flexDirection: 'row',
    width: 'auto',
    borderRadius: '100px'
  },
  formItemText: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500,
    whiteSpace: 'nowrap'
  },
  formItemButton: {
    width: '24px',
    height: '24px',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFFFFF',
    border: 'none',
    fontWeight: 700,
    fontSize: '0.8rem',
    borderRadius: '50%',
    textAlign: 'center',
    cursor: 'pointer'
  },
  formFooter: {
    display: 'flex',
    justifyContent: 'end',
    width: '100%',
    minHeight: '95px',
    alignItems: 'end',
    paddingBottom: '12px'
  },
  formControlButton: {
    display: 'flex',
    columnGap: '20px',
    height: '48.47px'
  },
  Container: {
    height: '100%',
    padding: '20px 30px 30px 30px',
    overflow: 'hidden'
  },
  viewBtnGroupsOpen: {
    border: '1px solid #c3c3c3',
    color: '#787878'
  },
  viewBtnGroupsClose: {
    border: '1px solid ',
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main
  },
  viewBtnGroupsOpenDelete: {
    display: 'none'
  },
  viewBtnGroupsCloseDelete: {
    display: 'flex'
  },
  boxInforUser: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  boxInforUserRight: {
    minWidth: '300px',
    position: 'relative',
    backgroundColor: '#FFFFFF',
    padding: '10px 0 0 0',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '20px',
    marginTop: '30px',
    transition: 'all .3s'
  },

  containerGroupsAndObservation: {
    width: '100%',
    display: 'flex'
  },
  photoContainer: {
    position: 'relative',
    maxWidth: '900px',
    minHeight: '520px',
    backgroundColor: '#FFFFFF',
    padding: '10px 0 0 0',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '20px',
    marginTop: '20px',
    paddingTop: 20,
    paddingBottom: 15,
    transition: 'all .3s'
  },
  containerGroups: {
    fontSize: theme.typography.pxToRem(14),
    color: '#000',
    width: '100%',
    marginTop: '-10px',
    '& p': {
      margin: '-5px 0px 5px 0px',
      padding: '0px'
    }
  },
  containerObservation: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  groups: {
    width: '100%',
    display: 'flex',
    columnGap: 15
  },
  groupsTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#000000',
    marginBottom: theme.spacing(1)
  }
}))
