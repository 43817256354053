import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  modalButtons: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    columnGap: '15px'
  },
  contentChildrens: {
    marginTop: '20px',
    maxHeight: '400px',
    boxSizing: 'border-box',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    rowGap: 20,
    borderTopStyle: 'solid',
    borderBottomStyle: 'solid',
    borderTopWidth: '1px',
    borderBottomWidth: '1px',
    padding: '10px',
    borderTopColor: theme.palette.primary.main,
    borderBottomColor: theme.palette.primary.main,
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: '10px'
    }
  },
  name: {
    fontSize: theme.typography.pxToRem(14),
    padding: '10px 0'
  }
}))
