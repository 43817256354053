import { ONETOONE, ONETOONE_SEARCH } from 'src/services/api'
import axios, { tokenAuthorization } from 'src/utils/axios'

export async function getOneToOneById(id) {
  const response = await axios.get(ONETOONE + `/${id}`, tokenAuthorization())
  const { data } = response?.data
  return data
}

export async function postOneToOne(payload) {
  const response = await axios.post(ONETOONE, payload, tokenAuthorization())
  const { data } = response.data
  return data
}

export async function putOneToOne(id, payload) {
  const response = await axios.put(
    ONETOONE + `/${id}`,
    payload,
    tokenAuthorization()
  )
  return response
}

export async function postOneToOneSearch(payload) {
  const response = await axios.post(
    ONETOONE_SEARCH,
    payload,
    tokenAuthorization()
  )
  const { data } = response.data
  return data
}
