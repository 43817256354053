import React from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import GuestGuard from 'src/guards/GuestGuard'
import AuthGuard from 'src/guards/AuthGuard'
import Login from 'src/screens/Auth/Login'
import ForgotPassword from 'src/screens/Auth/ForgotPassword'
import Token from 'src/screens/Auth/Token'

import DashboardLayout from 'src/layouts/Dashboard'

import Home from './Navigations/home'
import Profile from './Navigations/profile'
import CustomersRoutes from './Navigations/customers'
import UsersRoutes from './Navigations/users'
import UserGroupsRoutes from './Navigations/userGroups'
import EvaluationCyclesRoutes from './Navigations/evaluationCycle'
import CompetenceGrouper from './Navigations/competenceGrouper'
import Metrics from './Navigations/metrics'
import CompetencyGrades from './Navigations/competencyGrades'
import Notifications from './Navigations/notifications'
import DevelopmentPlan from './Navigations/developmentPlan'
import Impressions from './Navigations/impressions'

export default function Router() {
  const isAuth =
    localStorage.getItem('accessToken') && localStorage.getItem('refreshToken')

  const redirectTo = isAuth ? '/home' : '/'

  const allRoutes = [
    Home,
    Profile,
    Metrics,
    CustomersRoutes,
    UsersRoutes,
    UserGroupsRoutes,
    CompetenceGrouper,
    EvaluationCyclesRoutes,
    CompetencyGrades,
    Notifications,
    DevelopmentPlan,
    Impressions
  ]

  return useRoutes([
    {
      path: '/',
      children: [
        {
          path: '/',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: '/esqueci-senha/:email?',
          element: <ForgotPassword />
        },
        {
          path: 'token',
          element: <Token />
        }
      ]
    },

    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: allRoutes.flat()
    },

    { path: '*', element: <Navigate to={redirectTo} replace /> }
  ])
}
