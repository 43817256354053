import React, { useState, useCallback, useEffect } from 'react'
import useAuth from 'src/hooks/useAuth'
import RoleBasedGuard from 'src/guards/RoleBasedGuard'
import useGlobalUseStyles from 'src/themes/globalUseStyles'
import { useTheme } from '@material-ui/styles'
import { Form } from 'react-final-form'
import { useLoadingContext } from 'src/context/LoadingContext'
import { useAlertContext } from 'src/context/AlertContext'
import { messageResponse } from 'src/utils/messageResponse'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { GroupOutlined as GroupOutlinedIcon } from '@material-ui/icons'
import {
  Box,
  Table,
  TableRow,
  TableBody,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { Switch } from '@material-ui/core'
import {
  Button,
  NewBoxWidget,
  HeaderBreadcrumbs,
  TableHead,
  TableToolbar,
  TableLoading,
  TableNotFound,
  Helmet
} from 'src/components'
import { Input, MuiSwitch } from 'src/components/Fields'
import { newUserGroupValidation } from 'src/utils/form/validations'
import { getUsers } from 'src/services/hooks/users/useUsers'
import { postUserGroups } from 'src/services/hooks/userGroup/useUserGroup'
import { enumAssignment } from 'src/@enum/user'
import { getComparator, applySortFilter } from 'src/utils'
import { maskCPF } from 'src/utils/regex/masks'
import makeStyles, { MuiCheckbox } from './style'

const TABLE_HEAD = [
  { id: 'name', label: 'Nome', alignRight: false },
  { id: 'email', label: 'E-mail', alignRight: false },
  { id: 'document', label: 'CPF', alignRight: false },
  { id: 'occupationArea', label: 'Área de atuação', alignRight: false },
  { id: 'assignment', label: 'Experiência', alignRight: false },
  { id: 'leaders', label: 'Líder', alignRight: false },
  { id: 'groups', label: 'No grupo', alignRight: false }
]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottom: 'none',
    padding: '4px 4px 4px 16px'
  }
}))

function NewGroups() {
  const theme = useTheme()
  const classes = makeStyles()
  const globalClasses = useGlobalUseStyles()

  const navigate = useNavigate()

  const [role] = useState([1, 2])
  const { user } = useAuth()
  const currentCustomerId = user?.customerId

  const { notifications, onCallAlert } = useAlertContext()
  const { onCloseLoading, onOpenLoading } = useLoadingContext()

  const [data, setData] = useState([])
  const [name, setName] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [checkedStatus, setCheckedStatus] = useState(true)

  const [page, setPage] = useState(0)
  const [order, setOrder] = useState('asc')
  const [selectedLeader, setSelectedLeader] = useState([])
  const [selectedGroup, setSelectedGroup] = useState([])
  const [orderBy, setOrderBy] = useState('name')
  const [filterName, setFilterName] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleClickUserLeader = (event, id) => {
    const selectedIndex = selectedLeader.indexOf(id)
    let selected = []
    if (selectedIndex === -1) {
      selected = selected.concat(selectedLeader, id)
    } else if (selectedIndex === 0) {
      selected = selected.concat(selectedLeader.slice(1))
    } else if (selectedIndex === selectedLeader.length - 1) {
      selected = selected.concat(selectedLeader.slice(0, -1))
    } else if (selectedIndex > 0) {
      selected = selected.concat(
        selectedLeader.slice(0, selectedIndex),
        selectedLeader.slice(selectedIndex + 1)
      )
    }
    setSelectedLeader(selected)
  }

  const handleClickUserGroup = (event, id) => {
    const selectedIndex = selectedGroup.indexOf(id)
    let selected = []
    if (selectedIndex === -1) {
      selected = selected.concat(selectedGroup, id)
    } else if (selectedIndex === 0) {
      selected = selected.concat(selectedGroup.slice(1))
    } else if (selectedIndex === selectedGroup.length - 1) {
      selected = selected.concat(selectedGroup.slice(0, -1))
    } else if (selectedIndex > 0) {
      selected = selected.concat(
        selectedGroup.slice(0, selectedIndex),
        selectedGroup.slice(selectedIndex + 1)
      )
    }
    setSelectedGroup(selected)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setPage(0)
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  const handleFilterByName = (event) => {
    setPage(0)
    setFilterName(event.target.value)
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0

  const filteredData = applySortFilter(
    data,
    getComparator(order, orderBy),
    filterName
  )

  const isNotFound = !filteredData?.length && !!filterName && data

  const getListUsers = useCallback(async () => {
    try {
      setIsLoading(true)
      const response = await getUsers()
      setData(response)
      setIsLoading(false)
    } catch (e) {
      console.error(e)
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    getListUsers()
  }, [getListUsers])

  const onSubmit = async (values) => {
    try {
      if (!name) {
        onCallAlert({
          type: 'error',
          message: 'Adicione um nome ao grupo para prosseguir'
        })
        return
      }

      if (name.length < 4) {
        onCallAlert({
          type: 'error',
          message: 'O nome do grupo deve ter ao menos 4  caracteres!'
        })
        return
      }

      if (checkedStatus) {
        if (!selectedGroup.length || !selectedLeader.length) {
          onCallAlert({
            type: 'error',
            message: 'Selecione ao menos um membro e um líder'
          })
          return
        }
      }

      const leaderHasNoGroup = selectedLeader.filter(
        (item) => !selectedGroup.includes(item)
      )
      if (leaderHasNoGroup.length) {
        onCallAlert({
          type: 'error',
          message: 'Você só pode atribuir papel de líder para membros do grupo'
        })
        return
      }

      const payload = {
        name: name,
        customerId: currentCustomerId,
        leaders: selectedLeader,
        members: selectedGroup,
        groupPermissions: [],
        isActive: checkedStatus
      }

      onOpenLoading()
      const create = await postUserGroups(payload)
      onCallAlert({
        type: 'success',
        message: notifications.userGroups.success.addNewGroup
      })
      return navigate('/groups/view/' + create.id)
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      onCloseLoading()
    }
  }

  const handleChangeStatus = (event) => {
    setCheckedStatus(event.target.checked)
  }

  return (
    <RoleBasedGuard hasContent roles={role}>
      <Helmet title="Novo grupo" />
      <Box className={globalClasses.breadcrumb}>
        <HeaderBreadcrumbs
          icon={<GroupOutlinedIcon />}
          links={[
            { name: 'Grupos de usuários', href: '/groups' },
            { name: 'Novo grupo' }
          ]}
        />
      </Box>
      <Box className={globalClasses.content}>
        <NewBoxWidget
          header={
            <>
              <div className={classes.inputName}>
                <Form
                  onSubmit={(values) => onSubmit(values)}
                  initialValues={{
                    name: ''
                  }}
                  validate={newUserGroupValidation}
                  render={({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                      <Input
                        name="name"
                        label="Adicionar nome ao novo grupo"
                        onChange={(e) => {
                          form.change('name', e?.target?.value)
                          setName(e?.target?.value)
                        }}
                        maxLength={150}
                      />
                    </form>
                  )}
                />
              </div>
              <p className={classes.pTitle}>
                Adicione os membros do seu novo grupo:
              </p>
            </>
          }
          options={
            <TableToolbar
              numSelected={selectedGroup.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              options={
                <>
                  <Switch
                    name="isActive"
                    color="primary"
                    checked={checkedStatus}
                    onChange={handleChangeStatus}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <Typography variant="inherit">Status do grupo</Typography>
                </>
              }
            />
          }
          actions={
            <Box className={classes.actions}>
              <Button
                variant="contained"
                label="Criar grupo"
                onClick={onSubmit}
                disabled={!name}
              />
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                labelRowsPerPage={'Registros por página'}
                component="div"
                count={data?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          }
          borderActions
          noBodyPadding
          fullBody
        >
          <TableContainer
            sx={{
              maxHeight: '100%',
              '&::-webkit-scrollbar': {
                height: '4px',
                width: '4px',
                borderRadius: '10px'
              },
              '&::-webkit-scrollbar-track': {
                background: '#CFD2D3',
                borderRadius: '10px'
              },
              '&::-webkit-scrollbar-thumb': {
                background: theme.palette.primary.main,
                borderRadius: '10px'
              }
            }}
          >
            <Table stickyHeader>
              <TableHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={data?.length}
                numSelected={selectedGroup.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {filteredData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row) => {
                    const {
                      id,
                      name,
                      email,
                      document,
                      occupationArea,
                      assignment,
                      occupation
                    } = row
                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <StyledTableCell align="left">{name}</StyledTableCell>
                        <StyledTableCell align="left">{email}</StyledTableCell>
                        <StyledTableCell align="left">
                          {maskCPF(document) ?? 'Não informado'}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {occupationArea}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {enumAssignment[assignment]}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {occupation === 3 && (
                            <MuiSwitch
                              onChange={(event) =>
                                handleClickUserLeader(event, id)
                              }
                              inputProps={{ 'aria-label': 'controlled' }}
                              color="default"
                            />
                          )}
                        </StyledTableCell>
                        <StyledTableCell width="10%">
                          <MuiCheckbox
                            color="default"
                            onChange={(event) =>
                              handleClickUserGroup(event, id)
                            }
                          />
                        </StyledTableCell>
                      </TableRow>
                    )
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell
                      colSpan={12}
                      style={{ height: 53 * emptyRows, border: 'none' }}
                    />
                  </TableRow>
                )}
              </TableBody>
              {isLoading && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={12}
                      sx={{
                        border: 'none',
                        height: 'calc(100vh - 405px)'
                      }}
                    >
                      <div className={classes.boxInfor}>
                        <TableLoading />
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              {data.length === 0 && !isLoading && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={12}
                      sx={{
                        border: 'none',
                        height: 'calc(100vh - 405px)'
                      }}
                    >
                      <div className={classes.boxInfor}>
                        <TableNotFound />
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={12}
                      sx={{
                        border: 'none',
                        height: 'calc(100vh - 405px)'
                      }}
                    >
                      <Typography variant="h6" paragraph>
                        Nenhum registro encontrado!
                      </Typography>

                      <Typography variant="body2">
                        Nenhum resultado encontrado para &nbsp;
                        <strong>&quot;{filterName}&quot;</strong>.
                        <br /> Tente verificar erros de digitação.
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </NewBoxWidget>
      </Box>
    </RoleBasedGuard>
  )
}

export default NewGroups
