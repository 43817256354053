import React, { useState } from 'react'
import { useTheme } from '@material-ui/styles'
import {
  NewBoxWidget,
  TableHead,
  TableToolbar,
  TableLoading,
  TableNotFound,
  Button,
  Tooltip
} from 'src/components'
import {
  Box,
  Table,
  TableRow,
  TableBody,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material'
import { Button as ButtonUi } from '@material-ui/core'
import { getComparator, applySortFilter } from 'src/utils'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'
import makeStyles from './style'
import { Form } from 'react-final-form'
import { MuiSwitch, Input, InputMultiline } from 'src/components/Fields'

import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined'

import { formCompetence } from 'src/utils/form/validations'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottom: 'none',
    padding: '16px'
  }
}))

export function TabViewCompetences({
  data,
  valuesForm = [],
  onChangeCompetences,
  onChangeValue,
  onCloseModal,
  onHandleSelectToShelve,
  onEditCompetence,
  isOpenEditCompetences,
  setIsOpenEditCompetences
}) {
  const theme = useTheme()
  const classes = makeStyles()
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const [filterName, setFilterName] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [isLoading, setIsLoading] = useState(false)

  const [dataEditCompetence, setDataEditCompetence] = useState({
    id: "",
    name: "",
    description: ""
  })

  function onHandleOpenEdit({
    id = "",
    name = "",
    description = "",
  }) {
    setDataEditCompetence({
      id,
      name,
      description: description !== null ? description : "",
    })
    setIsOpenEditCompetences(true)
  }

  function onHandleSubmit({ name, description }) {
    onEditCompetence({id: dataEditCompetence.id, name, description})
  }

  const TABLE_HEAD = [
    { id: 'name', label: 'Nome', alignRight: false },
    { id: 'description', label: 'Descrição', alignRight: false },
    { id: 'action', label: '', alignRight: false },
    { id: 'added', label: 'Adicionada', alignRight: false }
  ]

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event) => {
    setPage(0)
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  const handleFilterByName = (event) => {
    setPage(0)
    setFilterName(event.target.value)
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0

  const filteredData = applySortFilter(
    data,
    getComparator(order, orderBy),
    filterName
  )

  const isNotFound = !filteredData?.length && !!filterName && data

  return (
    <div className={classes.containerTab}>
      <NewBoxWidget
        borderActions
        style={{ boxShadow: 'none', background: 'transparent' }}
        noBodyPadding
        width={'100%'}
        minHeight="75vh"
        options={
          <TableToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
          />
        }
        actions={
          <Box className={classes.actions}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              labelRowsPerPage={'Exibir'}
              component="div"
              count={data?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <Button label="Fechar" onClick={onCloseModal} />
          </Box>
        }
      >
        <TableContainer
          sx={{
            maxHeight: '60vh',
            overflow: 'inherit',
            '&::-webkit-scrollbar': {
              height: '4px',
              width: '4px',
              borderRadius: '10px'
            },
            '&::-webkit-scrollbar-track': {
              background: '#CFD2D3',
              borderRadius: '10px'
            },
            '&::-webkit-scrollbar-thumb': {
              background: `${theme.palette.primary.main}`,
              borderRadius: '10px'
            }
          }}
        >
          <Table stickyHeader>
            <TableHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={data?.length}
              onRequestSort={handleRequestSort}
            />

            {/*-------Sesão para editar agrupador--------*/}
            {isOpenEditCompetences ? (
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={12}
                    sx={{
                      border: 'none',
                      height: 'calc(50vh - 220px)'
                    }}
                  >
                    <div className={classes.boxEdit}>
                      <Form
                        onSubmit={(values) =>
                          onHandleSubmit({
                            name: values.name,
                            description: values.description
                          })
                        }
                        validate={formCompetence}
                        initialValues={{
                          id: dataEditCompetence?.id,
                          name: dataEditCompetence?.name,
                          description: dataEditCompetence?.description
                        }}
                        render={({ handleSubmit, form, values, pristine }) => (
                          <form
                            onSubmit={handleSubmit}
                            className={classes.boxForm}
                          >
                            <p className={classes.textTitleFormEdit}>Editar:</p>
                            <Input
                              label="Nome*"
                              name="name"
                              placeholder="Digite o nome"
                              maxLength={150}
                            />
                            <div>
                              <InputMultiline
                                label="Descrição"
                                name="description"
                                placeholder="Digite a descritivo"
                                rows={3}
                                multiline={'multiline'}
                                onChange={(e) =>
                                  form.change('description', String(e?.target?.value ?? "").slice(0, 300) )
                                }
                              />
                              <p className={classes.wordCounter}>{values.description ? values.description.length : 0}/300</p>
                            </div>
                            <span className={classes.modalButtons}>
                              <Button
                                label="Cancelar"
                                variant="underline"
                                onClick={() => setIsOpenEditCompetences(false)}
                              />
                              <Button label="Enviar" disabled={pristine} type="submit" />
                            </span>
                          </form>
                        )}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {filteredData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row) => {
                    const { id, name: labelName, description } = row
                    const isAdded = valuesForm.includes(id)
                    return (
                      <>
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          style={{ cursor: 'pointer' }}
                          className={classes.competenceItem}
                        >
                          <StyledTableCell
                            align="left"
                            className={classes.columnName}
                          >
                            <div className={classes.competenceOptions}>
                              <div className={classes.boxOptionsEdit}>
                                <div className={classes.editBadge}></div>
                                <Tooltip title={'Editar competência'}>
                                  <button
                                    className={classes.editButton}
                                    onClick={() => onHandleOpenEdit({
                                      id: id,
                                      name: labelName,
                                      description: description,
                                    })}
                                  >
                                    <CreateOutlinedIcon
                                      sx={{ fontSize: 16, color: '#ffffff' }}
                                    />
                                  </button>
                                </Tooltip>
                              </div>
                              {labelName}
                            </div>
                          </StyledTableCell>

                          <StyledTableCell
                            align="left"
                            className={classes.columnDescription}
                          >
                            {description}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <div className={classes.competenceOptions}>
                              <ButtonUi
                                className={classes.competenceButton}
                                onClick={() =>
                                  onHandleSelectToShelve({ id, labelName })
                                }
                              >
                                Arquivar
                              </ButtonUi>
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            className={classes.columnActionShelve}
                          >
                            <MuiSwitch
                              checked={isAdded}
                              onChange={() =>
                                onChangeValue(
                                  onChangeCompetences({
                                    idCompetence: id
                                  }) ?? []
                                )
                              }
                            />
                          </StyledTableCell>
                        </TableRow>
                      </>
                    )
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell
                      colSpan={12}
                      style={{ height: 53 * emptyRows, border: 'none' }}
                    />
                  </TableRow>
                )}
              </TableBody>
            )}

            {isLoading && (
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={12}
                    sx={{
                      border: 'none',
                      height: 'calc(50vh - 220px)'
                    }}
                  >
                    <TableLoading />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            {data.length === 0 && !isLoading && (
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={12}
                    sx={{ border: 'none' }}
                  >
                    <TableNotFound />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            {isNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={12}
                    sx={{ py: 10, border: 'none' }}
                  >
                    <Typography variant="h6" paragraph>
                      Nenhum registro encontrado!
                    </Typography>

                    <Typography variant="body2">
                      Nenhum resultado encontrado para &nbsp;
                      <strong>&quot;{filterName}&quot;</strong>.
                      <br /> Tente verificar erros de digitação.
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </NewBoxWidget>
    </div>
  )
}
