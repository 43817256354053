import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px',
    padding: '10px 30px 30px 30px',
    marginRight: '10px',
    overflowX: 'hidden',
    height: '100%'
  },
  boxContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '10px 30px 30px 30px',
    marginRight: '10px',
    overflowX: 'hidden',
    height: '100%'
  },
  content: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    background: '#ffffff',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
    padding: '20px',
    marginRight: '10px',
    overflowX: 'hidden',
    height: 'fit-content'
  },
  headerInformations: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',

    marginBottom: 10
  },
  btnClose: {
    marginRight: -10,
    marginTop: -10
  },
  boxFormTime: {
    display: 'flex',
    gap: 10
  },
  boxForm: {
    display: 'flex',
    gap: 30,
    flexDirection: 'column',
    padding: '15px 0px 0px 0px'
  },
  formButtons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center'
  },
  labelRequired: {
    color: '#6E7191',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(15)
  },
  boxScroll: {
    width: 'calc(100% + 40px)',
    marginLeft: -20,
    maxHeight: 'calc(100vh - 305px)',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px 10px 5px 0px',
    overflowX: 'hidden',
    overflowY: 'scroll',
    gap: 20,
    padding: '0px 20px 0px 20px',
    justifyContent: 'space-between',
    '&::-webkit-scrollbar': {
      width: 4,
      marginRight: -30
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: 10
    }
  },
  breadcrumb: {
    padding: '0px 20px 0px 0px'
  },
  boxInforUser: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    backgroundColor: '#F7F7FC',
    padding: 10,
    borderRadius: 10,
    marginBottom: 30,
    marginTop: -20,
    fontSize: 14
  },
  boxContentBtn: {
    background: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    boxShadow: '0px 0px 6px -3px #3e3e3e',
    borderRadius: 50,
    border: 'none',
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  boxBtnNavegation: {
    gap: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))
