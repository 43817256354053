import React from 'react'
import { Helmet as HelmetBase } from 'react-helmet-async'
import { PREFIX_TITLE_PAGE } from 'src/utils/constants'

export function Helmet({ title }) {
  const customer = JSON.parse(localStorage.getItem('customer'))
  const favicon = customer?.favicon

  return (
    <HelmetBase>
      {favicon ? (
        <link rel="icon" href={favicon} type="image/png" />
      ) : (
        <>
          <link
            rel="icon"
            href="./img/favicon/favicon.ico"
            sizes="16x16"
            type="image/png"
          />
          <link
            rel="icon"
            href="./img/favicon/favicon-16x16.png"
            sizes="16x16"
            type="image/png"
          />
          <link
            rel="icon"
            href="./img/favicon/favicon-32x32.png"
            sizes="32x32"
            type="image/png"
          />
          <link
            rel="icon"
            href="./img/favicon/favicon-96x96.png"
            sizes="32x32"
            type="image/png"
          />
        </>
      )}
      <title>{`${PREFIX_TITLE_PAGE + title}`}</title>
    </HelmetBase>
  )
}
