import React from 'react'
import classnames from 'classnames'
import { Box, Typography } from '@material-ui/core'
import { Modal as ModalUi } from '@mui/material'
import useStyles from './styles'

export function ModalViewPDF({
  children,
  title,
  subtitle,
  header,
  headerIcon,
  noBodyPadding,
  options,
  actions,
  borderActions,
  width,
  height,
  fullBody,
  isOpen,
  onHandleClose
}) {
  const classes = useStyles({
    fullBody,
    width,
    height,
    borderActions
  })

  return (
    <ModalUi open={isOpen} onClose={onHandleClose}>
      <Box className={classes.container}>
        {(title || header) && (
          <Box className={classes.header}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                {headerIcon && (
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.headerIconSpacing}
                  >
                    {headerIcon}
                  </Box>
                )}
                {title && (
                  <Box className={classes.headerSpacing}>
                    <Typography className={classes.title} noWrap>
                      {title}
                    </Typography>
                    <Typography className={classes.subtitle}>
                      {subtitle}
                    </Typography>
                  </Box>
                )}
                {header}
              </Box>

              {options && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  className={classes.options}
                >
                  {options}
                </Box>
              )}
            </Box>
          </Box>
        )}
        <Box
          className={classnames(classes.content, {
            [classes.noPadding]: noBodyPadding
          })}
        >
          {children}
        </Box>
        {actions && (
          <Box className={classes.footer}>
            <Box className={classes.actions}>{actions}</Box>
          </Box>
        )}
      </Box>
    </ModalUi>
  )
}
