import React, { useState, useCallback, useEffect } from 'react'
import useAuth from 'src/hooks/useAuth'
import { useTheme } from '@material-ui/styles'
import { Box, FormControlLabel, Typography } from '@material-ui/core'
import { Accordion, AccordionSummary } from '@mui/material'
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'
import { ProgressChips } from 'src/components'
import { useAlertContext } from 'src/context/AlertContext'
import { messageResponse } from 'src/utils/messageResponse'
import useStyles, { MuiCheckbox } from './style'

export function CardGroupCompetences({
  dataGrouperFromUser,
  userRole,
  payloadCompetencies,
  setPayloadCompetencies,
  payloadCompetenciesId,
  setPayloadCompetenciesId
}) {
  const theme = useTheme()
  const classes = useStyles()

  const { onCallAlert } = useAlertContext()

  const { user } = useAuth()

  const currentCustomerId = user?.customerId

  const [isLoading, setIsLoading] = useState(false)

  const getData = useCallback(async (data) => {
    try {
      setIsLoading(true)
      setPayloadCompetencies(data)
      setIsLoading(false)
    } catch (error) {
      onCallAlert({
        type: 'error',
        message: messageResponse(error?.response)
      })
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    if (!payloadCompetencies.length) {
      dataGrouperFromUser.length && getData(dataGrouperFromUser)
    }
  }, [
    currentCustomerId,
    dataGrouperFromUser,
    getData,
    payloadCompetencies.length
  ])

  const dataNotSelected =
    !payloadCompetenciesId.length &&
    !!payloadCompetenciesId &&
    payloadCompetencies &&
    'Nenhum selecionado'

  const dataSelected =
    payloadCompetenciesId.length > 1
      ? `${payloadCompetenciesId.length} selecionados`
      : `${payloadCompetenciesId.length} selecionado`

  const handleSelectCompetence = (event, id) => {
    const selectedIndex = payloadCompetenciesId.indexOf(id)
    let selected = []
    if (selectedIndex === -1) {
      selected = selected.concat(payloadCompetenciesId, id)
    } else if (selectedIndex === 0) {
      selected = selected.concat(payloadCompetenciesId.slice(1))
    } else if (selectedIndex === payloadCompetenciesId.length - 1) {
      selected = selected.concat(payloadCompetenciesId.slice(0, -1))
    } else if (selectedIndex > 0) {
      selected = selected.concat(
        payloadCompetenciesId.slice(0, selectedIndex),
        payloadCompetenciesId.slice(selectedIndex + 1)
      )
    }
    setPayloadCompetenciesId(selected)
  }

  return (
    <Box className={classes.container}>
      {!isLoading && payloadCompetencies.length > 0 && (
        <>
          <Box className={classes.boxContainerOfCollapse}>
            <Box className={classes.containerOfCollapse}>
              {payloadCompetencies.map(({ category, groupers }, idx) => (
                <Accordion
                  key={idx}
                  sx={{
                    boxShadow: '0px 0px 0px !important',
                    margin: '0px 0px 3px 0px !important',
                    '&:before': {
                      display: 'none'
                    }
                  }}
                >
                  <AccordionSummary
                    sx={{
                      height: 52,
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: '#F7F7FC',
                      borderBottom: 0
                    }}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Box className={classes.btnExpand}>
                      <Typography className={classes.accordionTitlesCategory}>
                        {category}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  {groupers?.map(
                    ({ id, name, namedCompetencies }, idx_grouper) => (
                      <>
                        <Box className={classes.containerOfGrouper} key={id}>
                          <Typography
                            className={classes.accordionTitlesGrouper}
                          >
                            {name}
                          </Typography>
                        </Box>
                        {namedCompetencies?.map((item, idx_competencies) => (
                          <Box
                            className={classes.containerOfCompetence}
                            key={idx_competencies}
                          >
                            <FormControlLabel
                              control={
                                <MuiCheckbox
                                  value={item.id}
                                  checked={
                                    !!(payloadCompetenciesId ?? []).filter(
                                      (value) => value === item.id
                                    )[0]
                                  }
                                  style={{ color: theme.palette.primary.main }}
                                  onChange={(event) =>
                                    handleSelectCompetence(event, item.id)
                                  }
                                />
                              }
                              label={
                                <Typography
                                  className={classes.competenceTitles}
                                >
                                  {item.name}
                                </Typography>
                              }
                            />
                          </Box>
                        ))}
                      </>
                    )
                  )}
                </Accordion>
              ))}
            </Box>
            <Box className={classes.footerCollapse}>
              <Typography>{dataNotSelected || dataSelected}</Typography>
            </Box>
          </Box>
          <ProgressChips
            customerId={currentCustomerId}
            userRole={userRole}
            dataCompetencies={payloadCompetenciesId}
            dataGrouperFromUser={dataGrouperFromUser}
          />
        </>
      )}
    </Box>
  )
}
