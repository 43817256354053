export const dataPermissions = [
  {
    id: 1,
    name: '1:1s - Editar'
  },
  {
    id: 2,
    name: '1:1s - Visualizar'
  },
  {
    id: 3,
    name: 'Administrativo - Editar'
  },
  {
    id: 4,
    name: 'Administrativo - Visualizar'
  },
  {
    id: 5,
    name: 'Celebrações - Editar'
  },
  {
    id: 6,
    name: 'Celebrações - Visualizar'
  },
  {
    id: 7,
    name: 'Customização de Tema - Editar'
  },
  {
    id: 8,
    name: 'Customização de Tema - Visualizar'
  },
  {
    id: 9,
    name: 'Feedbacks - Editar'
  },
  {
    id: 10,
    name: 'Feedbacks - Visualizar'
  },
  {
    id: 11,
    name: 'Impressões - Editar'
  },
  {
    id: 12,
    name: 'Impressões - Visualizar'
  },
  {
    id: 13,
    name: 'Períodos - Editar'
  },
  {
    id: 14,
    name: 'Períodos - Visualizar'
  },
  {
    id: 15,
    name: 'PDIs - Editar'
  },
  {
    id: 16,
    name: 'PDIs - Visualizar'
  }
]
