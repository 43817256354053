import React, { useState, useEffect, useCallback } from 'react'
import RoleBasedGuard from 'src/guards/RoleBasedGuard'
import useAuth from 'src/hooks/useAuth'
import { useTheme } from '@material-ui/styles'
import 'moment/locale/pt-br'
import makeStyles, { MuiCheckbox } from './styles'
import FormControlLabel from '@mui/material/FormControlLabel'
import { styled } from '@mui/material/styles'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import useGlobalUseStyles from 'src/themes/globalUseStyles'
import { Form } from 'react-final-form'
import { useAlertContext } from 'src/context/AlertContext'
import { useLoadingContext } from 'src/context/LoadingContext'
import { messageResponse } from 'src/utils/messageResponse'
import { Typography, Box, Grid, Tabs, Tab } from '@material-ui/core'
import { Autocomplete, Input, Date } from 'src/components/Fields'
import { evaluationCyclesValidation } from 'src/utils/form/validations'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import {
  getIntervalType,
  getRepeatOnType,
  postCycleSettingSearch,
  postCycleSetting,
  putCycleSetting
} from 'src/services/hooks/cycleSetting/useCycleSetting'
import {
  enumCycleSettingIntervalType,
  enumCycleSettingRepeatOnType
} from 'src/@enum/cycleSetting'
import {
  Button,
  HeaderBreadcrumbs,
  NewBoxWidget,
  BoxHero,
  Helmet
} from 'src/components'
import { TodayOutlined as TodayOutlinedIcon } from '@material-ui/icons'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function EvaluationCycles() {
  const theme = useTheme()
  const classes = makeStyles()
  const globalClasses = useGlobalUseStyles()

  const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />
      }}
    />
  ))({
    borderBottom: '1px solid',
    borderColor: theme.palette.primary.main,
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'left',
      backgroundColor: 'transparent'
    },
    '& .MuiTabs-indicatorSpan': {
      width: '100%',
      backgroundColor: theme.palette.primary.main
    },
    '& .MuiTab-wrapper': {
      color: '#000000'
    }
  })
  const [role] = useState([1, 2])

  const { user } = useAuth()

  const currentCustomerId = user?.customerId

  const { notifications, onCallAlert } = useAlertContext()
  const { onCloseLoading, onOpenLoading } = useLoadingContext()

  const [data, setData] = useState([{}])
  const [populateSelectOptions, setPopulateSelectOptions] = useState({
    intervalTypes: [],
    repeatOnTypes: []
  })
  const [competenceScoreIntervalTypes, setCompetenceScoreIntervalTypes] =
    useState()
  const [oneToOneRepetitionIntervalTypes, setOneToOneRepetitionIntervalTypes] =
    useState()

  const [valueTab, setValueTab] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValueTab(newValue)
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    }
  }

  const getDataSelectOptions = useCallback(async () => {
    try {
      const resultIntervalTypes = await getIntervalType()
      const intervalTypes = Object.keys(resultIntervalTypes).map((key) => ({
        value: resultIntervalTypes[key],
        title: enumCycleSettingIntervalType[resultIntervalTypes[key]]
      }))

      const resultRepeatOnTypes = await getRepeatOnType()
      const repeatOnTypes = Object.keys(resultRepeatOnTypes).map((key) => ({
        value: resultRepeatOnTypes[key],
        title: enumCycleSettingRepeatOnType[resultRepeatOnTypes[key]]
      }))

      setPopulateSelectOptions({
        intervalTypes: intervalTypes,
        repeatOnTypes: repeatOnTypes
      })
    } catch (error) {
      console.error(error)
    }
  }, [])

  const getCycleSettingSearch = useCallback(
    async (value) => {
      try {
        onOpenLoading()
        const payload = {
          customerId: value
        }
        const response = await postCycleSettingSearch(payload)
        setData(response)
        setCompetenceScoreIntervalTypes(
          response.competenceScoreRepetition?.interval
        )
        setOneToOneRepetitionIntervalTypes(
          response.oneToOneRepetition?.interval
        )
      } catch (error) {
        console.error(error)
      } finally {
        onCloseLoading()
      }
    },
    [onCloseLoading, onOpenLoading]
  )

  useEffect(() => {
    getDataSelectOptions()
    currentCustomerId && getCycleSettingSearch(currentCustomerId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCustomerId])

  const onHandleSubmitUpdate = async (values) => {
    try {
      const payload = {
        id: data?.id,
        customerId: currentCustomerId,
        competenceScoreRepetition: {
          each: values.competenceScoreRepetitionEach,
          interval: values.competenceScoreRepetitionInterval,
          repeatOn: values.competenceScoreRepetitionRepeatOn,
          start: values.competenceScoreRepetitionStart
        },
        oneToOneRepetition: {
          each: values.oneToOneRepetitionEach,
          interval: values.oneToOneRepetitionInterval,
          repeatOn: values.oneToOneRepetitionRepeatOn,
          start: values.oneToOneRepetitionStart
        },
        oneToOneEmailNotification: values.oneToOneEmailNotification
      }
      onOpenLoading()
      await putCycleSetting(data?.id, payload)
      onCallAlert({
        type: 'success',
        message: notifications.evaluationCycles.success.editEvaluationCycles
      })
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      onCloseLoading()
    }
  }

  const onHandleSubmitCreate = async (values) => {
    try {
      const payload = {
        customerId: currentCustomerId,
        competenceScoreRepetition: {
          each: values.competenceScoreRepetitionEach,
          interval: values.competenceScoreRepetitionInterval,
          repeatOn: values.competenceScoreRepetitionRepeatOn,
          start: values.competenceScoreRepetitionStart
        },
        oneToOneRepetition: {
          each: values.oneToOneRepetitionEach,
          interval: values.oneToOneRepetitionInterval,
          repeatOn: values.oneToOneRepetitionRepeatOn,
          start: values.oneToOneRepetitionStart
        },
        oneToOneEmailNotification: values.oneToOneEmailNotification
      }
      onOpenLoading()
      await postCycleSetting(payload)
      onCallAlert({
        type: 'success',
        message: notifications.evaluationCycles.success.addNewEvaluationCycles
      })
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      onCloseLoading()
    }
  }

  return (
    <RoleBasedGuard hasContent roles={role}>
      <Helmet title="Ciclos de avaliação" />
      <Box className={globalClasses.breadcrumb}>
        <HeaderBreadcrumbs
          icon={<TodayOutlinedIcon />}
          links={[{ name: 'Ciclos de avaliação' }]}
        />
      </Box>
      <Box className={globalClasses.content}>
        <Form
          onSubmit={(values) =>
            data?.id
              ? onHandleSubmitUpdate(values)
              : onHandleSubmitCreate(values)
          }
          validate={evaluationCyclesValidation}
          initialValues={{
            competenceScoreRepetitionEach:
              data?.competenceScoreRepetition?.each || 1,
            competenceScoreRepetitionInterval:
              data?.competenceScoreRepetition?.interval || null,
            competenceScoreRepetitionRepeatOn:
              data?.competenceScoreRepetition?.repeatOn || null,
            competenceScoreRepetitionStart:
              data?.competenceScoreRepetition?.start || null,

            oneToOneRepetitionEach: data?.oneToOneRepetition?.each || 1,
            oneToOneRepetitionInterval:
              data?.oneToOneRepetition?.interval || null,
            oneToOneRepetitionRepeatOn:
              data?.oneToOneRepetition?.repeatOn || null,
            oneToOneRepetitionStart: data?.oneToOneRepetition?.start || null,

            oneToOneEmailNotification: data?.oneToOneEmailNotification || false
          }}
          render={({ handleSubmit, form, values, valid }) => (
            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
              <NewBoxWidget
                title="Ciclos de avaliação"
                subtitle="Defina de quanto em quanto tempo serão aplicadas as avaliações e cerimônias."
                actions={
                  <Button
                    label="Aplicar"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={!valid}
                  />
                }
                noBodyPadding
                height={550}
                width={550}
              >
                <form onSubmit={handleSubmit}>
                  <Grid container>
                    <Grid item xs={12}>
                      <StyledTabs
                        value={valueTab}
                        onChange={handleChange}
                        aria-label="cycle setting"
                        indicatorColor="secondary"
                        centered
                      >
                        <Tab
                          label="Avaliação de desempenho"
                          {...a11yProps(0)}
                        />
                        <Tab label="1:1" {...a11yProps(1)} />
                      </StyledTabs>
                      <TabPanel value={valueTab} index={0}>
                        <BoxHero
                          title="Repetir a cada:"
                          removeDivider
                          mb={3}
                          style={{ fontSize: 14, fontWeight: 400 }}
                        >
                          <Grid
                            container
                            item
                            style={{
                              alignItems: 'center',
                              direction: 'column'
                            }}
                            spacing={3}
                          >
                            <Grid item xs={5}>
                              <Input
                                label="Número de intervalos"
                                type="number"
                                placeholder="Número de intervalos"
                                name="competenceScoreRepetitionEach"
                              />
                            </Grid>
                            <Grid item xs={7}>
                              <Autocomplete
                                label="Período"
                                options={populateSelectOptions.intervalTypes}
                                placeholder="Período"
                                name="competenceScoreRepetitionInterval"
                                getOptionLabel={(option) => option.title || ''}
                                onChange={(value) =>
                                  setCompetenceScoreIntervalTypes(value)
                                }
                              />
                            </Grid>
                            <Grid item xs={5}>
                              <Date
                                label="Data de início*"
                                name="competenceScoreRepetitionStart"
                                disablePast
                              />
                            </Grid>
                            {competenceScoreIntervalTypes !== 2 && (
                              <Grid item xs={7}>
                                <Autocomplete
                                  label="Repetir"
                                  options={populateSelectOptions.repeatOnTypes}
                                  placeholder="Repetir"
                                  name="competenceScoreRepetitionRepeatOn"
                                  getOptionLabel={(option) =>
                                    option.title || ''
                                  }
                                />
                              </Grid>
                            )}
                          </Grid>
                        </BoxHero>
                      </TabPanel>
                      <TabPanel value={valueTab} index={1}>
                        <BoxHero
                          title="Repetir a cada:"
                          removeDivider
                          mb={3}
                          style={{ fontSize: 14, fontWeight: 400 }}
                        >
                          <Grid
                            container
                            item
                            style={{
                              alignItems: 'center',
                              direction: 'column'
                            }}
                            spacing={3}
                          >
                            <Grid item xs={5}>
                              <Input
                                label="Número de intervalos"
                                type="number"
                                placeholder="Número de intervalos"
                                name="oneToOneRepetitionEach"
                              />
                            </Grid>
                            <Grid item xs={7}>
                              <Autocomplete
                                label="Período"
                                options={populateSelectOptions.intervalTypes}
                                placeholder="Período"
                                name="oneToOneRepetitionInterval"
                                getOptionLabel={(option) => option.title || ''}
                                onChange={(value) =>
                                  setOneToOneRepetitionIntervalTypes(value)
                                }
                              />
                            </Grid>
                            <Grid item xs={5}>
                              <Date
                                label="Data de início*"
                                name="oneToOneRepetitionStart"
                                disablePast
                              />
                            </Grid>
                            {oneToOneRepetitionIntervalTypes !== 2 && (
                              <Grid item xs={7}>
                                <Autocomplete
                                  label="Repetir"
                                  options={populateSelectOptions.repeatOnTypes}
                                  placeholder="Repetir"
                                  name="oneToOneRepetitionRepeatOn"
                                  getOptionLabel={(option) =>
                                    option.title || ''
                                  }
                                />
                              </Grid>
                            )}

                            <Grid item xs={12}>
                              <FormControlLabel
                                control={
                                  <MuiCheckbox
                                    primaryColor={theme.palette.primary.main}
                                    name="oneToOneEmailNotification"
                                    inputProps={{
                                      'aria-label': 'controlled'
                                    }}
                                    color="default"
                                    checked={
                                      values?.oneToOneEmailNotification
                                        ? true
                                        : false
                                    }
                                    onChange={() =>
                                      form.change(
                                        'oneToOneEmailNotification',
                                        !values?.oneToOneEmailNotification
                                      )
                                    }
                                  />
                                }
                                label={
                                  <Typography
                                    variant="inherit"
                                    className={classes.formLabelActivation}
                                  >
                                    Notificar 1:1 aos usuários por e-mail
                                  </Typography>
                                }
                              />
                            </Grid>
                          </Grid>
                        </BoxHero>
                      </TabPanel>
                    </Grid>
                  </Grid>
                </form>
              </NewBoxWidget>
            </MuiPickersUtilsProvider>
          )}
        />
      </Box>
    </RoleBasedGuard>
  )
}

export default EvaluationCycles
