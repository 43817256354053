import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  containerCreate: {
    marginTop: '20px',
    padding: '0 20px',
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    columnGap: '15px'
  },
  wordCounter: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
    letterSpacing: theme.typography.pxToRem(0.15),
    marginTop: "10px"
  }
}))
