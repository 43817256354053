import React, { useCallback, useState, useEffect } from 'react'
import { useTheme } from '@material-ui/styles'
import { Button, Tooltip } from 'src/components'
import { DefaultStyledSlider } from './style'
import Cropper from 'react-easy-crop'
import makeStyles from './style'
import getCroppedImg from './Crop'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
export function UploadImageProfile({
  onConfirmImage,
  onCloseEdit,
  aspect,
  imageProfile
}) {
  const theme = useTheme()
  const classes = makeStyles()

  const [isSelectedImage, setIsSelectedImage] = useState(false)
  const [image, setImage] = useState(imageProfile)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [zoom, setZoom] = useState(1)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const onClose = useCallback(() => {
    setIsSelectedImage(false)
    setCroppedImage(null)
    setImage(null)
    onCloseEdit()
  }, [onCloseEdit])

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels)
      setCroppedImage(croppedImage)
      onConfirmImage(croppedImage)
      setIsSelectedImage(false)
      onCloseEdit()
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, image, onConfirmImage, onCloseEdit])

  const onHandleImageUpload = async (e) => {
    setImage(URL.createObjectURL(e.target.files[0]))
    setIsSelectedImage(true)
  }

  useEffect(() => {
    setImage(imageProfile)
  }, [imageProfile])

  return (
    <>
      <div className={classes.container}>
        {isSelectedImage ? (
          <>
            <div className={classes.containerCrop}>
              <Cropper
                image={image}
                crop={crop}
                zoom={zoom}
                aspect={aspect}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                showGrid={false}
                //cropShape="round" // circle
                style={{
                  containerStyle: {
                    backgroundColor: 'transparent'
                  }
                }}
              />
            </div>
            <div className={classes.controls}>
              <DefaultStyledSlider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                classes={{ root: classes.slider }}
                onChange={(e, zoom) => setZoom(zoom)}
              />
            </div>
          </>
        ) : (
          <div className={classes.containerCrop}>
            <Tooltip placement="top" title="Selecionar imagem">
              <label className={classes.boxUplod}>
                <input
                  type="file"
                  name="cover"
                  onChange={onHandleImageUpload}
                  accept="image/png, image/jpeg"
                  style={{ display: 'none' }}
                />
                {croppedImage ? (
                  <img
                    className={classes.imgCropped}
                    src={croppedImage}
                    alt="cropped"
                  />
                ) : image ? (
                  <img
                    className={classes.imgCropped}
                    src={image || croppedImage}
                    alt="cropped"
                  />
                ) : (
                  <AddAPhotoIcon
                    sx={{
                      height: 70,
                      width: 70,
                      display: 'flex',
                      alignItems: 'center',
                      color: theme.palette.primary.main
                    }}
                  />
                )}
              </label>
            </Tooltip>
          </div>
        )}
      </div>
      {isSelectedImage && (
        <div className={classes.controlButton}>
          <Button label="Cancelar" onClick={onClose} variant="dark" />
          <Button label="Confirmar" onClick={showCroppedImage} />
        </div>
      )}
    </>
  )
}
