import React from 'react'
import { useTheme } from '@material-ui/styles'
import { Box, Typography } from '@material-ui/core'
import { Inbox as InboxIcon } from '@material-ui/icons'

export default function TableNotFound() {
  const theme = useTheme()

  return (
    <Box
      style={{
        marginTop: 50
      }}
    >
      <InboxIcon
        style={{
          fontSize: 52,
          color: '#CFD2D3'
        }}
      />
      <Typography
        style={{
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: 18,
          color: theme.palette.primary.main
        }}
      >
        Nenhum registro
      </Typography>
      <Typography
        style={{
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: 18,
          color: '#787878'
        }}
      >
        Não temos registros no momento.
      </Typography>
    </Box>
  )
}
