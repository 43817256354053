import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useTheme } from '@material-ui/styles'
import {
  Input,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuList,
  Stack
} from '@mui/material'
import { IconButton, Typography, Box } from '@material-ui/core'
import {
  Group as GroupIcon,
  Close as CloseIcon,
  Search as SearchIcon
} from '@material-ui/icons'
import { Tooltip, Avatar, BadgeTooltip } from 'src/components'
import { enumCategoryNameColor } from 'src/@enum/metrics'
import { getImageNameColor } from 'src/utils'
import useInput from 'src/hooks/useInput'
import useStyles from './styles'

export function ChartFilterCompare({
  items,
  itemsSelected,
  onSelected,
  onSelectUsers,
  setSelectedUsers,
  category,
  ...props
}) {
  const theme = useTheme()
  const classes = useStyles({ props })
  const anchorRef = useRef(null)

  const [open, setOpen] = useState(false)
  const [isOpenSearch, setOpenSearch] = useState(false)
  const [listUserSelected, setUserSeleted] = useState([])

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
    setTimeout(() => {
      setOpenSearch(false)
    }, 500)
  }

  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }
    prevOpen.current = open
  }, [open])

  const { state: query, bindings } = useInput()

  const users = items?.filter(
    ({ name }) => !query || name.toLowerCase().includes(query.toLowerCase())
  )

  const handleSelectOption = useCallback(
    async (data) => {
      if (!listUserSelected.includes(data.id)) {
        await listUserSelected.push(data.id)
        onSelectUsers(data)
      } else {
        const index = listUserSelected.findIndex(function (item, i) {
          return item === data.id
        })
        await listUserSelected.splice(index, 1)
        onSelectUsers(data)
      }
      setUserSeleted(listUserSelected)
      setSelectedUsers(listUserSelected)
    },
    [listUserSelected, onSelectUsers, setSelectedUsers]
  )

  return (
    <Stack direction="row" spacing={2}>
      <div>
        {!open ? (
          <Tooltip placement="top" title="Comparar">
            <IconButton
              ref={anchorRef}
              id="button-open"
              aria-controls={open ? 'menu-open' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              className={classes.iconButton}
              onClick={handleToggle}
            >
              <GroupIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip placement="top" title="Fechar">
            <IconButton
              ref={anchorRef}
              id="menu-close"
              aria-controls={open ? 'menu-close' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              className={classes.iconCloseButton}
              onClick={handleToggle}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        )}
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-end"
          transition
          disablePortal
          sx={{ zIndex: 1 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom'
              }}
            >
              <Paper
                style={{
                  borderRadius: 6,
                  paddingLeft: theme.spacing(2),
                  paddingRight: theme.spacing(2),
                  minWidth: 200,
                  backgroundColor: '#F7F7FC',
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)'
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-open"
                    aria-labelledby="menu-open"
                  >
                    <Box display="flex" justifyContent="space-between">
                      <Box
                        display="flex"
                        alignItems="center"
                        className={classes.container}
                      >
                        {!isOpenSearch ? (
                          items?.map((data) => (
                            <BadgeTooltip
                              key={data.id}
                              placement="top"
                              disableFocusListener
                              disableTouchListener
                              user={data}
                              selected={listUserSelected.includes(data.id)}
                              borderColor={
                                listUserSelected.includes(data.id) &&
                                enumCategoryNameColor[
                                  category.toLocaleLowerCase()
                                ]
                              }
                              onClick={() => handleSelectOption(data)}
                            />
                          ))
                        ) : (
                          <Box className={classes.containerSearch}>
                            <Input
                              color="success"
                              id="search-collaborators"
                              placeholder="Busque outros colaboladores"
                              style={{
                                width: '100%',
                                height: 46
                              }}
                              {...bindings}
                            />
                            <Box
                              alignItems="center"
                              justifyContent="flex-start"
                              className={classes.containerSearchUser}
                            >
                              {query &&
                                users.map((data) => (
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    className={classes.containerAvatar}
                                    key={data.id}
                                    onClick={() => handleSelectOption(data)}
                                  >
                                    <Avatar
                                      src={data.profileImage}
                                      aria-label={data.name}
                                      alt={data.name}
                                      color={
                                        !data.profileImage &&
                                        getImageNameColor(data.name).color
                                      }
                                      borderColor={
                                        listUserSelected.includes(data.id) &&
                                        enumCategoryNameColor[
                                          category.toLocaleLowerCase()
                                        ]
                                      }
                                      className={classes.avatar}
                                    >
                                      {!data.profileImage &&
                                        getImageNameColor(data.name).name}
                                    </Avatar>
                                    <Typography className={classes.nameUser}>
                                      {data.name}
                                    </Typography>
                                  </Box>
                                ))}
                              {!users.length && (
                                <Typography
                                  className={classes.containerResultUser}
                                >
                                  Nenhum usuário encontrado!
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        )}
                      </Box>
                      {!isOpenSearch ? (
                        <IconButton
                          color="primary"
                          className={classes.iconSearchButton}
                          aria-owns="search"
                          aria-haspopup="true"
                          onClick={() => setOpenSearch(true)}
                        >
                          <SearchIcon />
                        </IconButton>
                      ) : (
                        <IconButton
                          color="primary"
                          className={classes.iconActiveSearchButton}
                          aria-owns="close-search"
                          aria-haspopup="true"
                          onClick={() => setOpenSearch(false)}
                        >
                          <CloseIcon />
                        </IconButton>
                      )}
                    </Box>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  )
}
