import { makeStyles, Checkbox, withStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  modalButtons: {
    display: 'flex',
    justifyContent: 'end',
    paddingTop: 15,
    height: 60,
    gap: 14
  },
  sectionInfo: {
    display: 'flex',
    flexDirection: 'column',
    width: 430,
    margin: 0,
    padding: '20px 0px 0px 0px',
    '& h3': {
      margin: 0
    },
    '& ul': {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      margin: '-5px 0px 10px 0px',
      padding: 0,
      '& li': {
        listStyle: 'none',
        display: 'flex',
        '& p': {
          display: 'flex',
          alignItems: 'center',
          marginRight: 15,
          marginLeft: 3
        }
      }
    }
  },
  wordCounter: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
    letterSpacing: theme.typography.pxToRem(0.15),
    marginTop: "10px",
    textAlign: "left",
  }
}))

export const MuiCheckbox = withStyles((theme) => ({
  root: {
    padding: '0px !important',
    color: theme.palette.primary.main,
    '&$checked': {
      color: theme.palette.primary.main
    }
  }
}))(Checkbox)
