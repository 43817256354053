import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  container: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: '#F7F7FC'
  },
  logotypeContainer: {
    width: '60%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '50%'
    },
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  logoImage: {
    width: '100%',
    objectFit: 'cover',
    margin: '0px',
    height: '100%'
  },
  formContainer: {
    width: '40%',
    height: 'calc(100vh - 100px)',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      overflow: 'visible'
    }
  },
  form: {
    width: 280
  },
  greeting: {
    fontWeight: 500,
    textAlign: 'center',
    color: '#14142B'
  },

  formDividerContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  formInfor: {
    textAlign: 'center',
    width: '100%',
    fontSize: '17px',
    paddingTop: 20,
    paddingBottom: 15,
    color: '#4E4B66'
  },

  formButtons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  forgetButton: {
    textTransform: 'none',
    fontWeight: 400
  },
  formInput: {
    marginTop: 10
  },
  formFields: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '25px'
  },
  labelRequired: {
    color: '#6E7191',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(15)
  }
}))
