import React, { useState, useCallback, useEffect } from 'react'
import useAuth from 'src/hooks/useAuth'
import RoleBasedGuard from 'src/guards/RoleBasedGuard'
import { useParams, useNavigate } from 'react-router-dom'
import { useTheme } from '@material-ui/styles'
import { Box, Typography, IconButton } from '@material-ui/core'
import {
  ArrowForwardIos as ArrowForwardIosIcon,
  Close as CloseIcon
} from '@material-ui/icons'
import {
  Avatar,
  Button,
  Tooltip,
  Modal,
  Helmet,
  NewBoxWidget,
  HeaderBreadcrumbs,
  Dot
} from 'src/components'
import { AssessmentOutlined as AssessmentOutlinedIcon } from '@material-ui/icons'
import { getUserById } from 'src/services/hooks/users/useUsers'
import { postDevelopmentPlan } from 'src/services/hooks/developmentPlan/useDevelopmentPlan'
import { getImageNameColor } from 'src/utils'
import { enumAssignment } from 'src/@enum/user'
import { useAlertContext } from 'src/context/AlertContext'
import { useLoadingContext } from 'src/context/LoadingContext'
import { messageResponse } from 'src/utils/messageResponse'
import clsx from 'clsx'
import BasicData from './Partials/BasicData'
import TargetsAndActivities from './Partials/TargetsAndActivities'
import GroupersAndSkills from './Partials/GroupersAndSkills'
import makeStyles from './style'
import useGlobalUseStyles from 'src/themes/globalUseStyles'

export function NewPDI() {
  const theme = useTheme()
  const classes = makeStyles()

  const globalClasses = useGlobalUseStyles()
  const navigate = useNavigate()

  const { notifications, onCallAlert } = useAlertContext()
  const { onCloseLoading, onOpenLoading } = useLoadingContext()

  const [role] = useState([2, 3, 4])
  const { id } = useParams()
  const { user } = useAuth()
  const currentUserId = user?.id
  const currentCustomerId = user?.customerId

  const [basicData, setBasicData] = useState(true)
  const [targetsAndActivities, setTargetsAndActivities] = useState()
  const [groupersAndSkills, setGroupersAndSkills] = useState()
  const [isOpenModal, setIsOpenModal] = useState(false)

  const [payloadBasicData, setPayloadBasicData] = useState()
  const [payloadTargets, setPayloadTargets] = useState([])
  const [payloadActivities, setPayloadActivities] = useState([])
  const [payloadCompetencies, setPayloadCompetencies] = useState([])
  const [payloadCompetenciesId, setPayloadCompetenciesId] = useState([])

  const [isValidTargets, setIsValidTargets] = useState(false)

  const [dataUser, setDataUser] = useState()

  function onClose() {
    setBasicData(false)
    setTargetsAndActivities(false)
    setGroupersAndSkills(false)
  }
  function nextOptionBasicData() {
    setTargetsAndActivities(true)
    setBasicData(false)
  }
  function nextOptionTargetsAndActivities() {
    setGroupersAndSkills(true)
    setTargetsAndActivities(false)
  }

  function onHandleBasicData() {
    setBasicData(!basicData)
  }
  function onHandleTargetsAndActivities() {
    setTargetsAndActivities(!targetsAndActivities)
  }
  function onHandleGroupersAndSkills() {
    setGroupersAndSkills(!groupersAndSkills)
  }

  const onHandleSubmit = async () => {
    try {
      onOpenLoading()
      const payload = {
        name: payloadBasicData?.name,
        description: payloadBasicData?.description,
        estimatedCompletion: payloadBasicData?.estimatedCompletion,
        status: 1,
        progress: 0,
        activities: payloadActivities,
        targets: payloadTargets,
        competencies: payloadCompetenciesId,
        fromUserId: currentUserId,
        toUserId: id,
        customerId: currentCustomerId,
        isTemplate: false,
        isActive: true
      }
      await postDevelopmentPlan(payload)
      onCallAlert({
        type: 'success',
        message: notifications.pdi.success.addNewPDI
      })
      navigate(`/metrics/user/${id}`)
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      onCloseLoading()
    }
  }

  const getDataUser = useCallback(async (id) => {
    try {
      onOpenLoading()
      const { data } = await getUserById(id)
      setDataUser(data?.data)
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      onCloseLoading()
    }
  }, [])

  useEffect(() => {
    id && getDataUser(id)
  }, [getDataUser, id])

  function handleMetricsUser(id) {
    navigate(`/metrics/user/${id}`)
  }

  const disabled =
    payloadBasicData && isValidTargets && payloadCompetenciesId.length

  return (
    <RoleBasedGuard hasContent roles={role}>
      <Modal
        isOpen={isOpenModal}
        onHandleClose={() => setIsOpenModal(false)}
        title="Salvar PDI?"
        subTitle="Você deseja salvar esse PDI como um modelo para ser usado posteriormente?"
      >
        <div className={classes.modalButtons}>
          <Button
            label="Não, obrigado"
            variant="dark"
            onClick={() => setIsOpenModal(false)}
          />
          <Button label="Salvar" onClick={() => setIsOpenModal(false)} />
        </div>
      </Modal>
      <Helmet title="Novo PDI" />
      <Box className={globalClasses.breadcrumb}>
        <HeaderBreadcrumbs
          icon={<AssessmentOutlinedIcon />}
          links={[
            {
              name: 'Métricas',
              href: '/metrics'
            },
            { name: dataUser?.name, href: `/metrics/user/${id}` },
            { name: 'Novo PDI' }
          ]}
        />
      </Box>
      <Box className={classes.container}>
        <Box className={classes.boxContent}>
          <NewBoxWidget
            title="Novo PDI"
            options={
              <Box className={classes.options}>
                <Tooltip placement="top" title="Fechar">
                  <IconButton onClick={() => handleMetricsUser(dataUser.id)}>
                    <CloseIcon htmlColor={theme.palette.primary.main} />
                  </IconButton>
                </Tooltip>
              </Box>
            }
            actions={
              <Button
                label="Criar PDI"
                disabled={!disabled}
                onClick={onHandleSubmit}
              />
            }
            width={400}
            height={500}
          >
            <Box className={classes.boxInforUser}>
              <Avatar
                src={dataUser?.imageProfile}
                aria-label={dataUser?.name}
                alt={dataUser?.name}
                color={
                  !dataUser?.imageProfile &&
                  getImageNameColor(dataUser?.name).color
                }
              >
                {getImageNameColor(dataUser?.name).name}
              </Avatar>
              <span>
                <strong>{dataUser?.name}</strong>
                <Typography className={classes.date}>
                  {dataUser?.occupationArea} -{' '}
                  {enumAssignment[dataUser?.assignment]}
                </Typography>
              </span>
            </Box>
            <Box className={classes.optionsList}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Dot
                  color={
                    payloadBasicData ? theme.palette.primary.main : '#E32929'
                  }
                  size="large"
                  style={{ marginRight: '-5px', zIndex: 1 }}
                />
                <button
                  className={clsx(
                    classes.option,
                    basicData ? classes.optionSelected : ''
                  )}
                  onClick={() => {
                    onHandleBasicData()
                    setGroupersAndSkills(false)
                    setTargetsAndActivities(false)
                  }}
                >
                  <p
                    className={
                      basicData
                        ? classes.optionTextSelected
                        : classes.optionText
                    }
                  >
                    Dados básicos
                  </p>
                  {basicData ? (
                    <CloseIcon htmlColor="#FFFFFF" />
                  ) : (
                    <ArrowForwardIosIcon htmlColor="#787878" />
                  )}
                </button>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Dot
                  color={
                    isValidTargets ? theme.palette.primary.main : '#E32929'
                  }
                  size="large"
                  style={{ marginRight: '-5px', zIndex: 1 }}
                />
                <button
                  className={clsx(
                    classes.option,
                    targetsAndActivities ? classes.optionSelected : ''
                  )}
                  onClick={() => {
                    onHandleTargetsAndActivities()
                    setGroupersAndSkills(false)
                    setBasicData(false)
                  }}
                >
                  <p
                    className={clsx(
                      targetsAndActivities
                        ? classes.optionTextSelected
                        : classes.optionText
                    )}
                  >
                    Tarefas e metas
                  </p>
                  {targetsAndActivities ? (
                    <CloseIcon htmlColor="#FFFFFF" />
                  ) : (
                    <ArrowForwardIosIcon htmlColor="#787878" />
                  )}
                </button>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Dot
                  color={
                    payloadCompetenciesId.length
                      ? theme.palette.primary.main
                      : '#E32929'
                  }
                  size="large"
                  style={{ marginRight: '-5px', zIndex: 1 }}
                />
                <button
                  className={clsx(
                    classes.option,
                    groupersAndSkills ? classes.optionSelected : ''
                  )}
                  onClick={() => {
                    onHandleGroupersAndSkills()
                    setTargetsAndActivities(false)
                    setBasicData(false)
                  }}
                >
                  <p
                    className={clsx(
                      groupersAndSkills
                        ? classes.optionTextSelected
                        : classes.optionText
                    )}
                  >
                    Agrupadores e competências
                  </p>
                  {groupersAndSkills ? (
                    <CloseIcon htmlColor="#FFFFFF" />
                  ) : (
                    <ArrowForwardIosIcon htmlColor="#787878" />
                  )}
                </button>
              </Box>
            </Box>
          </NewBoxWidget>
          {basicData && (
            <BasicData
              onClose={onClose}
              nextOptionBasicData={nextOptionBasicData}
              payloadBasicData={payloadBasicData}
              setPayloadBasicData={setPayloadBasicData}
            />
          )}
          {targetsAndActivities && (
            <TargetsAndActivities
              onClose={onClose}
              nextOptionTargetsAndActivities={nextOptionTargetsAndActivities}
              isValidForm={isValidTargets}
              onChangeValidForm={setIsValidTargets}
              payloadTargets={payloadTargets}
              setPayloadTargets={setPayloadTargets}
              payloadActivities={payloadActivities}
              setPayloadActivities={setPayloadActivities}
            />
          )}
          {groupersAndSkills && dataUser && (
            <GroupersAndSkills
              onClose={onClose}
              userId={id}
              userRole={dataUser?.occupation}
              userCustomer={dataUser?.customerId}
              payloadCompetencies={payloadCompetencies}
              setPayloadCompetencies={setPayloadCompetencies}
              payloadCompetenciesId={payloadCompetenciesId}
              setPayloadCompetenciesId={setPayloadCompetenciesId}
            />
          )}
        </Box>
      </Box>
    </RoleBasedGuard>
  )
}

export default NewPDI
