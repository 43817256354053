import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  boxActivities: {
    width: 320,
    maxHeight: 'calc(100vh - 70px)',
    backgroundColor: '#fff',
    borderRadius: 4,
    top: '70px'
  },
  titleTop: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '20px',
    height: '40px',
    fontSize: '14px',
    color: '#787878'
  },
  linkBottom: {
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    fontSize: '14px',
    color: '#787878',
    border: 'none',
    backgroundColor: '#fff',
    width: '100%',
    cursor: 'pointer',
    borderRadius: '0px 0px 8px 8px',
    justifyContent: 'center',
    transition: '0.5s',
    '&:hover': {
      color: theme.palette.primary.main
    }
  },

  scrollListNotificationActivities: {
    borderTop: 'solid 1px ',
    borderBottom: 'solid 1px ',
    borderColor: theme.palette.primary.main,
    transition: '0.5s',
    overflowY: 'scroll',
    overflowX: 'hidden',
    paddingTop: '10px',
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: '10px'
    }
  },

  listNotificationActivities: {
    padding: '0px 10px 0px 10px'
  },
  spaceBetweenBlocks: {
    height: '10px',
    display: 'block',
    width: '100%'
  },
  expandNotificationActive: {
    maxHeight: 'calc(100vh - 200px)'
  },
  expandNotificationDisabled: {
    maxHeight: '168px'
  },
  historyButtonActive: {
    display: 'flex'
  },
  historyButtonDisabled: {
    display: 'none'
  },
  buttonSeeMoreActive: {
    display: 'none'
  },
  buttonSeeMoreDisabled: {
    display: 'flex'
  }
}))
