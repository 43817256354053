import React from "react";
import makeStyles from './style'
import { Typography } from '@material-ui/core'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { ModalAddCompetences } from "../ModalAddCompetences";

export function InputCompetences({
  isOpen,
  onHandleModal,
  auxData,
  values,
  onChangeValue,
  customerId,
  setAuxData
}) {
  const customer = JSON.parse(localStorage.getItem('customer'))
  const classes = makeStyles({
    primaryColor: customer?.primaryColor
  })
  const RenderIconArrow = isOpen
  ? ArrowDropUpIcon
  : ArrowDropDownIcon
  return (
    <>
      <ModalAddCompetences
        isOpenModal={isOpen}
        onCloseModal={onHandleModal}
        dataCompetence={auxData.dataCompetence}
        valuesForm={values?.competences}
        customerId={customerId}
        onUpdateDataCompetence={(e) => setAuxData({
          ...auxData,
          dataCompetence: e
        })}
        onChangeValue={onChangeValue}
      />
      <button
        type="button"
        className={classes.competencesContainer}
        onClick={onHandleModal}
      >
        <Typography className={classes.competenceButtonText}>
          Adicionar competências*
        </Typography>
        <RenderIconArrow htmlColor="rgba(0, 0, 0, 0.54)" />
      </button>
    </>
);
}
