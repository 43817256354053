import PropTypes from 'prop-types'
import { BackgroundLogin } from 'src/assets'
import { NavbarAuth } from 'src/components'
import {
  StyledRoot,
  StyledSectionBg,
  StyledSection,
  StyledContent,
  StyledNavbarLogo
} from './styles'

LoginLayout.propTypes = {
  children: PropTypes.node
}

export default function LoginLayout({ children }) {
  return (
    <>
      <StyledNavbarLogo>
        <NavbarAuth />
      </StyledNavbarLogo>
      <StyledRoot>
        <StyledSection>
          <StyledSectionBg src={BackgroundLogin} alt="Growk Background" />
        </StyledSection>
        <StyledContent>{children}</StyledContent>
      </StyledRoot>
    </>
  )
}
