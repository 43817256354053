export const getGreeting = () => {
  const d = new Date()
  if (d.getHours() > 5 && d.getHours() < 12) {
    return 'Bom Dia,'
  } else if (d.getHours() >= 12 && d.getHours() <= 17) {
    return 'Boa tarde,'
  } else if (d.getHours() >= 18) {
    return 'Boa noite,'
  } else {
    return 'Bem-vindo,'
  }
}
