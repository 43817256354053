import React from 'react'
import { Box, Link } from '@mui/material'
import NewBreadcrumbs from 'src/components/NewBreadcrumbs'
export function HeaderBreadcrumbs({
  icon,
  links,
  action,
  heading,
  moreLink = '' || [],
  sx,
  ...other
}) {
  return (
    <Box sx={{ ...sx }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '12px 0px',
            '& svg': { width: 30, height: 30 },
            zIndex: 1,
            color: '#000',
            textDecoration: 'none',
            marginRight: 1
          }}
        >
          {icon}
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <NewBreadcrumbs links={links} {...other} />
        </Box>

        {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
      </Box>

      <Box>
        {typeof moreLink === 'string' ? (
          <Link href={moreLink} target="_blank" variant="body2">
            {moreLink}
          </Link>
        ) : (
          moreLink.map((href) => (
            <Link
              noWrap
              key={href}
              href={href}
              variant="body2"
              target="_blank"
              sx={{ display: 'table' }}
            >
              {href}
            </Link>
          ))
        )}
      </Box>
    </Box>
  )
}
