import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import makeStyles from './style'
import { Modal, ModalShelveCompetence } from 'src/components'
import { Box, Tab, Tabs } from '@mui/material'
import { TabContext, TabPanel } from '@material-ui/lab'
import { messageResponse } from 'src/utils/messageResponse'
import { TabViewCompetences } from './partials/TabViewCompetences'
import { TabArchivedCompetences } from './partials/TabArchivedCompetences'
import { TabNewCompetences } from './partials/TabNewCompetences'
import { useLoadingContext } from 'src/context/LoadingContext'
import { postCompetenceGrouperSearch } from 'src/services/hooks/competenceGrouper/useCompetenceGrouper'
import { useAlertContext } from 'src/context/AlertContext'
import {
  postCompetence,
  putCompetence
} from 'src/services/hooks/competence/useCompetence'

function RenderTabPanel({ children, valueTab }) {
  return (
    <TabPanel style={{ padding: '0px' }} value={valueTab} index={valueTab}>
      {children}
    </TabPanel>
  )
}

ModalAddCompetences.propTypes = {
  isOpenModal: PropTypes.bool,
  onCloseModal: PropTypes.func,
  dataCompetence: PropTypes.array,
  onUpdateDataCompetence: PropTypes.func,
  name: PropTypes.string,
  customerId: PropTypes.string
}

export function ModalAddCompetences({
  isOpenModal,
  onCloseModal,
  dataCompetence = [],
  valuesForm,
  onUpdateDataCompetence,
  customerId,
  onChangeValue
}) {
  const classes = makeStyles()
  const tabs = {
    ALLCOMPETENCES: 0,
    ADDED: 1,
    ARCHIVED: 2,
    NEWCOMPETENCES: 3
  }
  const [currentTab, setCurrentTab] = useState(tabs.ALLCOMPETENCES)
  const [groupsWithCompetence, setGroupsWithCompetence] = useState([])
  const [data, setData] = useState([])
  const [competenceSelectedToShelve, setCompetenceSelectedToShelve] = useState({
    id: '',
    name: ''
  })
  const [isOpenModalShelve, setIsOpenModalShelve] = useState(false)
  const [isOpenEditCompetences, setIsOpenEditCompetences] = useState(false)
  const isTabNewCompetence = currentTab === tabs.NEWCOMPETENCES
  const { onCloseLoading, onOpenLoading } = useLoadingContext()
  const { notifications, onCallAlert } = useAlertContext()
  const shouldShowModalCompetence = isOpenModal && !isOpenModalShelve

  useEffect(() => {
    setData(dataCompetence)
  }, [dataCompetence])

  const dataAllCompetences = (data ?? []).filter((elem) => elem.isActive)
  const dataAddedCompetences = (data ?? []).filter((elem) =>
    elem.isActive &&
    (valuesForm ?? []).includes(elem?.id)
  )
  const dataArchivedCompetences = data.filter((elem) => elem.isActive === false)

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue)
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    }
  }

  function onChangeCompetences({ idCompetence }) {
    const auxCompetences = valuesForm ?? []
    if ((auxCompetences ?? []).includes(idCompetence)) {
      const position = auxCompetences.indexOf(idCompetence)
      auxCompetences.splice(position, 1)
      return [...auxCompetences]
    }
    auxCompetences.push(idCompetence)
    return [...auxCompetences]
  }

  function onHandleShelve() {
    setIsOpenModalShelve(!isOpenModalShelve)
  }

  async function onHandleSelectToShelve({ id, labelName }) {
    try {
      const payload = {
        customerId,
        isActive: true,
        competenceId: id
      }
      onOpenLoading()
      const response = await postCompetenceGrouperSearch(payload)
      const getGroups = response.map((elem) => ({ name: elem.name }))
      setGroupsWithCompetence(getGroups)
      setCompetenceSelectedToShelve({
        id,
        name: labelName
      })
      onHandleShelve()
    } catch (error) {
      const messageError = messageResponse(error?.response)
      onCallAlert({
        type: 'error',
        message: messageError ?? notifications.global.error
      })
    } finally {
      onCloseLoading()
    }
  }

  async function onConfirmCompetenceSelected() {
    try {
      onOpenLoading()
      const payload = {
        id: competenceSelectedToShelve?.id,
        customerId,
        name: competenceSelectedToShelve?.name,
        isActive: false
      }
      await putCompetence(payload)
      const dataUpdated = data.map((item) =>
        item?.id === competenceSelectedToShelve?.id
          ? { ...item, isActive: false }
          : item
      )
      onUpdateDataCompetence(dataUpdated)
      onHandleShelve()
      onCallAlert({
        type: 'success',
        message: notifications.competences.success.archived
      })
    } catch (error) {
      const messageError = messageResponse(error?.response)
      onCallAlert({
        type: 'error',
        message: messageError ?? notifications.global.error
      })
    } finally {
      onCloseLoading()
    }
  }

  async function onUnarchiveCompetence(rowItem) {
    const { id, name } = rowItem
    try {
      const payload = {
        id,
        customerId,
        name,
        isActive: true
      }
      onOpenLoading()
      await putCompetence(payload)
      onCallAlert({
        type: 'success',
        message: notifications.competences.success.update
      })
      const dataUpdated = data.map((item) =>
        item?.id === payload?.id ? { ...item, isActive: true } : item
      )
      onUpdateDataCompetence(dataUpdated)
    } catch (error) {
      const messageError = messageResponse(error?.response)
      onCallAlert({
        type: 'error',
        message: messageError ?? notifications.global.error
      })
    } finally {
      onCloseLoading()
    }
  }

  async function onAddNewCompetence({
    nameCompetence,
    description,
    shouldBind
  }) {
    try {
      const payload = {
        customerId,
        name: nameCompetence,
        description,
        isActive: true
      }
      onOpenLoading()
      const result = await postCompetence(payload)
      onUpdateDataCompetence([...data, result?.data?.data])
      if (shouldBind) {
        onChangeCompetences({ idCompetence: result?.data?.data?.id })
      }
      setCurrentTab(tabs.ALLCOMPETENCES)
      onCallAlert({
        type: 'success',
        message: notifications.competences.success.addedNew
      })
    } catch (error) {
      const messageError = messageResponse(error?.response)
      onCallAlert({
        type: 'error',
        message: messageError ?? notifications.global.error
      })
    } finally {
      onCloseLoading()
    }
  }

  async function onEditCompetence({
    id,
    name,
    description
  }) {
    try {
      const payload = {
        id,
        customerId,
        name,
        description,
        isActive: true
      }
      onOpenLoading()
      await putCompetence(payload)
      const updated = dataCompetence.map((item) => item.id !== id ? item : ({
        ...item,
        name,
        description
      }))
      onUpdateDataCompetence(updated ?? [])
      onCallAlert({
        type: 'success',
        message: notifications.competences.success.update
      })
      setIsOpenEditCompetences(false)
    } catch (error) {
      const messageError = messageResponse(error?.response)
      onCallAlert({
        type: 'error',
        message: messageError ?? notifications.global.error
      })
    } finally {
      onCloseLoading()
    }
  }

  return (
    <TabContext value={currentTab}>
      <ModalShelveCompetence
        isOpen={isOpenModalShelve}
        onClose={onHandleShelve}
        name={competenceSelectedToShelve?.name}
        onConfirm={onConfirmCompetenceSelected}
        groupsWithCompetence={groupsWithCompetence}
      />
      <Modal
        isOpen={shouldShowModalCompetence}
        onHandleClose={onCloseModal}
        className={clsx(
          classes.modalContainer,
          !isTabNewCompetence && classes.tableWidth
        )}
        title="Competências"
        subTitle="Visualize e edite dados das competências"
      >
        <div className={classes.BoxContainer}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              padding: '0px',
              marginBottom: '0px !important',
              marginTop: '10px !important'
            }}
          >
            <Tabs
              value={currentTab}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Todas" {...a11yProps(tabs.ALLCOMPETENCES)} />
              <Tab label="Adicionadas" {...a11yProps(tabs.ADDED)} />
              <Tab label="Arquivadas" {...a11yProps(tabs.ARCHIVED)} />
              <Tab label="Nova" {...a11yProps(tabs.NEWCOMPETENCES)} />
            </Tabs>
          </Box>

          <RenderTabPanel valueTab={tabs.ALLCOMPETENCES}>
            <TabViewCompetences
              onEditCompetence={onEditCompetence}
              data={dataAllCompetences}
              valuesForm={valuesForm}
              onChangeCompetences={onChangeCompetences}
              onChangeValue={onChangeValue}
              onCloseModal={onCloseModal}
              onHandleSelectToShelve={onHandleSelectToShelve}
              isOpenEditCompetences={isOpenEditCompetences}
              setIsOpenEditCompetences={setIsOpenEditCompetences}
            />
          </RenderTabPanel>
          <RenderTabPanel valueTab={tabs.ADDED}>
            <TabViewCompetences
              onEditCompetence={onEditCompetence}
              data={dataAddedCompetences}
              valuesForm={valuesForm}
              onChangeCompetences={onChangeCompetences}
              onChangeValue={onChangeValue}
              onCloseModal={onCloseModal}
              onHandleSelectToShelve={onHandleSelectToShelve}
              isOpenEditCompetences={isOpenEditCompetences}
              setIsOpenEditCompetences={setIsOpenEditCompetences}
            />
          </RenderTabPanel>
          <RenderTabPanel valueTab={tabs.ARCHIVED}>
            <TabArchivedCompetences
              data={dataArchivedCompetences}
              onUnarchiveCompetence={onUnarchiveCompetence}
            />
          </RenderTabPanel>
          <RenderTabPanel valueTab={tabs.NEWCOMPETENCES}>
            <TabNewCompetences
              onAddNewCompetence={onAddNewCompetence}
              onCloseModal={onCloseModal}
            />
          </RenderTabPanel>
        </div>
      </Modal>
    </TabContext>
  )
}
