import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/styles'
import { Field } from 'react-final-form'
import { MuiSwitch } from './style'

export function Switch({
  name,
  label,
  placeholder,
  parse,
  rows,
  multiline,
  ...props
}) {
  const theme = useTheme()

  return (
    <Field
      primaryColor={theme.palette.primary.main}
      parse={parse}
      name={name ?? 'name'}
      type="checkbox"
      render={({ input }) => (
        <MuiSwitch
          primaryColor={theme.palette.primary.main}
          {...input}
          {...props}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      )}
    />
  )
}

Switch.propTypes = {
  name: PropTypes.string
}
