import React, { Fragment, useEffect } from 'react'
import { useTheme } from '@material-ui/styles'
import { Typography, Box, FormControlLabel } from '@material-ui/core'
import { Tooltip } from 'src/components'
import { enumStatus } from 'src/@enum/developmentPlan'
import moment from 'moment'
import useStyles, { MuiCheckbox } from './style'

export function CardExpectedEvoluction({
  data,
  payloadTargets,
  setPayloadTargets,
  payloadActivities,
  setPayloadActivities,
  disabledSave,
  isArchive
}) {
  const theme = useTheme()

  const statusColor = data?.status
  const classes = useStyles({
    statusColor,
    disabledSave
  })

  useEffect(() => {
    if (data) {
      const dataActivities = data?.activities.map((item) => item)
      const dataTargets = data?.targets.map((item) => item)
      setPayloadActivities(dataActivities)
      setPayloadTargets(dataTargets)
    }
  }, [
    data,
    data?.activities,
    data?.targets,
    setPayloadActivities,
    setPayloadTargets
  ])

  const handleSelectAction = (idx_activitie) => {
    let newObj = [...payloadActivities]
    if (newObj[idx_activitie].completed === false) {
      newObj[idx_activitie].completed = true
      return setPayloadActivities(newObj)
    }
    newObj[idx_activitie].completed = false
    return setPayloadActivities(newObj)
  }

  const handleSelectTargets = (idx_target, idx_activitie) => {
    let newObj = [...payloadTargets]
    if (newObj[idx_target].activities[idx_activitie].completed === false) {
      newObj[idx_target].activities[idx_activitie].completed = true
      return setPayloadTargets(newObj)
    }
    newObj[idx_target].activities[idx_activitie].completed = false
    setPayloadTargets(newObj)
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.boxEvolutionStatus}>
        <Box className={classes.statusOfEvolution}>
          <Box className={classes.headerBoxEvolutionStatus}>
            <Typography className={classes.titleBoxEvolutionStatus}>
              {data.name}
            </Typography>
            <Typography className={classes.descriptionBoxEvolutionStatus}>
              {data.description}
            </Typography>
          </Box>
          <Box
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              paddingLeft: 15
            }}
            className={classes.statusOfEvolution}
          >
            <Typography className={classes.statusOfEvolutionText}>
              {enumStatus[data.status]}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.optionList}>
          {payloadActivities?.map((item, idx_activitie) => (
            <Box
              key={item.createdAt}
              display="flex"
              alignItems="center"
              className={classes.optionsCheckList}
            >
              <FormControlLabel
                control={
                  <Tooltip
                    placement="top"
                    title={
                      !disabledSave
                        ? item.completed
                          ? 'Desmarcar'
                          : 'Marcar como feito'
                        : ''
                    }
                  >
                    <MuiCheckbox
                      value={item.description}
                      checked={item.completed}
                      style={{
                        color:
                          data?.status === 4
                            ? '#787878'
                            : data?.status === 6
                            ? '#E32929'
                            : theme.palette.primary.main
                      }}
                      onChange={() => handleSelectAction(idx_activitie)}
                      disabled={disabledSave}
                    />
                  </Tooltip>
                }
              />
              <Typography className={classes.descriptionCheckList}>
                {item.description}
              </Typography>
            </Box>
          ))}
          {payloadTargets?.map((item, idx_target) => (
            <Fragment key={idx_target}>
              <Box className={classes.titleOptionsList}>
                <Typography>{item.description}</Typography>
              </Box>
              {item.activities.map((item, idx_activitie) => (
                <Box
                  key={item.createdAt}
                  display="flex"
                  alignItems="center"
                  className={classes.optionsCheckList}
                >
                  <FormControlLabel
                    control={
                      <Tooltip
                        placement="top"
                        title={
                          !disabledSave
                            ? item.completed
                              ? 'Desmarcar'
                              : 'Marcar como feito'
                            : ''
                        }
                      >
                        <MuiCheckbox
                          value={item.description}
                          checked={item.completed}
                          style={{
                            color:
                              data?.status === 4
                                ? '#787878'
                                : data?.status === 6
                                ? '#E32929'
                                : theme.palette.primary.main
                          }}
                          onChange={() =>
                            handleSelectTargets(idx_target, idx_activitie)
                          }
                          disabled={disabledSave}
                        />
                      </Tooltip>
                    }
                  />
                  <Typography className={classes.descriptionCheckList}>
                    {item.description}
                  </Typography>
                </Box>
              ))}
            </Fragment>
          ))}
        </Box>
        <Box className={classes.footerStatusOfEvolution}>
          <Box>
            <Typography className={classes.footerStatusOfEvolutionTitle}>
              Criado em
            </Typography>
            <Typography>{moment(data.createAt).format('L')}</Typography>
          </Box>
          <Box>
            <Typography className={classes.footerStatusOfEvolutionTitle}>
              {isArchive ? 'Finalizado em' : 'Final previsto em'}
            </Typography>
            <Typography>
              {moment(data.estimatedCompletion).format('L')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
