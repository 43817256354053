import React, { useState, useCallback, useEffect } from 'react'
import { useTheme } from '@material-ui/styles'
import { Typography, Box } from '@material-ui/core'
import { useAlertContext } from 'src/context/AlertContext'
import { messageResponse } from 'src/utils/messageResponse'
import { enumCategory } from 'src/@enum/metrics'
import { postCompetenceGrouperSearch } from 'src/services/hooks/competenceGrouper/useCompetenceGrouper'
import { getCompetence } from 'src/services/hooks/competence/useCompetence'
import useStyles from './style'

export function ProgressChips({
  customerId,
  userRole,
  dataCompetencies,
  dataUserGroups,
  dataGrouperFromUser
}) {
  const theme = useTheme()
  const classes = useStyles()

  const { onCallAlert } = useAlertContext()

  const [isLoading, setIsLoading] = useState(false)

  const [data, setData] = useState()
  const [auxData, setAuxData] = useState({
    dataCompetence: []
  })

  const getData = useCallback(
    async (competencies, userGroups, grouperFromUser, role) => {
      try {
        setIsLoading(true)
        if (grouperFromUser) {
          const formatedData = Object.entries(enumCategory).map(
            (itemCategory) => {
              const idCategory = Number(itemCategory[0] ?? 0)
              const labelCategory = itemCategory[1]
              const getGrouperInCategory = grouperFromUser.filter(
                (itemCompetence) => itemCompetence.categoryId === idCategory
              )
              const groupers = getGrouperInCategory
                .map((itemGrouper) => itemGrouper.groupers)
                .flat()
              return {
                labelCategory,
                idCategory,
                grouper: groupers.map((itemGrouper) => ({
                  ...itemGrouper,
                  competences: competencies.filter((itemSelected) =>
                    itemGrouper.competences.includes(itemSelected)
                  )
                }))
              }
            }
          )

          const getIndividualGroups = formatedData.reduce(
            (initialValue, itemFormatedData) => {
              const isOnlyOneItem = itemFormatedData.grouper.length === 1
              if (isOnlyOneItem) {
                initialValue.push({
                  labelCategory: itemFormatedData.labelCategory,
                  idCategory: itemFormatedData.idCategory,
                  grouper: itemFormatedData.grouper[0]
                })
              } else {
                const individualGroups = itemFormatedData.grouper.map(
                  (itemGrouper) => ({
                    labelCategory: itemFormatedData.labelCategory,
                    idCategory: itemFormatedData.idCategory,
                    grouper: itemGrouper
                  })
                )
                initialValue.push(...individualGroups)
              }
              return initialValue
            },
            []
          )
          const getOnlyExistCompetences = getIndividualGroups.filter(
            (itemGrouperFilter) =>
              itemGrouperFilter.grouper.competences.length > 0
          )

          setData(getOnlyExistCompetences)
        } else {
          const payload = {
            customerId: customerId,
            userGroups: userGroups,
            isActive: true
          }
          const data = await postCompetenceGrouperSearch(payload)

          const formatedData = Object.entries(enumCategory).map(
            (itemCategory) => {
              const idCategory = Number(itemCategory[0] ?? 0)
              const labelCategory = itemCategory[1]
              const getGrouperInCategory = data.filter(
                (itemCompetence) => itemCompetence.category === idCategory
              )
              return {
                labelCategory,
                idCategory,
                grouper: getGrouperInCategory.map((itemGrouper) => ({
                  ...itemGrouper,
                  competences: competencies.filter((itemSelected) =>
                    itemGrouper.competences.includes(itemSelected)
                  )
                }))
              }
            }
          )

          const getIndividualGroups = formatedData.reduce(
            (initialValue, itemFormatedData) => {
              const isOnlyOneItem = itemFormatedData.grouper.length === 1
              if (isOnlyOneItem) {
                initialValue.push({
                  labelCategory: itemFormatedData.labelCategory,
                  idCategory: itemFormatedData.idCategory,
                  grouper: itemFormatedData.grouper[0]
                })
              } else {
                const individualGroups = itemFormatedData.grouper.map(
                  (itemGrouper) => ({
                    labelCategory: itemFormatedData.labelCategory,
                    idCategory: itemFormatedData.idCategory,
                    grouper: itemGrouper
                  })
                )
                initialValue.push(...individualGroups)
              }
              return initialValue
            },
            []
          )
          const getOnlyExistCompetences = getIndividualGroups.filter(
            (itemGrouperFilter) =>
              itemGrouperFilter.grouper.competences.length > 0
          )

          const enumFilterRole = {
            3: 2,
            4: 4
          }
          const dataGroupFilterRole = getOnlyExistCompetences.filter(
            (item) => item.idCategory !== enumFilterRole[role]
          )

          setData(dataGroupFilterRole)
        }

        setIsLoading(false)
      } catch (error) {
        onCallAlert({
          type: 'error',
          message: messageResponse(error?.response)
        })
        setIsLoading(false)
      }
    },
    []
  )

  const getDataCompetence = useCallback(async () => {
    try {
      const responseDataCompetence = await getCompetence()
      const { data } = responseDataCompetence?.data
      setAuxData({
        dataCompetence: data
      })
    } catch (error) {
      onCallAlert({
        type: 'error',
        message: messageResponse(error?.response)
      })
    }
  }, [])

  useEffect(() => {
    getDataCompetence()
  }, [getDataCompetence])

  useEffect(() => {
    getData(dataCompetencies, dataUserGroups, dataGrouperFromUser, userRole)
  }, [dataUserGroups, getData, dataCompetencies, dataGrouperFromUser, userRole])

  function getLabelAuxData({ where, value }) {
    const result = (value ?? [])?.map(
      (item) => where.filter((itemFilter) => itemFilter?.id === item)[0]?.name
    )
    const formatString = result.filter((elem) => elem)
    return formatString?.length > 0
      ? formatString.join(', ')
      : 'Sem item registrado'
  }

  return (
    <Box className={classes.container}>
      {!isLoading && (
        <>
          {data?.map(({ labelCategory, grouper }, idx) => (
            <Box
              display="flex"
              alignItems="center"
              className={classes.selectedCompetences}
              key={idx}
            >
              <Typography
                style={{
                  fontWeight: 'bold',
                  color: theme.palette.primary.main
                }}
              >
                {`${labelCategory} >`}
              </Typography>
              <Typography style={{ fontWeight: 'bold' }}>
                {`${grouper.name} > `}
              </Typography>
              <Box style={{ marginLeft: 5 }} />
              <Typography color={theme.palette.primary.main}>
                {getLabelAuxData({
                  where: auxData.dataCompetence,
                  value: grouper?.competences
                })}
              </Typography>
            </Box>
          ))}
        </>
      )}
    </Box>
  )
}
