import axios, { tokenAuthorization } from 'src/utils/axios'
import { REFRESH_TOKEN } from 'src/services/api'

export async function postOauthRefreshToken(body) {
  const token = tokenAuthorization().headers
  const parameters = {
    method: 'POST',
    headers: token
  }
  const response = await axios.post(REFRESH_TOKEN, body, parameters)
  const { data } = response?.data
  return data
}
